import React from 'react'

const EyeHidden = (props) => (
    <svg
        width="20px"
        height="14px"
        viewBox="0 0 20 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>Hidden</title>
        <g
            id="Hidden"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M10,1.5 C7.95768864,1.5 6.30428057,2.13189398 5.00753691,2.97477736 C2.84993733,4.37721709 1.67167642,6.35870995 1.32950051,7.00033032 C1.87606909,8.03052233 4.5616469,12.5 10,12.5 C12.0423114,12.5 13.6957194,11.868106 14.9924631,11.0252226 C17.1500627,9.62278291 18.3283236,7.64129005 18.6704995,6.99966968 C18.1239309,5.96947767 15.4383531,1.5 10,1.5 Z"
                id="Oval"
                stroke="#3A3A3A"
                fill="#FFFFFF"
            />
            <circle id="Oval" fill="#3A3A3A" cx="10" cy="7" r="4" />
            <polygon
                id="Path"
                fill="#FFFFFF"
                points="5.29736328 5.73974609 9.18505859 6.42797852 7.05761719 3"
            />
            <rect
                id="Rectangle"
                fill="#FFFFFF"
                transform="translate(10.185340, 7.388573) rotate(-321.000000) translate(-10.185340, -7.388573) "
                x="8.6853398"
                y="-0.111427019"
                width="3"
                height="15"
            />
            <rect
                id="Rectangle-Copy"
                fill="#3A3A3A"
                transform="translate(10.185340, 7.388573) rotate(-321.000000) translate(-10.185340, -7.388573) "
                x="9.6853398"
                y="-0.111427019"
                width="1"
                height="15"
            />
            <rect
                id="Rectangle"
                fill="#FFFFFF"
                transform="translate(10.185340, 7.388573) rotate(-321.000000) translate(-10.185340, -7.388573) "
                x="8.6853398"
                y="-0.111427019"
                width="3"
                height="15"
            />
            <rect
                id="Rectangle-Copy"
                fill="#3A3A3A"
                transform="translate(10.185340, 7.388573) rotate(-321.000000) translate(-10.185340, -7.388573) "
                x="9.6853398"
                y="-1.11142702"
                width="1"
                height="17"
            />
        </g>
    </svg>
)

export default EyeHidden
