import React from 'react'

const Star = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0,0,24,24" {...rest}>
        <path
            fill={fill}
            d="M12 18.148l7.21 4.352-1.913-8.202 6.37-5.518-8.389-.712L12 .333 8.722 8.068.333 8.78l6.37 5.518L4.79 22.5z"
        />
    </svg>
)

export default Star
