import { useEffect } from 'react'
import { searchLocationIcon } from '../MapMarker/MapMarker'
import { isInitialLocation } from '../../utils/utils'
import { DATA_LAYER_Z_INDEX } from '../../consts/consts'

const zIndex = DATA_LAYER_Z_INDEX.indexOf('searchMarker')

export const useSearchMarker = ({ hasMap, mapObj, lat, lng, type }) => {
    // Draw radius circle on the map
    // Set a search location marker
    useEffect(() => {
        let searchMarker

        // Add marker for epicentre
        if (
            hasMap &&
            !window.siteSelector &&
            !window.screenshot &&
            !isInitialLocation(lat, lng)
        ) {
            searchMarker = new google.maps.Marker({
                map: mapObj.current,
                position: new google.maps.LatLng(lat, lng),
                title:
                    type === 'pinpoint'
                        ? 'Your pinpointed location'
                        : type === 'geolocation'
                        ? 'Your geolocation'
                        : 'Your search location',
                icon: searchLocationIcon(),
                zIndex,
            })
        }

        return () => {
            if (searchMarker) {
                searchMarker.setMap(null)
                searchMarker = null
            }
        }
    }, [hasMap, mapObj, lat, lng, type])
}
