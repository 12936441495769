import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import MobileDetect from 'mobile-detect'
import classnames from 'classnames/bind'
import css from './InputSelect.module.scss'

import { defaultStyles, slimStyles } from './reactSelectStyles'
import { DropdownIndicator, ClearIndicator } from './Components'
import { useMedia } from '../../hooks/hooks'

const styles = classnames.bind(css)

const InputSelect = ({
    value,
    options,
    handleChange,
    className,
    slim,
    ...rest
}) => {
    let selectStyles = defaultStyles()

    if (slim) {
        selectStyles = { ...selectStyles, ...slimStyles() }
    }

    return (
        <div className={styles('input-select', className)}>
            <NativeSelect
                value={value}
                options={options}
                handleChange={handleChange}
            />
            <Select
                value={value}
                options={options}
                components={{ DropdownIndicator, ClearIndicator }}
                styles={selectStyles}
                onChange={handleChange}
                {...rest}
            />
        </div>
    )
}

const mb = new MobileDetect(window.navigator.userAgent)

// Fall back to native select widget on mobile/tablet
export const NativeSelect = ({ value, options, handleChange }) => {
    const optgroupsOnly = options.some(({ options }) => !!options)
    if (optgroupsOnly) {
        if (!options.every(({ options }) => !!options)) {
            throw Error(
                "NativeSelect doesn't support mixed options and optgroups",
            )
        }
    }
    const possibleOptions = optgroupsOnly
        ? options
              .filter((option) => !!option.options)
              .map((option) => option.options)
              .flat()
        : options

    // max-device-width should match most but not all tablets, based on https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
    // The width is capped at <720p, so it shouldn't match any touch capable desktop screens.
    // Also restrict to touch capable devices: https://medium.com/@ferie/detect-a-touch-device-with-only-css-9f8e30fa1134
    // But this won't match higher resolution tablets, so we fall back to the UA string then.
    const isSmallScreen = useMedia('(max-device-width: 1279px)')
    const isTouch = useMedia('(hover: none) and (pointer: coarse)')
    const isMobileOrTablet =
        isTouch && (isSmallScreen || mb.mobile() || mb.tablet())

    const onChange = (event) => {
        const selectedOption = possibleOptions[event.target.selectedIndex]
        handleChange(selectedOption)
    }

    const renderOption = (option, i) => {
        // optgroup
        if (option.options) {
            return (
                <optgroup label={option.label} key={i}>
                    {option.options.map(renderOption)}
                </optgroup>
            )
        }

        // single option
        return (
            <option value={option?.value} key={i}>
                {option.label}
            </option>
        )
    }

    const select = (
        <select
            value={value?.value || ''}
            onChange={onChange}
            className={styles('input-select--native')}
        >
            {options.map(renderOption)}
        </select>
    )

    return isMobileOrTablet ? select : null
}

InputSelect.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default InputSelect
