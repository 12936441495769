import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    show: false,
}

const ogsSlice = createSlice({
    name: 'ogs',
    initialState,
    reducers: {
        resetOgs: () => initialState,
        replaceOgs: (state, { payload }) => ({
            ...payload,
        }),
        setOgsLoading: (state, { payload }) => {
            state.loading = payload
        },
    },
})

const { actions, reducer } = ogsSlice

export const { resetOgs, replaceOgs, setOgsLoading } = actions

export default reducer
