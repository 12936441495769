import React from 'react'

const FF = ({ ...rest }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" {...rest}>
        <path fill="#5AB947" d="M3 0L0 9.9 8 16l7.9-6.1-3-9.9z" />
        <path
            fill="#FEFEFE"
            d="M10.6 6.4H9.4V4.1h1.2l.3-.9H8.4v7.4h1V7.3h.9zM7.5 6.4H6.3V4.1h1.2l.3-.9H5.3v7.4h1V7.3h.9z"
        />
    </svg>
)

export default FF
