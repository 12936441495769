import React from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames/bind'
import Heading from '../Heading/Heading'
import CategoryList from '../CategoryList/CategoryList'
import ListCta from '../ListCta/ListCta'
import { Step } from '../MultiStep/MultiStep'

import { replaceOverlay } from '../../store/Overlay/Overlay'
import { LABELS, OVERLAY_DEFAULTS } from '../../consts/consts'
import css from '../Selector/Selector.module.scss'

const styles = classnames.bind(css)

const OverlaySelector = ({ config, closeModal }) => {
    const dispatch = useDispatch()

    const selectOverlay = (c) => () => {
        const defaults = OVERLAY_DEFAULTS[c.value]
        const defaultOverlay = c.options.find((o) => o.level === defaults.level)
        dispatch(
            replaceOverlay({
                level: defaultOverlay.level,
                label: LABELS.overlay[c.value],
                value: defaultOverlay.options
                    ? defaultOverlay.options.find(
                          (o) => o.value === defaults.value,
                      ).value
                    : defaults.value,
                optgroup: defaultOverlay.optgroup,
                year: defaultOverlay.year,
            }),
        )
        closeModal()
    }

    return (
        <div className={styles('selector')}>
            <Step headingText="Add an overlay" aria-live="polite">
                <div className={styles('selector__row')}>
                    <Heading
                        heading="h3"
                        text="Choose an overlay type"
                        isSubHeading
                    />
                    <CategoryList>
                        {config.map((c) => (
                            <ListCta
                                key={c.value}
                                text={LABELS.overlay[c.value]}
                                handleClick={selectOverlay(c)}
                                chevron
                            />
                        ))}
                    </CategoryList>
                </div>
            </Step>
        </div>
    )
}

export default OverlaySelector
