import { createSlice } from '@reduxjs/toolkit'

import { fetchSites } from '../Sites/Sites'

const initialState = {
    nonOperational: false,
    nonFootball: false,
    hideAll: false,
}

const optionsSlice = createSlice({
    name: 'mapOptions',
    initialState,
    reducers: {
        resetMapOptions: () => initialState,
        replaceMapOptions: (state, { payload }) => payload,
        updateOption: (state, { payload }) => {
            return { ...state, ...payload }
        },
    },
})

const { actions, reducer } = optionsSlice

// Use applyFilter and unapplyFilter to update sites at the same time
export const { resetMapOptions, replaceMapOptions, updateOption } = actions

/**
 * Updates the options in state,
 * then dispatches action to fetch sites
 */
export const applyOption = (payload) => (dispatch) => {
    dispatch(actions.updateOption(payload))
    const fetchIfOneOf = ['nonOperational', 'nonFootball', 'hideAll']
    if (Object.keys(payload).some((k) => fetchIfOneOf.includes(k))) {
        dispatch(fetchSites())
    }
}

export default reducer
