import React from 'react'
import PropTypes from 'prop-types'

const Download = ({ fill, ...rest }) => (
    <svg width="14" height="17" viewBox="0 0 14 17" {...rest}>
        <path d="M14 6h-4V0H4v6H0l7 7 7-7zM0 15v2h14v-2H0z" fill={fill} />
    </svg>
)

Download.defaultProps = {
    fill: null,
}

Download.propTypes = {
    fill: PropTypes.string,
}

export default Download
