import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames/bind'

import { useOnClickOutside } from '../../hooks/hooks'

import css from './DropdownNav.module.scss'

const styles = classnames.bind(css)

const DropdownNav = ({
    buttonIcon,
    menuName,
    alignLeft,
    children,
    className,
}) => {
    const [open, setOpen] = useState(false)

    const ref = useRef()
    useOnClickOutside(ref, () => setOpen(false))

    // cancel delayed close if it it was opened again in the meanwhile
    const timeoutRef = useRef()
    if (open) {
        clearTimeout(timeoutRef.current)
    }

    return (
        <div
            ref={ref}
            className={styles('dropdown-nav', className)}
            onMouseEnter={() => {
                setOpen(true)
            }}
            onMouseLeave={() => {
                timeoutRef.current = setTimeout(() => {
                    setOpen(false)
                }, 500)
            }}
        >
            <button
                type="button"
                className={styles('dropdown-nav__button', {
                    'dropdown-nav__button--open': open,
                })}
                tabIndex={0}
                onFocus={() => {
                    setOpen(true)
                }}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                <span className="visually-hidden">
                    {open ? `Close ${menuName}` : `Open ${menuName}`}
                </span>{' '}
                {buttonIcon}
            </button>
            {open && (
                <div
                    className={styles('dropdown-nav__menu', {
                        'dropdown-nav__menu--open': open,
                        'dropdown-nav__menu--left': alignLeft,
                    })}
                    aria-live="polite"
                >
                    <span className="visually-hidden" role="status">
                        {menuName} is open
                    </span>
                    {children}
                </div>
            )}
        </div>
    )
}

DropdownNav.defaultProps = {
    className: null,
}

DropdownNav.propTypes = {
    buttonIcon: PropTypes.node.isRequired,
    menuName: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default DropdownNav
