import { createAxiosClient, post } from '../Services'
import { throttleDebouncePromise } from '../../utils/utils'
import { urls } from '../../urls'
import { GetSearchAutocompleteError } from '../../utils/errors'

export const client = createAxiosClient()

const url = urls.api.main_search_autocomplete()

const getBoundsParam = (bounds) => {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    return `${ne.lat()},${ne.lng()},${sw.lat()},${sw.lng()}`
}

export const getSearchAutocomplete = (options) => (query) => {
    const data = {
        ...options,
        query,
    }

    if (window.map) {
        const bounds = window.map.getBounds()
        if (bounds) {
            data.bounds = getBoundsParam(bounds)
        }
    }

    return post({ url, client, data })
        .then(({ data }) => data)
        .catch(() => Promise.reject(new GetSearchAutocompleteError(query)))
}

export const getSearchAutocompleteDebounced = (options) =>
    throttleDebouncePromise(getSearchAutocomplete(options), {
        debounce: 300,
        cache: true,
        immediateIfCached: true,
    })
