import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    list: [], // list of stringified JSON objects
}

const customGeojsonSlice = createSlice({
    name: 'customGeojson',
    initialState,
    reducers: {
        resetCustomGeojson: () => initialState,
        replaceCustomGeojson: (state, { payload }) => payload,
        addCustomGeojson: (state, { payload: geojsonString }) => {
            state.list.push(geojsonString)
        },
        removeCustomGeojson: (state, { payload: index }) => {
            state.list.splice(index, 1)
        },
    },
})

const { actions, reducer } = customGeojsonSlice

export const {
    resetCustomGeojson,
    replaceCustomGeojson,
    addCustomGeojson,
    removeCustomGeojson,
} = actions

export default reducer
