import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames/bind'
import { useAlert } from '../../hooks/hooks'

import {
    ACTIVE_MODAL_RENAME_MAP,
    SAVED_MAP_MAX_LENGTH,
} from '../../consts/consts'

import Modal, { useModal } from '../Modal/Modal'
import Close from '../Close/Close'
import FormStep from '../FormStep/FormStep'
import Button from '../Button/Button'
import Heading from '../Heading/Heading'
import InputText from '../InputText/InputText'
import { createOrUpdateSavedMap } from '../../store/SavedMap/SavedMap'

import css from './SavedMapModalRename.module.scss'

const styles = classnames.bind(css)

const SavedMapModalRename = () => {
    const { activeModal, closeModal } = useModal()
    const dispatch = useDispatch()
    const alert = useAlert()

    const slug = useSelector(({ savedMap: { slug } }) => slug)
    const isSavedMap = Boolean(slug)

    const [label, setLabel] = useState(slug || '')

    useEffect(() => {
        setLabel(slug || '')
    }, [slug, activeModal]) // the activeModal dep is needed to reset value if the modal was cancelled

    const title = 'Update saved map'

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(createOrUpdateSavedMap(label, alert))
        closeModal()
    }

    return (
        <>
            {activeModal === ACTIVE_MODAL_RENAME_MAP && (
                <Modal titleText={title}>
                    <FormStep className={styles('save-search')}>
                        <form onSubmit={handleSubmit}>
                            <div className="visually-hidden" role="status">
                                {title}
                            </div>
                            <Heading heading="h2" text={title} />
                            {/* <p className={styles('save-search__description')}></p> */}
                            <div className={styles('save-search__row--full')}>
                                <div>
                                    <Heading
                                        heading="h4"
                                        text="Change saved map title (optional)"
                                        isSubHeading
                                    />
                                    <InputText
                                        type="text"
                                        value={label}
                                        placeholder="New name"
                                        handleChange={(e) =>
                                            setLabel(e.target.value)
                                        }
                                        aria-labelledby="search_name"
                                        required={isSavedMap}
                                        maxLength={SAVED_MAP_MAX_LENGTH}
                                    />
                                    <span
                                        id="search_name"
                                        className="visually-hidden"
                                    >
                                        Enter a name
                                    </span>
                                </div>
                            </div>
                            <div>
                                <Button
                                    focused
                                    type="submit"
                                    text={slug ? 'Update' : 'Save'}
                                    isRounded
                                    disabled={!slug}
                                    required
                                />
                                <Button
                                    text="Cancel"
                                    handleClick={closeModal}
                                    isCancel
                                />
                            </div>
                        </form>
                    </FormStep>
                    <Close handleClick={closeModal} />
                </Modal>
            )}
        </>
    )
}

export default SavedMapModalRename
