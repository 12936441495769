import React from 'react'
import PropTypes from 'prop-types'

const Overlay = ({ fill, ...rest }) => (
    <svg width="18" height="19" viewBox="0,0,18,19" {...rest}>
        <path
            fill={fill}
            d="M9 16.5l-7.4-5.7L0 12.1l9 7 9-7-1.6-1.3L9 16.5zM9 14l7.4-5.7L18 7 9 0 0 7l1.6 1.3L9 14z"
        />
    </svg>
)

Overlay.defaultProps = {
    fill: null,
}

Overlay.propTypes = {
    fill: PropTypes.string,
}

export default Overlay
