import React from 'react'
import classnames from 'classnames/bind'
import css from './SiteInfo.module.scss'
import { SITE_INFO_TOOLTIP_RATING } from '../../consts/consts'
import Bar from '../Bar/Bar'
import Boxout from '../Boxout/Boxout'

const styles = classnames.bind(css)

const SiteInfoRatings = ({ ratings }) => {
    const blocks = ratings
        .filter(
            ({ rating, pamRating }) => rating !== null || pamRating !== null,
        )
        .map(({ identifier, sectionName, pamRating, rating }) => (
            <Boxout
                key={identifier}
                title={sectionName}
                tooltipText={SITE_INFO_TOOLTIP_RATING[identifier] || null}
                className={styles(
                    'site-information__boxout',
                    'site-information__statistic',
                )}
            >
                <Bar heading="Target" maxValue={4} value={pamRating} />
                <Bar
                    heading="Actual"
                    maxValue={4}
                    value={rating ? rating.score : '-'}
                />
            </Boxout>
        ))

    return (
        <div className={styles('site-information__row')}>
            <div className={styles('site-information__statistics')}>
                {blocks}
            </div>
        </div>
    )
}

export default SiteInfoRatings
