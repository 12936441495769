import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { useSelector } from 'react-redux'
import classnames from 'classnames/bind'
import { UiContext } from '../../context/UiContext'
import { urls } from '../../urls'

import SiteCard from '../SiteCard/SiteCard'
import Heading from '../Heading/Heading'
import Loading from '../Loading/Loading'

import css from './ResultsList.module.scss'

const styles = classnames.bind(css)

const ResultsList = ({ className }) => {
    const sites = useSelector(({ sites }) => sites.results)
    const hasResults = useSelector(({ sites }) => !!sites.results.length)
    const loading = useSelector(({ sites }) => sites.loading)
    const { numWithinBounds, numMatching } = useSelector(
        ({ sites }) => sites.stats.sites,
    )

    const { setActiveSiteId, setShowSidebar, isTablet, loggedIn } = useContext(
        UiContext,
    )

    if (loading) {
        return <Loading />
    }
    if (!hasResults) {
        return <Heading text="No results" />
    }

    return (
        <div className={styles('results-list', className)}>
            <h2 className={styles('results-list__heading')}>
                {numWithinBounds !== numMatching ? (
                    <>
                        {parseInt(numWithinBounds, 10).toLocaleString()} sites
                        in view (out of{' '}
                        {parseInt(numMatching, 10).toLocaleString()})
                    </>
                ) : (
                    `${parseInt(numMatching, 10).toLocaleString()} sites`
                )}
            </h2>
            {numWithinBounds &&
                sites.filter(({ cluster }) => !cluster).length === 0 &&
                'Zoom in to see individual sites listed here'}
            {sites
                .filter(({ cluster }) => !cluster)
                .map((site) => (
                    <SiteCard
                        key={site.id}
                        site={site}
                        className={styles('results-list__site-card')}
                        tabIndex="0"
                        loggedIn={loggedIn}
                        onClick={() => {
                            setActiveSiteId(site.id)

                            if (isTablet) {
                                setShowSidebar(false)
                                navigate(urls.site(site.id))
                            }
                        }}
                        onKeyPress={() => {
                            setActiveSiteId(site.id)
                            setShowSidebar(false)
                            navigate(urls.site(site.id))
                        }}
                    />
                ))}
        </div>
    )
}

ResultsList.propTypes = {
    className: PropTypes.string,
}

export default ResultsList
