import { colors, fonts } from '../../consts/styleVariables'

// prettier-ignore
const baseOptionStyles = (base, state) => ({
    ...base,
    padding: '10px 32px',
    fontSize: '13px',
    lineHeight: '16px',
    color: state.isSelected ? colors.white : colors.green,
    cursor: 'pointer',
    backgroundColor: state.isSelected ? colors.greenTwo : state.isFocused ? colors.greenFour : '',
    '&:active': {
        color: colors.white,
        backgroundColor: colors.greenTwo,
    },
    '&:hover': {
        color: colors.white,
        backgroundColor: colors.greenTwo,
    },
})

// prettier-ignore
export const defaultStyles = () => ({
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    control: (base) => ({
        ...base,
        fontSize: '13px',
        color: colors.grey,
        border: `1px solid ${colors.greyThree}`,
        cursor: 'pointer',
        padding: '6px 8px',
        boxShadow: 'none',

        // Hover not available from state
        '&:hover': {
            borderColor: colors.grey,
        },
    }),
    menu: (base) => ({
        ...base,
        width: '100%',
        padding: 0,
        marginTop: '-1px',
        marginBottom: 0,
        border: `1px solid ${colors.greyThree}`,
        borderRadius: '3px',
        backgroundColor: colors.white,
        boxShadow: '1px 3px 4px 0 rgba(0,0,0,0.3)',
        cursor: 'pointer',
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: () => ({
        alignSelf: 'stretch',
        backgroundColor: colors.greyThree,
        marginBottom: 2,
        marginRight: 6,
        marginTop: 2,
        width: 1,
    }),
    option: (base, state) => ({
        ...baseOptionStyles(base, state)
    }),
    multiValue: (base) => ({
        ...base,
        color: colors.grey,
        backgroundColor: colors.white,
        border: `1px solid ${colors.greyThree}`,
        borderRadius: '2px',
        margin: '0 2px 0 0',
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: colors.grey,
        paddingLeft: '10px',
        fontSize: '14px',
    }),
    multiValueRemove: (base) => ({
        ...base,
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: colors.red,
        '&:hover': {
            backgroundColor: 'transparent',
            color: colors.red,
            opacity: '0.8',
        },
    }),
    multiValueContainer: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
    }),
    placeholder: (base) => ({
        ...base,
        color: colors.greyTwo,
        fontSize: '16px',
    }),
    group: () => ({
        padding: 0,
    }),
    groupHeading: () => ({
        fontFamily: fonts.heading,
        textTransform: 'uppercase',
        padding: '15px 32px 10px 32px',
        borderBottom: `1px solid ${colors.greyThree}`,
    }),
    singleValue: (base) => ({
        ...base,
        overflow: 'visible',
    }),
})

// prettier-ignore
export const slimStyles = () => ({
    indicatorSeparator: () => ({
        alignSelf: 'stretch',
        backgroundColor: colors.greyThree,
        marginBottom: 5,
        marginRight: 4,
        marginTop: 5,
        width: 1,
    }),
    control: (base) => ({
        ...base,
        fontSize: '13px',
        border: `1px solid ${colors.greyThree}`,
        color: colors.grey,
        cursor: 'pointer',
        padding: '0',
        boxShadow: 'none',
        minHeight: '13px',

        // Hover not available from state
        '&:hover': {
            borderColor: colors.grey,
        },
    }),
    placeholder: (base) => ({
        ...base,
        color: colors.greyTwo,
        fontWeight: 600,
        fontSize: '16px',
    }),
    menu: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    option: (base, state) => ({
        ...baseOptionStyles(base, state),
        padding: '10px',
    }),
})

// For the colour picker dropdown in ColorValuesSelect
// prettier-ignore
const dot = (color) => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: '10px',
        content: '" "',
        display: 'block',
        marginRight: '15px',
        height: '10px',
        width: '10px',
    },
})

// prettier-ignore
export const reactColorSelectStyles = () => ({
    option: (base, { data, isFocused, isSelected }) => {
        let backgroundColor = 'transparent'
        let color = colors.green
        if (isSelected) {
            backgroundColor = '#F5F5F5'
            color = colors.green
        } else if (isFocused) {
            backgroundColor = colors.greenTwo
            color = colors.white
        }

        return {
            ...base,
            ...dot(data?.color),
            padding: '10px 18px',
            fontSize: '13px',
            lineHeight: '16px',
            color,
            cursor: 'pointer',
            backgroundColor,

            // Hover not available from state
            '&:hover': {
                color: colors.white,
                backgroundColor: colors.greenTwo,
            },
        }
    },
    input: (base) => ({ ...base, ...dot() }),
    placeholder: (base, { data }) => ({
        ...base,
        ...dot(data?.color),
        color: colors.greyTwo,
        fontSize: '16px',
    }),
    singleValue: (base, { data }) => ({
        ...base,
        ...dot(data?.color),
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    control: (base) => ({
        ...base,
        fontSize: '13px',
        color: colors.grey,
        border: `1px solid ${colors.greyThree}`,
        cursor: 'pointer',
        padding: '6px 8px',
        boxShadow: 'none',

        // Hover not available from state
        '&:hover': {
            borderColor: colors.grey,
        },
    }),
    menu: (base) => ({
        ...base,
        width: '100%',
        padding: 0,
        marginTop: '-1px',
        marginBottom: 0,
        border: `1px solid ${colors.greyThree}`,
        borderRadius: '3px',
        backgroundColor: colors.white,
        boxShadow: '1px 3px 4px 0 rgba(0,0,0,0.3)',
        cursor: 'pointer',
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
})

// prettier-ignore
export const customStyles = {
    moreResults: {
        padding: '10px 32px',
        fontSize: '12px',
        lineHeight: '16px',
        color: colors.green,
        cursor: 'default',
        backgroundColor: 'trasparent',
    },
}
