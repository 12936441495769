import React from 'react'
import PropTypes from 'prop-types'

import ReactTooltip from 'react-tooltip'

import classnames from 'classnames/bind'
import InfoIcon from '../Icons/Info'
import css from './Tooltip.module.scss'

const styles = classnames.bind(css)

const Tooltip = ({ content, className }) => {
    return (
        <>
            <ReactTooltip />
            <p
                aria-label={content}
                data-tip={content}
                className={styles('tooltip__text-box', className)}
            >
                <InfoIcon />
            </p>
        </>
    )
}

Tooltip.defaultProps = {
    className: null,
}

Tooltip.propTypes = {
    content: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default Tooltip
