/* eslint-disable no-console */
import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GeoJSONOverlay } from '../../utils/geojsonTiles'
import { setBoundariesLoading } from '../../store/Boundaries/Boundaries'
import { createOverlayTooltip } from './utils'
import { DATA_LAYER_Z_INDEX } from '../../consts/consts'
import { GEOSITE_URL } from '../../urls'

const zIndex = DATA_LAYER_Z_INDEX.indexOf('boundaries')

const country = 'england'

export const useBoundaries = ({ hasMap, mapObj }) => {
    const dispatch = useDispatch()

    const show = useSelector(({ boundaries: { show } }) => show) && hasMap
    const zoom = useSelector(({ map: { zoom } }) => zoom)

    // `level` in local state is needed because we need to switch area levels automatically
    // TODO: sidebar currently shows only `selectedLevel`
    const selectedLevel = useSelector(({ boundaries: { level } }) => level)
    const [level, setLevel] = useState(selectedLevel)
    useEffect(() => {
        // Set `level` based on `selectedLevel` and
        // switch to MSOA if trying to view LSOAs when zoomed out
        setLevel(selectedLevel === 'lsoa' && zoom < 12 ? 'msoa' : selectedLevel)
    }, [selectedLevel, zoom])

    // refs
    const geoJSONOverlayRef = useRef()
    const mapDataLayerRef = useRef()
    const boundariesLoadingRef = useRef(false)

    // setup/teardown
    // NB add new functionality in a separate useEffect
    useEffect(() => {
        if (hasMap) {
            mapDataLayerRef.current = new google.maps.Data()
            mapDataLayerRef.current.setStyle({
                zIndex,
                fillOpacity: 0,
                strokeWeight: 1,
                strokeColor: '#333',
            })
            geoJSONOverlayRef.current = new GeoJSONOverlay(
                mapObj.current,
                GEOSITE_URL,
                zIndex,
            )
        } else if (geoJSONOverlayRef.current) {
            geoJSONOverlayRef.current.clearOverlay(mapDataLayerRef.current)
        }
    }, [mapObj, hasMap])

    // Add tooltips
    useEffect(() => {
        if (hasMap) {
            const tooltip = createOverlayTooltip(mapObj.current.getDiv())

            const mouseoverListener = mapDataLayerRef.current.addListener(
                'mouseover',
                (event) => {
                    const f = event.feature
                    const code = f.getProperty('code')
                    const name = f.getProperty('name')
                    const tooltipText = `${name} - ${code}`

                    // Highlight feature
                    mapDataLayerRef.current.revertStyle()
                    mapDataLayerRef.current.overrideStyle(f, {
                        strokeWeight: 2,
                        strokeOpacity: 0.7,
                    })

                    tooltip.show(tooltipText)
                },
            )
            const mouseoutListener = mapDataLayerRef.current.addListener(
                'mouseout',
                () => {
                    mapDataLayerRef.current.revertStyle()
                    tooltip.hide()
                },
            )
            return () => {
                google.maps.event.removeListener(mouseoverListener)
                google.maps.event.removeListener(mouseoutListener)
                tooltip.remove()
            }
        }
        return () => {}
    }, [mapObj, hasMap, dispatch])

    // Load boundaries when `level` is changed
    useEffect(() => {
        if (boundariesLoadingRef.current) {
            // TODO
            console.warn('Switching between boundaries while loading')
        }

        if (!show || !level) {
            return
        }

        dispatch(setBoundariesLoading(true))
        boundariesLoadingRef.current = true

        geoJSONOverlayRef.current
            .setOverlay({
                country,
                level,
                overlay: 'boundaries',
                colourmap: level === 'ogs' ? 'ogs' : 'boundaries',
                mapDataLayer: mapDataLayerRef.current,
            })
            .then(({ refreshStyle }) => {
                refreshStyle()
                boundariesLoadingRef.current = false
                dispatch(setBoundariesLoading(false))
            })
    }, [show, level, dispatch, mapObj])

    // Toggle visibility
    useEffect(() => {
        if (hasMap) {
            mapDataLayerRef.current.setMap(show ? mapObj.current : null)
        }
    }, [mapObj, hasMap, show])
}
