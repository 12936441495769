import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import classnames from 'classnames/bind'

import { urls } from '../../urls'
import { UiContext } from '../../context/UiContext'
import { colors } from '../../consts/styleVariables'
import InitialLoading from '../InitialLoading/InitialLoading'
import DropdownNav from '../DropdownNav/DropdownNav'
import InlineLink from '../InlineLink/InlineLink'
import MenuButton from '../MenuButton/MenuButton'
import Logo from '../Icons/Logo'
import IconUser from '../Icons/User'

import css from './SiteHeader.module.scss'

const styles = classnames.bind(css)

const SiteHeader = ({ className, fullwidth }) => {
    const {
        loggedIn,
        ffStaff,
        showHamburger,
        isTablet,
        allowSidebar,
    } = useContext(UiContext)

    return (
        <InitialLoading showLoading={!fullwidth}>
            <div
                className={styles(
                    'site-header',
                    {
                        'site-header--has-hamburger': showHamburger,
                    },
                    {
                        'site-header--fullwidth': fullwidth,
                    },
                    className,
                )}
            >
                {fullwidth && (
                    <a
                        href="https://footballfoundation.org.uk/"
                        className={styles('site-header__partners')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="/images/logos/ff-logo.png"
                            alt="Football Foundation logo"
                        />
                        <img
                            src="/images/logos/prem-league-logo.png"
                            alt="Premier League logo"
                        />
                        <img
                            src="/images/logos/fa-logo.png"
                            alt="The FA Logo"
                        />
                        <img
                            src="/images/logos/dcms-logo.png"
                            alt="Department for Digital, Culture, Media & Sport logo"
                        />
                    </a>
                )}

                <Link to={urls.home()} className={styles('site-header__brand')}>
                    <Logo className={styles('site-header__brand-icon')} />
                    <span className={styles('site-header__brand-name')}>
                        PitchFinder
                    </span>
                </Link>

                <DropdownNav
                    menuName="User Menu"
                    className={styles('site-header__dropdown-nav')}
                    buttonIcon={
                        <div
                            className={styles(
                                'site-header__dropdown-nav__icon',
                            )}
                        >
                            <span>MENU</span>
                            <IconUser
                                fill={
                                    loggedIn ? colors.greenTwo : colors.greyTwo
                                }
                            />
                        </div>
                    }
                    alignLeft={isTablet || fullwidth}
                >
                    <ul>
                        {loggedIn && (
                            <>
                                <li>
                                    <InlineLink to={urls.savedMaps()}>
                                        Saved maps
                                    </InlineLink>
                                </li>
                                <li>
                                    <InlineLink
                                        to={urls.passwordChange()}
                                        external
                                    >
                                        Change password
                                    </InlineLink>
                                </li>
                            </>
                        )}
                        {ffStaff && (
                            <li>
                                <InlineLink
                                    to={urls.accountsPending()}
                                    external
                                >
                                    Pending registrations
                                </InlineLink>
                            </li>
                        )}
                        <li>
                            <InlineLink
                                to={loggedIn ? urls.logout() : urls.login()}
                                external
                            >
                                {loggedIn ? 'Log out' : 'Sign in'}
                            </InlineLink>
                        </li>
                        <div
                            className={styles(
                                'site-header__dropdown-nav_break',
                            )}
                        />
                        <li>
                            <InlineLink to={urls.about()} external>
                                About
                            </InlineLink>
                        </li>
                        <li>
                            <InlineLink to={urls.suggest()} external>
                                Suggest a new site
                            </InlineLink>
                        </li>
                        <li>
                            <InlineLink to={urls.terms()} external>
                                Terms & conditions
                            </InlineLink>
                        </li>
                    </ul>
                </DropdownNav>

                {isTablet && showHamburger && allowSidebar && (
                    <MenuButton
                        className={styles('site-header__menu-button')}
                    />
                )}
            </div>
        </InitialLoading>
    )
}

SiteHeader.propTypes = {
    className: PropTypes.string,
}

export default SiteHeader
