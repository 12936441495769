import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './Stat.module.scss'

const styles = classnames.bind(css)

const Stat = ({ label, value, className, ...rest }) => (
    <div className={styles('stat', className)} {...rest}>
        <span className={styles('stat__label')}>{label}</span>
        <span className={styles('stat__value')}>{value}</span>
    </div>
)

Stat.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    className: PropTypes.string,
}

export default Stat
