import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import Tooltip from '../Tooltip/Tooltip'

import css from './Boxout.module.scss'

const styles = classnames.bind(css)

const Boxout = ({
    title,
    tooltipText,
    children,
    className,
    zeroBottomPadding,
}) => (
    <div className={styles('boxout', className)}>
        <div
            className={styles('boxout__inner', {
                'boxout__inner--zeroBottomPadding': zeroBottomPadding,
            })}
        >
            {title && <h3 className={styles('boxout__heading')}>{title}</h3>}
            {tooltipText && (
                <Tooltip
                    content={tooltipText}
                    className={styles('boxout__tooltip')}
                />
            )}
            {children}
        </div>
    </div>
)

Boxout.defaultProps = {
    title: null,
    className: null,
}

Boxout.propTypes = {
    title: PropTypes.string,
    tooltipText: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
}

export default Boxout
