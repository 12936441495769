import React, { useState, useEffect, useLayoutEffect } from 'react'
import classnames from 'classnames/bind'
import { animated, useSpring } from 'react-spring'

import Heading from '../Heading/Heading'
import FormStep from '../FormStep/FormStep'
import BackButton from '../BackButton/BackButton'

import css from './MultiStep.module.scss'

const styles = classnames.bind(css)

export const createParentStepsArray = (configObject) =>
    Object.values(configObject).map(({ label }) => label)

export const createChildStepsArray = (configObject, activeStep) => {
    const [config] = Object.values(configObject).filter(
        ({ label }) => label === activeStep,
    )
    return config ? config.options : []
}

const useAnimation = () =>
    useSpring({
        opacity: 1,
        from: {
            opacity: 0,
        },
        config: {
            duration: 200,
        },
    })

export const Step = ({ headingText, children }) => {
    const animationProps = useAnimation()

    return (
        <FormStep>
            <animated.div style={animationProps}>
                <div className="visually-hidden" role="status">
                    {headingText} Step
                </div>
                <Heading
                    heading="h2"
                    text={headingText}
                    className={styles('multi-step__heading')}
                />
                {children}
            </animated.div>
        </FormStep>
    )
}

export const SubStep = ({
    headingText,
    stepName,
    parentName,
    handleBack,
    children,
    description,
}) => {
    const animationProps = useAnimation()

    return (
        <FormStep>
            <animated.div style={animationProps}>
                <div className="visually-hidden" role="status">
                    Select A {stepName} Step
                </div>
                <div>
                    <BackButton
                        text={`Back to ${parentName}`}
                        handleClick={() => handleBack()}
                        chevron
                    />
                </div>
                {headingText && (
                    <Heading
                        heading="h2"
                        text={headingText}
                        className={styles('multi-step__heading')}
                    />
                )}
                {description && <p>{description}</p>}
                {children}
            </animated.div>
        </FormStep>
    )
}

export const useMultiStep = (configObject) => {
    const parentSteps = createParentStepsArray(configObject)
    const [activeStep, setActiveStep] = useState(null)
    const [activeSubStep, setActiveSubStep] = useState(null)
    const [showActiveStep, setShowActiveStep] = useState(false)
    const [childSteps, setChildSteps] = useState([])

    useEffect(() => {
        if (activeStep !== null) {
            setChildSteps(createChildStepsArray(configObject, activeStep))
        }
    }, [configObject, activeStep])

    useLayoutEffect(() => {
        setShowActiveStep(activeStep !== null && activeSubStep === null)
    }, [activeStep, activeSubStep])

    return {
        activeStep,
        setActiveStep,
        activeSubStep,
        setActiveSubStep,
        parentSteps,
        childSteps,
        showActiveStep,
    }
}
