import React from 'react'
import classnames from 'classnames/bind'
import css from './Loading.module.scss'

const styles = classnames.bind(css)

const Loading = () => (
    <div className={styles('loading')}>
        <span
            className={styles('loading__icon')}
            role="img"
            aria-label="Loading"
        >
            ⚽
        </span>
    </div>
)

export default Loading
