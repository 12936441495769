import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classnames from 'classnames/bind'

import { UiContext } from '../../context/UiContext'
import { updateGroup, unapplyGroup } from '../../store/Filters/Filters'
import { fetchSites } from '../../store/Sites/Sites'
import { newFilterGroupId } from '../../utils/utils'

import BackButton from '../BackButton/BackButton'
import FormStep from '../FormStep/FormStep'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'
import FilterGroupMeta from '../FilterGroupMeta/FilterGroupMeta'

import { selectColors } from '../../consts/styleVariables'
import {
    ACTIVE_MODAL_FILTER_SELECTOR,
    DEFAULT_FILTER_GROUP_LABEL,
} from '../../consts/consts'

import css from './FilterGroupForm.module.scss'
import selectorCss from '../Selector/Selector.module.scss'

const styles = classnames.bind(css)
const selectorStyles = classnames.bind(selectorCss)

const FilterGroupForm = () => {
    const dispatch = useDispatch()

    const {
        setActiveModal,
        previousModal,
        setPreviousModal,
        activeFilterGroupId,
        setActiveFilterGroupId,
    } = useContext(UiContext)

    const activeFilterGroup = useSelector(
        ({ filters }) => filters[activeFilterGroupId] || null,
    )
    const numFilterGroups = useSelector(
        ({ filters }) => Object.keys(filters || {}).length,
    )

    const namePlaceholder = `${DEFAULT_FILTER_GROUP_LABEL} ${numFilterGroups +
        1}`

    const [label, setLabel] = useState(
        activeFilterGroup?.label || namePlaceholder,
    )

    const [color, setColor] = useState(activeFilterGroup?.icon)

    return (
        <FormStep className={styles('filter-group-form')}>
            {activeFilterGroup && previousModal && (
                <div>
                    <BackButton
                        text="Back to Categories"
                        handleClick={() => {
                            setPreviousModal(null)
                            setActiveModal(ACTIVE_MODAL_FILTER_SELECTOR)
                        }}
                        chevron
                    />
                </div>
            )}
            <div className="visually-hidden" role="status">
                {activeFilterGroup
                    ? `Edit "${activeFilterGroup.label}" filter group`
                    : 'Add a filter group'}
            </div>
            <Heading
                heading="h2"
                text={
                    activeFilterGroup
                        ? 'Edit a filter group'
                        : 'Add a filter group'
                }
            />
            <div className={selectorStyles('selector__row--full')}>
                <FilterGroupMeta
                    labelValue={label}
                    handleChangeLabel={(e) => setLabel(e.target.value)}
                    color={color}
                    handleChangeColor={(e) => setColor(e?.color)}
                    colorOptions={selectColors}
                />
            </div>
            <Button
                className={styles('filter-group-form__button')}
                text={activeFilterGroup ? 'Update' : 'Add new filter group'}
                handleClick={() => {
                    if (label) {
                        // Update a group
                        if (activeFilterGroupId) {
                            dispatch(
                                updateGroup({
                                    filterGroupId: activeFilterGroupId,
                                    label,
                                    icon: color,
                                }),
                            )
                            dispatch(fetchSites())

                            if (activeFilterGroup && previousModal) {
                                setPreviousModal(null)
                                setActiveModal(ACTIVE_MODAL_FILTER_SELECTOR)
                            } else {
                                setPreviousModal(null)
                                setActiveModal(null)
                            }
                        } else {
                            // Add a new group
                            const filterGroupId = newFilterGroupId(
                                numFilterGroups,
                            )
                            dispatch(
                                // Add a new empty filter group
                                // With a unique ID
                                updateGroup({
                                    filterGroupId,
                                    label,
                                    rules: [],
                                    icon: color,
                                }),
                            )
                            setActiveFilterGroupId(filterGroupId)
                            setPreviousModal(null)
                            setActiveModal(ACTIVE_MODAL_FILTER_SELECTOR)
                        }
                    }
                }}
                isRounded
                disabled={!label}
            />
            {activeFilterGroup ? (
                <Button
                    className={styles('filter-group-form__button')}
                    text="Delete filter group"
                    handleClick={() => {
                        dispatch(
                            // Remove the group
                            // TODO: Review UX
                            unapplyGroup({
                                filterGroupId: activeFilterGroupId,
                            }),
                        )
                        setActiveModal(null)
                        setActiveFilterGroupId(null)
                    }}
                    isCancel
                />
            ) : (
                <Button
                    className={styles('filter-group-form__button')}
                    text="Cancel"
                    handleClick={() => {
                        setActiveModal(null)
                    }}
                    isCancel
                />
            )}
        </FormStep>
    )
}

export default FilterGroupForm
