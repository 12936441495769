import React from 'react'
import PropTypes from 'prop-types'

const Edit = ({ fill, ...rest }) => (
    <svg width="14" height="14" viewBox="0,0,14,14" {...rest}>
        <path
            fill={fill}
            d="M.3 11v2.8H3l8.3-8.3-2.9-2.9L.2 11zm13.2-7.7c.3-.3.3-.8 0-1L11.8.4a.7.7 0 0 0-1 0L9.2 1.8l2.9 2.9 1.3-1.4z"
        />
    </svg>
)

Edit.defaultProps = {
    fill: null,
}

Edit.propTypes = {
    fill: PropTypes.string,
}

export default Edit
