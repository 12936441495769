import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import InputText from '../InputText/InputText'
import Heading from '../Heading/Heading'
import css from './InputRange.module.scss'

const styles = classnames.bind(css)

const InputRangeHeader = ({ text }) => (
    <Heading
        className={styles('input-range__heading')}
        heading="h3"
        text={text}
        isSubHeading
    />
)

const InputRange = ({
    label,
    type = 'text',
    from,
    fromDefault,
    to,
    toDefault,
    measurement = '',
    handleChangeFrom,
    handleChangeTo,
    className,
}) => {
    return (
        <div className={styles('input-range', className)}>
            <div className={styles('input-range__field')}>
                <InputRangeHeader text={`Min ${measurement}`} />
                <InputText
                    type={type}
                    value={from}
                    placeholder={fromDefault}
                    handleChange={({ target: { value } }) => {
                        handleChangeFrom(value.replace(/\D/g, ''))
                    }}
                    aria-labelledby={`${label}_value`}
                    inputMode="numeric"
                    pattern="[0-9]*"
                />
                <span id={`${label}_value`} className="visually-hidden">
                    Enter a date
                </span>
            </div>
            <div className={styles('input-range__field')}>
                <InputRangeHeader text={`Max ${measurement}`} />
                <InputText
                    type={type}
                    value={to}
                    placeholder={toDefault}
                    handleChange={({ target: { value } }) => {
                        handleChangeTo(value.replace(/\D/g, ''))
                    }}
                    aria-labelledby={`${label}_value`}
                    inputMode="numeric"
                    pattern="[0-9]*"
                />
                <span id={`${label}_value`} className="visually-hidden">
                    Enter a date
                </span>
            </div>
        </div>
    )
}

InputRange.defaultProps = {
    className: null,
}

InputRange.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
    handleChangeFrom: PropTypes.func.isRequired,
    handleChangeTo: PropTypes.func.isRequired,
}

export default InputRange
