import React from 'react'

import { LOCATION_PIN_COLOUR } from '../../consts/styleVariables'

const LocationPin = (props) => (
    <svg width="23" height="31" viewBox="0,0,23,36" {...props}>
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <ellipse
                    fill="#1C1C1C"
                    opacity=".4"
                    cx="11"
                    cy="31.803"
                    rx="6.735"
                    ry="2.62"
                />
                <path
                    d="M11 0C4.919 0 0 4.919 0 11c0 8.25 11 20.429 11 20.429S22 19.25 22 11c0-6.081-4.919-11-11-11z"
                    stroke="#FFF"
                    strokeWidth="1.571"
                    fill={LOCATION_PIN_COLOUR}
                />
            </g>
            <circle stroke="#FFF" strokeWidth="1.2" cx="11" cy="11" r="4.4" />
            <path stroke="#FFF" strokeWidth="1.2" d="M11 5v12M17 11H5" />
        </g>
    </svg>
)

export default LocationPin
