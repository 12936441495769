import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import PlusIcon from '../Icons/Plus'
import css from './AddButton.module.scss'

const styles = classnames.bind(css)

const AddButton = ({ text, handleClick }) => (
    <button
        type="button"
        onClick={handleClick}
        className={styles('add-button')}
    >
        {text} <PlusIcon className={styles('add-button__icon')} />
    </button>
)

AddButton.propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
}

export default AddButton
