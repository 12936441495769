import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './InputRadio.module.scss'

const styles = classnames.bind(css)

const InputRadio = ({
    label,
    name,
    id,
    value,
    placeholder,
    handleChange,
    checked,
    ...rest
}) => (
    <div className={styles('input-radio')}>
        <label htmlFor={id} className={styles('input-radio__label')}>
            <input
                className={styles('input-radio__input', 'visually-hidden')}
                id={id}
                name={name}
                type="radio"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                checked={checked}
                {...rest}
            />
            <span
                className={styles('input-radio__mask', {
                    'input-radio__mask--selected': checked,
                })}
            >
                {label}
            </span>
        </label>
    </div>
)

InputRadio.defaultProps = {
    placeholder: null,
    checked: false,
}

InputRadio.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
}

export default InputRadio
