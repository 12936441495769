import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import css from './Button.module.scss'

const styles = classnames.bind(css)

const Button = ({
    text,
    handleClick,
    isRounded,
    isCancel,
    className,
    focused,
    bold,
    ...rest
}) => {
    // handle initial focus
    const ref = useRef()
    useEffect(() => {
        if (focused && ref.current) {
            ref.current.focus()
        }
    }, [focused])

    return (
        <button
            ref={ref}
            type="button"
            onClick={handleClick}
            className={styles('button', className, {
                'button--rounded': isRounded,
                'button--cancel': isCancel,
                'button--bold': bold,
            })}
            {...rest}
        >
            {text}
        </button>
    )
}

Button.defaultProps = {
    isRounded: false,
    isCancel: false,
    className: null,
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func, // not required if we're in a form and listening to onSubmit
    isRounded: PropTypes.bool,
    isCancel: PropTypes.bool,
    className: PropTypes.string,
}

export default Button
