import React from 'react'
import PropTypes from 'prop-types'

const ChevronRight = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest}>
        <path
            d="M19 12l-.893-1.017L8.452 0 6 2.035 14.763 12 6 21.965 8.452 24l9.655-10.983L19 12z"
            fill={fill}
        />
    </svg>
)

ChevronRight.defaultProps = {
    fill: null,
}

ChevronRight.propTypes = {
    fill: PropTypes.string,
}

export default ChevronRight
