import React, { useContext } from 'react'
import AriaModal from 'react-aria-modal'
import classnames from 'classnames/bind'
import { UiContext } from '../../context/UiContext'
import css from './Modal.module.scss'

const styles = classnames.bind(css)

// Animated AriaModal
// See: https://github.com/davidtheclark/react-aria-modal/blob/master/demo/js/demo-five.js

export const useModal = () => {
    const {
        activeModal,
        modalHasEntered,
        setModalHasEntered,
        setActiveModal,
    } = useContext(UiContext)

    const onEnter = () => {
        setModalHasEntered(true)
    }

    const closeModal = () => {
        setModalHasEntered(false)

        // Wait for animations, before un-mounting
        setTimeout(() => {
            setActiveModal(null)
        }, 200)
    }

    return {
        onEnter,
        closeModal,
        modalHasEntered,
        activeModal,
    }
}

const Modal = ({ children, titleText }) => {
    const { modalHasEntered, onEnter, closeModal } = useModal()

    return (
        <AriaModal
            titleText={titleText}
            onExit={closeModal}
            onEnter={onEnter}
            focusTrapOptions={{ clickOutsideDeactivates: true }}
            verticallyCenter
            underlayColor={false}
            underlayClass={styles('underlay', {
                'has-entered': modalHasEntered,
            })}
        >
            <div
                className={styles('modal', {
                    'has-entered': modalHasEntered,
                })}
            >
                <div className={styles('modal__body')}>{children}</div>
            </div>
        </AriaModal>
    )
}

export default Modal
