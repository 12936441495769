import { queryStringToObject } from './utils/queryString'
import { get } from './services/Services'
import { getLatLngAreas } from './services/Location/Location'
import store from './store'
import { urls } from './urls'
import { replaceLocation } from './store/Location/Location'
import { applyOption } from './store/MapOptions/MapOptions'
import { latLng2PointRelative } from './components/Map/utils'

// We need to keep track of the markers for the site selector widget to detect nearby click events.
window.markers = new Set()

const queryObject = queryStringToObject(window.location.search)

// get data from public site detail api and pass it to the parent frame
export const selectSite = (siteId) =>
    get({
        url: urls.api.site_detail(siteId),
        params: { address_details: true },
        disableCaseConverter: true,
    }).then(({ data }) => {
        window.parent.postMessage(
            {
                type: 'site_selected',
                key: window.siteSelectorKey,
                data,
            },
            '*',
        )
    })

export const selectLocation = (latLng) =>
    window.parent.postMessage(
        {
            type: 'location_selected',
            key: queryObject.key,
            data: {
                latitude: latLng.lat(),
                longitude: latLng.lng(),
            },
        },
        '*',
    )

const init = () => {
    const { map } = window

    get({ url: urls.siteSelectorUi() }).then(({ data }) => {
        // send html for site selector UI to be added/styled on the parent page
        window.parent.postMessage(
            {
                type: 'ui',
                html: data.trim(),
            },
            '*',
        )
    })

    map.setOptions({
        styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }],
    })

    // Create singleton marker for pinpointed location
    window.siteSelectorLocationMarker = new google.maps.Marker()
    const locationMarker = window.siteSelectorLocationMarker

    // Trigger pinpoint on single click only (to allow zoom in with double click)
    google.maps.event.addListener(map, 'click', (event) => {
        const mapZoom = map.getZoom()
        setTimeout(() => {
            // don't allow adding a location marker near a site
            // (this is a workaround because we can't stop event propagation when opening an info window)
            const eventCoords = latLng2PointRelative(event.latLng)
            let isLocationNearSite = false
            window.markers.forEach((marker) => {
                // calculate disctance (in pixels) bewteen click and each site icon
                const markerPosition = marker.getPosition()
                if (markerPosition) {
                    const markerCoords = latLng2PointRelative(markerPosition)
                    const a = markerCoords.x - eventCoords.x
                    const b = markerCoords.y - eventCoords.y
                    const distancePixels = Math.sqrt(a * a + b * b)
                    if (distancePixels < 20) {
                        isLocationNearSite = true
                    }

                    // calculate disctance (in meters) bewteen click and each site icon
                    const distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(
                        markerPosition,
                        event.latLng,
                    )
                    if (distanceMeters < 30) {
                        isLocationNearSite = true
                    }
                }
            })
            if (!isLocationNearSite)
                if (mapZoom === map.getZoom()) {
                    locationMarker.setPosition(event.latLng)
                    if (!queryObject.disable_location_select) {
                        locationMarker.setMap(map)
                    }
                }
        }, 300)
    })

    // pre-select non-site location
    if (
        !queryObject.disable_location_select &&
        queryObject.lat &&
        queryObject.lng
    ) {
        locationMarker.setPosition({
            lat: parseFloat(queryObject.lat),
            lng: parseFloat(queryObject.lng),
        })
        locationMarker.setMap(map)
    }

    google.maps.event.addListener(locationMarker, 'click', (event) => {
        setTimeout(() => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Select location?')) {
                selectLocation(event.latLng)
            } else {
                locationMarker.setPosition(null)
                locationMarker.setMap(null)
            }
        }, 1)
    })

    // perform search when postMessage is recieved
    window.addEventListener(
        'message',
        (event) => {
            if (event.data.type === 'search') {
                const searchValue = event.data.value
                getLatLngAreas({
                    type: 'query',
                    value: searchValue,
                }).then(({ lat, lng, areasAtLocation }) => {
                    store.dispatch(
                        replaceLocation({
                            lat,
                            lng,
                            areasAtLocation,
                            type: 'query',
                            label: searchValue,
                            value: searchValue,
                        }),
                    )
                })
            }
        },
        false,
    )

    if (queryObject.show_all) {
        store.dispatch(applyOption({ nonOperational: true, nonFootball: true }))
    }
}

if (queryObject && queryObject.site_selector) {
    window.siteSelector = true
    window.siteSelectorKey = queryObject.key

    const interval = setInterval(() => {
        if (window.google && window.map) {
            clearInterval(interval)
            init()
        }
    }, 200)
}
