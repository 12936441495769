import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: null,

    // year: 2016
    // label: "Local Authority"
    // level: "la"
    // optgroup: "population"

    // value: has different shape depending on the overlay

    // Keys are the first lower bound followed by all the upper bounds for each offset.
    // Values are true or false depending on which one is visible (first value is ignored).
    // offsets: {0: true, 20: true, 40: false, ...}
}

const overlaySlice = createSlice({
    name: 'overlay',
    initialState,
    reducers: {
        resetOverlay: () => initialState,
        replaceOverlay: (state, { payload }) => ({
            ...payload,
            loading: payload.loading && Object.keys(payload).length > 1,
        }),
        setOverlayLoading: (state, { payload }) => {
            state.loading = payload
        },
        replaceOffsets: (state, { payload }) => {
            state.offsets = payload
        },
        toggleOffset: (state, { payload }) => {
            state.offsets[payload] = !state.offsets[payload]
        },
    },
})

const { actions, reducer } = overlaySlice

export const {
    resetOverlay,
    replaceOverlay,
    setOverlayLoading,
    replaceOffsets,
    toggleOffset,
} = actions

export default reducer
