import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './FormStep.module.scss'

const styles = classnames.bind(css)

const FormStep = ({ children, className }) => (
    <div className={styles('form-step', className)}>{children}</div>
)

FormStep.defaultProps = {
    className: null,
}

FormStep.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default FormStep
