import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames/bind'
import {
    PRIORITY_PROJECT_COLOURS,
    DATA_LAYER_Z_INDEX,
} from '../../consts/consts'
import { useAlert } from '../../hooks/hooks'
import css from './Map.module.scss'
import { addCustomGeojson } from '../../store/CustomGeojson/CustomGeojson'
import { encodedMarkerIcon } from '../MapMarker/MapMarker'

const styles = classnames.bind(css)

const zIndex = DATA_LAYER_Z_INDEX.indexOf('customGeojson')

const removeFeaturesWithoutLocation = (geojson) => {
    const filteredFeatures = []
    geojson.features.forEach((feature) => {
        try {
            if (feature.geometry.coordinates[0])
                if (feature.geometry.coordinates[1]) {
                    filteredFeatures.push(feature)
                }
        } catch (_) {
            // ignore errors
        }
    })
    geojson.features = filteredFeatures
}

const setIcons = (dataLayer) => {
    dataLayer.setStyle((feature) => {
        const icon = feature.getProperty('icon')
        const title = feature.getProperty('name') || ''
        const pitchfinderId = feature.getProperty('pitchfinder_id')
        const locationKnown = feature.getProperty('location_known')

        if (icon) {
            if (icon.indexOf('number_') !== -1) {
                const number = icon.split('_')[1]

                const {
                    pfIdKnown,
                    pfIdNotKnown,
                    locationNotKnown,
                } = PRIORITY_PROJECT_COLOURS
                //  colour = location_known ? (pitchfinder_id ? light blue : mid blue) : dark blue
                const colour = locationKnown
                    ? pitchfinderId
                        ? pfIdKnown
                        : pfIdNotKnown
                    : locationNotKnown

                return {
                    title,
                    zIndex,
                    label: {
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '700',
                        text: number,
                    },
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillOpacity: 1,
                        fillColor: colour,
                        strokeOpacity: 1,
                        strokeWeight: 3,
                        strokeColor: colour,
                        scale: 11,
                        // scale: 100  // make it larger to help test z-index fix
                    },
                }
            }

            // if there's no numbered icon then use the default one with an optional color
            return {
                title,
                zIndex,
                icon: encodedMarkerIcon({
                    size: 1, // i.e. not a cluster
                    ...(icon && !icon.startsWith('number_')
                        ? { colors: [icon] }
                        : {}),
                }),
            }
        }

        // Set title and z-index even if it has no icon (i.e. non-lffp-specific geojson)
        return { title, zIndex }
    })
}

export const useCustomGeojson = ({
    hasMap,
    mapObj,
    autoZoom,
    loggedIn,
    setHiddenSiteIds,
}) => {
    const alert = useAlert()

    const dispatch = useDispatch()
    const list = useSelector(({ customGeojson: { list } }) => list)

    const [droppingFile, setDroppingFile] = useState(false)

    const dataLayer = useRef()

    useEffect(() => {
        dataLayer.current = new google.maps.Data()
        return () => {
            dataLayer.current.setMap(null)
            dataLayer.current = null
        }
    }, [])

    // add each custom geojson object to the map
    useEffect(() => {
        if (!hasMap) {
            return undefined
        }
        const hiddenSiteIds = []
        // add everything
        list.map((str) => JSON.parse(str)).forEach((geojson) => {
            if (!geojson.features.length) {
                alert.current.error(
                    'The GeoJSON file contains no features/sites/projects',
                )
                return
            }
            if (!geojson.features.length) {
                alert.current.error(
                    'The GeoJSON file contains no sites/projects that have a location specified',
                )
                return
            }
            dataLayer.current.addGeoJson(geojson)
            setIcons(dataLayer.current)

            // Hide existing site markers and use the ones from the custom geosjon instead.
            hiddenSiteIds.push(
                ...geojson.features
                    .map(({ properties: { pitchfinder_id } }) => pitchfinder_id) // eslint-disable-line camelcase
                    .filter(Boolean),
            )
        })
        setHiddenSiteIds(hiddenSiteIds)

        dataLayer.current.setMap(mapObj.current)

        autoZoom({ customGeojson: list.length ? dataLayer.current : null })

        return () => {
            // remove everything
            if (dataLayer.current) {
                dataLayer.current.setMap(null)
                dataLayer.current.forEach((f) => dataLayer.current.remove(f))
            }
        }
    }, [alert, mapObj, hasMap, autoZoom, setHiddenSiteIds, list])

    const handleGeojsonString = (geojsonString) => {
        let geojson
        try {
            geojson = JSON.parse(geojsonString)
        } catch (e) {
            alert.current.error('Not a valid GeoJSON file')
        }
        if (geojson) {
            removeFeaturesWithoutLocation(geojson)
            dispatch(addCustomGeojson(JSON.stringify(geojson)))
        }
    }

    const onDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()

        setDroppingFile(false)

        if (!loggedIn) {
            alert.current.error('Login required')
            return
        }

        const { files } = e.dataTransfer
        if (files.length) {
            // process file(s) being dropped
            // grab the file data from each file
            files.forEach((file) => {
                const reader = new FileReader()
                reader.onload = (e) => handleGeojsonString(e.target.result)
                reader.onerror = () =>
                    alert.current.error(
                        "Couldn't load GeoJSON file. Please try again.",
                    )
                reader.readAsText(file)
            })
        } else {
            // process non-file (e.g. text or html) content being dropped
            // grab the plain text version of the data
            const plainText = e.dataTransfer.getData('text/plain')
            if (plainText) {
                handleGeojsonString(plainText)
            } else {
                alert.current.error(
                    "Couldn't load GeoJSON file. Please try again.",
                )
            }
        }
    }

    const onDragEnter = () => {
        setDroppingFile(true)
    }
    const onDragLeave = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setDroppingFile(false)
    }

    // Add dragenter listener to the map container to show 'dropping file' graphics
    useEffect(() => {
        if (!hasMap) {
            return undefined
        }
        const mapContainer = mapObj.current.getDiv()
        mapContainer.addEventListener('dragenter', onDragEnter, false)
        return () => {
            mapContainer.removeEventListener('dragenter', onDragEnter)
        }
    }, [mapObj, hasMap])

    const fileDropOverlay = (
        <div
            className={styles('drop-container')}
            style={{ display: droppingFile ? 'block' : 'none' }}
            id="drop-container"
        >
            <div
                className={styles('drop-silhouette')}
                onDragOver={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onDrop={onDrop}
                onDragLeave={onDragLeave}
            />
        </div>
    )

    return { fileDropOverlay }
}
