import React from 'react'
import PropTypes from 'prop-types'

const SearchThin = ({ fill, ...rest }) => (
    <svg
        width="71"
        height="60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            clipRule="evenodd"
            d="M42.0104 42.5104c7.42-7.42 7.42-19.4501 0-26.8701-7.42-7.41991-19.4501-7.41991-26.87 0-7.42001 7.42-7.42001 19.4501 0 26.8701 7.4199 7.42 19.45 7.42 26.87 0z"
            stroke={fill}
            strokeWidth="2"
        />
        <path
            stroke={fill}
            d="M42.0104 42.5104l.7071-.7071 14.84925 14.84924-.7071.7071z"
        />
    </svg>
)

SearchThin.defaultProps = {
    fill: '#009A3A',
}

SearchThin.propTypes = {
    fill: PropTypes.string,
}

export default SearchThin
