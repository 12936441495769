import { createAxiosClient, get } from '../Services'
import { throttleDebouncePromise } from '../../utils/utils'
import { AutoCompleteError } from '../../utils/errors'
import { urls } from '../../urls'

export const client = createAxiosClient()

export const getFieldValues = (fieldId, inputValue) => {
    const url = urls.api.field_values(fieldId)
    const params = {
        q: inputValue,
    }

    return get({ url, client, params })
        .then(({ data: { results, notShown } }) => {
            return {
                results: results.map((value) => ({ value, label: `${value}` })),
                notShown,
            }
        })
        .catch(() => Promise.reject(new AutoCompleteError(inputValue)))
}

export const getFieldValuesDebounced = throttleDebouncePromise(getFieldValues, {
    throttle: 500,
    debounce: 200,
    cache: true,
    immediateIfCached: true,
})

export const getForeignKeyValues = (fieldId, inputValue) => {
    const url = urls.api.related_objects(fieldId)
    const params = {
        q: inputValue,
    }

    return get({ url, client, params })
        .then(({ data: { results, notShown } }) => {
            return {
                results,
                notShown,
            }
        })
        .catch(() => Promise.reject(new AutoCompleteError(inputValue)))
}

export const getForeignKeyValuesDebounced = throttleDebouncePromise(
    getForeignKeyValues,
    {
        throttle: 500,
        debounce: 200,
        cache: true,
        immediateIfCached: true,
    },
)
