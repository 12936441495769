import React, {
    useState,
    useContext,
    useCallback,
    useEffect,
    useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classnames from 'classnames/bind'

import { LOCATION_TABINDEX, LABELS } from '../../consts/consts'
import { isInitialLocation } from '../../utils/utils'

import { UiContext } from '../../context/UiContext'
import { updateLocation } from '../../store/Location/Location'
import { fetchSites } from '../../store/Sites/Sites'
import { usePromise, useDebounce, useAlert } from '../../hooks/hooks'
import FormStep from '../FormStep/FormStep'
import Heading from '../Heading/Heading'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import SiteSearchTabs from '../SiteSearchTabs/SiteSearchTabs'
import Button from '../Button/Button'

import { getLatLngAreas } from '../../services/Location/Location'
import css from './SiteSearchForm.module.scss'

const styles = classnames.bind(css)

const SiteSearchForm = ({
    headingText = 'Edit location',
    buttonText = 'Update location',
    onChange = () => {},
    hideButtons = false,
    children,
    path,
}) => {
    const dispatch = useDispatch()

    const { setActiveModal, setShowSidebar, loggedIn } = useContext(UiContext)

    // populate local state from redux, and keep it up to date
    const committedLocation = useSelector((state) => state.location)
    const [location, setLocation] = useState(committedLocation)
    useEffect(() => {
        setLocation(committedLocation)
    }, [committedLocation])

    // Keep reference of when to show submit button
    const [showSubmitButton, setShowSubmitButton] = useState(!hideButtons)

    // change type to geolocation when you type in the same label
    useEffect(() => {
        if (
            location.type !== 'geolocation' &&
            String(location.value)
                .trim()
                .toLowerCase() === LABELS.locationType.geolocation.toLowerCase()
        ) {
            setLocation({
                type: 'geolocation',
                value: LABELS.locationType.geolocation,
                label: LABELS.locationType.geolocation,
            })
        }
    }, [setLocation, location.type, location.value])

    // tabindex
    const initialTabIndex = location.travelMode
        ? LOCATION_TABINDEX.travel
        : location.area
        ? LOCATION_TABINDEX.area
        : LOCATION_TABINDEX.radius

    const [tabIndex, setTabIndex] = useState(initialTabIndex)

    // Get a new location object based on which tab is selected
    const getLocationToDispatch = () => {
        if (tabIndex === LOCATION_TABINDEX.radius) {
            return {
                ...location,
                area: null,
                travelTime: null,
                travelMode: null,
            }
        }
        if (
            tabIndex === LOCATION_TABINDEX.travel &&
            location.travelMode &&
            location.travelTime
        ) {
            return {
                ...location,
                area: null,
                radius: null,
            }
        }
        return {
            ...location,
            radius: null,
            travelTime: null,
            travelMode: null,
        }
    }

    // Ideally usePromise would accept deps too but that doesn't work
    // with the hooks lint rules, so we need to use useCallback explicilty.
    // Having a stable reference to the function means the previous result
    // will be memoised by usePromise based on the deps.
    const [result, loading, error] = usePromise(
        useCallback(
            () =>
                getLatLngAreas({
                    type: location.type,
                    value: location.value,
                    lat: location.lat,
                    lng: location.lng,
                }),
            // deps:
            [location.type, location.value, location.lat, location.lng],
        ),
        // initial value for usePromise result:
        {
            lat: location.lat,
            lng: location.lng,
            areasAtLocation: location.areasAtLocation,
        },
    )
    const { lat, lng, areasAtLocation } = result || {}

    // handle geolocation errors
    const alert = useAlert()
    useEffect(() => {
        if (error && location.type === 'geolocation') {
            alert.current.error(
                "Couldn't determine your location. You might have to change your browser settings to allow it.",
            )
        }
    }, [alert, error, location.type])

    // Update location based on new lat, lng, areasAtLocation
    React.useEffect(() => {
        if (loading) {
            return
        }
        if (
            location.lat !== lat ||
            location.lng !== lng ||
            location.areasAtLocation !== areasAtLocation
        ) {
            // If an area was selected from the autocomplete then select that in its own section too
            let { area } = location
            if (location.type === 'area') {
                const areaToSelect =
                    location.areasAtLocation &&
                    location.areasAtLocation.find(
                        (a) => a.options[0].value === location.value,
                    )
                if (areaToSelect) {
                    area = areaToSelect.options[0]
                    setTabIndex(LOCATION_TABINDEX.area)
                }
            }
            setLocation({
                ...location,
                lat,
                lng,
                areasAtLocation,
                area,
            })
        }
    }, [
        loading,
        setTabIndex,
        location,
        location.type,
        lat,
        lng,
        areasAtLocation,
    ])

    // Prevent changing the loading state too often
    const loadingDebounced = useDebounce(loading, 400)

    // True if geocoding couldn't identify what was typed under 'Find on map' optgroup
    const notFound =
        location.type === 'query' && location.value && !lat && !loadingDebounced

    // make sure submit button is visible
    const submitButtonSectionRef = useRef()
    useEffect(() => {
        if (
            showSubmitButton &&
            submitButtonSectionRef.current?.scrollIntoViewIfNeeded
        ) {
            submitButtonSectionRef.current.scrollIntoViewIfNeeded(false)
        }
    }, [showSubmitButton, loadingDebounced, buttonText, location])

    // Change submit button text depending on which tab is selected
    const buttonTextForTabs = {
        [LOCATION_TABINDEX.radius]: 'Search within radius',
        [LOCATION_TABINDEX.travel]: 'Search by travel time',
        [LOCATION_TABINDEX.area]: 'Search within region',
    }
    const buttonTextForTab = loadingDebounced
        ? 'Loading...'
        : notFound
        ? 'Location not found'
        : location.type === 'area' // if selecting an area from the autocomplete (no tabs visible)
        ? `${buttonTextForTabs[LOCATION_TABINDEX.area]}(s)`
        : buttonTextForTabs[tabIndex] || buttonText

    // Update redux state with focused location.
    // This should be called only after the we have the lat/lng AND the the areas at that point (via getLatLngAreas).
    // Because we call getLatLngAreas in this component we have to keep it mounted until we get back the result.
    const goToLocation = () => {
        // getLocationToDispatch() returns location and nullifies values from inactive tabs
        const newLocation = getLocationToDispatch()
        dispatch(updateLocation(newLocation))
        dispatch(fetchSites())
        setActiveModal(null)
        setShowSidebar(false)
        onChange()
    }

    return (
        <FormStep className={styles('filter-location-form')}>
            <div className="visually-hidden" role="status">
                {headingText}
            </div>
            <Heading
                heading="h2"
                text={headingText}
                className={styles('filter-location-form__heading')}
            />
            <ErrorBoundary
                hasError={error}
                message="Unable to perform search. Please try again later."
            >
                <SiteSearchTabs
                    path={path}
                    location={location}
                    tabIndex={tabIndex}
                    changeTabIndex={setTabIndex}
                    loggedIn={loggedIn}
                    useCurrentLocation={() => {
                        setShowSubmitButton(true)
                        setLocation({
                            type: 'geolocation',
                            value: LABELS.locationType.geolocation,
                            label: LABELS.locationType.geolocation,
                        })
                    }}
                    changeLocation={(option) => {
                        setShowSubmitButton(true)
                        if (option) {
                            if (Array.isArray(option)) {
                                // area/region multi select mode
                                setLocation({
                                    ...location,
                                    area: option,
                                    value: option,
                                    label: option
                                        .map(({ label }) => label)
                                        .join(', '),
                                })
                            } else {
                                setLocation({
                                    ...location,
                                    ...option, // pass all attributes that come back from the autocomplete
                                })
                            }
                        } else {
                            // When clearing the main search autocomplete we restore everything to default
                            setLocation({})
                            setShowSubmitButton(!hideButtons)
                        }
                    }}
                    changeRadius={({ value }) => {
                        setLocation({ ...location, radius: value })
                    }}
                    changeArea={(option) => {
                        setLocation({
                            ...location,
                            area: option, // area: {value, label} | null
                        })
                    }}
                    changeTravelTime={({ target: { value } }) => {
                        setLocation({
                            ...location,
                            travelTime: parseInt(value, 10),
                        })
                    }}
                    changeTravelMode={({ value }) => {
                        setLocation({ ...location, travelMode: value })
                    }}
                />
                {showSubmitButton && (
                    <div
                        ref={submitButtonSectionRef}
                        className={styles(
                            'filter-location-form__submit_buttons',
                        )}
                    >
                        <Button
                            text={buttonTextForTab}
                            handleClick={goToLocation}
                            isRounded
                            bold
                            disabled={loadingDebounced || notFound}
                        />
                        {Object.values(location).filter(Boolean).length &&
                        !isInitialLocation(location.lat, location.lng) ? (
                            <Button
                                text="Clear Search"
                                handleClick={() => {
                                    setLocation({})
                                }}
                            />
                        ) : null}
                    </div>
                )}
            </ErrorBoundary>
            {children}
        </FormStep>
    )
}

export default SiteSearchForm
