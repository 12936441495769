import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './EditButton.module.scss'

import EditIcon from '../Icons/Edit'

const styles = classnames.bind(css)

const EditButton = ({ title, handleClick, className, disabled }) => (
    <button
        type="button"
        title={title}
        className={styles('edit-button', className)}
        onClick={handleClick}
        disabled={disabled}
    >
        <EditIcon className={styles('edit-button__icon')} />
    </button>
)

EditButton.defaultProps = {
    className: null,
}

EditButton.propTypes = {
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default EditButton
