import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import classnames from 'classnames/bind'
import { NativeSelect } from '../InputSelect/InputSelect'
import inputSelectCss from '../InputSelect/InputSelect.module.scss'
import { defaultStyles } from '../InputSelect/reactSelectStyles'

const styles = classnames.bind(inputSelectCss)

const SelectRegion = ({
    areaValue,
    options,
    changeArea,
    className,
    ...rest
}) => {
    return (
        <div className={styles('input-select', className)}>
            <NativeSelect
                selected={areaValue}
                options={options}
                handleChange={changeArea}
            />
            <Select
                type="text"
                aria-labelledby="browse-regions-aria-label"
                value={areaValue}
                isClearable
                options={options}
                styles={defaultStyles()}
                onChange={changeArea}
                {...rest}
            />
        </div>
    )
}

const option = PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
})

SelectRegion.propTypes = {
    areaValue: PropTypes.oneOfType([option, PropTypes.arrayOf(option)]),
    options: PropTypes.array,
    changeArea: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default SelectRegion
