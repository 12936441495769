/*
From Google Maps API v3 samples (15 Mar 2010):
http://code.google.com/p/gmaps-samples-v3/
To have conversion PointToTile
http://code.google.com/apis/maps/documentation/v3/examples/map-coordinates.html
*/

const MERCATOR_RANGE = 256

const degreesToRadians = (deg) => deg * (Math.PI / 180)

const radiansToDegrees = (rad) => rad / (Math.PI / 180)

const bound = (value, optMin, optMax) =>
    Math.min(Math.max(value, optMin), optMax)

function MercatorProjection() {
    this.pixelOrigin = new google.maps.Point(
        MERCATOR_RANGE / 2,
        MERCATOR_RANGE / 2,
    )
    this.pixelsPerLonDegree = MERCATOR_RANGE / 360
    this.pixelsPerLonRadian = MERCATOR_RANGE / (2 * Math.PI)
}

MercatorProjection.prototype.fromLatLngToPoint = function fromLatLngToPoint(
    latLng,
    optPoint,
) {
    const point = optPoint || new google.maps.Point(0, 0)
    const origin = this.pixelOrigin
    point.x = origin.x + latLng.lng() * this.pixelsPerLonDegree
    // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
    // 89.189.  This is about a third of a tile past the edge of the world tile.
    const siny = bound(
        Math.sin(degreesToRadians(latLng.lat())),
        -0.9999,
        0.9999,
    )
    point.y =
        origin.y +
        0.5 * Math.log((1 + siny) / (1 - siny)) * -this.pixelsPerLonRadian
    return point
}

MercatorProjection.prototype.fromPointToLatLng = function fromPointToLatLng(
    point,
) {
    const origin = this.pixelOrigin
    const lng = (point.x - origin.x) / this.pixelsPerLonDegree
    const latRadians = (point.y - origin.y) / -this.pixelsPerLonRadian
    const lat = radiansToDegrees(
        2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2,
    )
    return new google.maps.LatLng(lat, lng)
}

let projection

export const pointToTile = (latLng, zoom) => {
    projection = projection || new MercatorProjection()
    const worldCoordinate = projection.fromLatLngToPoint(latLng)
    const pixelCoordinate = new google.maps.Point(
        worldCoordinate.x * 2 ** zoom,
        worldCoordinate.y * 2 ** zoom,
    )
    const tileCoordinate = new google.maps.Point(
        Math.floor(pixelCoordinate.x / MERCATOR_RANGE),
        Math.floor(pixelCoordinate.y / MERCATOR_RANGE),
    )
    return tileCoordinate
}
