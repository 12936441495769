import { navigate } from '@reach/router'
import { createSlice } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { urls } from '../../urls'
import { mapObject } from '../../utils/utils'

// services
import {
    createSavedMap,
    updateSavedMap,
    deleteSavedMap,
} from '../../services/SavedMaps/SavedMaps'

// actions
import { resetFilters, replaceFilters } from '../Filters/Filters'
import {
    resetLocation,
    replaceLocation,
    overrideGssCode,
} from '../Location/Location'
import { resetMapOptions, replaceMapOptions } from '../MapOptions/MapOptions'
import { resetOverlay, replaceOverlay } from '../Overlay/Overlay'
import { resetBoundaries, replaceBoundaries } from '../Boundaries/Boundaries'
import { resetOgs, replaceOgs } from '../OpenGreenSpaces/OpenGreenSpaces'
import {
    resetCustomGeojson,
    replaceCustomGeojson,
} from '../CustomGeojson/CustomGeojson'
import { fetchSites } from '../Sites/Sites'

const initialState = {
    slug: null,
    loading: null, // this indicates if a saved map api or an url change is pending (not whether sites are loaded too)
    lastModified: null,
    own: null,
}

const savedMapSlice = createSlice({
    name: 'savedMap',
    initialState,
    reducers: {
        reset: () => initialState,
        setSavedMapLoading: (state, { payload }) => {
            state.loading = payload
        },
        update: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            }
        },
        replace: (state, { payload }) => payload,
    },
})

const { actions, reducer } = savedMapSlice

export const resetSavedMap = actions.reset

const removeEmptySlices = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).map(([key, { ...props }]) => {
            delete props.loading
            if (props == null || !Object.keys(props).length) {
                return []
            }
            return [key, props]
        }),
    )
}

const stateToSavedMap = (state) => {
    const location = { ...state.location }
    delete location.gssCodeOverride
    return {
        slug: state.savedMap.slug,
        data: removeEmptySlices({
            location,
            mapOptions: state.mapOptions,
            filters: state.filters,
            overlay: state.overlay.level ? state.overlay : null,
            boundaries: state.boundaries.level ? state.boundaries : null,
            ogs: state.ogs,
            customGeojson: state.customGeojson.list.length
                ? state.customGeojson
                : null,
            // map: state.map,  // TODO: keep zoom/bounds?
        }),
    }
}

export const restoreSavedMap = (data, gssCodeOverride) => (dispatch) => {
    batch(() => {
        if (data.data.filters) {
            dispatch(replaceFilters(data.data.filters))
        }
        if (data.data.location) {
            dispatch(replaceLocation(data.data.location))
        }
        if (gssCodeOverride) {
            dispatch(overrideGssCode(gssCodeOverride))
        }
        if (data.data.mapOptions) {
            dispatch(replaceMapOptions(data.data.mapOptions))
        }
        if (data.data.overlay) {
            const { offsets } = data.data.overlay
            if (offsets) {
                // If the keys in the overlay offsets are floats
                // then Axios converts the decimal point to underscore.
                // Here we convert them back to float.
                data.data.overlay.offsets = mapObject(offsets, (k, v) => [
                    parseFloat(k.replace('_', '.')),
                    v,
                ])
            }
            dispatch(replaceOverlay(data.data.overlay))
        }
        if (data.data.boundaries) {
            dispatch(replaceBoundaries(data.data.boundaries))
        }
        if (data.data.ogs) {
            dispatch(replaceOgs(data.data.ogs))
        }
        if (data.data.customGeojson) {
            dispatch(replaceCustomGeojson(data.data.customGeojson))
        }
        dispatch(
            actions.update({
                slug: data.slug,
                lastModified: data.lastModified,
                own: data.own,
                loading: false,
            }),
        )
        dispatch(fetchSites())
    })
}

export const resetAll = () => (dispatch) => {
    batch(() => {
        dispatch(actions.reset())
        dispatch(resetFilters())
        dispatch(resetLocation())
        dispatch(resetMapOptions())
        dispatch(resetOverlay())
        dispatch(resetBoundaries())
        dispatch(resetOgs())
        dispatch(resetCustomGeojson())
        dispatch(fetchSites())
    })
}

const preventParallel = ({ loading }, alert) => {
    if (loading) {
        // This should never happen because we disable the button while there's a pending request.
        alert.current.error('Please try again in a few seconds.')
        if (window.Sentry) {
            Sentry.captureException(new Error('Concurrent saved map api call'))
        }
    }
    return loading
}

const onSuccess = ({ dispatch, slug, replace }) => async () => {
    if (replace !== true && replace !== false) {
        throw new Error('Saved maps: `replace` argument must be true or false')
    }
    if (!slug) {
        throw new Error('Saved maps: `slug` argument is not defined')
    }
    await navigate(urls.savedMap(slug), { replace })
    dispatch(actions.setSavedMapLoading(false))
}

const onError = ({ dispatch, alert, message, prevState }) => async (e) => {
    // restore previous state on error
    dispatch(actions.replace(prevState))
    if (
        e.response &&
        e.response.status === 400 &&
        e.response.data.slug &&
        e.response.data.slug[0] === 'saved map with this slug already exists.'
    ) {
        alert.current.error('A saved map with this title already exists')
    } else {
        alert.current.error(message)
        if (window.Sentry) {
            Sentry.captureException(e)
        }
    }
}

export const createOrUpdateSavedMap = (slug, alert) => (dispatch, getState) => {
    const prevState = getState()

    const { gssCodeOverride } = prevState.location

    if (preventParallel(prevState, alert)) {
        return
    }

    dispatch(
        actions.update({
            own: true,
            loading: true,
            lastModified: new Date().toISOString(),
            ...(slug && { slug }),
        }),
    )

    const nextState = getState()

    if (prevState.savedMap.slug) {
        // Update
        if (gssCodeOverride) {
            throw new Error('Saved map with gss code override update attempted')
        }
        const replace = prevState.savedMap.slug !== nextState.savedMap.slug
        const { slug } = nextState.savedMap
        const message = 'There was an error updating saved map'
        updateSavedMap(prevState.savedMap.slug, stateToSavedMap(nextState))
            .then(onSuccess({ dispatch, slug, replace }))
            .then(() => alert.current.show('Saved map has been updated'))
            .catch(onError({ dispatch, alert, message, prevState }))
    } else {
        // Create
        if (!slug) {
            throw new Error('Missing slug for createSavedMap')
        }
        const message = 'There was an error creating the saved map'

        createSavedMap(stateToSavedMap(nextState))
            .then(onSuccess({ dispatch, slug, replace: false }))
            .then(() => alert.current.show('A new saved map has been created'))
            .catch(onError({ dispatch, alert, message, prevState }))
    }
}

export const cloneSavedMap = (slug, alert) => (dispatch, getState) => {
    const prevState = getState()

    if (preventParallel(prevState, alert)) {
        return
    }

    dispatch(
        actions.update({
            slug,
            own: true,
            loading: true,
            lastModified: new Date().toISOString(),
        }),
    )

    const nextState = getState()

    const message = 'There was an error creating the saved map'

    createSavedMap(stateToSavedMap(nextState))
        .then(onSuccess({ dispatch, slug, replace: false }))
        .then(() => alert.current.show('A new saved map has been created'))
        .catch(onError({ dispatch, alert, message, prevState }))
}

export const destroySavedMap = (slug, alert) => (dispatch, getState) => {
    const prevState = getState()

    if (preventParallel(prevState, alert)) {
        return
    }

    dispatch(
        actions.update({
            loading: true,
        }),
    )

    deleteSavedMap(slug)
        .then(() => {
            dispatch(actions.setSavedMapLoading(false))
            alert.current.show('Saved map has been deleted')
        })
        .catch((e) => {
            alert.current.error('There was an error deleting the saved map')
            dispatch(actions.replace(prevState.savedMap)) // Revert to previous saved map metadata
            if (window.Sentry) {
                Sentry.captureException(e)
            }
        })
}

export default reducer
