import React from 'react'
import ExternalLinkIcon from '../Icons/ExternalLinkIcon'

const ExternalLink = ({ url, title }) => (
    <>
        <a href={url}>{title || url.replace(/(https?:\/\/)|(www\.)/g, '')}</a>
        &nbsp;&nbsp;
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            title="Open in new tab"
        >
            <ExternalLinkIcon />
        </a>
    </>
)

export default ExternalLink
