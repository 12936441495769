import React from 'react'
import PropTypes from 'prop-types'

const Square = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest}>
        <rect width="24" height="24" fill={fill} />
    </svg>
)

Square.defaultProps = {
    fill: null,
}

Square.propTypes = {
    fill: PropTypes.string,
}

export default Square
