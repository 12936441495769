import React from 'react'
import PropTypes from 'prop-types'

const CurrentLocation = ({ fill, ...rest }) => (
    <svg
        width="22"
        height="22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.94 10c-.46-4.17-3.77-7.48-7.94-7.94V0h-2v2.06C5.83 2.52 2.52 5.83 2.06 10H0v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V22h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H22v-2h-2.06zM11 7c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-7 4c0 3.87 3.13 7 7 7s7-3.13 7-7-3.13-7-7-7-7 3.13-7 7z"
            fill={fill}
        />
    </svg>
)

CurrentLocation.defaultProps = {
    fill: '#009A3A',
}

CurrentLocation.propTypes = {
    fill: PropTypes.string,
}

export default CurrentLocation
