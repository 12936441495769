import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames/bind'
import ChevronRight from '../Icons/ChevronRight'

import css from './ListCta.module.scss'

const styles = classnames.bind(css)

const ListCta = ({ text, handleClick, chevron, children }) => (
    <button
        type="button"
        onClick={handleClick}
        className={styles('list-cta', {
            'list-cta--chevron': chevron,
        })}
    >
        {text}{' '}
        {chevron && <ChevronRight className={styles('list-cta__chevron')} />}
        {children}
    </button>
)

ListCta.propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    children: PropTypes.node,
}

export default ListCta
