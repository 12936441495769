// based on https://github.com/schiehll/react-alert-template-basic
import classnames from 'classnames/bind'

import React from 'react'

// TODO: remove icons from here, and use ones from /components/Icons/
import InfoIcon from './icons/InfoIcon'
import SuccessIcon from './icons/SuccessIcon'
import ErrorIcon from './icons/ErrorIcon'
import CloseIcon from './icons/CloseIcon'

import css from './Message.module.scss'

const styles = classnames.bind(css)

const Message = ({ message, options, style, close }) => {
    const icon = {
        info: <InfoIcon />,
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <ErrorIcon />,
    }[options.type]

    return (
        <div className={styles('alert', options.type)} style={style}>
            {icon}
            <span style={{ flex: 2 }}>{message}</span>
            <button onClick={close} className={styles('button')} type="button">
                <CloseIcon />
            </button>
        </div>
    )
}

export default Message
