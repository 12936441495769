import { initialState as initialLocaionState } from '../store/Location/Location'

function getCacheKey(...args) {
    args.forEach((a) => {
        if (
            a !== null &&
            !Number.isNaN(Number(a)) &&
            !['string', 'number', 'boolean', 'undefined'].includes(typeof a)
        ) {
            throw Error(`Can't create cache key for ${a} : ${typeof a}`)
        }
    })
    return args.join('---')
}

export function throttleDebouncePromise(
    inner,
    { throttle, debounce, cache, immediateIfCached },
) {
    // Ordinary debounce functions cause issues with promises and react-select but this works well.
    // Based on: https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
    const resolveCallbacks = []
    const store = {}
    let timeout = null
    let time = new Date()

    return (...args) => {
        // Return cached value immediately if exists and if immediateIfCached is true
        if (cache && immediateIfCached) {
            const cacheKey = getCacheKey(...args)
            if (Object.prototype.hasOwnProperty.call(store, cacheKey)) {
                return new Promise((resolve) => resolve(store[cacheKey]))
            }
        }

        const run = () => {
            // Resolve only the last callback if there's any left
            const resolve = resolveCallbacks.pop()

            if (resolve) {
                // Remove all the previous resolve callbacks
                resolveCallbacks.splice(0, resolveCallbacks.length)

                // Return cached value only now if exists and if immediateIfCached is false
                if (cache && !immediateIfCached) {
                    const cacheKey = getCacheKey(...args)
                    if (Object.prototype.hasOwnProperty.call(store, cacheKey)) {
                        return resolve(store[cacheKey])
                    }
                }

                // If it wasn't cached then execute the inner function and cache when it resolves
                const promise = inner(...args).then((result) => {
                    // Store in cache if caching is enabled
                    if (cache) {
                        store[getCacheKey(...args)] = result
                    }
                    return result
                })
                resolve(promise)
            }

            // Reset time for throttle
            time = new Date()
            return undefined
        }

        // E.g. if a key is pressed continously this will keep making requests
        // and NOT wait until the key is released.
        if (throttle && new Date() - time > throttle) {
            run()
        }

        // When used with throttle this only ensures the last promise is always called.
        // Without throttle it does what a debounce function is supposed to.
        clearTimeout(timeout)
        timeout = setTimeout(run, debounce)

        // Return a promise each time the final debounced function is called.
        // This promise will just store the resolve function in `resolveCallbacks`
        // and when it's time we'll call the last one.
        return new Promise((resolve) => {
            resolveCallbacks.push(resolve)
        })
    }
}

export function debounce(wait, func) {
    let timeout
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            timeout = null
            func(...args)
        }, wait)
    }
}

export function uuidv4() {
    const crypto = window.crypto || window.msCrypto
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        // prettier-ignore
        // eslint-disable-next-line
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
    )
}

let filterGroupIdSequence = 0
export function newFilterGroupId(min) {
    if (min) {
        filterGroupIdSequence = Math.max(min, filterGroupIdSequence)
    }
    // This has to return a valid sql identifier
    const newId = `filterGroupId${(filterGroupIdSequence += 1)}`
    return newId
}

export function newSavedMapSlug() {
    const length = 8
    const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    return Array(length)
        .fill()
        .map(() => possible.charAt(Math.floor(Math.random() * possible.length)))
        .join('')
}

export function getCookie(cname) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i]

        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export function memoWithObjectArg(fn) {
    const cache = new Map()
    return (argsObj) => {
        const key = JSON.stringify(argsObj)
        if (cache.has(key)) {
            return cache.get(key)
        }
        const result = fn(argsObj)
        cache.set(key, result)
        return result
    }
}

export const removeFirstOccurrence = (array, element) => {
    const index = array.indexOf(element)
    if (index !== -1) {
        array.splice(index, 1)
    }
}

export const toCamel = (s) =>
    s.replace(/([-_][a-z0-9])/gi, ($1) =>
        $1
            .toUpperCase()
            .replace('-', '')
            .replace('_', ''),
    )

// This function helps flag any data that has non-camelcase keys.
// It can be used to check the request body, to make sure axios-case-converter won't modify it.
export const isAllCamelCase = (o) => {
    const keysToCamel = (o) => {
        if (Array.isArray(o)) {
            o.map(keysToCamel)
        } else if (o === Object(o) && typeof o !== 'function') {
            Object.keys(o).forEach((k) => {
                if (toCamel(k) !== k || !/[a-z0-9]$/i.test(k)) {
                    throw Error()
                }
                keysToCamel(o[k])
            })
        }
    }

    try {
        keysToCamel(o)
        return true
    } catch (_) {
        return false
    }
}

export const formatCurrency = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
}).format

export const formatCamelCaseToSentenceCase = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
}

// Location.initialState has lat/lng coordinates to position map when loaded without any filters.
// We treat this differently from a location that was selected by users.
export const isInitialLocation = (lat, lng) =>
    initialLocaionState.lat === lat && initialLocaionState.lng === lng

// To allow dwonloading files we need to submit data as from an html form
export const submitAsForm = (path, params, method = 'post') => {
    const form = document.createElement('form')
    form.method = method
    form.action = path
    if (process.env.NODE_ENV !== 'production') {
        // open in a new tab locally: this makes it easier to debug any errors
        form.target = 'new'
    }

    Object.entries(params).forEach(([key, value]) => {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = JSON.stringify(value)
        form.appendChild(hiddenField)
    })
    // csrf
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = 'csrfmiddlewaretoken'
    hiddenField.value = getCookie('csrftoken')
    form.appendChild(hiddenField)

    document.body.appendChild(form)
    form.submit()
}

const camelToSnake = (str) => {
    return str
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
}

export const renameKeysToSnakeCase = (o) => {
    if (Array.isArray(o)) {
        return o.map(renameKeysToSnakeCase)
    }
    if (o === Object(o) && typeof o !== 'function') {
        return Object.fromEntries(
            Object.entries(o).map(([k, v]) => [
                camelToSnake(k),
                renameKeysToSnakeCase(v),
            ]),
        )
    }
    return o
}

export const isEmptyObject = (o) => !Object.keys(o).length

export const sample = (arr) => arr[Math.floor(Math.random() * arr.length)]

// 'Network Error' is not a native exception, it's a custom one raised by axios
// whenever xhr.onerror is triggered: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequestEventTarget/onerror
// 'Request aborted' is currently raised by axios when a request was aborted NOT by us.
// So it's not raised when using the `cancelPrevious` option, i.e. the axios abort controller.
// 'timeout of 0ms exceeded' is similar to 'Network Error', see: https://github.com/axios/axios/issues/2103#issuecomment-541638515
export const isConnectionError = (e) =>
    ['Network Error', 'Request aborted', 'timeout of 0ms exceeded'].includes(
        e.message,
    )

export const capitalize = (s) =>
    String(s)
        .charAt(0)
        .toUpperCase() + String(s).slice(1)

export const mapObject = (o, f) =>
    Object.fromEntries(Object.entries(o).map(([k, v]) => f(k, v)))

export const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length
