import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames/bind'

import { UiContext } from '../../context/UiContext'

import {
    ACTIVE_MODAL_FILTER_SELECTOR,
    ACTIVE_MODAL_FILTER_GROUP,
    FILTER_GROUP_ICONS_TO_COLOURS,
    DEFAULT_FILTER_GROUP_LABEL,
} from '../../consts/consts'
import { newFilterGroupId } from '../../utils/utils'
import { unapplyFilter, unapplyGroup } from '../../store/Filters/Filters'

import AddButton from '../AddButton/AddButton'
import CircleIcon from '../Icons/Circle'
import FilterCard from '../FilterCard/FilterCard'
import EditButton from '../EditButton/EditButton'
import DeleteFilterButton from '../DeleteFilterButton/DeleteFilterButton'

import css from './FilterGroups.module.scss'

const styles = classnames.bind(css)

const FilterGroups = ({ disabled }) => {
    const {
        setActiveModal,
        setActiveSubStepId,
        setActiveFilterGroupId,
    } = useContext(UiContext)

    const dispatch = useDispatch()

    // Get an array of filters from state
    const filters = Object.values(useSelector(({ filters }) => filters))

    // Check if any of the filter groups have rules.
    // Used to determine which UI elements to show
    const hasRules = filters.some(({ rules }) => rules.length > 0)

    // Add a new filter. If no intended filterGroupId is passed
    // (i.e. filter is not being added to a group), generate a unique id
    const AddFilterButton = ({ filterGroupId = newFilterGroupId() }) =>
        disabled ? (
            <br />
        ) : (
            <AddButton
                text="Filter sites"
                handleClick={() => {
                    setActiveFilterGroupId(filterGroupId)
                    setActiveModal(ACTIVE_MODAL_FILTER_SELECTOR)
                }}
            />
        )

    const FilterGroup = ({ label, color, children, i }) => (
        <div className={styles('filter-group')}>
            <span className={styles('filter-group__label')}>
                {label || `${DEFAULT_FILTER_GROUP_LABEL} ${i + 1}`}
                {color && (
                    <CircleIcon
                        fill={FILTER_GROUP_ICONS_TO_COLOURS[color] || color}
                        className={styles('filter-group__circle')}
                    />
                )}
            </span>
            {children}
        </div>
    )

    const EditGroupButton = ({ filterGroupId }) =>
        disabled ? null : (
            <EditButton
                title="Edit a filter group"
                className={styles('filter-group__edit')}
                handleClick={() => {
                    setActiveFilterGroupId(filterGroupId)
                    setActiveModal(ACTIVE_MODAL_FILTER_SELECTOR)
                }}
            />
        )

    const DeleteGroupButton = ({ filterGroupId }) =>
        disabled ? null : (
            <DeleteFilterButton
                className={styles('filter-group__delete')}
                handleClick={() => {
                    dispatch(
                        unapplyGroup({
                            filterGroupId,
                        }),
                    )
                }}
                inverse
            />
        )

    return (
        <div
            className={styles('filter-groups', {
                'filter-groups--empty': !!filters.length,
            })}
        >
            {/* If Map over each filter group */}
            {filters.map(({ filterGroupId, label, rules, icon }, i) => (
                <div key={filterGroupId}>
                    {/* If there are rules, map over them or show the add button */}
                    {rules.length > 0 ? (
                        <FilterGroup label={label} color={icon} i={i}>
                            <EditGroupButton filterGroupId={filterGroupId} />
                            {rules.map(
                                ({ id, label, operator, value, quick }) => (
                                    <FilterCard
                                        key={id}
                                        label={label}
                                        operator={operator}
                                        value={value}
                                        quick={quick}
                                        disabled={disabled}
                                        handleDelete={() => {
                                            dispatch(
                                                unapplyFilter({
                                                    id,
                                                    filterGroupId,
                                                }),
                                            )
                                        }}
                                        handleClick={() => {
                                            // Set the active filter group, for the Filter Selector to
                                            // add/update filters in
                                            setActiveFilterGroupId(
                                                filterGroupId,
                                            )

                                            if (!quick) {
                                                // Set the active sub step, for the Filter Selector to use
                                                setActiveSubStepId(id)
                                            }

                                            // Open the filterSelector modal
                                            setActiveModal(
                                                ACTIVE_MODAL_FILTER_SELECTOR,
                                            )
                                        }}
                                    />
                                ),
                            )}
                        </FilterGroup>
                    ) : (
                        <FilterGroup label={label} color={icon}>
                            <DeleteGroupButton filterGroupId={filterGroupId} />
                            <AddFilterButton filterGroupId={filterGroupId} />
                        </FilterGroup>
                    )}
                </div>
            ))}

            {/* If rules have been added, show the "Add filter group"  */}
            {!disabled &&
                (hasRules ? (
                    <AddButton
                        tabKey="2"
                        text="Add another set of sites"
                        handleClick={() => {
                            setActiveFilterGroupId(null)
                            setActiveModal(ACTIVE_MODAL_FILTER_GROUP)
                        }}
                    />
                ) : (
                    <AddFilterButton />
                ))}
        </div>
    )
}

FilterGroups.defaultProps = {
    disabled: false,
}

export default FilterGroups
