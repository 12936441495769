import React from 'react'
import classnames from 'classnames/bind'
import css from './SiteInfo.module.scss'
import Boxout from '../Boxout/Boxout'
import Detail from '../Detail/Detail'
import ExternalLink from '../ExternalLink/ExternalLink'

import { SiteInfoDetailGrid, getFormattedAddress } from './SiteInfoUtils'

const styles = classnames.bind(css)

const SiteInfoContact = ({ contactInfo, address }) => {
    const formattedAddress =
        address && Object.keys(address).length && getFormattedAddress(address)

    const detailProps = {
        className: styles(
            'site-information__detail',
            'site-information__detail--full',
        ),
    }

    return (
        <Boxout title="Contact" className={styles('site-information__boxout')}>
            <SiteInfoDetailGrid>
                {formattedAddress ? (
                    <Detail
                        label="Address"
                        value={
                            <address>
                                <ExternalLink
                                    url={`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`}
                                    title={formattedAddress}
                                />
                            </address>
                        }
                        {...detailProps}
                    />
                ) : null}

                {contactInfo.facebook && (
                    <Detail
                        label="Facebook"
                        value={contactInfo.facebook}
                        {...detailProps}
                    />
                )}

                {contactInfo.twitter && (
                    <Detail
                        label="Twitter"
                        value={contactInfo.twitter}
                        {...detailProps}
                    />
                )}

                {contactInfo.website && (
                    <Detail
                        label="Website"
                        value={contactInfo.website}
                        {...detailProps}
                    />
                )}

                {contactInfo.email && (
                    <Detail
                        label="Email"
                        value={contactInfo.email}
                        {...detailProps}
                    />
                )}

                {contactInfo.telephoneNumber ? (
                    <Detail
                        label="Telephone Number"
                        value={
                            /[1-9]/g.test(contactInfo.telephoneNumber)
                                ? contactInfo.telephoneNumber
                                : 'N/A'
                        }
                        {...detailProps}
                    />
                ) : null}
            </SiteInfoDetailGrid>
        </Boxout>
    )
}

export default SiteInfoContact
