import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import CheckIcon from '../Icons/Check'

import css from './InputCheckbox.module.scss'

const styles = classnames.bind(css)

const InputCheckbox = ({
    label,
    name,
    id,
    handleChange,
    checked,
    isTick,
    className,
    disabled,
}) => (
    <div
        className={styles(
            'input-checkbox',
            {
                'input-checkbox--is-tick': isTick,
                'disabled': disabled,
            },
            className,
        )}
    >
        <label htmlFor={id} className={styles('input-checkbox__label')}>
            <input
                className={styles('input-checkbox__input', 'visually-hidden')}
                id={id}
                name={name}
                type="checkbox"
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
            />
            <span
                className={styles('input-checkbox__mask', {
                    'input-checkbox__mask--selected': checked,
                })}
            >
                {/* Show tick only if checked for fat button style checkbox icon */}
                {!isTick && checked && (
                    <CheckIcon className={styles('input-checkbox__icon')} />
                )}

                {/* Always show the tick for isTick */}
                {isTick && (
                    <CheckIcon className={styles('input-checkbox__icon')} />
                )}

                {label}
            </span>
        </label>
    </div>
)

InputCheckbox.defaultProps = {
    checked: false,
    isTick: false,
    className: null,
}

InputCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    isTick: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

export default InputCheckbox
