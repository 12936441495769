import React, { useState } from 'react'
import classnames from 'classnames/bind'

import css from './SiteInfo.module.scss'
import facilitiesCss from './SiteInfoFacilities.module.scss'

import Boxout from '../Boxout/Boxout'
import Detail from '../Detail/Detail'
import Stat from '../Stat/Stat'
import Tabs from '../Tabs/Tabs'
import {
    formatCurrency,
    formatCamelCaseToSentenceCase,
} from '../../utils/utils'
import { SiteInfoFacilitiesDetailGrid } from './SiteInfoFacilities'

const styles = classnames.bind(css)
const facilitiesStyles = classnames.bind(facilitiesCss)

// Make sure this list matches GrantSummary.STATUS_CHOICES
const STATUS_PRE_PIPELINE = 'Pre-pipeline'
const STATUS_APPLICATION_DEVELOPMENT = 'Application development'
const STATUS_IN_ASSESSMENT = 'In Assessment'
const STATUS_AWARDED = 'Awarded'
// STATUS_WITHDRAWN = 'Withdrawn or rejected' // we're not displaying this

const STATUSES = [
    STATUS_PRE_PIPELINE,
    STATUS_APPLICATION_DEVELOPMENT,
    STATUS_IN_ASSESSMENT,
    STATUS_AWARDED,
]

// prettier-ignore
const sections = {
    'Overview': ['grantCode', 'status', 'grantAmount', 'projectValue', 'grantPercentage'],
    'Grant details': [ 'grantProjectDescription', 'grantOrganisationName', 'offerDate', 'openForUseDate', 'imd'],
    'Funding': ['schemeName', 'nffsFundingType', 'fundingWindow', 'lffp' , 'nffsFunding' ],
    'Staff': ['grantOfficer', 'grantManager', 'technicalAdvisor'],
}

const format = {
    grantAmount: formatCurrency,
    projectValue: formatCurrency,
    openForUseDate: (v) => new Date(v).toLocaleDateString(),
    offerDate: (v) => new Date(v).toLocaleDateString(),
    grantPercentage: (v) => `${v}%`,
    imd: (v) => `${v}%`,
}

const labels = {
    ...Object.fromEntries(
        Object.values(sections)
            .flat()
            .map((k) => [k, formatCamelCaseToSentenceCase(k)]),
    ),
    imd: 'Index of Multiple Deprivation',
    nffsFunding: 'NFFS Funded',
    nffsFundingType: 'NFFS Funding Type',
    lffp: 'LFFP Project',
}

const GrantsHeader = ({ grants }) => {
    const children = STATUSES.map((s) => {
        const filteredGrants = grants.filter(({ status }) => status === s)
        const sum = filteredGrants.reduce((sum, g) => sum + g.grantAmount, 0)
        return (
            <Stat
                key={s}
                label={`${s.replace('Pre-', 'In ')} (${filteredGrants.length})`}
                value={`${formatCurrency(sum)}`}
                className={styles('site-information__grant', {
                    'site-information__grant--disabled': !filteredGrants.length,
                })}
            />
        )
    })
    return <div className={styles('site-information__grants')}>{children}</div>
}

const getGrantTabPanel = (grant) => {
    if (process.env.NODE_ENV !== 'production') {
        const availableFields = new Set(Object.keys(grant))
        const invalidFields = Object.values(sections)
            .flat()
            .filter((f) => !availableFields.has(f))
        if (invalidFields.length) {
            throw new Error(`Invalid grant fields: ${invalidFields.join(', ')}`)
        }
    }

    return (
        <div key={grant.grantCode} tabkey={grant.grantCode}>
            {Object.entries(sections).map(([section, fields]) => (
                <React.Fragment key={section}>
                    <h4
                        className={facilitiesStyles(
                            'site-information-facilities__heading',
                        )}
                    >
                        {section}
                    </h4>
                    <SiteInfoFacilitiesDetailGrid>
                        {fields
                            .map((k) => [k, grant[k]])
                            .map(([k, v]) => (
                                <Detail
                                    key={k}
                                    label={labels[k]}
                                    value={
                                        v == null || v === ''
                                            ? 'N/A'
                                            : format[k]
                                            ? format[k](v)
                                            : v
                                    }
                                    className={facilitiesStyles(
                                        'site-information-facilities__detail',
                                    )}
                                />
                            ))}
                    </SiteInfoFacilitiesDetailGrid>
                </React.Fragment>
            ))}
        </div>
    )
}

const GrantsList = ({ grants }) => {
    const tabs = grants.map((grant) => ({
        name: grant.offerYear
            ? `${grant.status} (${grant.offerYear})`
            : grant.status,
        panel: getGrantTabPanel(grant),
    }))

    const [tabIndex, setTabIndex] = useState(0)

    return (
        <div
            className={facilitiesStyles(
                'site-information-facilities__tabs-container',
            )}
        >
            <Tabs
                tabList={tabs.map((tab) => tab.name)}
                tabPanels={tabs.map((tab) => tab.panel)}
                controlledTabIndex={tabIndex}
                updateControlledTabIndex={setTabIndex}
                stacked
            />
        </div>
    )
}

const SiteInfoGrants = ({ grants }) => {
    return (
        <div className={styles('site-information__row')}>
            <Boxout
                title="FF Grants"
                className={styles('site-information__boxout')}
            >
                <GrantsHeader grants={grants} />
                {grants.length ? <GrantsList grants={grants} /> : null}
            </Boxout>
        </div>
    )
}

export default SiteInfoGrants
