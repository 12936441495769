import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import CrossIcon from '../Icons/Cross'
import css from './DeleteButton.module.scss'

const styles = classnames.bind(css)

const DeleteButton = ({ text, handleClick, className }) => (
    <button
        type="button"
        onClick={handleClick}
        className={styles('delete-button', className)}
    >
        {text} <CrossIcon className={styles('delete-button__icon')} />
    </button>
)

DeleteButton.defaultProps = {
    className: null,
}

DeleteButton.propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default DeleteButton
