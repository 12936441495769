import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames/bind'
import ChevronLeft from '../Icons/ChevronLeft'

import css from './BackButton.module.scss'

const styles = classnames.bind(css)

const BackButton = ({ text, handleClick }) => (
    <button
        type="button"
        onClick={handleClick}
        className={styles('back-button')}
    >
        {text} <ChevronLeft className={styles('back-button__chevron')} />
    </button>
)

BackButton.propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
}

export default BackButton
