import { queryStringToObject } from '../utils/queryString'
import { getLatLngAreas } from '../services/Location/Location'
import { getGeoJsonForFeature } from '../services/Geo/Geo'
import { initialState as locationInitalState } from './Location/Location'
import { initialMapState } from './Map/Map'
import {
    QUICK_FILTER_GROUP_ID,
    DEFAULT_FILTER_GROUP_LABEL,
    LABELS,
} from '../consts/consts'
import quickFilterConfig from '../data/quick_filter_config'

// NB this isn't called on popstate events, only on full page reload.
// But this isn't a problem because we don't throw away the redux state when navigating to other frontend pages,
// OR have a full reload when coming back from backend pages.
// And also because we use replaceState to change the querystring based on quick filters.
// And we don't change the querystring for logged in users.
// The only minor issue with this approach is when a logged out user selects a filter, goes to another frontend page
// and then goes back to the map and selects different quick filters (not using the back button during these steps).
// Then navigating back to the first map page will use the same set of quick filters as the last one.
// Also: view saved map logged out -> home -> map -> select quick filters -> back -> back -> saved map will have the quick filters in the url

export const setInitialStateFromQueryString = async () => {
    const queryObject = queryStringToObject(window.location.search)

    const initialState = {}

    if (!queryObject) {
        return initialState
    }

    // Map over the query to create new quick filters rules object
    const rules = Object.entries(queryObject).reduce((acc, [key, value]) => {
        // Check the filter is in the config
        const quickFilter = quickFilterConfig.find(({ id }) => id === key)
        const enabled = value && value !== 'false'
        if (quickFilter && enabled) {
            acc.push(quickFilter)
        }
        return acc
    }, [])

    if (Object.keys(rules).length) {
        initialState.filters = {
            [QUICK_FILTER_GROUP_ID]: {
                filterGroupId: QUICK_FILTER_GROUP_ID,
                label: `${DEFAULT_FILTER_GROUP_LABEL} 1`,
                rules,
            },
        }
    }

    if (queryObject.zoom) {
        initialState.map = {
            ...initialMapState,
            zoom: parseInt(queryObject.zoom, 10),
        }
    }

    if (queryObject.lat) {
        initialState.location = {
            ...locationInitalState,
            lat: queryObject.lat,
            lng: queryObject.lng,
            label: queryObject.query,
            value: queryObject.query,
            ...(queryObject.radius && {
                radius: queryObject.radius,
            }),
        }
    } else {
        let point = await getLatLngAreas({
            value: queryObject.query,
            type: 'query',
        })
        if (queryObject.query && !queryObject.code && !point.lat) {
            // TODO: show 'not found' message
            return initialState
        }
        const value = queryObject.query
        let label = queryObject.query
        let areaLabel
        if (queryObject.code) {
            const geoJson = await getGeoJsonForFeature(queryObject.code)
            areaLabel = geoJson.features[0].properties.name
            if (!(point && point.lat)) {
                const coords =
                    geoJson.features[0].properties.centroid_4326.coordinates
                point = {
                    lng: coords[0][0],
                    lat: coords[0][1],
                }
            }
            if (!queryObject.query) {
                label = areaLabel
            }
        }

        // Update map location
        initialState.location = {
            ...locationInitalState,
            type: 'query',
            ...(point && {
                lat: point.lat,
                lng: point.lng,
            }),
            ...(label && { label }),
            ...(value && { value }),
            ...(queryObject.radius &&
                !queryObject.code && {
                    radius: parseInt(queryObject.radius, 10),
                }),
            ...(queryObject.code && {
                area: { value: queryObject.code, label: areaLabel },
            }),
        }

        if (!initialState.location.query && !initialState.location.code) {
            delete initialState.location.radius
        }

        // use geolocation when the same label is used in the querystring
        if (
            initialState?.location?.label?.toLowerCase() ===
            LABELS.locationType.geolocation.toLowerCase()
        ) {
            const { lat, lng, areasAtLocation } = await getLatLngAreas({
                type: 'geolocation',
            })
            initialState.location = {
                ...initialState.location,
                lat,
                lng,
                type: 'geolocation',
                value: LABELS.locationType.geolocation,
                label: LABELS.locationType.geolocation,
                areasAtLocation,
            }
        }
    }
    return initialState
}
