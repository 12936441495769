import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import Heading from '../Heading/Heading'
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import css from './QuickFilters.module.scss'
import quickFilterConfig from '../../data/quick_filter_config'

const styles = classnames.bind(css)

const QuickFilters = ({
    activeFilterIds,
    applyQuickFilter,
    unapplyQuickFilter,
    listView,
}) => {
    const optgroups = quickFilterConfig
        .map(({ optgroup }) => optgroup)
        .filter((item, index, array) => array.indexOf(item) === index)

    return optgroups.map((optgroup) => (
        <div
            key={optgroup}
            className={styles('quick-filters', {
                'quick-filters--list-view': listView,
            })}
        >
            <Heading heading="h3" text={`${optgroup}:`} isSubHeading />
            {quickFilterConfig
                .filter(({ optgroup: o }) => o === optgroup)
                .map(({ id, label }) => (
                    <InputCheckbox
                        key={id}
                        id={id}
                        label={label}
                        name={id}
                        isTick={listView}
                        checked={activeFilterIds.includes(id)}
                        handleChange={() => {
                            if (activeFilterIds.includes(id)) {
                                // Since this is a quick filter it doesn't need a value,
                                // and applyQuickFilter is already aware of activeFilterGroupId
                                // and will set quick:true as well.
                                unapplyQuickFilter({ id, label })
                            } else {
                                applyQuickFilter({ id, label })
                            }
                        }}
                    />
                ))}
        </div>
    ))
}

QuickFilters.defaultProps = {
    activeFilterIds: [],
}

QuickFilters.propTypes = {
    activeFilterIds: PropTypes.arrayOf(PropTypes.string),
    applyQuickFilter: PropTypes.func.isRequired,
    unapplyQuickFilter: PropTypes.func.isRequired,
}

export default QuickFilters
