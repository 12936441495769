import React from 'react'
import { ACTIVE_MODAL_OVERLAY_SELECTOR } from '../../consts/consts'

import OverlaySelector from '../OverlaySelector/OverlaySelector'
import Modal, { useModal } from '../Modal/Modal'
import Close from '../Close/Close'
import { ConfigNotLoaded } from '../Selector/Selector'

import { usePromiseGlobal } from '../../hooks/hooks'
import { transformedConfig } from '../../utils/overlay'
import { getConfig } from '../../services/Config/Config'

const OverlaySelectorModal = () => {
    const [config, loading, error] = usePromiseGlobal(getConfig)

    const { activeModal, closeModal } = useModal()

    const overlayConfig = config && transformedConfig(config.overlays)

    return (
        <>
            {activeModal === ACTIVE_MODAL_OVERLAY_SELECTOR && (
                <Modal titleText="Overlay Selector">
                    {error || loading ? (
                        <ConfigNotLoaded label="overlays" error={error} />
                    ) : (
                        <OverlaySelector
                            config={overlayConfig}
                            closeModal={closeModal}
                        />
                    )}
                    <Close handleClick={closeModal} />
                </Modal>
            )}
        </>
    )
}

export default OverlaySelectorModal
