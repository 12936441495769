import React, { useEffect } from 'react'
import { useDispatch, batch } from 'react-redux'
import classnames from 'classnames/bind'
import { navigate } from '@reach/router'

import { urls } from '../../urls'

import { resetFilters } from '../../store/Filters/Filters'
import { resetLocation } from '../../store/Location/Location'
import { resetMapOptions } from '../../store/MapOptions/MapOptions'
import { resetOverlay } from '../../store/Overlay/Overlay'
import { resetBoundaries } from '../../store/Boundaries/Boundaries'
import { resetCustomGeojson } from '../../store/CustomGeojson/CustomGeojson'
import { resetSavedMap } from '../../store/SavedMap/SavedMap'

import SiteSearchForm from '../SiteSearchForm/SiteSearchForm'
import Heading from '../Heading/Heading'
import LocationThin from '../Icons/LocationThin'
import SearchThin from '../Icons/SearchThin'
import MatchThin from '../Icons/MatchThin'

import css from './BrowseSites.module.scss'

const styles = classnames.bind(css)

const BrowseSites = ({ headingText, path }) => {
    const dispatch = useDispatch()

    // reset everything on the hompage
    useEffect(() => {
        // This component is on the hompage only, but we double check anyway
        if (path === urls.home()) {
            batch(() => {
                dispatch(resetFilters())
                dispatch(resetLocation())
                dispatch(resetMapOptions())
                dispatch(resetOverlay())
                dispatch(resetBoundaries())
                dispatch(resetCustomGeojson())
                dispatch(resetSavedMap())
            })
        }
    }, [dispatch, path])

    return (
        <div className={styles('browse-sites')}>
            <div className={styles('browse-sites__header')} />
            <div className={styles('browse-sites__content')}>
                <div className={styles('browse-sites__content__search')}>
                    <SiteSearchForm
                        path={path}
                        headingText={headingText}
                        hideButtons
                        buttonText="Search now"
                        onChange={() => {
                            navigate(urls.map())
                        }}
                    />
                </div>

                <Heading
                    className={styles('browse-sites__content__title')}
                    text="Welcome to Pitchfinder - The largest database of football pitches in England."
                />

                <div className={styles('browse-sites__content__features')}>
                    <div className={styles('browse-sites__content__feature')}>
                        <LocationThin />
                        <Heading text="Locate" />
                        <span>Find your nearest football pitch</span>
                    </div>

                    <div className={styles('browse-sites__content__feature')}>
                        <SearchThin />
                        <Heading text="Explore" />
                        <span>
                            Explore the different facilities found at the site,
                            and compare with other sites in your area
                        </span>
                    </div>

                    <div className={styles('browse-sites__content__feature')}>
                        <MatchThin />
                        <Heading text="Play" />
                        <span>
                            Learn how to book a slot and secure your pitch
                        </span>
                    </div>
                </div>

                {/* Stub divs used to create shards */}
                <div className={styles('browse-sites__content__shard_left')} />
                <div className={styles('browse-sites__content__shard_right')} />
            </div>

            <div className={styles('browse-sites__branding')}>
                <a
                    href="https://footballfoundation.org.uk"
                    className={styles('browse-sites__branding__logo')}
                >
                    <img
                        src="/images/logos/ff-partner-logo.png"
                        alt="Football Foundation Partners logo"
                    />
                </a>
                <p className={styles('browse-sites__branding__desc')}>
                    The Football Foundation is the charity of the Premier
                    League, The FA and Government, through Sport England. We
                    deliver outstanding grassroots football facilities, meaning
                    more games, better games and more people playing - helping
                    transform communities where demand is greatest and impact
                    will be strongest.
                </p>

                {/* Overlays to create FF branding pattern */}
                <div className={styles('browse-sites__branding__overlay')} />
            </div>

            <footer className={styles('browse-sites__footer')}>
                <ul className={styles('browse-sites__footer__links')}>
                    <li>
                        <a href="/about/">About</a>
                    </li>
                    <li>
                        <a href="/terms/">Terms & Conditions</a>
                    </li>
                    {/* <li> */}
                    {/*     <a href="/static/widget/"> */}
                    {/*         Add pitchfinder to your website */}
                    {/*     </a> */}
                    {/* </li> */}
                </ul>
                <span className={styles('browse-sites__footer__copyright')}>
                    © {new Date().getFullYear()} Football Foundation -
                    Registered charity number 1079309
                </span>
            </footer>
        </div>
    )
}

export default BrowseSites
