import { createSlice } from '@reduxjs/toolkit'

import { updateLocation } from '../Location/Location'
import { getSites } from '../../services/FilterSites/FilterSites'
import { debounce } from '../../utils/utils'

const initialState = {
    loading: null,
    results: [],
    stats: {
        sites: {
            numMatching: 0,
            numWithinBounds: 0,
            ffFundedSites: 0,
        },
        facilities: {
            totalFacilities: 0,
            num3GPitches: 0,
            numGrassPitches: 0,
        },
        teams: {
            numClubsLastSeason: 0,
            numTeamsLastSeason: 0,
        },
        grants: {
            grantsNumPrePipeline: 0,
            grantsPrePipeline: 0,
            grantsProjectValPrePipeline: 0,
            grantsNumApplicationDev: 0,
            grantsApplicationDev: 0,
            grantsProjectValApplicationDev: 0,
            grantsNumAwarded: 0,
            grantsAwarded: 0,
            grantsProjectValAwarded: 0,
            grantsNumPending: 0,
            grantsPending: 0,
            grantsProjectValPending: 0,
        },
    },
}

// LFFP only grant data: https://projects.torchbox.com/projects/ff-pitchfinder/tickets/703
initialState.stats.grantsLffp = { ...initialState.stats.grants }

const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        fetchSitesStart: (state) => {
            state.loading = true
        },
        fetchSitesSuccess: (state, { payload }) => {
            state.loading = false
            state.results = payload.results
            // update stats without overriding existing values in fetchSitesSummarySuccess
            if (!state.stats) {
                state.stats = {}
            }
            state.stats.sites = { ...state.stats.sites, ...payload.stats.sites }
        },
        replaceSitesStats: (state, { payload }) => {
            // these stats should be a superset of the stats added in fetchSitesSuccess above
            state.stats = payload.stats
        },
        fetchSitesError: (state) => {
            state.loading = true
        },
    },
})

const { actions, reducer } = sitesSlice

export const { fetchSitesStart, fetchSitesSuccess, replaceSitesStats } = actions

/**
 * Async Action to get list of sites
 */
const fetchSitesInner = (dispatch, getState) => {
    const state = getState()

    getSites(state)
        .then((response) => {
            dispatch(fetchSitesSuccess(response.data.sites))
            if (response.data.travelBoundary)
                dispatch(
                    updateLocation({
                        travelBoundary: response.data.travelBoundary,
                        travelBoundaryStats: JSON.stringify(
                            response.data.travelBoundaryStats,
                        ), // stringify only to keep reference stable
                    }),
                )
        })
        .catch((e) => {
            if (!e.cancelled) {
                dispatch(actions.fetchSitesError())
            }
        })
}

// Make debounce timeout longer initially but then change it to be shorter
let fetchSitesDebounced = debounce(2500, fetchSitesInner)
setTimeout(() => {
    fetchSitesDebounced = debounce(200, fetchSitesInner)
}, 10000)

export const fetchSites = () => (dispatch, getState) => {
    dispatch(fetchSitesStart())
    fetchSitesDebounced(dispatch, getState)
}

/**
 * This will fake a form submission to be able to download an xls file
 * We (probably) can't tell when that will complete.
 */
export const exportSites = (contentType = 'xls') => (dispatch, getState) => {
    getSites(getState(), contentType)
}

/**
 * Async Action to get the summary stats for sites.
 * This is done separately from fetchSites() because
 * we don't need to fetch this that ofter, see summaryMiddleware.
 */

const fetchSitesSummaryInner = (dispatch, getState) => {
    const state = getState()

    // clear current values first but keep those that are used outside the summary tab
    const oldStats = state.sites?.stats
    if (oldStats) {
        const clearedStats = {
            stats: {
                sites: {
                    numWithinBounds: oldStats.numWithinBounds,
                    numMatching: oldStats.numMatching,
                },
            },
        }
        dispatch(replaceSitesStats(clearedStats))
    }

    getSites(state, 'json', 'summary')
        .then((response) => {
            dispatch(replaceSitesStats(response.data.sites))
        })
        .catch((e) => {
            if (!e.cancelled) {
                dispatch(actions.fetchSitesError())
            }
        })
}

// debounce for a short time, mainly to prevent multiple invocations
// from a series of synchromously dispatched actions
const fetchSitesSummaryDebounced = debounce(200, fetchSitesSummaryInner)

export const fetchSitesSummary = () => (dispatch, getState) => {
    // we're not changing the loading state for the summary
    fetchSitesSummaryDebounced(dispatch, getState)
}

export default reducer
