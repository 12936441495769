import {
    FILTER_GROUP_ICONS_TO_COLOURS,
    FILTER_GROUP_ICON_LABELS,
} from './consts'

export const colors = {
    red: '#fd2415',
    white: '#ffffff',
    green: '#00713c',
    greenTwo: '#009a3a',
    greenThree: '#5AB947',
    greenFour: '#ebf7f2',
    grey: '#1c1c1c',
    greyTwo: '#D8D8D8',
    greyThree: '#B8BFC1',
    purple: '#54448C',
    purpleTwo: '#3c3064',
    purpleThree: '#6e5fa0',
}

export const selectColors = Object.entries(FILTER_GROUP_ICONS_TO_COLOURS).map(
    ([value, color]) => ({
        value,
        color,
        label: FILTER_GROUP_ICON_LABELS[value],
    }),
)

export const fonts = {
    body: `'Montserrat', sans-serif`,
    heading: `'Barlow Condensed', sans-serif`,
}

export const LOCATION_PIN_COLOUR = '#EA5064'
