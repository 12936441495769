import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import classnames from 'classnames/bind'
import css from './FilterValuesAsyncSelect.module.scss'
import { defaultStyles } from '../InputSelect/reactSelectStyles'
import { getForeignKeyValuesDebounced } from '../../services/AutoComplete/AutoComplete'
import {
    ClearIndicator,
    NoOptionsMessage,
    Placeholder,
    CustomOption,
} from '../InputSelect/Components'

const styles = classnames.bind(css)

const FilterValuesAsyncSelect = ({
    value,
    isMulti,
    operator,
    options,
    handleChange,
    className,
    configId,
    ...rest
}) => {
    const loadOptions = async (inputValue) => {
        // If we have an initial set of options that always includes all possible values.
        // Passing options={options} would be easier but doesn't seem to work with AsyncSelect.
        let cleanedInputValue = inputValue
        // TODO: https://github.com/farzher/fuzzysort
        if (options) {
            if (cleanedInputValue.trim()) {
                cleanedInputValue = cleanedInputValue.toLowerCase().trim()
                return options.filter(({ label }) =>
                    label.toLowerCase().includes(cleanedInputValue),
                )
            }
            return options
        }

        // If there are no initial options then we always have to make a request
        const { notShown, results } = await getForeignKeyValuesDebounced(
            configId,
            cleanedInputValue,
        )

        return notShown > 0 ? [...results, { notShown }] : results
    }

    useEffect(() => {
        // When switching back from multi to single select then we need to
        // drop any additional values and keep only the first one.
        if (!isMulti && Array.isArray(value)) {
            handleChange(value[0])
        }
        // When switching from single to multi select then we need to ensure this is a list.
        if (isMulti && !Array.isArray(value)) {
            handleChange([value])
        }
    }, [isMulti, value, handleChange])

    return (
        <div className={styles('input-select', className)}>
            <AsyncSelect
                isMulti={isMulti}
                value={
                    operator === 'between' && value?.length > 2
                        ? value.slice(0, 2)
                        : value
                }
                isClearable
                defaultOptions
                cacheOptions={false} // disabled, doesn't work properly
                loadOptions={loadOptions}
                components={{
                    ClearIndicator,
                    NoOptionsMessage,
                    Placeholder,
                    Option: CustomOption,
                    // No Dropdown Indicator because this should look like a TextInput
                    DropdownIndicator: null,
                }}
                styles={defaultStyles()}
                onChange={(option) => {
                    handleChange(option)
                }}
                {...rest}
            />
        </div>
    )
}

const optionPropType = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
})

FilterValuesAsyncSelect.propTypes = {
    value: PropTypes.oneOfType([
        optionPropType,
        PropTypes.arrayOf(optionPropType),
    ]),
    options: PropTypes.arrayOf(optionPropType),
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default FilterValuesAsyncSelect
