import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import classnames from 'classnames/bind'

import css from './InlineLink.module.scss'

const styles = classnames.bind(css)

const InlineLink = ({ to, external, children, className }) => {
    return (
        <>
            {external ? (
                <a className={styles('inline-link', className)} href={to}>
                    {children}
                </a>
            ) : (
                <Link className={styles('inline-link', className)} to={to}>
                    {children}
                </Link>
            )}
        </>
    )
}

InlineLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default InlineLink
