import React from 'react'
import PropTypes from 'prop-types'

const Jersey = ({ fill, ...rest }) => (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M11 0c1.6568542 0 3 1.34314575 3 3 0 1.04261582-.5318671 1.96100742-1.3390822 2.49865559l9.3484816 2.79919452v1.06573632L22 9.36v3.9378502l-4.016-1.2016787.0006323 10.0163774H3.99658495L3.996 12.1021715 0 13.2978502V8.29785023L.137 8.256l10.717-3.209L11 5c1.1045695 0 2-.8954305 2-2s-.8954305-2-2-2c-1.0543618 0-1.91816512.81587779-1.99451426 1.85073766L9 3H8c0-1.65685425 1.34314575-3 3-3zm.005 6.113L7.05541985 7.2805298c.76670845 1.38446267 2.2415971 2.32164172 3.93518875 2.32164172 1.6970319 0 3.1744714-.94099034 3.9398523-2.33008412L11.005 6.113z"
            fill={fill}
            fillRule="evenodd"
        />
    </svg>
)

Jersey.defaultProps = {
    fill: null,
}

Jersey.propTypes = {
    fill: PropTypes.string,
}

export default Jersey
