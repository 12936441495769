import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames/bind'
import { createPopupClass } from './createPopupClass'

import CrossIcon from '../Icons/Cross'

import css from './createPopupStyles.scss'

const styles = classnames.bind(css)

const MapPopupClose = ({ handleClose }) => (
    // eslint-disable-next-line
    <button className={styles('map-popup__close')} onClick={handleClose}>
        <CrossIcon className={styles('map-popup__close-icon')} />
    </button>
)

const MapPopup = ({ map, lat, lng, handleClose, children }) => {
    useEffect(() => {
        const emptyDiv = document.createElement('div')

        const Popup = createPopupClass()

        const position = new window.google.maps.LatLng(lat, lng)

        const popup = new Popup(position, emptyDiv)

        popup.setMap(map)

        ReactDOM.render(
            <>
                {children}
                <MapPopupClose handleClose={handleClose} />
            </>,
            emptyDiv,
        )

        return () => {
            popup.setMap(null)
        }
    }, [map, handleClose, children, lat, lng])

    return null
}

export default MapPopup
