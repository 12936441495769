import React from 'react'
import PropTypes from 'prop-types'

const Accessible = ({ fill, ...rest }) => (
    <svg width="14" height="20" viewBox="0 0 14 20" {...rest}>
        <path
            fill={fill}
            d="M14 11V9c-1.5 0-3-.8-4-1.8L8.5 5.7a2 2 0 0 0-.6-.4A2 2 0 0 0 6.8 5 2 2 0 0 0 5 7v6c0 1.1.9 2 2 2h5v5h2v-5.5a2 2 0 0 0-2-2H9V9.1c1.3 1 3.3 1.9 5 1.9zm-6.2 5A3 3 0 1 1 4 12.2V10A5 5 0 1 0 9.9 16h-2zM7 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        />
    </svg>
)

Accessible.defaultProps = {
    fill: null,
}

Accessible.propTypes = {
    fill: PropTypes.string,
}

export default Accessible
