import { isInitialLocation } from '../../utils/utils'
import { DATA_LAYER_Z_INDEX } from '../../consts/consts'

// Based on:
// http://www.geocodezip.com/v3_polygon_example_radiusCircle.html
export const drawCircle = (point, radius, bounds) => {
    const d2r = Math.PI / 180 // degrees to radians
    const r2d = 180 / Math.PI // radians to degrees
    const earthsradius = 3963 // 3963 is the radius of the earth in miles

    const points = 128

    // find the radius in lat/lon
    const rlat = (radius / earthsradius) * r2d
    const rlng = rlat / Math.cos(point.lat() * d2r)

    const extp = []

    const start = points + 1
    const end = 0

    for (let i = start; i > end; i -= 1) {
        const theta = Math.PI * (i / (points / 2))
        const ey = point.lng() + rlng * Math.cos(theta) // center a + radius x * cos(theta)
        const ex = point.lat() + rlat * Math.sin(theta) // center b + radius y * sin(theta)
        extp.push(new google.maps.LatLng(ex, ey))
        bounds.extend(extp[extp.length - 1])
    }

    return extp
}

/**
 * Creates the tooltip that is shown
 * when you hover over a drawn polygon or an overlay feature object
 */
export const createOverlayTooltip = (mapEl) => {
    const tooltip = document.createElement('div')
    tooltip.setAttribute('id', 'map-tooltip')
    tooltip.style.zIndex = DATA_LAYER_Z_INDEX.length

    const rootEl = document.getElementById('root')
    rootEl.appendChild(tooltip)

    const mouseMove = (e) => {
        tooltip.style.left = `${e.pageX + 20}px`
        tooltip.style.top = `${e.pageY + 20}px`
    }

    mapEl.addEventListener('mousemove', mouseMove)

    const touchStart = (e) => {
        const { pageX, pageY } = e.touches[0]
        tooltip.style.left = `${Math.max(0, pageX - 80)}px`
        tooltip.style.top = `${Math.max(0, pageY - 60)}px`
    }

    mapEl.addEventListener('touchstart', touchStart)

    function show(content) {
        tooltip.innerHTML = content
        tooltip.style.display = 'block'
    }

    function hide() {
        tooltip.style.display = 'none'
    }

    function remove() {
        mapEl.removeEventListener('mousemove', mouseMove)
        mapEl.removeEventListener('touchstart', touchStart)
        tooltip.remove()
    }

    return {
        show,
        hide,
        remove,
    }
}

export const extendBounds = (bounds, value) => {
    if (value instanceof google.maps.Data) {
        value.forEach((f) => {
            if (f.getGeometry().getArray) {
                f.getGeometry()
                    .getArray()
                    .forEach((f) => {
                        if (f.getArray) {
                            f.getArray().forEach((f) => {
                                if (f.getArray) {
                                    f.getArray().forEach((f) => {
                                        bounds.extend(f)
                                    })
                                } else {
                                    bounds.extend(f)
                                }
                            })
                        } else {
                            bounds.extend(f)
                        }
                    })
            } else {
                f.getGeometry().forEachLatLng((f) => {
                    bounds.extend(f)
                })
            }
        })
    } else if (value instanceof google.maps.LatLngBounds) {
        bounds.union(value)
    } else if (value instanceof google.maps.LatLng) {
        if (!isInitialLocation(value.lat(), value.lng())) {
            bounds.extend(value)
        }
    } else if (value instanceof google.maps.Polygon) {
        value.getPath().forEach((e) => bounds.extend(e))
    } else {
        const msg = `extendBounds: unkown value: ${value}`
        console.error(msg)
        if (window.Sentry) {
            Sentry.captureException(new Error(msg))
        }
    }
}

export const latLng2PointRelative = (latLng) => {
    const { map } = window
    const proj = map.getProjection()
    const topRight = proj.fromLatLngToPoint(map.getBounds().getNorthEast())
    const bottomLeft = proj.fromLatLngToPoint(map.getBounds().getSouthWest())
    const scale = 2 ** map.getZoom()
    const worldPoint = proj.fromLatLngToPoint(latLng)
    return new google.maps.Point(
        (worldPoint.x - bottomLeft.x) * scale,
        (worldPoint.y - topRight.y) * scale,
    )
}

export const latLng2PointAbsolute = (latLng) => {
    const point = latLng2PointRelative(latLng)
    const { top, left } = window.map.getDiv().getBoundingClientRect()
    return {
        x: point.x + left,
        y: point.y + top,
    }
}

export const point2LatLngRelative = (point) => {
    const { map } = window
    const proj = map.getProjection()
    const topRight = proj.fromLatLngToPoint(map.getBounds().getNorthEast())
    const bottomLeft = proj.fromLatLngToPoint(map.getBounds().getSouthWest())
    const scale = 2 ** map.getZoom()
    const worldPoint = new google.maps.Point(
        point.x / scale + bottomLeft.x,
        point.y / scale + topRight.y,
    )
    return proj.fromPointToLatLng(worldPoint)
}

export const point2LatLngAbsolute = (point) => {
    const { top, left } = window.map.getDiv().getBoundingClientRect()
    return point2LatLngRelative({
        y: point.y - top,
        x: point.x - left,
    })
}

export const increaseBounds = (bounds, ratio) => {
    const pointNorthEast = bounds.getNorthEast()
    const pointSouthWest = bounds.getSouthWest()
    const latAdjustment =
        (pointNorthEast.lat() - pointSouthWest.lat()) * (ratio - 1)
    const lngAdjustment =
        (pointNorthEast.lng() - pointSouthWest.lng()) * (ratio - 1)
    const newPointNorthEast = new google.maps.LatLng(
        pointNorthEast.lat() + latAdjustment,
        pointNorthEast.lng() + lngAdjustment,
    )
    const newPointSouthWest = new google.maps.LatLng(
        pointSouthWest.lat() - latAdjustment,
        pointSouthWest.lng() - lngAdjustment,
    )
    bounds.extend(newPointNorthEast)
    bounds.extend(newPointSouthWest)
}

// JSON.stringify([{lat: map.getBounds().getSouthWest().lat(), lng: map.getBounds().getSouthWest().lng()}, {lat: map.getBounds().getNorthEast().lat(), lng: map.getBounds().getNorthEast().lng()}])
const iom = [
    { lat: 54.09179700927391, lng: -4.743194868776932 },
    { lat: 54.38159275143753, lng: -4.334640791628495 },
]
const guernsey = [
    { lat: 49.41682769327902, lng: -2.653176370849619 },
    { lat: 49.505767516675924, lng: -2.5201388037109473 },
]
const jersey = [
    { lat: 49.16798149078218, lng: -2.239361033892282 },
    { lat: 49.25737172692681, lng: -2.03618753573487 },
]

const islands = { iom, guernsey, jersey }

export const getBoundsIfIsland = ({ lat, lng }) => {
    if (!lat) {
        return undefined
    }
    const [bounds] = Object.values(islands)
        .map((island) => new google.maps.LatLngBounds(...island))
        .filter((bounds) => bounds.contains({ lat, lng }))

    return bounds
}
