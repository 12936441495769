import React, { useRef, useEffect, useContext } from 'react'

import {
    FILTER_GROUP_ICONS_TO_COLOURS,
    DATA_LAYER_Z_INDEX,
} from '../../consts/consts'

import {
    colors as styleVariables,
    LOCATION_PIN_COLOUR,
} from '../../consts/styleVariables'

import { UiContext } from '../../context/UiContext'

import { memoWithObjectArg } from '../../utils/utils'

const zIndex = DATA_LAYER_Z_INDEX.indexOf('marker')

const iconWidth = 26
const iconHeight = 30

const clusterIcon = ({ size }) => {
    const radius = 4 * Math.log10(150 * size)
    const strokeWidth = 1
    const boxSize = radius * 2 + strokeWidth * 2
    const clusterIcon = `
        <svg
            width="${boxSize}px"
            height="${boxSize}px"
            viewBox="0 0 ${boxSize} ${boxSize}"
            version="1.1" xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                shape-rendering="optimizeQuality"
                cx="${radius + strokeWidth}"
                cy="${radius + strokeWidth}"
                r="${radius}"
                stroke-width="${strokeWidth}"
                stroke="#54448C"
                fill="#00AD8E"
            />
            <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="12px" font-family="Montserrat, sans-serif" dy=".3em">
                ${size}
            </text>
        </svg>`
    const icon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
            clusterIcon,
        )}`,
        scaledSize: new google.maps.Size(boxSize, boxSize), // size
    }
    return icon
}

const createPieChart = ({ cx, cy, r, colors = [], circleStrokeColor }) => {
    const commonAttrs = `stroke="${circleStrokeColor}" stroke-width="1"`

    if (colors.length === 1) {
        return `<circle ${commonAttrs} r="${r}" cx="${cx}" cy="${cy}" fill="${colors[0]}"  />`
    }

    if (colors.length > 1) {
        const paths = []
        for (let i = 0; i < colors.length; i += 1) {
            const fromAngle = (i * 360) / colors.length
            const toAngle = ((i + 1) * 360) / colors.length
            const fromCoordX = cx + r * Math.cos((fromAngle * Math.PI) / 180)
            const fromCoordY = cy + r * Math.sin((fromAngle * Math.PI) / 180)
            const toCoordX = cx + r * Math.cos((toAngle * Math.PI) / 180)
            const toCoordY = cy + r * Math.sin((toAngle * Math.PI) / 180)
            const d = `M${cx},${cy} L${fromCoordX},${fromCoordY} A${r},${r} 0 0,1 ${toCoordX},${toCoordY}z'`
            const path = `<path ${commonAttrs} d="${d}" fill="${colors[i]}" />`
            paths.push(path)
        }
        return paths.join('\n')
    }

    return ''
}

export const encodedMarkerIcon = memoWithObjectArg(
    ({
        colors = [],
        pinFillColor = styleVariables.purple,
        pinStrokeColor = '#fff',
        circleStrokeColor = '#fff',
        shadowColor = styleVariables.grey,
        isActive,
        ffFunded,
        size,
        scale,
    }) => {
        // Cluster icon
        if (size !== 1) {
            return clusterIcon({ size })
        }

        // stack circles on top of each other
        // Icon for a single site, not a cluster with multiple sites
        // const cyOffset = 12 - colors.length
        // const width = 7 - colors.length / 2
        // Generate a dot for each filter group color and offset
        // prettier-ignore
        // let markerContent = colors.length > 0
        //     ? colors
        //         .map((color, index) => {
        //             const cy = `${index * 4 + cyOffset}`
        //             const actualColor = FILTER_GROUP_ICONS_TO_COLOURS[color] || color
        //             return `<circle stroke-width="1" stroke="${circleStrokeColor}" fill="${actualColor}" cx="11.5" cy="${cy}" r="${width}" />`
        //         })
        //         .join('')
        //     : ''

        // use a single circle divided up like a pie chart
        const markerContent = createPieChart({
            r: 7,
            cx: 11.5,
            cy: 11.5,
            colors,
            circleStrokeColor,
        })

        // Change pin color if is funded, or use the default
        const pinFill = ffFunded ? styleVariables.greenTwo : pinFillColor

        const markerIcon = `
        <svg width="${iconWidth}px" height="${iconHeight}px" viewBox="0 0 ${iconWidth} ${iconHeight +
            2}" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <ellipse
                fill="${shadowColor}"
                opacity=".4"
                cx="11.5"
                cy="30"
                rx="5"
                ry="2"
            />
            <path
                d="M1.292 11.125c0 7.656 10.208 18.958 10.208 18.958s10.208-11.302 10.208-18.958A10.2 10.2 0 0 0 11.5.917 10.2 10.2 0 0 0 1.292 11.125z"
                stroke="${pinStrokeColor}"
                fill="${isActive ? styleVariables.grey : pinFill}"
            />
            ${markerContent}
        </svg>`

        const icon = {
            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                markerIcon,
            )}`,
            scaledSize: new google.maps.Size(
                iconWidth * scale,
                iconHeight * scale,
            ),
        }
        return icon
    },
)

export const searchLocationIcon = () => {
    // NB this should match LocationPin from '../Icons/LocationPin'
    // but watch out for differences between fillRule vs fill-rule
    const searchIcon = `
        <svg width="${iconWidth}px" height="${iconHeight}px" viewBox="0 0 ${iconWidth} ${iconHeight +
        6}" xmlns="http://www.w3.org/2000/svg" style="pointer-events: none;">
            <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
                <g fill-rule="nonzero">
                    <ellipse fill="#1C1C1C" opacity=".4" cx="11" cy="31.803" rx="6.735" ry="2.62"/>
                    <path d="M11 0C4.919 0 0 4.919 0 11c0 8.25 11 20.429 11 20.429S22 19.25 22 11c0-6.081-4.919-11-11-11z" stroke="#FFF" stroke-width="1.571" fill="${LOCATION_PIN_COLOUR}"/>
                </g>
                <circle stroke="#FFF" stroke-width="1.2" cx="11" cy="11" r="4.4"/>
                <path stroke="#FFF" stroke-width="1.2" d="M11 5v12M17 11H5"/>
            </g>
        </svg>
    `

    const icon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
            searchIcon,
        )}`,
        scaledSize: new google.maps.Size(iconWidth * 1.4, iconHeight * 1.4),
    }
    return icon
}

export const MapMarker = ({
    map,
    lat,
    lng,
    title,
    id,
    colors,
    ffFunded,
    allowClick,
    cluster,
    size,
    scale,
    hidden,
}) => {
    const { activeSiteId, setActiveSiteId: _setActiveSiteId } = useContext(
        UiContext,
    )

    // Set active site id only for individual sites but not for clusters
    const setActiveSiteId = cluster ? () => {} : _setActiveSiteId

    const marker = useRef(null)

    const markerIconDefaults = { colors, ffFunded, size, scale }
    const defaultMarkerIcon = encodedMarkerIcon(markerIconDefaults)
    const highlightedMarkerIcon = encodedMarkerIcon({
        ...markerIconDefaults,
        isActive: true,
    })

    // Create marker and remove marker.
    // Don't add any other deps here that would change the marker while it's visible.
    useEffect(() => {
        marker.current = new google.maps.Marker({ zIndex })
        return () => {
            window.markers.delete(marker.current)
            marker.current.setMap(null)
            marker.current = null
        }
    }, [map])

    // Set position, title, icon
    useEffect(() => {
        marker.current.setPosition(new google.maps.LatLng(lat, lng))
        marker.current.setTitle(title)
        marker.current.setIcon(defaultMarkerIcon)
        // Add it to the map only now. This might speed things up
        // if google doesn't make these changes in batch already.
        // (But don't show hidden markers, e.g. when we use a different site marker from custom geojson)
        marker.current.setMap(hidden ? null : map)
        window.markers.add(marker.current)
    }, [lat, lng, title, id, map, setActiveSiteId, defaultMarkerIcon, hidden])

    // toggle marker highlight
    useEffect(() => {
        if (String(activeSiteId) === String(id)) {
            marker.current.setIcon(highlightedMarkerIcon)
        } else {
            marker.current.setIcon(defaultMarkerIcon)
        }
    }, [id, activeSiteId, defaultMarkerIcon, highlightedMarkerIcon])

    // rebind event listeners
    useEffect(() => {
        if (allowClick) {
            marker.current.setClickable(true)
            marker.current.addListener('click', () => {
                setActiveSiteId(id)
            })
        } else {
            marker.current.setClickable(false)
            google.maps.event.clearListeners(marker.current, 'click')
        }
    }, [allowClick, id, setActiveSiteId])

    return null
}

export default MapMarker

export const MapMarkers = ({ markers, map, allowClick, hiddenMarkers }) => {
    // scale is 1 if there aren't multiple colours, and gets bigger depending on the number of colours
    const maxColoursPerSite = Math.max(
        ...markers.map(
            ({ icons }) =>
                (icons?.length &&
                    new Set(
                        icons.map((c) => FILTER_GROUP_ICONS_TO_COLOURS[c] || c),
                    ).size) ||
                0,
        ),
    )
    const scale = 1 + Math.min(1, Math.max(0, (maxColoursPerSite - 1) / 5))

    // under_score properties are automatically converted to camelCase
    return markers.map(
        ({ siteName: title, lat, lng, id, icons, ffFunded, cluster, size }) => {
            const colors = (icons || [])
                .filter(Boolean)
                .map((c) => FILTER_GROUP_ICONS_TO_COLOURS[c] || c)
            return (
                <MapMarker
                    id={id}
                    title={title}
                    key={id}
                    map={map}
                    lat={lat}
                    lng={lng}
                    colors={colors.length ? colors : undefined}
                    ffFunded={ffFunded}
                    allowClick={allowClick}
                    cluster={cluster}
                    size={size}
                    scale={scale}
                    hidden={hiddenMarkers.indexOf(id) !== -1}
                />
            )
        },
    )
}
