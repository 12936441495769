import React from 'react'
import classnames from 'classnames/bind'
import css from './SiteInfo.module.scss'
import { toCamel, capitalize } from '../../utils/utils'
import Boxout from '../Boxout/Boxout'
import Detail from '../Detail/Detail'
import { SiteInfoDetailGrid } from './SiteInfoUtils'

const styles = classnames.bind(css)

const SiteInfoDisability = ({ site }) => {
    const fields = [
        'disability_parking',
        'disability_activity_areas',
        'disability_social_areas',
        'disability_spectator_areas',
        'disability_changing_facilities',
        'disability_toilets',
        'disability_finding_and_reaching_the_entrance',
        'disability_reception_area',
        'disability_doorways',
        'disability_emergency_exits',
    ]

    return (
        <Boxout
            title="DISABILITY"
            className={styles('site-information__boxout')}
        >
            <SiteInfoDetailGrid>
                {fields.map((f) => (
                    <Detail
                        key={f}
                        label={f
                            .split('_')
                            .map(capitalize)
                            .join(' ')
                            .replace('The', 'the')
                            .replace('And', 'and')}
                        value={site.overview[toCamel(f)] ?? 'N/A'}
                        className={styles('site-information__detail')}
                    />
                ))}
                <Detail
                    label="Disability Notes"
                    value={site.overview.disabilityNotes || 'N/A'} // N/A for empty string too
                    className={styles('site-information__detail')}
                />
            </SiteInfoDetailGrid>
        </Boxout>
    )
}
export default SiteInfoDisability
