import React from 'react'
import { ACTIVE_MODAL_FILTER_SELECTOR } from '../../consts/consts'

import FilterSelector from '../FilterSelector/FilterSelector'
import Modal, { useModal } from '../Modal/Modal'
import Close from '../Close/Close'
import { ConfigNotLoaded } from '../Selector/Selector'
import { usePromiseGlobal } from '../../hooks/hooks'
import { getConfig } from '../../services/Config/Config'

export const removeEmptyAndSecondaryFilters = (config) =>
    config.reduce((acc, curr) => {
        const filteredConfig = {
            ...curr,
            options: curr.options
                .filter(({ secondary }) => secondary !== true)
                .filter(({ hidden }) => !hidden),
        }

        return filteredConfig.options.length > 0
            ? [...acc, filteredConfig]
            : acc
    }, [])

const FilterSelectorModal = () => {
    const [config, loading, error] = usePromiseGlobal(getConfig)

    const { activeModal, closeModal } = useModal()

    const filteredConfig =
        config && removeEmptyAndSecondaryFilters(config.filters)

    return (
        <>
            {activeModal === ACTIVE_MODAL_FILTER_SELECTOR && (
                <Modal titleText="Filter Selector">
                    {error || loading ? (
                        <ConfigNotLoaded label="filters" error={error} />
                    ) : (
                        <FilterSelector
                            config={config.filters}
                            filteredConfig={filteredConfig}
                            closeModal={closeModal}
                        />
                    )}
                    <Close handleClick={closeModal} />
                </Modal>
            )}
        </>
    )
}

export default FilterSelectorModal
