import { createSlice } from '@reduxjs/toolkit'

import { removeFirstOccurrence, sample } from '../../utils/utils'
import { selectColors } from '../../consts/styleVariables'

import { fetchSites } from '../Sites/Sites'

const initialState = {}

const ensureFilterGroupColours = (state) => {
    const filterGroups = Object.values(state)
    if (filterGroups.length > 1) {
        filterGroups.forEach((filterGroup) => {
            const allColours = selectColors.map(({ color }) => color)
            const usedColours = new Set(filterGroups.map(({ icon }) => icon))
            const unusedColours = allColours.filter((c) => !usedColours.has(c))
            if (unusedColours.length === 0) {
                unusedColours.push(sample(allColours))
            }
            filterGroup.icon = filterGroup.icon || sample(unusedColours)
        })
    }
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        replaceFilters: (state, { payload }) => {
            ensureFilterGroupColours(state)
            return payload
        },
        resetFilters: () => initialState,
        updateGroup: (state, { payload: { filterGroupId, ...rest } }) => {
            state[filterGroupId] = {
                ...state[filterGroupId],
                filterGroupId,
                ...rest,
            }
            ensureFilterGroupColours(state)
        },
        removeGroup: (state, { payload: { filterGroupId } }) => {
            delete state[filterGroupId]
        },
        updateFilter: (state, { payload }) => {
            const rule = state[payload.filterGroupId].rules.find(
                ({ id }) => id === payload.id,
            )
            if (rule) {
                Object.assign(rule, payload)
            } else {
                state[payload.filterGroupId].rules.push(payload)
            }
        },
        removeFilter: (state, { payload: { filterGroupId, id } }) => {
            const rule = state[filterGroupId].rules.find(
                ({ id: _id }) => _id === id,
            )
            if (rule) {
                // A rule appears only once in the `rules` array.
                removeFirstOccurrence(state[filterGroupId].rules, rule)
            }
        },
    },
})

const { actions, reducer } = filtersSlice

// Never use updateFilter, removeFilter directly!
// They are exported for tests only.
// Use applyFilter and unapplyFilter instead.
export const {
    replaceFilters,
    resetFilters,
    updateFilter,
    removeFilter,
    updateGroup,
    removeGroup,
} = actions

/**
 * Updates the filter in state,
 * then dispatches a fetch action
 */
export const applyFilter = (filterObj) => (dispatch) => {
    dispatch(actions.updateFilter(filterObj))

    dispatch(fetchSites())
}

/**
 * Removes the filter from state
 * then dispatches a fetch action
 */
export const unapplyFilter = ({ id, filterGroupId }) => (dispatch) => {
    dispatch(actions.removeFilter({ id, filterGroupId }))

    dispatch(fetchSites())
}

/**
 * Removes the group from state
 * then dispatches a fetch action
 */
export const unapplyGroup = ({ filterGroupId }) => (dispatch) => {
    dispatch(actions.removeGroup({ filterGroupId }))

    dispatch(fetchSites())
}

/**
 * Update the group in state
 * then dispatches a fetch action
 */
export const applyGroup = ({ filterGroupId, ...rest }) => (dispatch) => {
    dispatch(actions.updateGroup({ filterGroupId, ...rest }))

    dispatch(fetchSites())
}

export default reducer
