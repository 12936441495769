import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './FacilityCount.module.scss'

const styles = classnames.bind(css)

const FacilityCount = ({ number, name, className, darkTheme }) => (
    <div
        className={styles(
            'facility-count',
            {
                'facility-count--dark-theme': darkTheme,
            },
            className,
        )}
    >
        <span className={styles('facility-count__number')}>{number}</span>
        <span className={styles('facility-count__label')}>{name}</span>
    </div>
)

FacilityCount.defaultProps = {
    className: null,
}

FacilityCount.propTypes = {
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default FacilityCount
