import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    // default position, which can be null when multiple areas are selected
    lat: 53.3,
    lng: -4.6,
    // type: 'query' | 'area' | 'site' | 'pinpoint' | 'geolocation' depending on what is selected from the autocomplete
    // value: search query, gss code, or list of area objects, i.e. {value, label, level, lat, lng}
    // label
    // radius: non-zero number | null
    // area:  {label, value} | [{label, value}] | null // single area selected from the region tab, which can be used with e.g. postcode search
    // gssCodeOverride  // when a custom gss code is added to a saved map url
    // level: key of LAYER_CODES_AND_MODELS, needed to restrict multiple areas to the same are level
    // areasAtLocation:  non-empty array | null
    // travelMode: string
    // travelTime: minutes
    // loading  // if we have areas selected or a gss code override then we need to wait until we get the boundaries
    //             (could be also used if while we're geocoing for a search query in the url)
}

const removeEmpty = (obj) => {
    // Remove null (or undefined) from state object, just to make it easier to see in dev tools
    Object.keys(obj)
        .filter((key) => key !== 'radius') // undefined and null are not the same for the radius
        .forEach((key) => obj[key] == null && delete obj[key])
    return obj
}

const handleTypeArea = (newState) => {
    if (newState.type !== 'area') {
        throw new Error()
    }
    // If an area is selected from the autocomplete then clear all other values.
    delete newState.lat
    delete newState.lng
    delete newState.radius
    delete newState.travelMode
    delete newState.travelTime
    delete newState.areasAtLocation
    // set loading state until we get the boundaries
    if (newState.area?.length) {
        newState.loading = true
    }
    if (Array.isArray(newState.value)) {
        newState.label = newState.value.map(({ label }) => label).join(', ')
    }
}

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        resetLocation: () => initialState,
        replaceLocation: (state, { payload }) => {
            if (payload.type === 'area') {
                handleTypeArea(payload)
                // if the location was cleared
                if (!payload.value?.length) {
                    return initialState
                }
            }
            return payload
        },
        forceUpdateLocation: (state, { payload }) => ({
            ...state,
            ...payload,
        }),
        updateLocation: (state, { payload }) => {
            const newState = removeEmpty({ ...state, ...payload })

            if (newState.type === 'area') {
                handleTypeArea(newState)
                // if the location was cleared
                if (!newState.value?.length) {
                    return initialState
                }
            } else if (!newState.lat) {
                // When the location is cleared then we won't have any coordinates
                // in the payload, so we reset to the default map state.
                // TODO: it would be better to do this with `resetLocation` instead
                // TODO: reset zoom too?
                return initialState
            }

            return newState
        },
        overrideGssCode: (state, { payload }) => {
            return {
                value: payload,
                gssCodeOverride: payload,
                type: 'area',
                // Keep the current coords for now: they will be updated along with the label
                // when the boundaries load from the useArea() hook.
                lat: state.lat,
                lng: state.lng,
                label: 'Loading...',
                loading: true,
                // discard all other location state
            }
        },
        setLocationLoading: (state, { payload }) => {
            state.loading = payload
        },
    },
})

const { actions, reducer } = locationSlice

export const {
    resetLocation,
    replaceLocation,
    updateLocation,
    forceUpdateLocation,
    overrideGssCode,
    setLocationLoading,
} = actions

export default reducer
