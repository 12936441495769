import React from 'react'
import PropTypes from 'prop-types'

const DownArrow = ({ fill, ...rest }) => (
    <svg width="14px" height="7px" viewBox="0 0 14 7" {...rest}>
        <g>
            <polygon
                fill={fill}
                transform="translate(7.000000, 3.500000) rotate(-180.000000) translate(-7.000000, -3.500000) "
                points="7 0 14 7 0 7"
            />
        </g>
    </svg>
)

DownArrow.defaultProps = {
    fill: null,
}

DownArrow.propTypes = {
    fill: PropTypes.string,
}

export default DownArrow
