import React from 'react'
import classnames from 'classnames/bind'
import css from './FilterCard.module.scss'

import { operatorLabels } from '../ComposedInput/ComposedInput'
import DeleteFilterButton from '../DeleteFilterButton/DeleteFilterButton'

const styles = classnames.bind(css)

const formatFilterValue = (value, operator) => {
    if (Array.isArray(value)) {
        return value.map(formatFilterValue).join(', ')
    }

    // prettier-ignore
    if (['is_empty', 'is_not_empty', 'is_null', 'is_not_null'].includes(operator)) {
        return ''
    }

    return value.label || value
}

const FilterCard = ({
    id,
    label,
    operator,
    value,
    quick,
    handleClick,
    handleDelete,
    disabled,
}) => (
    <div className={styles('filter-card')}>
        <button
            type="button"
            className={styles('filter-card__edit-button')}
            disabled={disabled}
            onClick={() => {
                handleClick(id)
            }}
        >
            <span className={styles('filter-card__label')}>{label}</span>
            <span className={styles('filter-card__value')}>
                {operatorLabels[operator]}{' '}
                {quick ? '' : formatFilterValue(value, operator)}
            </span>
        </button>
        {!disabled && (
            <DeleteFilterButton
                className={styles('filter-card__delete-button')}
                handleClick={() => {
                    handleDelete(id)
                }}
            />
        )}
    </div>
)

export default FilterCard
