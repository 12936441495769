import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import Heading from '../Heading/Heading'
import InputText from '../InputText/InputText'
import ColorValuesSelect from '../ColorValuesSelect/ColorValuesSelect'
import Tooltip from '../Tooltip/Tooltip'

import css from './FilterGroupMeta.module.scss'

const styles = classnames.bind(css)

const FilterGroupMeta = ({
    labelValue,
    handleChangeLabel,
    color,
    handleChangeColor,
    colorOptions,
}) => {
    const moreThanOneFilterGroup = useSelector(
        ({ filters }) => Object.keys(filters).length > 1,
    )

    return (
        <div className={styles('filter-group-meta')}>
            <div className={styles('filter-group-meta__column-text')}>
                <Heading
                    heading="h4"
                    text="Your filter group title"
                    isSubHeading
                />
                <InputText
                    className={styles('filter-group-meta__input')}
                    type="text"
                    value={labelValue}
                    placeholder={labelValue}
                    handleChange={handleChangeLabel}
                    aria-labelledby="filter_group_name"
                />
                <span id="filter_group_name" className="visually-hidden">
                    Enter a filter group name
                </span>
            </div>
            <div className={styles('filter-group-meta__column-select')}>
                <Heading
                    heading="h4"
                    text="Your filter group colour"
                    isSubHeading
                    className={styles('filter-group-form__column-heading')}
                />
                {!moreThanOneFilterGroup && (
                    <Tooltip content="Colours will display after adding a second filter group" />
                )}
                <ColorValuesSelect
                    className={styles('filter-group-meta__input')}
                    color={color}
                    options={colorOptions}
                    handleChangeColor={handleChangeColor}
                    required={moreThanOneFilterGroup}
                />
            </div>
        </div>
    )
}

FilterGroupMeta.defaultProps = {
    labelValue: '',
    color: null,
}

FilterGroupMeta.propTypes = {
    labelValue: PropTypes.string,
    color: PropTypes.string,
    handleChangeLabel: PropTypes.func.isRequired,
    handleChangeColor: PropTypes.func.isRequired,
    colorOptions: PropTypes.array.isRequired,
}

export default FilterGroupMeta
