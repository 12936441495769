import React, { useContext, useRef } from 'react'
import classnames from 'classnames/bind'

import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
    OVERLAY_COLOURS,
    OVERLAY_SCALE_LABELS,
    LABELS,
    PRIORITY_PROJECT_COLOURS,
    FILTER_GROUP_ICONS_TO_COLOURS,
    OVERLAY_DEFAULT_OFFSETS,
} from '../../consts/consts'
import { isInitialLocation } from '../../utils/utils'

import Accordion from '../Accordion/Accordion'
import EyeVisible from '../Icons/EyeVisible'
import EyeHidden from '../Icons/EyeHidden'
import Circle from '../Icons/Circle'
import Square from '../Icons/Square'
import LocationPinIcon from '../Icons/LocationPin'
import FundedPinIcon from '../Icons/FundedPin'
import SitePinIcon from '../Icons/SitePin'
import CrossIcon from '../Icons/Cross'
import Loading from '../Loading/Loading'
import { UiContext } from '../../context/UiContext'
import { removeCustomGeojson } from '../../store/CustomGeojson/CustomGeojson'
import { toggleOffset } from '../../store/Overlay/Overlay'

import css from './MapKey.module.scss'

const styles = classnames.bind(css)

const getOverlayHeader = ({ optgroup, level, value, year }) => {
    const levelLabel = LABELS.levelShort[level]
    if (optgroup === 'deprivation') {
        const overlayLabel = value.substr(0, 1).toUpperCase() + value.substr(1)
        return `${levelLabel}s ranked by their Index of Multiple Deprivation (IMD: ${year}) - ${overlayLabel}:`
    }
    if (optgroup === 'population') {
        const ages = `${value.from} - ${value.to === 90 ? '90+' : value.to}`
        return `${levelLabel}s ranked by their population density: ${ages} years old age range:`
    }
    if (optgroup === 'activelives') {
        return `${LABELS.activeLivesDescriptions[value]} by ${levelLabel}`
    }
    if (optgroup === 'nssec') {
        return `${LABELS.nssecDescriptions[value]} by ${LABELS.levelShort[level]}`
    }
    return null
}

const MapKey = ({ className }) => {
    const { isTablet } = useContext(UiContext)

    const dispatch = useDispatch()

    const filters = Object.values(useSelector(({ filters }) => filters))

    const customGeojsonList =
        useSelector(({ customGeojson: { list } }) => list) || []

    const { optgroup, level, value, loading, year } = useSelector(
        ({ overlay }) => overlay,
        shallowEqual,
    )

    const lat = useSelector(({ location: { lat } }) => lat)
    const lng = useSelector(({ location: { lng } }) => lng)
    const hasSearchLocationPin =
        !window.screenshot &&
        !window.siteSelector &&
        !isInitialLocation(lat, lng)

    const labels = OVERLAY_SCALE_LABELS[optgroup]
    const overlayColours = OVERLAY_COLOURS[optgroup]
    const overlayHeader = getOverlayHeader({ optgroup, level, value, year })

    const offsets =
        useSelector(({ overlay }) => overlay.offsets) || OVERLAY_DEFAULT_OFFSETS
    const offsetKeys = Object.keys(offsets)
    const formattedOffsets = offsetKeys
        .slice(0, -1) // select all but the last element
        .map((_, i) => `${offsetKeys[i]} - ${offsetKeys[i + 1]}%`)

    if (optgroup === 'nssec') {
        formattedOffsets[formattedOffsets.length - 1] = '> 60%'
    }

    const zoom = useSelector(({ map: { zoom } }) => zoom) // TODO: remove zoom

    // NB this is used for screenshots only
    const mapKeyRef = useRef()
    window.mapKey = mapKeyRef.current

    // Key for custom geojson and priority projects
    const {
        pfIdKnown,
        pfIdNotKnown,
        locationNotKnown,
    } = PRIORITY_PROJECT_COLOURS

    /* eslint-disable camelcase */
    const priorityProjectColours = customGeojsonList
        .map(JSON.parse)
        .map(({ features }) => features)
        .flat()
        .map(({ properties }) => properties)
        .filter(({ icon }) => icon && icon.startsWith('number_'))
        .map(({ location_known, pitchfinder_id }) =>
            location_known
                ? pitchfinder_id
                    ? pfIdKnown
                    : pfIdNotKnown
                : locationNotKnown,
        )
    /* eslint-enable camelcase */

    const priorityProjects = [
        [pfIdKnown, 'Location known', '(existing site)'],
        [pfIdNotKnown, 'Location known', '(new site)'],
        [locationNotKnown, 'Location tbc', '(new site)'],
    ]
        .map(
            ([colour, line1, line2]) =>
                priorityProjectColours.includes(colour) && (
                    <li
                        key={colour}
                        className={styles('map-key__custom-geojson-item')}
                    >
                        <span style={{ background: colour }}>x</span>
                        <span>
                            {line1} <br />
                            {line2}
                        </span>
                    </li>
                ),
        )
        .filter(Boolean)

    const customGeojsonPanel = (window.screenshot ? [] : customGeojsonList)
        .map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={styles('map-key__row')}>
                <div className={styles('map-key__item')}>
                    Custom geojson #{i + 1}
                    <button
                        type="button"
                        title="Remove"
                        className={styles('map-key__custom-geojson-remove')}
                        onClick={() => dispatch(removeCustomGeojson(i))}
                        onKeyPress={(e) =>
                            ['Enter', ' '].includes(e.key) &&
                            dispatch(removeCustomGeojson(i))
                        }
                    >
                        <CrossIcon />
                    </button>
                </div>
            </div>
        ))
        .concat(
            priorityProjects.length ? (
                <div key="priority-projects" className={styles('map-key__row')}>
                    <div
                        className={styles(
                            'map-key__item',
                            'map-key__item--subheading',
                        )}
                    >
                        Priority projects:
                    </div>
                    <ul
                        className={styles('map-key__item')}
                        style={{ display: 'block' }}
                    >
                        {priorityProjects}
                    </ul>
                </div>
            ) : (
                []
            ),
        )

    const filterGroupsToShowInKey = filters?.filter(({ icon }) => icon)
    const filterGroupPanel = filterGroupsToShowInKey?.length ? (
        <div className={styles('map-key__row')}>
            {window.screenshot && (
                <div
                    className={styles(
                        'map-key__item',
                        'map-key__item--subheading',
                    )}
                >
                    Current facilities:
                </div>
            )}
            {filterGroupsToShowInKey.map(({ filterGroupId, label, icon }) => (
                <div key={filterGroupId} className={styles('map-key__item')}>
                    {label}
                    <Circle
                        className={styles('map-key__circle')}
                        fill={FILTER_GROUP_ICONS_TO_COLOURS[icon] || icon}
                    />
                </div>
            ))}
        </div>
    ) : null

    const mainPanel = (
        <div className={styles('map-key__row')}>
            <div className={styles('map-key__item')}>
                Site <SitePinIcon className={styles('map-key__icon')} />
            </div>
            <div className={styles('map-key__item')}>
                FF funded site{' '}
                <FundedPinIcon className={styles('map-key__icon')} />
            </div>
            {hasSearchLocationPin && (
                <div className={styles('map-key__item')}>
                    Your search location
                    <LocationPinIcon className={styles('map-key__icon')} />
                </div>
            )}
        </div>
    )

    // don't show brackets for activelives while loading because that depends on the current values
    const overlayPanel = optgroup && (
        <div
            className={styles('map-key__row', 'overlay', {
                screenshot: window.screenshot,
            })}
        >
            <div
                className={styles('map-key__item', 'map-key__item--subheading')}
            >
                {overlayHeader}
            </div>
            {loading !== false ? (
                <>
                    <Loading />
                    <br /> Loading overlay...
                </>
            ) : (
                formattedOffsets.map((value, i) => (
                    <div
                        key={i} // eslint-disable-line react/no-array-index-key
                        className={styles('map-key__item', {
                            hidden: !offsets[offsetKeys[i + 1]],
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={() =>
                            dispatch(toggleOffset(offsetKeys[i + 1]))
                        }
                        onKeyPress={(e) =>
                            ['Enter', ' '].includes(e.key) &&
                            dispatch(toggleOffset(offsetKeys[i + 1]))
                        }
                    >
                        {window.screenshot ? null : offsets[
                              offsetKeys[i + 1]
                          ] ? (
                            <EyeVisible className={styles('map-key__eye')} />
                        ) : (
                            <EyeHidden className={styles('map-key__eye')} />
                        )}
                        {value}
                        {labels[i] && (
                            <span
                                className={styles(
                                    'map-key__item-label',
                                    `map-key__item-label--${optgroup}`,
                                )}
                            >
                                {labels[i]}
                            </span>
                        )}
                        <Square
                            className={styles('map-key__square')}
                            fill={overlayColours[i]}
                        />
                    </div>
                ))
            )}
        </div>
    )

    return (
        <div
            className={styles('map-key', className, {
                screenshot: window.screenshot,
            })}
            ref={mapKeyRef}
        >
            <Accordion
                allowZeroExpanded
                preExpanded={isTablet || window.siteSelector ? [] : ['1']}
                headingClassName={styles('map-key__header')}
                panelClassName={styles('map-key__body')}
                buttonClassName={styles('map-key__button')}
                items={[
                    {
                        uuid: '1',
                        heading: window.screenshot
                            ? ''
                            : process.env.NODE_ENV === 'production'
                            ? 'Key'
                            : `Key (zoom: ${zoom})`,
                        panel: (
                            <>
                                {overlayPanel}
                                {customGeojsonPanel}
                                {filterGroupPanel}
                                {!window.screenshot && mainPanel}
                            </>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default MapKey
