import React from 'react'
import PropTypes from 'prop-types'

const Filter = ({ fill, ...rest }) => (
    <svg width="18" height="12" viewBox="0,0,16,12" {...rest}>
        <path fill={fill} d="M7 12h4v-2H7v2zM0 0v2h18V0H0zm3 7h12V5H3v2z" />
    </svg>
)

Filter.defaultProps = {
    fill: null,
}

Filter.propTypes = {
    fill: PropTypes.string,
}

export default Filter
