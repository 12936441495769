import { createAxiosClient, post } from '../Services'
import { urls } from '../../urls'

export const client = createAxiosClient()

const url = urls.api.initial_data()

export const getInitial = () => {
    // Simulate network errors:
    // if (true) {
    //     const e = new Error()
    //     e.message = 'Network Error'
    //     return Promise.reject(e)
    // }
    return post({ client, url }).then(({ data }) => data)
    // error handling is done in the calling component
}
