/* eslint max-classes-per-file: ["error", 20] */

class ExtendableError extends Error {
    constructor(message) {
        super(message)
        this.name = this.constructor.name
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor)
        } else {
            this.stack = new Error(message).stack
        }
    }
}

export class ServiceError extends ExtendableError {
    constructor(message) {
        super(message)
        this.name = `${this.name} [service]`
    }
}

export class AutoCompleteError extends ServiceError {}
export class GetConfigError extends ServiceError {}
export class GetLatLngAreasError extends ServiceError {}
export class GetSearchAutocompleteError extends ServiceError {}
export class GetSitesError extends ServiceError {}

export class PermissionError extends ExtendableError {}
export class ValidationError extends ExtendableError {}
