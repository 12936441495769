import _urls from './data/urls'

// Reach-router removes the trailing slash from props.path, so we do the same for django urls,
// so that they can be compared easily.
const removeTrailingSlash = (fn) => (...args) => fn(...args).replace(/\/+$/, '')

const origin =
    window.REACT_APP_PF_BACKEND_ORIGIN ||
    process.env.REACT_APP_PF_BACKEND_ORIGIN

export const GEOSITE_URL =
    window.REACT_APP_GEO_BACKEND_ORIGIN ||
    process.env.REACT_APP_GEO_BACKEND_ORIGIN

export const urls = {
    map: removeTrailingSlash(_urls.frontend_map),
    site: removeTrailingSlash(_urls.frontend_map_site),
    sitePopupOnly: removeTrailingSlash(_urls.frontend_map_site_popup),
    savedMap: removeTrailingSlash(_urls.frontend_saved_map),
    savedMapWithCode: removeTrailingSlash(_urls.frontend_saved_map_gss_code),
    savedMaps: removeTrailingSlash(_urls.frontend_saved_maps),
    home: () => '/',
    login: () => origin + _urls.login(),
    logout: () => origin + _urls.logout(),
    accountsPending: () => origin + _urls.accounts_pending(),
    passwordChange: () => origin + _urls.password_change(),
    about: () => `${origin}/about`,
    suggest: () => `${origin}/suggest`,
    terms: () => `${origin}/terms`,
    siteSelectorUi: () => origin + _urls.mapping.site_selector_ui(),
    api: Object.fromEntries(
        [
            'field_values',
            'related_objects',
            'config',
            'filter_sites',
            'initial_data',
            'get_point_and_areas',
            'main_search_autocomplete',
            'site_info', // internal api
            'site_detail', // public api
            'api_saved_maps',
            'api_saved_map',
            'api_saved_map_update',
            'api_saved_map_delete',
        ].map((name) => [
            name,
            (...args) => origin + _urls.mapping[name](...args),
        ]),
    ),
    params: {
        siteId: ':siteId',
        savedMapSlug: ':savedMapSlug',
        gssCode: ':gssCode',
    },
}
