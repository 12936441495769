import React from 'react'

const FundedPin = (props) => (
    <svg width="14" height="24" viewBox="0,0,14,24" {...props}>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-5 -1)">
                <ellipse
                    fill="#1C1C1C"
                    opacity=".4"
                    cx="12"
                    cy="22.238"
                    rx="4.286"
                    ry="1.667"
                />
                <path
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"
                    fill="#009a3a"
                    strokeWidth="1"
                    stroke="#fff"
                />
                <path d="M0 0h24v24H0z" />
            </g>
        </g>
    </svg>
)

export default FundedPin
