const OPTGROUPS = [
    'Pitch size',
    'Playing surface',
    'Must have'
]

export default [
    {
        id: 'full_sized_pitches',
        label: 'Full-size pitches',
        optgroup: OPTGROUPS[0],
    },
    {
        id: 'medium_sized_pitches',
        label: 'Medium pitches',
        optgroup: OPTGROUPS[0],
    },
    {
        id: 'smaller_sized_pitches',
        label: 'Smaller pitches',
        optgroup: OPTGROUPS[0],
    },
    {
        id: 'grass_pitches',
        label: 'Grass pitches',
        optgroup: OPTGROUPS[1],
    },
    {
        id: 'artificial_pitches',
        label: 'Any artificial pitches',
        optgroup: OPTGROUPS[1],
    },
    {
        id: 'threeg_artificial_pitches',
        label: '3G artificial pitches',
        optgroup: OPTGROUPS[1],
    },
    {
        id: 'indoor_pitches',
        label: 'Indoor pitches',
        optgroup: OPTGROUPS[1],
    },
    {
        id: 'ff_funded',
        label: 'Football Foundation funded',
        optgroup: OPTGROUPS[2],
    },
    {
        id: 'changing_rooms',
        label: 'Changing rooms',
        optgroup: OPTGROUPS[2],
    },
    {
        id: 'flood_lights',
        label: 'Flood lights',
        optgroup: OPTGROUPS[2],
    },
    {
        id: 'car_parking',
        label: 'Car parking',
        optgroup: OPTGROUPS[2],
    },
    {
        id: 'public_bookings',
        label: 'Public bookings',
        optgroup: OPTGROUPS[2],
    },
].map(f => ({...f, quick: true}))
