import { LABELS } from '../consts/consts'

export const transformedConfig = (configObject) => {
    // population: {}, deprevation: {}
    const config = Object.entries(configObject).map(([optgroup, values]) => {
        // years: {}
        const yearDate = Math.max(...Object.keys(values.years)) // latest year
        const yearOptions = values.years[yearDate]

        // level: {}
        const levelOptions = values.level.map((level) => {
            let options = null

            // Create array of options for select
            if (['select'].includes(values.type)) {
                options = Object.entries(yearOptions).map(([value, label]) => {
                    return { value, label }
                })
            }

            return {
                options,
                level,
                label: LABELS.level[level],
                type: values.type,
                year: yearDate,
                optgroup,
            }
        })

        return {
            value: optgroup,
            label: LABELS.overlay[optgroup],
            options: levelOptions,
        }
    })

    return config
}
