import { createAxiosClient, post } from '../Services'
import { urls } from '../../urls'
import { GetLatLngAreasError } from '../../utils/errors'
import { isEmptyObject } from '../../utils/utils'

export const client = createAxiosClient()

const url = urls.api.get_point_and_areas()

const getGeolocation = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                const { latitude, longitude } = pos.coords
                resolve({
                    lat: latitude,
                    lng: longitude,
                })
            },
            (err) => reject(err),
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            },
        )
    })
}

export const getLatLngAreas = async ({ type, value, lat, lng }) => {
    let data = { query: value, lat, lng }

    if (!lat) {
        delete data.lat
        delete data.lng
    }

    // If type!=query then we already have the coordinates,
    // so avoid doing geocoding by not sending the query.
    if (!value || type !== 'query') {
        delete data.query
    }

    // Request location from browser
    if (type === 'geolocation') {
        // usePromise will handle any errors from getGeolocation()
        data = await getGeolocation()
    }

    if (isEmptyObject(data)) {
        return Promise.resolve({
            lat: null,
            lng: null,
            sites: null,
        })
    }

    // search query -> determine lat/lng + areas at point
    // lat/lng      -> return same lat/lng + areas at point
    return post({ url, client, data })
        .then(({ data }) => data)
        .catch(() => Promise.reject(new GetLatLngAreasError()))
}
