import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ fill, ...rest }) => (
    <svg width="32" height="32" {...rest}>
        <path fill={fill} d="M6.1 0L0 19.7l15.9 12.2 16-12.2L25.8 0z" />
        <path
            fill="#FEFEFE"
            d="M21.3 12.8h-2.6V8.2h2.6l.6-1.8h-5.1v14.8h1.9v-6.5h2zM15.1 12.8h-2.6V8.2h2.6l.5-1.8h-5v14.8h1.9v-6.5h2z"
        />
    </svg>
)

Logo.defaultProps = {
    fill: null,
}

Logo.propTypes = {
    fill: PropTypes.string,
}

export default Logo
