import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import css from './IconButton.module.scss'

const styles = classnames.bind(css)

const IconButton = ({
    text,
    icon,
    title,
    handleClick,
    className,
    isSquare,
    disabled,
    buttonRef,
}) => (
    <button
        ref={buttonRef}
        type="button"
        className={styles(
            'icon-button',
            { 'icon-button--square': isSquare },
            className,
        )}
        onClick={handleClick}
        disabled={disabled}
        title={title}
    >
        <span className={styles('icon-button__icon')} aria-hidden>
            {icon}
        </span>
        <span className={styles('icon-button__text')}>{text}</span>
    </button>
)

IconButton.propTypes = {
    className: null,
}

IconButton.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default IconButton
