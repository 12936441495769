import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import CrossIcon from '../Icons/Cross'
import OrigamiIcon from '../Icons/Origami'
import css from './Close.module.scss'

const styles = classnames.bind(css)

const Close = ({ handleClick, className }) => (
    <span className={styles('close', className)}>
        <button
            type="button"
            className={styles('close__button')}
            title="Close"
            onClick={handleClick}
        >
            <CrossIcon className={styles('close__cross')} />
        </button>
        <OrigamiIcon className={styles('close__bg')} />
    </span>
)

Close.defaultProps = {
    className: null,
}

Close.propTypes = {
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default Close
