import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import classnames from 'classnames/bind'
import css from './FilterValuesAsyncCreatableSelect.module.scss'
import { defaultStyles } from '../InputSelect/reactSelectStyles'
import { useDebounced } from '../../hooks/hooks'
import { getFieldValuesDebounced } from '../../services/AutoComplete/AutoComplete'
import {
    ClearIndicator,
    NoOptionsMessage,
    CustomOption,
} from '../InputSelect/Components'

const styles = classnames.bind(css)

// Single select: always show Input because SingleValue is always hidden
const Input = (props) => {
    let inputProps = {}
    if (!props.selectProps.isMulti) {
        inputProps = {
            ...props,
            placeholder: 'Enter value',
            isHidden: false,
        }
    } else {
        inputProps = props
    }

    return <components.Input {...inputProps} />
}

// Single select: make SingleValue hidden because we're showing Input instead
const SingleValue = ({ children, ...props }) => {
    const wrapperProps = {}

    if (!props.selectProps.isMulti) {
        wrapperProps.style = { display: 'none' }
    }

    return (
        <div {...wrapperProps}>
            <components.SingleValue {...props}>
                {children}
            </components.SingleValue>
        </div>
    )
}

// By default the placeholder is not hidden on focus, only when one starts typing
// Also, we don't show it when isMulti is false, because we show Input/inputValue instead.
export const Placeholder = (props) => {
    const children = props.isFocused || !props.isMulti ? null : props.children
    return <components.Placeholder {...{ ...props, children }} />
}

const valueToOption = (value) => {
    if (value === null || typeof value === 'undefined') {
        return null
    }
    if (Array.isArray(value)) {
        return value.map(valueToOption)
    }
    return { value, label: value }
}

const FilterValuesAsyncCreatableSelect = ({
    value,
    isMulti,
    operator,
    options,
    handleChange,
    className,
    configId,
    loading,
    configType,
    ...rest
}) => {
    // TODO:
    // * allow adding two fields only when operator=between

    const loadOptions = async (inputValue) => {
        // Don't show possible values for FloatFields, just the one that was entered
        // because usaully this is used with the "less/greater than" operator.
        if (configType === 'double') {
            if (!inputValue) {
                return []
            }
            return [{ value: inputValue, label: inputValue }]
        }

        const { notShown, results } = await getFieldValuesDebounced(
            configId,
            inputValue,
        )

        return notShown > 0 ? [...results, { notShown }] : results
    }

    // `inputValue` is just the search field and `value` is where the actual value is stored.
    // However, we keep those in sync manually and update the value while typing (from `onInputChange``).
    // But we don't immediatley call `handleChange` to change the actual value to avoid too many re-renders.
    // (Autocomplete calls are triggered by react-select, so those are throttled separately.)
    //
    // NB we don't do this at all with isMulti!
    const [inputValue, setInputValue] = useState(value)
    const debouncedInputValue = useDebounced(
        inputValue,
        200,
        isMulti ? () => {} : handleChange,
    )

    // If `loading`` is false then set it to true while the actual is not updated yet via `handleChange`.
    // Together with allowCreateWhileLoading={false} this ensures `Create ${newOption}` is never shown.
    const isLoadingDebounced = loading || inputValue !== debouncedInputValue

    // When switching back from multi to single select then we need to
    // set `inputValue` which was undefined when isMulti was false.
    // This will also end up setting `value` afterwards.
    useEffect(() => {
        if (!isMulti && Array.isArray(value) && inputValue !== value[0]) {
            setInputValue(value[0])
        }
    }, [isMulti, inputValue, value])

    // When switching from single to multi select then we need to ensure this is a list.
    useEffect(() => {
        if (isMulti && !Array.isArray(value)) {
            handleChange([value])
        }
    }, [isMulti, value, handleChange])

    return (
        <div className={styles('input-select', className)}>
            <AsyncCreatableSelect
                isMulti={isMulti}
                backspaceRemovesValue={isMulti}
                value={valueToOption(
                    operator === 'between' && value?.length > 2
                        ? value.slice(0, 2)
                        : value,
                )}
                inputValue={isMulti ? undefined : inputValue}
                isLoading={isLoadingDebounced}
                isClearable
                allowCreateWhileLoading={false}
                options={null} // this component never has initial options
                defaultOptions
                cacheOptions={false} // disabled, doesn't work properly
                loadOptions={loadOptions}
                components={{
                    ClearIndicator,
                    NoOptionsMessage:
                        configType === 'double' ? () => null : NoOptionsMessage,
                    Placeholder,
                    Input,
                    SingleValue,
                    Option: CustomOption,
                    // No Dropdown Indicator because this should look like a TextInput
                    DropdownIndicator: null,
                }}
                styles={defaultStyles()}
                formatCreateLabel={(inputValue) => `${inputValue}`} // will be visible only with isMulti
                onInputChange={(newValue, { action }) => {
                    // action is one of: 'set-value', 'input-change', 'input-blur', 'menu-close'
                    if (!isMulti && action === 'input-change') {
                        setInputValue(newValue)
                    }

                    // allow pasting in a list of values
                    // https://github.com/JedWatson/react-select/issues/2977#issuecomment-416831127
                    if (
                        isMulti &&
                        ['in', 'not_in'].includes(operator) &&
                        action === 'input-change' &&
                        newValue.includes(',')
                    ) {
                        handleChange(newValue.split(',').map((s) => s.trim()))
                    }
                }}
                onFocus={() => {
                    if (!isMulti) {
                        setInputValue(inputValue)
                    }
                }}
                onChange={(option) => {
                    if (isMulti) {
                        handleChange(option && option.map(({ value }) => value))
                    } else {
                        setInputValue(option ? option.value : '')
                    }
                }}
                {...rest}
            />
        </div>
    )
}

// We don't have separate `label` for the values here,
// so we only work with a primitive string or number, instead of {value, label}.
const optionPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

FilterValuesAsyncCreatableSelect.propTypes = {
    value: PropTypes.oneOfType([
        optionPropType,
        PropTypes.arrayOf(optionPropType),
    ]),
    options: PropTypes.arrayOf(optionPropType),
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default FilterValuesAsyncCreatableSelect
