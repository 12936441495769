import axios from 'axios'

import axiosCaseConverter from 'axios-case-converter'
import { getCookie } from '../utils/utils'

export const createAxiosClient = (options = {}) => {
    const axiosInstance = axios.create(options)
    if (options.disableCaseConverter) {
        return axiosInstance
    }
    // Converter switches between snakeCase and camelCase
    return axiosCaseConverter(axiosInstance)
}

const setAuthOptions = (url, options) => {
    // Always send credentials, e.g. to be able to determine logged in status.
    // Django will ignore session cookies from different hostnames anyway (except for GET):
    // https://docs.djangoproject.com/en/2.2/ref/settings/#session-cookie-samesite
    options.withCredentials = true

    // TODO: IE11 polyfill for URL
    if (
        !url.startsWith('/') &&
        new window.URL(url).hostname !== document.location.hostname
    ) {
        const username =
            window.REACT_APP_BASIC_AUTH_LOGIN ||
            process.env.REACT_APP_BASIC_AUTH_LOGIN
        const password =
            window.REACT_APP_BASIC_AUTH_PASSWORD ||
            process.env.REACT_APP_BASIC_AUTH_PASSWORD

        if (username && password) {
            options.withCredentials = true
            options.auth = { username, password }
        }
    }
}

const setCsrf = (options) => {
    // We need to handle CSRF manually because the
    // xsrfCookieName & xsrfHeaderName options don't work.
    const csrftoken = getCookie('csrftoken')
    if (csrftoken) {
        options.withCredentials = true
        options.headers = {
            'X-CSRFTOKEN': csrftoken,
        }
    }
}

const cancelTokens = {}

const cancelPreviousAndSetToken = (name, options) => {
    if (name) {
        // cancel previous request with the same `name`
        if (cancelTokens[name]) cancelTokens[name].cancel()
        // create and save new token
        cancelTokens[name] = axios.CancelToken.source()
        // use newly created token in upcoming request
        options.cancelToken = cancelTokens[name].token
    }
}

const handleError = (e) => {
    if (axios.isCancel(e)) {
        // This just makes it easier to tell if a request was cancelled,
        // without having to import `axios.isCancel`
        const e = new Error('Cancelled')
        e.cancelled = true
        return Promise.reject(e)
    }

    if (e.response && e.response.status === 400 && e.response.data) {
        // We do input validation with DRF serialisers, which return a 400,
        // along with a detailed json error message in the response body.
        // This is useful to see what the validation message was without checking on the network tab.
        console.error(
            '⤷ API validation error:',
            JSON.stringify(e.response.data),
        )
    }

    // If a request was not cancelled then re-throw it
    return Promise.reject(e)
}

// Export consistent way of performing GET
export const get = ({
    url,
    client,
    params,
    cancelPrevious,
    disableCaseConverter,
    ...options
}) => {
    setAuthOptions(url, options)

    setCsrf(options)

    cancelPreviousAndSetToken(cancelPrevious, options)

    if (params && Object.keys(params).length) {
        options.params = params
    }

    return (client || createAxiosClient({ disableCaseConverter }))
        .get(url, options)
        .catch(handleError)
}

// Export consistent way of performing POST
export const post = ({
    url,
    client,
    data,
    cancelPrevious,
    disableCaseConverter,
    method = 'post',
    ...options
}) => {
    setAuthOptions(url, options)

    setCsrf(options)

    // `cancelPrevious` is a string that should match for all requests
    // where the previous one should be cancelled before making a new one.
    cancelPreviousAndSetToken(cancelPrevious, options)

    return (client || createAxiosClient({ disableCaseConverter }))
        [method](url, data, options)
        .catch(handleError)
}

// Export consistent way of performing DELETE
export const deleteRequest = ({
    url,
    client,
    disableCaseConverter,
    ...options
}) => {
    setAuthOptions(url, options)

    setCsrf(options)

    return (client || createAxiosClient({ disableCaseConverter }))
        .delete(url, options)
        .catch(handleError)
}
