import React from 'react'
import PropTypes from 'prop-types'

const Back = ({ fill, ...rest }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" {...rest}>
        <path
            fill={fill}
            d="M1.2 9.3l8.7-9a1 1 0 0 1 1.3 0l8.7 9c.3.4.3 1 0 1.4l-8.7 9a1 1 0 0 1-1.3 0l-8.7-9a1 1 0 0 1 0-1.4zm7.4 3.2V10h3.9v3h1.9V9c0-.6-.5-1-1-1H8.6V5.5L5.2 9l3.4 3.5z"
        />
    </svg>
)

Back.defaultProps = {
    fill: null,
}

Back.propTypes = {
    fill: PropTypes.string,
}

export default Back
