import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useReloadOnProductionOnly } from '../../hooks/hooks'
import { restoreSavedMap, resetAll } from '../../store/SavedMap/SavedMap'

import { getSavedMapData } from '../../services/SavedMaps/SavedMaps'

const SavedMapLoader = ({ savedMapSlug, gssCode }) => {
    const dispatch = useDispatch()
    const reload = useReloadOnProductionOnly()

    useEffect(() => {
        let mounted = true
        if (savedMapSlug) {
            getSavedMapData(savedMapSlug)
                .then((result) => {
                    if (mounted) {
                        // saved map data -> redux
                        dispatch(restoreSavedMap(result, gssCode))
                    }
                })
                .catch((e) => {
                    if (e.cancelled) {
                        return
                    }
                    if (e.status === 404) {
                        reload()
                    } else {
                        throw e // TODO: handle non-404 errors
                    }
                })
            return () => {
                mounted = false
                // reset when navigating away from a saved map
                dispatch(resetAll())
            }
        }
        return () => {
            mounted = false
        }
    }, [dispatch, savedMapSlug, gssCode, reload]) // Make sure NOT to add any new deps here that can change

    return null
}

export default SavedMapLoader
