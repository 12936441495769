import React, { useContext } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import { ACTIVE_MODAL_SITE_SEARCH, LABELS } from '../../consts/consts'
import { UiContext } from '../../context/UiContext'

import EditButton from '../EditButton/EditButton'
import LocationPinIcon from '../Icons/LocationPin'

import css from './Location.module.scss'

const styles = classnames.bind(css)

const Location = ({ className, disabled, gssCodeOverride }) => {
    const { setActiveModal } = useContext(UiContext)
    const { radius, travelMode, travelTime, label, type, area } = useSelector(
        ({ location }) => location,
        shallowEqual,
    )

    const displayLabel = Object.prototype.hasOwnProperty.call(
        LABELS.locationType,
        type,
    )
        ? LABELS.locationType[type]
        : label || 'England'

    const areaLabel =
        area && area.label !== displayLabel ? ` (${area.label})` : ''

    return (
        <div className={styles('location', className)}>
            <LocationPinIcon className={styles('location__icon')} />
            <div className={styles('location__text')}>
                <span className={styles('location__label')}>
                    {displayLabel}
                    {areaLabel}
                </span>
                {radius && (
                    <span className={styles('location__distance')}>
                        {radius} mile radius
                    </span>
                )}
                {travelMode && travelTime && (
                    <span className={styles('location__distance')}>
                        Within {travelTime} mins of {label} if travelling via{' '}
                        {LABELS.travelMode[travelMode]}
                    </span>
                )}
            </div>
            {!disabled && (
                <EditButton
                    title={
                        gssCodeOverride
                            ? 'Create a new saved map to edit the location'
                            : 'Edit location'
                    }
                    handleClick={() => {
                        setActiveModal(ACTIVE_MODAL_SITE_SEARCH)
                    }}
                    className={styles('location__edit')}
                    disabled={Boolean(gssCodeOverride)}
                />
            )}
        </div>
    )
}

Location.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

Location.defaultProps = {
    disabled: false,
}

export default Location
