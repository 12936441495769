import { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { DATA_LAYER_Z_INDEX, LABELS, SQ_KM_TO_SQ_MI } from '../../consts/consts'
import { createOverlayTooltip } from './utils'

const zIndex = DATA_LAYER_Z_INDEX.indexOf('travel')

export const addTooltip = ({
    mapObj,
    dataLayer,
    travelMode,
    travelTime,
    travelBoundaryStats,
}) => {
    const stats = JSON.parse(travelBoundaryStats)
    const tooltip = createOverlayTooltip(mapObj.current.getDiv())
    const mouseoverListener = dataLayer.addListener('mouseover', (event) => {
        const f = event.feature
        // Highlight feature
        dataLayer.revertStyle()
        dataLayer.overrideStyle(f, { strokeWeight: 4, strokeOpacity: 0.7 })
        const areaSqMiles = stats.catchmentAreaSqKm * SQ_KM_TO_SQ_MI
        const populationDensitySqMiles =
            stats.population.populationDensitySqKm / SQ_KM_TO_SQ_MI
        // Show tooltip
        // prettier-ignore
        const tooltipText = `
            Within ${travelTime} mins if travelling via ${LABELS.travelMode[travelMode]}<br>
            IMD percentile: ${stats.deprivation.overall.percentile}% (100% is the least deprived)<br>
            Population: ${stats.population.population}<br>
            Population density: ${populationDensitySqMiles.toFixed(0)} per square mile<br>
            Area: ${areaSqMiles.toFixed(4)} square miles
        `
        tooltip.show(tooltipText, 'html')
    })
    const mouseoutListener = dataLayer.addListener('mouseout', () => {
        dataLayer.revertStyle()
        tooltip.hide()
    })

    return () => {
        google.maps.event.removeListener(mouseoverListener)
        google.maps.event.removeListener(mouseoutListener)
        tooltip.remove()
    }
}

export const useTravelBoundary = ({ hasMap, mapObj, autoZoom }) => {
    // Get travel options and boundary
    const {
        travelMode,
        travelTime,
        travelBoundary,
        travelBoundaryStats,
    } = useSelector(({ location }) => location, shallowEqual)

    // Applies GeoJson of selected travel options to map
    useEffect(() => {
        if (!hasMap) {
            return undefined
        }
        const dataLayer = new google.maps.Data()
        dataLayer.setStyle({
            fillOpacity: 0,
            zIndex,
        })
        dataLayer.setMap(mapObj.current)

        if (travelMode && travelTime) {
            // TODO Create single endpoint for travelMode and replace
            // travelBoundary updates in sites reducer with api call here
            if (travelBoundary) {
                const travelBoundaryGeoJsonFormat = {
                    type: 'Feature',
                    geometry: JSON.parse(travelBoundary),
                }
                dataLayer.addGeoJson(travelBoundaryGeoJsonFormat)

                autoZoom({ travelBoundary: dataLayer })

                return addTooltip({
                    mapObj,
                    dataLayer,
                    travelMode,
                    travelTime,
                    travelBoundaryStats,
                })
            }
        }

        return () => {
            dataLayer.setMap(null)
            autoZoom({ travelBoundary: null })
        }
    }, [
        hasMap,
        mapObj,
        autoZoom,
        travelMode,
        travelTime,
        travelBoundary,
        travelBoundaryStats,
    ])
}
