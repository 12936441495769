import React from 'react'
import PropTypes from 'prop-types'

import {
    Accordion as ReactAccordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'

const Accordion = ({
    items,
    headingClassName,
    panelClassName,
    buttonClassName,
    ...rest
}) => (
    <ReactAccordion {...rest}>
        {items.map(({ heading, panel, uuid }) => (
            <AccordionItem key={uuid} uuid={uuid}>
                {heading && (
                    <AccordionItemHeading className={headingClassName}>
                        <AccordionItemButton className={buttonClassName}>
                            {heading}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                )}
                <AccordionItemPanel className={panelClassName}>
                    {panel}
                </AccordionItemPanel>
            </AccordionItem>
        ))}
    </ReactAccordion>
)

Accordion.defaultProps = {
    items: null,
    headingClassName: null,
    panelClassName: null,
    buttonClassName: null,
}

Accordion.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            uuid: PropTypes.string.isRequired,
            heading: PropTypes.node.isRequired,
            panel: PropTypes.node.isRequired,
        }),
    ),
    headingClassName: PropTypes.string,
    panelClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
}

export default Accordion
