import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './Heading.module.scss'

const styles = classnames.bind(css)

const Heading = ({ heading: HeaderTag, text, isSubHeading, className }) => (
    <HeaderTag
        className={styles(
            'heading',
            {
                'heading--sub': isSubHeading,
            },
            className,
        )}
    >
        {text}
    </HeaderTag>
)

Heading.defaultProps = {
    heading: 'h1',
    isSubHeading: false,
    className: null,
}

Heading.propTypes = {
    heading: PropTypes.string,
    isSubHeading: PropTypes.bool,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default Heading
