import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import CrossIcon from '../Icons/Cross'
import css from './RemoveButton.module.scss'

const styles = classnames.bind(css)

const RemoveButton = ({ text, handleClick }) => (
    <button
        type="button"
        onClick={handleClick}
        className={styles('remove-button')}
    >
        <span className="visually-hidden">{text}</span>{' '}
        <CrossIcon className={styles('remove-button__icon')} />
    </button>
)

RemoveButton.propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
}

export default RemoveButton
