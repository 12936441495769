import React, { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useAlert } from '../../hooks/hooks'
import { urls } from '../../urls'
import { LABELS } from '../../consts/consts'

const LoadingMonitor = ({ path }) => {
    const alert = useAlert()

    const {
        asyncInitialStateLoading,
        sitesLoading,
        overlayLoading,
        savedMapLoading,
        boundariesLoading,
        locationLoading,
        locationWillLoad,
        overlayLevel,
        boundariesLevel,
    } = useSelector(
        ({
            asyncInitialState: { loading: asyncInitialStateLoading },
            sites: { loading: sitesLoading },
            overlay: { loading: overlayLoading, level: overlayLevel },
            location: {
                loading: locationLoading,
                type: locationType,
                area: locationArea,
            },
            savedMap: { loading: savedMapLoading },
            boundaries: { loading: boundariesLoading, level: boundariesLevel },
        }) => ({
            asyncInitialStateLoading,
            sitesLoading,
            overlayLoading,
            savedMapLoading,
            boundariesLoading,
            locationLoading,
            locationWillLoad:
                locationType === 'area' ||
                (locationArea && locationArea.length),
            overlayLevel: LABELS.level[overlayLevel],
            boundariesLevel: LABELS.level[boundariesLevel],
        }),
        shallowEqual,
    )

    const isMap = path === urls.map()
    const isMapWithSitePopup = path === urls.sitePopupOnly(urls.params.siteId)
    const isSavedMap = path === urls.savedMap(urls.params.savedMapSlug)
    const isSavedMapWithCode =
        path ===
        urls.savedMapWithCode(urls.params.savedMapSlug, urls.params.gssCode)

    const pageSupportsScreenshot = [
        isMap,
        isMapWithSitePopup,
        isSavedMap,
        isSavedMapWithCode,
    ].some(Boolean)

    // initial `loading` states are usually `null`, so `false` means a "slice" has finished loading at least once
    const conditions = [
        asyncInitialStateLoading === false, // has more significance if we have a querystring (which cannot be a saved map)
        sitesLoading === false, // this is debounced but it keeps being `true` while the debounce is pending
        isSavedMap || isSavedMapWithCode ? savedMapLoading === false : true, // if this is false that just means that a saved map related request has finished (not that a saved map is fully loaded)
        overlayLevel ? overlayLoading === false : true,
        boundariesLevel ? boundariesLoading === false : true,
        locationWillLoad ? locationLoading === false : true,
    ]
    const allDone = conditions.every(Boolean)

    useEffect(() => {
        if (window.screenshot && pageSupportsScreenshot && allDone) {
            // show message to reload page (currently this isn't meant to be used on production)
            if (process.env.NODE_ENV !== 'production') {
                alert.current.show(
                    'Taking screenshot in a moment... To go back to normal mode clear the querystring and do a full page reload',
                )
            }
            const done = () => {
                // trigger AWS lambda function (or download png when testing it)
                window.dispatchEvent(new Event('captureready'))
                // allow running it only once
                window.screenshot = false
            }

            // The first tilesloaded events is fired on page load and the second one after an auto zoom
            const i = setInterval(() => {
                if (!window.screenshot) {
                    clearInterval(i)
                }
                if (window.tilesLoaded > 1) {
                    done()
                }
            }, 1000)

            // If we don't get enough tilesloaded events then trigger captureready after a delay
            setTimeout(() => {
                if (window.screenshot) {
                    clearInterval(i)
                    done()
                }
            }, 5000)
        }
    }, [pageSupportsScreenshot, allDone, alert])

    return (
        pageSupportsScreenshot &&
        conditions.some(Boolean) && (
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'center',
                    zIndex: 9999,
                    pointerEvents: 'none',
                }}
            >
                {asyncInitialStateLoading && <div>Loading...</div>}
                {locationWillLoad && locationLoading && (
                    <div>Loading location and selected areas...</div>
                )}
                {sitesLoading && <div>Loading sites...</div>}
                {(isSavedMap || isSavedMapWithCode) && savedMapLoading && (
                    <div>Loading saved map...</div>
                )}
                {overlayLevel && overlayLoading && (
                    <div>Loading {overlayLevel} overlay...</div>
                )}
                {boundariesLevel && boundariesLoading && (
                    <div>Loading {boundariesLevel} boundaries...</div>
                )}
            </div>
        )
    )
}

export default LoadingMonitor
