import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import Heading from '../Heading/Heading'

import css from './Bar.module.scss'

const styles = classnames.bind(css)

const Bar = ({ heading, value, className, maxValue }) => {
    const blocks = []

    if (typeof value === 'number') {
        const valueRemainder = (value % 1).toFixed(2)
        const valueInt = Math.floor(value)

        for (let i = 0; i < valueInt; i += 1) {
            blocks.push(
                <div className={styles('bar__block')} key={i}>
                    <div className={styles('bar__fill', className)} />
                </div>,
            )
        }

        if (value !== maxValue) {
            blocks.push(
                <div className={styles('bar__block')} key={valueRemainder}>
                    <div
                        className={styles('bar__fill', className)}
                        style={{
                            width: `${valueRemainder * 100}%`,
                        }}
                    />
                </div>,
            )

            for (let j = valueInt + 1; j < maxValue; j += 1) {
                blocks.push(<div className={styles('bar__block')} key={j} />)
            }
        }
    } else {
        blocks.push(<span key="span">--</span>)
    }
    return (
        <div className={styles('bar', className)}>
            <Heading
                text={heading}
                heading="h3"
                isSubHeading
                className={styles('bar__heading')}
            />
            <div className={styles('bar__row', className)}>{blocks}</div>
        </div>
    )
}

Bar.defaultProps = {
    heading: '',
    className: null,
}

Bar.propTypes = {
    heading: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    maxValue: PropTypes.number.isRequired,
}

export default Bar
