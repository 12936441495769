import React, { createContext, useState, useEffect } from 'react'

import { getInitial } from '../services/Initial/Initial'
import { useMedia, usePromise } from '../hooks/hooks'
import { isConnectionError } from '../utils/utils'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'

export const UiContext = createContext()

export default ({ children }) => {
    // - Track:
    // - the active modal
    // - if the modal has entered
    // - the previous modal
    const [activeModal, setActiveModal] = useState(null)
    const [modalHasEntered, setModalHasEntered] = useState(false)
    const [previousModal, setPreviousModal] = useState(null)

    // - Track activeSubStepId: only the ID but not any additional data
    // - Jump to active substep (e.g. when editing a filter from the sidebar)
    const [activeSubStepId, setActiveSubStepId] = useState(null)

    // - Track activeFilterGroupId: only the ID but not any additional data
    // - Sets the active filter group to assign filters to in filter selector
    // - when opened from the sidebar
    const [activeFilterGroupId, setActiveFilterGroupId] = useState(null)

    // - Track whether we the user is logged in (from Services/Initial)
    const [loggedIn, setLoggedIn] = useState(false)
    const [ffStaff, setFFStaff] = useState(false)

    // - Track whether to slide the sidebar with animation
    const [showSidebar, setShowSidebar] = useState(false)

    // - Track whether to allow the sidebar to be shown
    const [allowSidebar, setAllowSidebar] = useState(true)

    const [showHamburger, setShowHamburger] = useState(true)

    // - Track whether the initial data request was successful
    // - Used by <InitialLoading /> wrapper to show a loader
    const [initialData, setInitialData] = useState(false)

    const [activeSiteId, setActiveSiteId] = useState(null)

    // -  Track whether custom epicentre feature has been enabled for Map.js
    const [pinpointingEpicentre, setPinpointingEpicentre] = useState(false)

    // - Media queries: This is used on desktop too, doesn't have to be a mobile device
    const isMobile = useMedia('(max-width: 767px)')
    const isTablet = useMedia('(max-width: 1023px)')

    // get initial data (related to the current user)
    const [data, , error] = usePromise(getInitial)

    useEffect(() => {
        if (data) {
            setLoggedIn(data.loggedIn)
            setFFStaff(data.ffStaff)
            setInitialData(true)
            if (data.userId && window.Sentry) {
                Sentry.setUser({
                    id: data.userId,
                    username: data.username,
                    email: data.email,
                })
            }
            // Set data attribute to be used by GTM:
            // https://projects.torchbox.com/projects/ff-pitchfinder/tickets/684#update-62927729
            // This will add `data=gtm-logged-in="true/false"`
            document.body.dataset.gtmLoggedIn = data.loggedIn
        }
    }, [data])

    if (error) {
        if (isConnectionError(error.message)) {
            // Rendering ErrorBoundary without it catching an exception won't notify sentry
            return (
                <ErrorBoundary
                    hasError
                    fullPage
                    message={`Couldn't connect to the ${
                        process.env.NODE_ENV === 'production'
                            ? 'Internet'
                            : 'backend'
                    }`}
                />
            )
        }
        // Any other error will be caught by the global error boundary, and will notify sentry.
        throw error
    }

    return (
        <UiContext.Provider
            value={{
                activeModal,
                setActiveModal,
                modalHasEntered,
                setModalHasEntered,
                previousModal,
                setPreviousModal,
                activeSubStepId,
                setActiveSubStepId,
                activeFilterGroupId,
                setActiveFilterGroupId,
                loggedIn,
                ffStaff,
                showSidebar: window.siteSelector ? false : showSidebar,
                setShowSidebar,
                allowSidebar: window.siteSelector ? false : allowSidebar,
                setAllowSidebar,
                showHamburger: window.siteSelector ? false : showHamburger,
                setShowHamburger,
                initialData,
                activeSiteId,
                setActiveSiteId,
                pinpointingEpicentre: window.siteSelector
                    ? true
                    : pinpointingEpicentre,
                setPinpointingEpicentre,
                isTablet,
                isMobile,
            }}
        >
            {children}
        </UiContext.Provider>
    )
}
