import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

/**
 * Element.closest Polyfill
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
 */

if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
    Element.prototype.closest = (s) => {
        let el = this

        do {
            if (el.matches(s)) return el
            el = el.parentElement || el.parentNode
        } while (el !== null && el.nodeType === 1)
        return null
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
/* eslint-disable */
;(function(arr) {
    arr.forEach(function(item) {
        if (item.hasOwnProperty('remove')) {
            return
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode === null) {
                    return
                }
                this.parentNode.removeChild(this)
            },
        })
    })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])
/* eslint-enable */
