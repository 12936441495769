import { createAxiosClient, post } from '../Services'
import { urls } from '../../urls'
import { GetSitesError } from '../../utils/errors'
import { submitAsForm, renameKeysToSnakeCase } from '../../utils/utils'

export const client = createAxiosClient()

const url = urls.api.filter_sites()

export const getSites = (
    state,
    contentType = 'json', // 'json', 'xls', 'csv'
    listOrSummary = 'list', // 'list', 'summary'
) => {
    // tests currently don't call this function with any `data`
    const data = {
        listOrSummary,
        map: state.map,
        mapOptions: state.mapOptions,
        filters: state.filters,
        location: { ...state.location },
        screenshot: Boolean(window.screenshot),
    }

    if (data.location.lat === null) {
        delete data.location.lat
    }
    if (data.location.lng === null) {
        delete data.location.lng
    }

    // Fetch sites as json by default
    if (contentType === 'json') {
        return post({
            url,
            client,
            data,
            cancelPrevious: `getSites-${contentType}-${listOrSummary}`,
        }).catch(() => Promise.reject(new GetSitesError()))
        // handle errors in the applyFilter and unapplyFilter async actions instead
    }

    // If there's a contentType then download a csv/xls file
    submitAsForm(url, {
        data: renameKeysToSnakeCase({ ...data, contentType }),
    })
    return undefined
}
