import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './DeleteFilterButton.module.scss'

import CrossIcon from '../Icons/Cross'

const styles = classnames.bind(css)

const DeleteFilterButton = ({ handleClick, className, inverse }) => (
    <button
        className={styles('delete-filter-button', className, {
            'delete-filter-button--inverse': inverse,
        })}
        type="button"
        onClick={() => {
            handleClick()
        }}
    >
        <CrossIcon
            className={styles('delete-filter-button__icon', {
                'delete-filter-button__icon--inverse': inverse,
            })}
        />
    </button>
)

DeleteFilterButton.defaultProps = {
    className: null,
}

DeleteFilterButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default DeleteFilterButton
