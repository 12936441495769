import React from 'react'
import PropTypes from 'prop-types'

const User = ({ fill, ...rest }) => (
    <svg width="24" height="16" {...rest}>
        <path
            fill={fill}
            d="M16 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4z"
        />
    </svg>
)

User.defaultProps = {
    fill: null,
}

User.propTypes = {
    fill: PropTypes.string,
}

export default User
