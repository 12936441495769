import React, { useContext } from 'react'

import { UiContext } from '../../context/UiContext'
import Loading from '../Loading/Loading'

// Used to wrap components that are dependent on data coming from Services/Initial
const InitialLoading = ({ children, showLoading }) => {
    const { initialData } = useContext(UiContext)
    return initialData || !showLoading ? children : <Loading />
}

export default InitialLoading
