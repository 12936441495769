import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { Helmet } from 'react-helmet'

import UiContextProvider from './context/UiContext'
import { ReactAlertContext } from './context/ReactAlertContext'

import { urls } from './urls'
import { useReloadOnProductionOnly } from './hooks/hooks'
import { queryStringToObject } from './utils/queryString'

import Layout from './components/Layout/Layout'
import Sidebar from './components/Sidebar/Sidebar'
import MapLoader from './components/MapLoader/MapLoader'
import FilterSelectorModal from './components/FilterSelectorModal/FilterSelectorModal'
import SiteSearchModal from './components/SiteSearchModal/SiteSearchModal'
import FilterGroupModal from './components/FilterGroupModal/FilterGroupModal'
import SavedMapModalSave from './components/SavedMapModalSave/SavedMapModalSave'
import SavedMapModalRename from './components/SavedMapModalRename/SavedMapModalRename'
import OverlaySelectorModal from './components/OverlaySelectorModal/OverlaySelectorModal'
import ShareMapModal from './components/ShareMapModal/ShareMapModal'
import BrowseSites from './components/BrowseSites/BrowseSites'
import SavedMapList from './components/SavedMapList/SavedMapList'
import Message from './components/Message/Message'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'

const reactAlertOptions = {
    context: ReactAlertContext,
    template: Message,
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '10px',
    transition: transitions.FADE,
    containerStyle: {
        transform: null, // reset default value which breaks positioning
        margin: 'auto', // center it vith this and left/right:0
        left: 0,
        right: 0,
        maxWidth: 632, // match the inner width of modals
        zIndex: 1100, // should match $z-index--message in _variables.scss
    },
}

const NotFound = () => {
    // This is rendered during development only because on port 3000 django can't handle 404s.
    // E.g. if we navigate via pop/pushState to an url that doesn't exists any more
    // then we'll call useReloadOnProductionOnly() from another component, like SavedMapLoader,
    // which will end up rendering a server side 404 page via location.reload().
    // However that doesn't happen locally when we're on port 3000. Redirecting to port 8000 would be more confusing.

    const reload = useReloadOnProductionOnly()
    useEffect(() => {
        reload()
    })
    return null
}

function App() {
    const query = queryStringToObject(window.location.search)?.query
    const title = `Pitchfinder - Find a Pitch Near ${
        query ? query.toUpperCase() : 'You'
    } | Football Foundation`
    const description = query
        ? `Find a football pitch near ${query.toUpperCase()}.`
        : 'PitchFinder is the largest database of football facilities in England. Find a football pitch near you!'

    return (
        <ErrorBoundary fullPage>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <UiContextProvider>
                <AlertProvider {...reactAlertOptions}>
                    <Router>
                        {/* Root Page */}
                        <Layout
                            path={urls.home()}
                            rightPanel={
                                <BrowseSites headingText="FIND A PITCH NEAR YOU" />
                            }
                            browseLayout
                        />
                        {/* Map Page(s) */}
                        <Layout
                            path={urls.map()}
                            leftPanel={<Sidebar />}
                            rightPanel={<MapLoader />}
                            mapLayout
                        />
                        <Layout
                            path={urls.sitePopupOnly(urls.params.siteId)}
                            leftPanel={<Sidebar />}
                            rightPanel={<MapLoader />}
                            mapLayout
                        />
                        <Layout
                            path={urls.site(urls.params.siteId)}
                            leftPanel={<Sidebar />}
                            rightPanel={<MapLoader />}
                            siteLayout
                        />
                        <Layout
                            path={urls.savedMaps()}
                            rightPanel={<SavedMapList />}
                            siteLayout
                        />
                        <Layout
                            path={urls.savedMap(urls.params.savedMapSlug)}
                            leftPanel={<Sidebar />}
                            rightPanel={<MapLoader />}
                            mapLayout
                        />
                        <Layout
                            path={urls.savedMapWithCode(
                                urls.params.savedMapSlug,
                                urls.params.gssCode,
                            )}
                            leftPanel={<Sidebar />}
                            rightPanel={<MapLoader />}
                            mapLayout
                        />
                        {/* NB `Layout` contains components that MUST be rendered for EVERY route. */}
                        <NotFound default />
                    </Router>

                    {/* Below are rendered outside Router as they have no url "path" */}
                    <FilterSelectorModal />
                    <OverlaySelectorModal />
                    <FilterGroupModal />
                    <SiteSearchModal />
                    <SavedMapModalSave />
                    <SavedMapModalRename />
                    <ShareMapModal />
                </AlertProvider>
            </UiContextProvider>
        </ErrorBoundary>
    )
}

export default App
