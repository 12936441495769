import { post, get, deleteRequest } from '../Services'
import { urls } from '../../urls'
import { isAllCamelCase } from '../../utils/utils'

const checkCamelCase = (data) => {
    if (!isAllCamelCase(data)) {
        throw new Error(
            `Saved map data not in camelcase: ${JSON.stringify(data)}`,
        )
    }
}

export const createSavedMap = (data) => {
    checkCamelCase(data)
    return post({
        url: urls.api.api_saved_maps(),
        data,
    }).then(({ data }) => data)
}

export const updateSavedMap = (savedMapSlug, data) => {
    checkCamelCase(data)
    return post({
        url: urls.api.api_saved_map_update(savedMapSlug),
        method: 'patch',
        data,
    }).then(({ data }) => data)
}

export const deleteSavedMap = (id) => {
    return deleteRequest({
        url: urls.api.api_saved_map_delete(id),
    })
}

export const getSavedMapData = (savedMapSlug) => {
    return get({
        url: urls.api.api_saved_map(savedMapSlug),
        cancelPrevious: 'getSavedMapData',
    }).then(({ data }) => data)
}

export const getSavedMapsForUser = () => {
    return get({
        url: urls.api.api_saved_maps(),
    }).then(({ data }) => data)
}
