import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './index.scss'

import App from './App'
import store from './store'
import { debounce, isConnectionError } from './utils/utils'
import detectOldBrowser from './utils/detectOldBrowser'
import './screenshot'
import './siteSelector'

if (
    process.env.NODE_ENV === 'production' &&
    !window.location.hostname.includes('localhost') &&
    window.Sentry
) {
    Sentry.init({
        dsn: `${window.REACT_APP_SENTRY_API_KEY ||
            process.env.REACT_APP_SENTRY_API_KEY}`,
        environment:
            process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
        beforeSend: (event, hint) => {
            if (navigator.userAgent.indexOf('jsdom') !== -1) {
                return null
            }
            // Change `event.fingerprint` for events that we don't care about.
            // These can be easily ignored/muted from the sentry UI.
            // `required` config from https://browser-update.org/#install
            const isOldBrowser = detectOldBrowser({
                c: -3, // Chrome
                f: -3, // Firefox
                e: -3, // Edge
                o: -3, // Opera
                s: -2, // Safari
            })
            const isIOS =
                /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
            const error = hint.originalException
            const message = (error?.message && error.message) || ''
            const eventStr = JSON.stringify(event) + JSON.stringify(hint)
            // https://projects.torchbox.com/projects/ff-pitchfinder/tickets/681
            const mapError1 = /NotLoadingAPIFromGoogleMapsError/
            const mapError2 = /Could not load "/
            // https://projects.torchbox.com/projects/ff-pitchfinder/tickets/734
            const mapError3 = /null is not an object \(evaluating 'a\.scale'\)/
            const mapError4 = /null is not an object \(evaluating 'g\.lineJoin="round"'\)/
            if (eventStr.match(mapError1)) {
                // This one is only in the breadcrumbs, so looking at the `message` is not enough.
                event.fingerprint = ['NotLoadingAPIFromGoogleMapsError']
            } else if (message.match(mapError2)) {
                event.fingerprint = ['google-maps-loading-error']
            } else if (
                isIOS &&
                (eventStr.match(mapError3) || eventStr.match(mapError4))
            ) {
                event.fingerprint = ['google-maps-ios-error']
            } else if (isConnectionError(message)) {
                event.fingerprint = ['network-error']
            } else if (isOldBrowser) {
                event.fingerprint = ['error-in-old-browser']
            }
            return event
        },
    })

    Sentry.setTag('lang', 'javascript')

    Sentry.setTag('site', 'pitchfinder')

    if (window.siteSelector) {
        Sentry.setTag('mode', 'siteselector')
    } else if (window.screenshot) {
        Sentry.setTag('mode', 'screenshot')
    } else {
        Sentry.setTag('mode', 'default')
    }
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
)

// Prevent dropping files by default. It's allowed over the map only. See `customGeojson.js`
const preventDefault = (e) => e.preventDefault()
document.getElementById('root').addEventListener('drop', preventDefault)
document.getElementById('root').addEventListener('dragover', preventDefault)

// Use CSS variables to fix the viewport height
const setViewportHeight = debounce(500, () => {
    const vh = window.innerHeight
    document.documentElement.style.setProperty('--viewport-height', `${vh}px`)
})
setViewportHeight()
window.addEventListener('resize', setViewportHeight)
