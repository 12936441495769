import React, { useContext, useEffect } from 'react'

import classnames from 'classnames/bind'
import { UiContext } from '../../context/UiContext'
import { urls } from '../../urls'
import SavedMapLoader from '../SavedMapLoader/SavedMapLoader'
import LoadingMonitor from '../LoadingMonitor/LoadingMonitor'
import SiteHeader from '../SiteHeader/SiteHeader'

import css from './Layout.module.scss'

const styles = classnames.bind(css)

const Layout = ({
    leftPanel,
    rightPanel,
    browseLayout,
    mapLayout,
    siteLayout,
    children,
    ...rest
}) => {
    const { setShowHamburger } = useContext(UiContext)

    useEffect(() => {
        const hideSidebar = [urls.home(), urls.savedMaps()].includes(rest.path)
        setShowHamburger(!hideSidebar)
    }, [rest.path, setShowHamburger])

    return (
        <>
            {/* Render first for keyboard nav */}
            {!window.siteSelector && (
                <SiteHeader fullwidth={rest.path === urls.home()} />
            )}
            <div
                className={styles(
                    'layout',
                    { 'layout--browse': browseLayout },
                    { 'layout--map': mapLayout },
                    { 'layout--site': siteLayout },
                    { 'layout--site-selector': window.siteSelector },
                )}
            >
                <div className={styles('layout__left-panel')}>
                    {leftPanel && React.cloneElement(leftPanel, { ...rest })}
                </div>
                <div className={styles('layout__right-panel')}>
                    {rightPanel && React.cloneElement(rightPanel, { ...rest })}
                </div>
                {children && React.cloneElement(children, { ...rest })}

                {/* SavedMapLoader should be rendered for every route
                in order to ensure it handles all url changes.
                This lets us do something when navigating away from a saved map
                and also makes it refresh the saved map data when navigating back to it. */}
                <SavedMapLoader {...rest} />
                {/* LoadingMonitor is included here so that we can reliably tell if we have a saved map or not */}
                <LoadingMonitor path={rest.path} />
            </div>
        </>
    )
}

export default Layout
