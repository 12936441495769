import React from 'react'
import PropTypes from 'prop-types'
import { Tabs as ReactTabs, Tab, TabList, TabPanel } from 'react-tabs'
import classnames from 'classnames/bind'

import css from './Tabs.module.scss'

const styles = classnames.bind(css)

const Tabs = ({
    tabList,
    tabPanels,
    className,
    panelClassName,
    controlledTabIndex,
    updateControlledTabIndex,
    stacked,
    light,
    alignLeft,
    allowOverflow,
}) => {
    /**
     * <ReactTabs /> can either handle its own state
     * or be "controlled" by external state (useful in a few rare cases)
     * https://github.com/reactjs/react-tabs#controlled-mode
     */
    // prettier-ignore
    const controlledProps =
        controlledTabIndex !== undefined &&
        updateControlledTabIndex !== undefined
            ? {
                selectedIndex: controlledTabIndex,
                onSelect: (tabIndex) => {
                    updateControlledTabIndex(tabIndex)
                },
            }
            : {}

    return (
        <ReactTabs
            className={styles(
                'tabs',
                {
                    'tabs--stacked': stacked,
                    'tabs--light': light,
                    'tabs--align-left': alignLeft,
                    'tabs--allow-overflow': allowOverflow,
                },
                className,
            )}
            {...controlledProps}
        >
            <TabList className={styles('tabs__list')}>
                {(
                    tabList ||
                    tabPanels.map((component) => component.props.name)
                ).map((name, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Tab key={i} className={styles('tabs__tab')}>
                        {name}
                    </Tab>
                ))}
            </TabList>
            {tabPanels.map((component) => (
                <TabPanel
                    key={component.props.tabkey}
                    className={styles('tabs__panel', panelClassName)}
                >
                    {component}
                </TabPanel>
            ))}
        </ReactTabs>
    )
}

Tabs.defaultProps = {
    className: null,
    panelClassName: null,
    controlledTabIndex: () => {},
    updateControlledTabIndex: () => {},
    light: false,
    alignLeft: false,
    allowOverflow: false,
}

Tabs.propTypes = {
    tabList: PropTypes.arrayOf(PropTypes.string),
    tabPanels: PropTypes.arrayOf(PropTypes.node).isRequired,
    className: PropTypes.string,
    panelClassName: PropTypes.string,
    controlledTabIndex: PropTypes.number,
    updateControlledTabIndex: PropTypes.func,
    light: PropTypes.bool,
    alignLeft: PropTypes.bool,
    allowOverflow: PropTypes.bool,
}

export default Tabs
