import React from 'react'
import { components } from 'react-select'
import classnames from 'classnames/bind'

import DownArrow from '../Icons/DownArrow'
import Cross from '../Icons/Cross'

import { colors } from '../../consts/styleVariables'
import css from './InputSelect.module.scss'
import { customStyles } from './reactSelectStyles'

const styles = classnames.bind(css)

// Custom Dropdown Indicator
export const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <DownArrow fill={colors.green} />
        </components.DropdownIndicator>
    )
}

// Custom Clear Indicator
export const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator
            {...props}
            className={styles('input-select__clear')}
        >
            <Cross fill={colors.green} />
        </components.ClearIndicator>
    )
}

// By default the placeholder is not hidden on focus, only when one starts typing
export const Placeholder = (props) => {
    return (
        <components.Placeholder
            {...{
                ...props,
                children: props.isFocused ? null : props.children,
            }}
        />
    )
}

// Custom message when there are no autocomplete results
export const NoOptionsMessage = (props) => {
    return (
        <components.NoOptionsMessage
            {...{
                ...props,
                children: 'No results',
            }}
        />
    )
}

// Custom menu item to show more results message if required
export const CustomOption = (props) =>
    props.data && props.data.notShown > 0 ? (
        <div style={customStyles.moreResults}>
            {props.data.notShown} more results
        </div>
    ) : (
        <components.Option {...props} />
    )
