import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './SiteCardIcon.module.scss'

import CrossIcon from '../Icons/Cross'
import CheckIcon from '../Icons/Check'

const styles = classnames.bind(css)

const SiteCardIcon = ({ name, className, darkTheme, value }) => {
    const icon = {
        true: <CheckIcon className={styles('icon', 'check')} />,
        false: <CrossIcon className={styles('icon', 'cross')} />,
    }[value]

    return (
        <div
            className={styles(
                'site-card-icon',
                {
                    'dark-theme': darkTheme,
                },
                className,
            )}
        >
            {icon}
            <span>{name}</span>
        </div>
    )
}

SiteCardIcon.defaultProps = {
    className: null,
    value: null,
}

SiteCardIcon.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    className: PropTypes.string,
}

export default SiteCardIcon
