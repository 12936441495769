import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames/bind'
import { useAlert } from '../../hooks/hooks'

import {
    ACTIVE_MODAL_SAVE_MAP,
    SAVED_MAP_MAX_LENGTH,
} from '../../consts/consts'
import { newSavedMapSlug } from '../../utils/utils'

import Modal, { useModal } from '../Modal/Modal'
import Close from '../Close/Close'
import FormStep from '../FormStep/FormStep'
import Button from '../Button/Button'
import Heading from '../Heading/Heading'
import InputText from '../InputText/InputText'
import {
    createOrUpdateSavedMap,
    cloneSavedMap,
} from '../../store/SavedMap/SavedMap'

import css from './SavedMapModalSave.module.scss'

const styles = classnames.bind(css)

const SavedMapModalSave = () => {
    const { activeModal, closeModal } = useModal()
    const dispatch = useDispatch()
    const alert = useAlert()

    const slug = useSelector(({ savedMap: { slug } }) => slug)
    const isSavedMap = Boolean(slug)

    const [label, setLabel] = useState(slug || '')

    useEffect(() => {
        setLabel(slug || '')
    }, [slug, activeModal]) // the activeModal dep is needed to reset value if the modal was cancelled

    const title = isSavedMap ? 'Save map as ' : 'Save map'

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isSavedMap) {
            dispatch(cloneSavedMap(label || newSavedMapSlug(), alert))
        } else {
            dispatch(createOrUpdateSavedMap(label || newSavedMapSlug(), alert))
        }
        closeModal()
    }

    return (
        activeModal === ACTIVE_MODAL_SAVE_MAP && (
            <Modal titleText={title}>
                <FormStep className={styles('save-search')}>
                    <form onSubmit={handleSubmit}>
                        <div className="visually-hidden" role="status">
                            {title}
                        </div>
                        <Heading heading="h2" text={title} />
                        <p className={styles('save-search__description')}>
                            Your filters, overlays and boundaries will be saved,
                            allowing you to quickly access them next time.
                        </p>
                        <div className={styles('save-search__row--full')}>
                            <div>
                                <Heading
                                    heading="h4"
                                    text={title}
                                    isSubHeading
                                />
                                <InputText
                                    type="text"
                                    value={label}
                                    placeholder="Leave this field empty to assign a randomly generated name"
                                    handleChange={(e) =>
                                        setLabel(e.target.value)
                                    }
                                    aria-labelledby="search_name"
                                    maxLength={SAVED_MAP_MAX_LENGTH}
                                />
                                <span
                                    id="search_name"
                                    className="visually-hidden"
                                >
                                    Enter a name
                                </span>
                            </div>
                        </div>
                        <div>
                            <Button
                                type="sumbmit"
                                text="Save"
                                isRounded
                                disabled={isSavedMap && label === slug}
                            />
                            <Button
                                text="Cancel"
                                handleClick={closeModal}
                                isCancel
                            />
                        </div>
                    </form>
                </FormStep>
                <Close handleClick={closeModal} />
            </Modal>
        )
    )
}

export default SavedMapModalSave
