import React, {
    useState,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames/bind'
import Heading from '../Heading/Heading'
import { Step, SubStep, useMultiStep } from '../MultiStep/MultiStep'
import CategoryList from '../CategoryList/CategoryList'
import ListCta from '../ListCta/ListCta'
import Button from '../Button/Button'
import InputSearch from '../InputSearch/InputSearch'
import ComposedInput from '../ComposedInput/ComposedInput'
import ActiveFilters from '../ActiveFilters/ActiveFilters'
import QuickFilters from '../QuickFilters/QuickFilters'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import FilterGroupEditButton from '../FilterGroupEditButton/FilterGroupEditButton'

import {
    applyFilter,
    unapplyFilter,
    updateGroup,
    unapplyGroup,
    applyGroup,
} from '../../store/Filters/Filters'
import { updateOption } from '../../store/MapOptions/MapOptions'
import { UiContext } from '../../context/UiContext'
import {
    ACTIVE_MODAL_FILTER_GROUP,
    ACTIVE_MODAL_FILTER_SELECTOR,
    DEFAULT_FILTER_GROUP_LABEL,
} from '../../consts/consts'
import { useAlert } from '../../hooks/hooks'

import css from '../Selector/Selector.module.scss'

const styles = classnames.bind(css)

const findConfigById = (filterId, configObjArray) =>
    configObjArray
        .reduce((acc, curr) => [...acc, ...curr.options], [])
        .filter(({ id }) => filterId === id)

export const getFilterConfig = (subStepObj, configObjArray) => {
    if (!subStepObj) {
        return null
    }

    const [configObj] = findConfigById(subStepObj.id, configObjArray)

    if (configObj && typeof configObj.secondary === 'string') {
        const [secondaryConfigObj] = findConfigById(
            configObj.secondary,
            configObjArray,
        )

        configObj.secondaryConfig = secondaryConfigObj
    }

    return configObj || null
}

const isValid = (filter) => {
    if (!filter) {
        return false
    }

    const { value, operator, type } = filter

    // prettier-ignore
    if (['is_empty', 'is_not_empty', 'is_null', 'is_not_null'].includes(operator)) {
        return true
    }

    // boolean values are currently represented as strings
    if (type === 'boolean') {
        if (!['true', 'false', 'null'].includes(value)) {
            throw new Error('Invalid boolean filter value')
        }
    }

    // even if isMulti=true react-select won't wrap a single value in an array
    if (['in', 'not_in'].includes(operator) && Array.isArray(value)) {
        return value.every((v) => ![undefined, null, ''].includes(v))
    }

    if ([undefined, null, ''].includes(value)) {
        return false
    }

    if (operator === 'between' && value?.some((v) => v === null)) {
        return false
    }

    return true
}

const FilterSelector = ({ config, filteredConfig, closeModal }) => {
    const alert = useAlert()
    const {
        activeStep,
        setActiveStep,
        activeSubStep,
        setActiveSubStep,
        parentSteps,
        childSteps,
        showActiveStep,
    } = useMultiStep(filteredConfig)

    const dispatch = useDispatch()

    const {
        isMobile,
        setActiveModal,
        setPreviousModal,
        setActiveSubStepId,
        activeSubStepId,
        activeFilterGroupId,
        setActiveFilterGroupId,
    } = useContext(UiContext)

    const [unappliedFilter, setUnappliedFilter] = useState()

    const [unappliedSecondaryFilter, setUnappliedSecondaryFilter] = useState()

    const filterGroup = useSelector(
        ({ filters }) => filters[activeFilterGroupId],
    )
    // Keep the intial filter group state in case the user wants to cancel changes
    const initialFilterGroup = useRef(filterGroup)

    // Rules is a list containing the values set for the current active filter group
    // Check for an active filter group, as the first time this is run
    // there won't be an empty filter group to add rules to in state.
    // The creation of the latter is handled in handleFilterValueDispatch()
    const rulesArray = filterGroup?.rules || []

    // convert rules to an object for easier access by id
    const rulesMap = Object.fromEntries(rulesArray.map((r) => [r.id, r]))

    const hasRules = rulesArray.length > 0

    const rule = hasRules ? rulesMap[activeSubStepId] : null

    const filterGroupColor = filterGroup?.icon

    const filterGroupLabel = filterGroup?.label || null

    // Check for activeSubStepId and rules
    // to set the active step and active sub step
    // if the FilterSelector should show the editor on first load
    // (i.e. has been loaded by a FilterCard)
    useEffect(() => {
        if (activeSubStepId && hasRules) {
            // Set in MultiStep state
            setActiveStep(rule.optgroup)
            setActiveSubStep(rule)

            // Reset in Context
            setActiveSubStepId(null)
        }
    }, [
        activeSubStepId,
        rule,
        hasRules,
        setActiveStep,
        setActiveSubStep,
        setActiveSubStepId,
        activeFilterGroupId,
        setActiveFilterGroupId,
    ])

    // Set the active config to pass to the composed input component
    const activeFilterConfig = getFilterConfig(activeSubStep, config)

    // Get an array of active primary filters for the active filters lits
    const activePrimaryFilters = rulesArray.filter(({ quick }) => !quick)

    // Get an array of active quick filter IDs to pass to quick filters
    // for checkbox value comparison
    const activeQuickFilterIds = rulesArray
        .filter(({ quick }) => quick)
        .map(({ id }) => id)

    // Wrap in useCallback to prevent redeclaring in handleChange={}
    // on each re-render when props change
    const handleFilterValueDispatch = useCallback(() => {
        // We don't want to add empty filter groups each time FilterSelector is opened,
        // If there aren't rules in the activeFilterGroup's state
        // dispatch a new filter group to update rules on
        if (!hasRules) {
            dispatch(
                updateGroup({
                    filterGroupId: activeFilterGroupId,
                    rules: [],
                    icon: filterGroupColor,
                    label:
                        filterGroupLabel || `${DEFAULT_FILTER_GROUP_LABEL} 1`,
                }),
            )
        }

        // If there is a valid value, add the filter to the store
        // otherwise remove it if the user has cleared the input.
        if (isValid(unappliedFilter)) {
            if (activeSubStep.id === 'grant_summaries__lffp') {
                dispatch(updateOption({ nonOperational: true }))
                alert.current.show(
                    'Showing non-operational sites too because the LFFP filter was selected',
                )
            }
            dispatch(
                applyFilter({
                    id: activeSubStep.id,
                    filterGroupId: activeFilterGroupId,
                    value: unappliedFilter.value,
                    operator: unappliedFilter.operator,
                    label: activeSubStep.label,
                    optgroup: activeSubStep.optgroup,
                    type: activeSubStep.type,
                    quick: activeSubStep.quick,
                }),
            )
        } else {
            dispatch(
                unapplyFilter({
                    id: activeSubStep.id,
                    filterGroupId: activeFilterGroupId,
                }),
            )
        }

        if (unappliedSecondaryFilter && isValid(unappliedSecondaryFilter)) {
            dispatch(
                applyFilter({
                    id: activeFilterConfig.secondaryConfig.id,
                    filterGroupId: activeFilterGroupId,
                    value: unappliedSecondaryFilter.value,
                    operator: unappliedSecondaryFilter.operator,
                    label: activeFilterConfig.secondaryConfig.label,
                    optgroup: activeFilterConfig.secondaryConfig.optgroup,
                    type: activeFilterConfig.secondaryConfig.type,
                    quick: activeFilterConfig.secondaryConfig.quick,
                }),
            )
        }
    }, [
        alert,
        activeSubStep,
        activeFilterConfig,
        dispatch,
        unappliedFilter,
        unappliedSecondaryFilter,
        activeFilterGroupId,
        hasRules,
        filterGroupColor,
        filterGroupLabel,
    ])

    const headingText = hasRules ? 'Choose another filter' : 'Choose a filter'
    const FilterSelectorButtons = () => (
        <div
            className={styles('selector__row', 'selector__row--columns', {
                'selector__row--padded': isMobile,
            })}
        >
            <div className={styles('selector__column')}>
                {/* left column is empty */}
            </div>
            <div className={styles('selector__column')}>
                <Button
                    text="Done"
                    onClick={closeModal}
                    isRounded
                    style={{ float: 'right' }}
                />
                <Button
                    text="Cancel"
                    handleClick={() => {
                        if (initialFilterGroup.current) {
                            dispatch(applyGroup(initialFilterGroup.current))
                        } else {
                            dispatch(
                                unapplyGroup({
                                    filterGroupId: activeFilterGroupId,
                                }),
                            )
                        }
                        closeModal()
                    }}
                    isCancel
                    style={{ float: 'right' }}
                />
            </div>
        </div>
    )

    return (
        <div className={styles('selector')}>
            {/* First Step */}
            {!activeStep && (
                <Step headingText={headingText} aria-live="polite">
                    {filterGroupLabel && (
                        <div className={styles('selector__row')}>
                            <Heading
                                heading="h3"
                                text="Filter Group Title"
                                isSubHeading
                            />
                            <FilterGroupEditButton
                                filterGroupLabel={filterGroupLabel}
                                filterGroupColor={filterGroupColor}
                                handleClick={() => {
                                    setPreviousModal(
                                        ACTIVE_MODAL_FILTER_SELECTOR,
                                    )
                                    setActiveModal(ACTIVE_MODAL_FILTER_GROUP)
                                }}
                            />
                        </div>
                    )}
                    {/* Selected filters */}
                    {activePrimaryFilters.length > 0 && (
                        <div className={styles('selector__row')}>
                            <Heading
                                heading="h3"
                                text="Your active filters"
                                isSubHeading
                            />
                            <ActiveFilters
                                activeFilters={activePrimaryFilters}
                                handleSetStep={(filter) => {
                                    setActiveStep(filter.optgroup)
                                    setActiveSubStep(filter)
                                }}
                                unapplyFilter={(id) => {
                                    dispatch(
                                        unapplyFilter({
                                            id,
                                            filterGroupId: activeFilterGroupId,
                                        }),
                                    )
                                }}
                            />
                        </div>
                    )}
                    <div className={styles('selector__row')}>
                        <Heading
                            heading="h3"
                            text="Search filters"
                            isSubHeading
                        />
                        <InputSearch
                            options={filteredConfig}
                            handleChange={(filter) => {
                                setActiveStep(filter.optgroup)
                                setActiveSubStep(filter)
                            }}
                            placeholder="e.g. Car park"
                        />
                    </div>
                    <div
                        className={styles(
                            'selector__row',
                            'selector__row--columns',
                        )}
                    >
                        <div className={styles('selector__column')}>
                            <QuickFilters
                                activeFilterIds={activeQuickFilterIds}
                                applyQuickFilter={({ id, label }) => {
                                    if (!hasRules) {
                                        dispatch(
                                            updateGroup({
                                                filterGroupId: activeFilterGroupId,
                                                rules: [],
                                                icon: filterGroupColor,
                                                label:
                                                    filterGroupLabel ||
                                                    `${DEFAULT_FILTER_GROUP_LABEL} 1`,
                                            }),
                                        )
                                    }

                                    dispatch(
                                        applyFilter({
                                            id,
                                            label,
                                            filterGroupId: activeFilterGroupId,
                                            quick: true,
                                        }),
                                    )
                                }}
                                unapplyQuickFilter={({ id }) => {
                                    dispatch(
                                        unapplyFilter({
                                            id,
                                            filterGroupId: activeFilterGroupId,
                                        }),
                                    )
                                }}
                            />
                        </div>
                        {isMobile && <FilterSelectorButtons />}
                        {parentSteps && (
                            <div className={styles('selector__column')}>
                                <Heading
                                    heading="h3"
                                    text="Create your own filter"
                                    isSubHeading
                                />
                                <CategoryList>
                                    {parentSteps
                                        .filter((group) => {
                                            // don't list categories that have only hidden fields
                                            return !config.find(
                                                ({ label }) => label === group,
                                            ).hidden
                                        })
                                        .map((group) => {
                                            return (
                                                <ListCta
                                                    key={group}
                                                    text={group}
                                                    handleClick={() => {
                                                        setActiveStep(group)
                                                    }}
                                                    chevron
                                                />
                                            )
                                        })}
                                </CategoryList>
                            </div>
                        )}
                    </div>
                    {!isMobile && <FilterSelectorButtons />}
                </Step>
            )}

            {/* Second Step - Category List */}
            {showActiveStep && (
                <SubStep
                    headingText={headingText}
                    stepName={activeStep}
                    parentName="Categories"
                    handleBack={() => {
                        setActiveStep(null)
                    }}
                >
                    <div className={styles('selector__row')}>
                        <Heading
                            heading="h3"
                            text="Search filters"
                            isSubHeading
                        />
                        <InputSearch
                            options={filteredConfig}
                            handleChange={(filter) => {
                                setActiveStep(filter.optgroup)
                                setActiveSubStep(filter)
                            }}
                            placeholder="e.g. Car park"
                        />
                    </div>
                    <div className={styles('selector__row')}>
                        <Heading
                            heading="h3"
                            text={activeStep || ''}
                            isSubHeading
                        />
                        {childSteps && (
                            <CategoryList columns>
                                {childSteps
                                    .filter(({ hidden }) => !hidden) // don't list hidden filter fields
                                    .map((filter) => (
                                        <ListCta
                                            key={filter.id}
                                            text={filter.label}
                                            handleClick={() => {
                                                setActiveSubStep(filter)
                                            }}
                                            chevron
                                        />
                                    ))}
                            </CategoryList>
                        )}
                    </div>
                </SubStep>
            )}

            {/* Third Step - Set filter value */}
            {activeSubStep && (
                <SubStep
                    headingText={activeFilterConfig.label}
                    stepName={activeFilterConfig.label}
                    parentName={activeStep}
                    description={activeFilterConfig.description}
                    handleBack={() => {
                        setActiveSubStep(null)
                    }}
                >
                    <div
                        className={styles(
                            'selector__row',
                            'selector__row--full',
                        )}
                    >
                        <ErrorBoundary message="Something went wrong setting this filter. Please try again later.">
                            {/* Filter  */}
                            <ComposedInput
                                required
                                headingText={
                                    activeFilterConfig.secondaryConfig
                                        ? activeFilterConfig.label
                                        : 'Sites with...'
                                }
                                config={activeFilterConfig}
                                activeFilter={rulesMap[activeSubStep.id] || {}}
                                setUnappliedFilter={setUnappliedFilter}
                            />

                            {/* Secondary Filter  */}
                            {activeFilterConfig.secondaryConfig && (
                                <ComposedInput
                                    headingText={
                                        activeFilterConfig.secondaryConfig.label
                                    }
                                    config={activeFilterConfig.secondaryConfig}
                                    activeFilter={
                                        rulesMap[
                                            activeFilterConfig.secondaryConfig
                                                .id
                                        ] || {}
                                    }
                                    setUnappliedFilter={
                                        setUnappliedSecondaryFilter
                                    }
                                />
                            )}
                        </ErrorBoundary>
                    </div>

                    <div className={styles('selector__button-group')}>
                        <Button
                            className={styles('selector__button')}
                            text={
                                rulesMap[activeSubStep.id]
                                    ? 'Update Filter'
                                    : 'Add Filter'
                            }
                            disabled={!isValid(unappliedFilter)}
                            isRounded
                            handleClick={() => {
                                // Update the dispatched prop
                                // on the filter
                                handleFilterValueDispatch()

                                // Reset UI back to first step
                                setActiveStep(null)
                                setActiveSubStep(null)

                                closeModal()
                            }}
                        />

                        <Button
                            className={styles('selector__button')}
                            text="Add another filter"
                            handleClick={() => {
                                if (isValid(unappliedFilter)) {
                                    handleFilterValueDispatch()
                                }

                                // Reset UI back to first step
                                setActiveStep(null)
                                setActiveSubStep(null)
                            }}
                        />
                        {rulesMap[activeSubStep.id] && (
                            <Button
                                className={styles('selector__button')}
                                text="Remove filter"
                                isCancel
                                handleClick={() => {
                                    dispatch(
                                        unapplyFilter({
                                            id: activeSubStep.id,
                                            filterGroupId: activeFilterGroupId,
                                        }),
                                    )
                                    // Reset UI back to first step
                                    setActiveStep(null)
                                    setActiveSubStep(null)
                                }}
                            />
                        )}
                    </div>
                </SubStep>
            )}
        </div>
    )
}

export default FilterSelector
