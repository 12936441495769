import {
    configureStore,
    getDefaultMiddleware,
    combineReducers,
} from '@reduxjs/toolkit'
import * as asyncInitialState from 'redux-async-initial-state'

import mapOptions from './store/MapOptions/MapOptions'
import location from './store/Location/Location'
import filters from './store/Filters/Filters'
import sites, { fetchSitesSummary } from './store/Sites/Sites'
import map from './store/Map/Map'
import boundaries from './store/Boundaries/Boundaries'
import overlay from './store/Overlay/Overlay'
import ogs from './store/OpenGreenSpaces/OpenGreenSpaces'
import savedMap from './store/SavedMap/SavedMap'
import customGeojson from './store/CustomGeojson/CustomGeojson'

import { setInitialStateFromQueryString } from './store/setInitialStateFromQueryString'

// https://github.com/KELiON/redux-async-initial-state#usage
// We need outerReducer to replace full state as soon as it loaded
const reducer = asyncInitialState.outerReducer(
    combineReducers({
        mapOptions,
        location,
        filters,
        sites,
        map,
        boundaries,
        overlay,
        ogs,
        customGeojson,
        savedMap,
        // We need innerReducer to store loading state, i.e. for showing loading spinner
        // If you don't need to handle loading state you may skip it
        asyncInitialState: asyncInitialState.innerReducer,
    }),
)

window.shouldLoadSitesSummary = false
// Fetch site summary stats when anything affecting the site results change.
// This could be easier done with a useEffect in the Summary component
// but that way we'd re-fetch the data each time the sidebar tabs change back it.
// However to reduce server load we don't keep loading this any more unless the
// summary tab was selected at least once. See `shouldLoadSitesSummary` in `Summary.js`
const summaryMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    const [slice] = action.type.split('/')
    if (slice && ['location', 'filters', 'mapOptions'].includes(slice)) {
        const prevState = getState()
        next(action)
        const nextState = getState()
        if (
            prevState.location !== nextState.location ||
            prevState.filters !== nextState.filters ||
            prevState.mapOptions !== nextState.mapOptions
        ) {
            if (window.shouldLoadSitesSummary) {
                dispatch(fetchSitesSummary())
            }
        }
    } else {
        // load it for the first time
        if (action.type === 'redux-async-initial-state/STATE_LOADING_DONE') {
            if (window.shouldLoadSitesSummary) {
                dispatch(fetchSitesSummary())
            }
        }
        next(action)
    }
}

const store = configureStore({
    reducer,
    middleware: [
        ...getDefaultMiddleware(),
        asyncInitialState.middleware(setInitialStateFromQueryString),
        summaryMiddleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
})

// Kicks off the asyncInitialState middleware :-/
// https://github.com/KELiON/redux-async-initial-state/issues/20
store.dispatch({ type: 'STORE INITIALISED' })

export default store
