import React from 'react'
import PropTypes from 'prop-types'

const Info = ({ fill, ...rest }) => (
    <svg width="14" height="14" {...rest}>
        <path
            d="M7 .333A6.67 6.67 0 00.333 7 6.67 6.67 0 007 13.667 6.67 6.67 0 0013.667 7 6.67 6.67 0 007 .333zm.667 10H6.333v-4h1.334v4zm0-5.333H6.333V3.667h1.334V5z"
            fill={fill}
            fillRule="evenodd"
        />
    </svg>
)

Info.defaultProps = {
    fill: null,
}

Info.propTypes = {
    fill: PropTypes.string,
}

export default Info
