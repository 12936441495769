import { useEffect, useReducer, useRef } from 'react'
import { initialMapState } from '../../store/Map/Map'
import { extendBounds, increaseBounds, getBoundsIfIsland } from './utils'
import { useMedia } from '../../hooks/hooks'

const zoomLevelsSiteSelector = {
    null: initialMapState.zoom,
    query: 15,
    site: 15,
    pinpoint: 15,
    geolocation: 15,
}

const zoomLevels = window.siteSelector
    ? zoomLevelsSiteSelector
    : {
          null: initialMapState.zoom,
          query: 12,
          site: 14,
          pinpoint: 14,
          geolocation: 14,
      }

const zoomLevelsMobile = window.siteSelector
    ? zoomLevelsSiteSelector
    : {
          null: initialMapState.zoom,
          query: 14,
          site: 14,
          pinpoint: 14,
          geolocation: 14,
      }

export const useAutoZoom = ({ mapObj, type, lat, lng, sites }) => {
    // set default zoom levels depending on media query
    const zoomRef = useRef(zoomLevels)
    const isMobile = useMedia('(max-width: 768px)')
    useEffect(() => {
        zoomRef.current = isMobile ? zoomLevelsMobile : zoomLevels
    }, [isMobile])

    // Zoom in when the location type changes
    const hasLat = Boolean(lat)
    useEffect(() => {
        // Set default when no location is specified for some reason,
        // e.g. logged out and only quick filters present in url, wihtout a query.
        const zoom = !hasLat
            ? initialMapState.zoom
            : zoomRef.current[type || null]
        if (zoom && mapObj.current) {
            mapObj.current.setZoom(zoom)
        }
    }, [mapObj, type, hasLat])

    // Collect various map features as values (except for sites)
    const [autoZoomDict, autoZoom] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {},
    )

    // Single variable containing all site locations (this way it can be used as a dep below)
    const siteCoords =
        window.screenshot && sites.length
            ? JSON.stringify(sites.map(({ lat, lng }) => [lat, lng]))
            : null

    // Perform auto-zoom
    useEffect(() => {
        // all the things we should bring into view:
        const values = Object.values(autoZoomDict).filter(Boolean)
        // extend bounds with sites only if we don't have an area and only for screenshots
        const zoomToSites =
            window.screenshot && siteCoords && !autoZoomDict.areas
        // Fit map bounds, except if we have only a location, which is handled via setZoom() above
        if (values.length && !(values.length === 1 && autoZoomDict.location)) {
            // track when tiles are loaded (used for screenshots only!)
            google.maps.event.addListenerOnce(window.map, 'tilesloaded', () => {
                window.tilesLoaded = (window.tilesLoaded || 0) + 1
            })
            // extend bounds with various map features
            const bounds = new google.maps.LatLngBounds()
            values.forEach(extendBounds.bind(null, bounds))
            if (zoomToSites) {
                JSON.parse(siteCoords).forEach(([lat, lng]) =>
                    bounds.extend(new google.maps.LatLng(lat, lng)),
                )
            }
            if (!bounds.isEmpty()) {
                // limit max zoom while calling fitBounds
                mapObj.current.setOptions({ maxZoom: 18 })
                if (zoomToSites) {
                    // increase bounds to not have any sites too close to the edge
                    increaseBounds(bounds, 1.15)
                }
                mapObj.current.fitBounds(bounds)
                mapObj.current.setOptions({ maxZoom: null })
                // set global variable (for screenshots only!)
                window.bounds = bounds
                // For some LAs we need to manually zoom out to avoid issues with the fixed device height set in the lambda function.
                if (window.screenshot) {
                    mapObj.current.setZoom(mapObj.current.getZoom() - 1)
                }
            }
        }
    }, [mapObj, autoZoomDict, siteCoords])

    // Jump to new position whenever state.location.lat/lng change.
    // This happens only when a new location is specified, but not when panning on the map.
    useEffect(() => {
        if (lat) {
            const latLng = new google.maps.LatLng(lat, lng)
            mapObj.current.setCenter(latLng)
            autoZoom({ location: latLng })
        }
        // If we have multiple areas selected then lat/lng will be undefined,
        // but we'll auto-zoom to the areas in useAreas(), so we can skip this
    }, [mapObj, lat, lng])

    // Special case for some islands when taking a screenshot
    useEffect(() => {
        if (!window.screenshot) {
            return
        }
        const bounds = getBoundsIfIsland({ lat, lng })
        if (bounds) {
            autoZoom({ island: bounds })
        }
    }, [lat, lng])

    return autoZoom
}
