import { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { ReactAlertContext } from '../context/ReactAlertContext'

// https://usehooks.com/useDebounce/
export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
            clearTimeout(handler)
        }
    }, [value, delay]) // Only re-call effect if value or delay changes

    return debouncedValue
}

// custom hook
export function useDebounced(value, delay, fn) {
    // This is like `useDebounce` but also accepts a
    // function `fn` to call with the `value` after `delay`.

    if (!fn) {
        throw Error('Missing third argument')
    }

    const debouncedValue = useDebounce(value, delay)

    // Use a ref to handle changes to `fn`,
    // which allows passing only `debouncedValue`
    // to the second `useEffect` deps.
    const functionRef = useRef(fn)
    useEffect(() => {
        functionRef.current = fn
    }, [fn])

    // We need to be able to tell if this is the first call or not
    const firstCallRef = useRef(true)

    // Execute function if debouncedValue changes,
    // except if it's the first call
    useEffect(() => {
        if (!firstCallRef.current) {
            functionRef.current(debouncedValue)
        } else {
            firstCallRef.current = false
        }
    }, [debouncedValue])

    // Return `debouncedValue`, just like `useDebounce` does.
    // NB we're NOT returning the result of `fn(value)`
    return debouncedValue
}

// https://usehooks.com/useScript/
const cachedScripts = []
export function useScript(src) {
    // Keeping track of script loaded and error state
    const [state, setState] = useState({
        loaded: false,
        error: false,
    })

    useEffect(
        () => {
            // If cachedScripts array already includes src that means another instance ...
            // ... of this hook already loaded this script, so no need to load again.
            if (cachedScripts.includes(src)) {
                setState({
                    loaded: true,
                    error: false,
                })
                return undefined
            }
            cachedScripts.push(src)

            // Create script
            const script = document.createElement('script')
            script.src = src
            script.async = true

            // Script event listener callbacks for load and error
            const onScriptLoad = () => {
                setState({
                    loaded: true,
                    error: false,
                })
            }

            const onScriptError = () => {
                // Remove from cachedScripts we can try loading again
                const index = cachedScripts.indexOf(src)
                if (index >= 0) cachedScripts.splice(index, 1)
                script.remove()

                setState({
                    loaded: true,
                    error: true,
                })
            }

            script.addEventListener('load', onScriptLoad)
            script.addEventListener('error', onScriptError)

            // Add script to document body
            document.body.appendChild(script)

            // Remove event listeners on cleanup
            return () => {
                script.removeEventListener('load', onScriptLoad)
                script.removeEventListener('error', onScriptError)
            }
        },
        [src], // Only re-run effect if script src changes
    )

    return [state.loaded, state.error]
}

// Custom hook to simplify handling controlled input elements
// https://youtu.be/dpw9EHDh2bM?t=3052
export const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue)

    return {
        value,
        onChange: (event) => {
            setValue(event.target.value)
        },
    }
}

// https://usehooks.com/usePrevious/
export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef()

    // Store current value in ref
    useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current
}

const usePromiseQueue = []

// If the reference to `getPromise` doesn't change
// it will be executed only once for a given component.
// While it's pending `initialValue` will be used as the `result`
// in the return value: `[result, pending, error]`
// but it will be used only the first time this hook was called.
// If you run into any issues it's probably safe to swap this with `useAsync` from react-async:
// https://github.com/async-library/react-async/blob/next/packages/react-async/src/useAsync.js
// The main difference is that this won't discard results from a long running request
// if the component was unmounted (or `getPromise` changed) before it finished.
export function usePromise(getPromise, initialValue, global) {
    // The state is an array of [result:any, pending:bool, error:false|Error]
    let [state, setState] = useState([initialValue, true, false]) // eslint-disable-line

    // If the promise finishes only when a component is unmounted
    // then we can't use setState at that point, but we can store
    // the result in a ref. When the component is mounted again
    // useEffect won't be called again but we can return the
    // result we stored in a ref previously.
    // Otherwise the status would remain pending.
    const resultAfterUnmount = useRef()

    // Track loading state with a ref too, not just in state[1]
    // This way it won't be set to false temporarily when getPromise changes,
    // and we don't have to trigger unnecessary rerenders either.
    const loading = useRef(true)

    // Shortcut to set the component state or keep in a ref if it
    // wasn't mounted by the time the promise had been resolved.
    const setStateOrRef = (mounted, nextState) => {
        // TODO: ignore cancelled requests
        loading.current = false
        if (mounted) {
            setState(nextState)
        } else {
            // But don't overwrite it if the next promise resolved before this one
            // and was also unmounted beforehand.
            resultAfterUnmount.current = resultAfterUnmount.current || nextState
        }
    }

    // Execute the promise only once, assuming the reference to the
    // getPromise function remains stable.
    useEffect(() => {
        loading.current = true
        resultAfterUnmount.current = null
        let mounted = true
        let interval // used only when global=true

        if (global) {
            const queued = usePromiseQueue.filter((q) => q[0] === getPromise)[0]
            if (queued) {
                // If `queued` is defined that means another component already started the request.
                if (queued.length === 1) {
                    // Still in progress but there's no result yet -> check later
                    // (doing this with setInterval because this useEffect won't be called again for this component)
                    interval = setInterval(() => {
                        if (queued.length > 1) {
                            setStateOrRef(mounted, queued[1])
                            clearInterval(interval)
                        }
                    }, 100)
                } else {
                    // We already have the result
                    setStateOrRef(mounted, queued[1])
                }
            } else {
                // This is the first component that called this hook.
                // Add an object to the queue, so that other components will know it's already in progress.
                const queued = [getPromise]
                usePromiseQueue.push(queued)
                // Call promise -> store result in the `queued` object -> change component state.
                // The state of other components will be changed from the code above.
                getPromise()
                    .then((result) => {
                        const results = [result, false, false]
                        queued.push(results)
                        setStateOrRef(mounted, results)
                    })
                    .catch((error) => {
                        const results = [null, false, error]
                        queued.push(results)
                        setStateOrRef(mounted, results)
                    })
            }
        } else {
            getPromise()
                .then((result) =>
                    setStateOrRef(mounted, [result, false, false]),
                )
                .catch((error) => setStateOrRef(mounted, [null, false, error]))
        }
        return () => {
            mounted = false
            clearInterval(interval)
        }
    }, [getPromise, global])

    // `resultAfterUnmount.current` will be defined if previosuly the
    // promise was completed after the component had been unmounted.
    // This means useEffect abaove wasn't called this time either,
    // so we return resultAfterUnmount.current instead.
    // And we also clear the ref and store the value in local state now.
    if (resultAfterUnmount.current) {
        state = resultAfterUnmount.current
        resultAfterUnmount.current = null
        setState(state)
    }

    // If the ref to getPromise changed then we need to
    // set the loading state back to true right away.
    // If we did it in useEffect then the current render would return false first.
    if (usePrevious(getPromise) !== getPromise) {
        loading.current = true
    }

    // Update loading state (wihtout calling setState)
    if (state[1] !== loading.current) {
        state = [state[0], loading.current, state[2]]
    }

    return state
}

export function usePromiseGlobal(getPromise, initialValue) {
    return usePromise(getPromise, initialValue, true)
}

// https://usehooks.com/useMedia/
export const useMediaFirstMatch = (queries, values, defaultValue) => {
    // Array containing a media query list for each query

    const mediaQueryLists = queries.map((q) => window.matchMedia(q))

    // Function that gets value based on matching media query

    const getValue = () => {
        // Get index of first media query that matches

        const index = mediaQueryLists.findIndex((mql) => mql.matches)

        // Return related value or defaultValue if none

        return typeof values[index] !== 'undefined'
            ? values[index]
            : defaultValue
    }

    // State and setter for matched value

    const [value, setValue] = useState(getValue)

    useEffect(
        () => {
            // Event listener callback

            // Note: By defining getValue outside of useEffect we ensure that it has ...

            // ... current values of hook args (as this hook callback is created once on mount).

            const handler = () => setValue(getValue)

            // Set a listener for each media query with above handler as callback.

            mediaQueryLists.forEach((mql) => mql.addListener(handler))

            // Remove listeners on cleanup

            return () =>
                mediaQueryLists.forEach((mql) => mql.removeListener(handler))
        },
        // eslint-disable-next-line
        [], // Empty array ensures effect is only run on mount and unmount
    )

    return value
}

// NOT the same as https://usehooks.com/useMedia/ but a simpler version
export const useMedia = (queries) => {
    return useMediaFirstMatch([queries], [true], false)
}

// an alternative version of react-alert's useAlert hook that returns the underlying ref, which has a stable reference
export const useAlert = (Context) => {
    return useContext(Context || ReactAlertContext)
}

export const useReloadOnProductionOnly = () => {
    const alert = useAlert()
    return useCallback(() => {
        // This function is called when we know we have a 404 error, e.g. an API returned a 404.
        if (process.env.NODE_ENV === 'production') {
            // On production we reload the page and let django handle its own 404 page.
            // We have no client side 404 page at all.
            window.location.reload()
        } else {
            // However, locally we just show a message because then django is running on a different port,
            // and it would be more confusing to redirect from port 3000 to 8000.
            alert.current.error(
                '404 - on production this page will be reloaded, and will use the server side 404 template',
            )
        }
        return null
    }, [alert])
}

// https://usehooks.com/useOnClickOutside/
export function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return
                }

                handler(event)
            }

            document.addEventListener('mousedown', listener)
            document.addEventListener('touchstart', listener)

            return () => {
                document.removeEventListener('mousedown', listener)
                document.removeEventListener('touchstart', listener)
            }
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler],
    )
}
