import React, { useRef, useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { navigate, Link } from '@reach/router'
import classnames from 'classnames/bind'

import { debounce, isConnectionError } from '../../utils/utils'
import { useReloadOnProductionOnly } from '../../hooks/hooks'

import { UiContext } from '../../context/UiContext'
import { urls } from '../../urls'
import { selectSite } from '../../siteSelector'

import { initialState as initialLocaionState } from '../../store/Location/Location'
import { applyMapState, initialMapState } from '../../store/Map/Map'
import { MapMarkers } from '../MapMarker/MapMarker'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { getSite } from '../../services/Site/Site'
import { useDrawingTools } from './drawingTools'
import { usePinpoint } from './pinpoint'
import { useOverlays } from './overlays'
import { useBoundaries } from './boundaries'
import { useOgs } from './ogs'
import { useAreas } from './areas'
import { useTravelBoundary } from './travelBoundary'
import { useSearchMarker } from './searchMarker'
import { useSearchRadius } from './searchRadius'
import { useCustomGeojson } from './customGeojson'
import { useAutoZoom } from './autoZoom'

import MapKey from '../MapKey/MapKey'
import GlobalSiteOptions from '../GlobalSiteOptions/GlobalSiteOptions'
import MapPopup from '../MapPopup/MapPopup'
import SiteCard from '../SiteCard/SiteCard'
import Loading from '../Loading/Loading'
import SiteInfo from '../SiteInfo/SiteInfo'
import { SiteInfoSpringWrapper } from '../SiteInfo/SiteInfoUtils'

import './Map.legacy.scss'
import css from './Map.module.scss'

const styles = classnames.bind(css)

const goToMap = () => {
    if (window.history.state?.key) {
        window.history.back()
    } else {
        navigate(urls.map())
    }
}

const Map = (props) => {
    const { siteSelector } = window
    const reload = useReloadOnProductionOnly()

    // Refs
    const mapEl = useRef(null)
    const mapObj = useRef(null)

    // Redux
    const dispatch = useDispatch()
    const sites = useSelector(({ sites }) => sites.results)
    const {
        lat,
        lng,
        radius,
        type,
        gssCodeOverride,
        value: locationValue,
    } = useSelector(({ location }) => location, shallowEqual)
    const isSavedMap = useSelector(({ savedMap: { slug } }) => Boolean(slug))
    const hasGlobalSiteOptions = useSelector(
        ({ mapOptions: { nonOperational, nonFootball, hideAll } }) =>
            nonOperational || nonFootball || hideAll,
    )

    // Get the active site ID from context (can be set by ResultsList)
    const {
        loggedIn,
        activeSiteId,
        setActiveSiteId,
        pinpointingEpicentre,
        setPinpointingEpicentre,
        setAllowSidebar,
        isTablet,
    } = useContext(UiContext)

    // Tracks if map has been loaded
    const [hasMap, setHasMap] = useState(false)

    // Tracks the active site, derived from state
    const [activeSite, setActiveSite] = useState(null)

    // When a map marker is clicked this will be `true`, allowing us to show popups.
    // If a popup is closed this will be `false` and then selecting a site
    // from the results list will only highlight markers without showing a popup.
    const [popupOpen, setPopupOpen] = useState(false)

    const [loadingSiteInfo, setLoadingSiteInfo] = useState(false)

    const [siteInfoOpen, setSiteInfoOpen] = useState(false)

    const [previousMap, setPreviousMap] = useState(null)

    const [hiddenSiteIds, setHiddenSiteIds] = useState([])

    const [connectionError, setConnectionError] = useState(false)

    const isDesktop = !isTablet

    // Initialise map (called only during the first render)
    // * set mapObj.current
    // * add event listeners
    useEffect(() => {
        if (window.screenshot) {
            mapObj.current = new google.maps.Map(mapEl.current, {
                zoom: initialMapState.zoom,
                disableDefaultUI: true,
            })
        } else {
            const mapOptions = {
                zoom: initialMapState.zoom,
                // don't allow clicking on built-in POI icons/labels, like
                // parks, schools, etc
                clickableIcons: false,
            }
            if (window.siteSelector) {
                mapOptions.gestureHandling = 'greedy'
            }

            mapObj.current = new google.maps.Map(mapEl.current, mapOptions)
        }

        // We expose map as global variable, but it's meant to be used only
        // outside components where we can't easily pass a specific instance.
        window.map = mapObj.current

        // Map will be considered loaded only after the first idle event fires
        google.maps.event.addListenerOnce(mapObj.current, 'idle', () => {
            setHasMap(true)
            // Trigger a resize to ensure tiles/controls are rendered.
            google.maps.event.trigger(mapObj.current, 'resize')
        })

        if (window.siteSelector) {
            // Fix map tiles not always loading in the site selector iframe
            google.maps.event.addListener(mapObj.current, 'idle', () => {
                google.maps.event.trigger(mapObj.current, 'resize')
            })
        }

        // Whenever the map bounds change we need to dispatch an action to reload the sites
        google.maps.event.addListener(
            mapObj.current,
            'idle',
            debounce(300, () => {
                const bounds = mapObj.current.getBounds()
                if (!bounds) {
                    // eslint-disable-next-line no-console
                    console.warn(
                        "Map became idle but it has no bounds. This can happen if the center wasn't set.",
                    )
                    return
                }
                const ne = bounds.getNorthEast()
                const sw = bounds.getSouthWest()

                dispatch(
                    applyMapState({
                        northEast: { lat: ne.lat(), lng: ne.lng() },
                        southWest: { lat: sw.lat(), lng: sw.lng() },
                        zoom: mapObj.current.getZoom(),
                    }),
                )
            }),
        )

        // Remove listeners if we ever need to unmount the map
    }, [dispatch]) // adding more deps here is likely wrong

    // Set default when no location is specified for some reason,
    // e.g. logged out and only quick filters present in url, wihtout a query.
    useEffect(() => {
        if (!lat) {
            mapObj.current.setCenter(initialLocaionState)
        }
    }, [lat])

    // change map control layout depending on screen size
    useEffect(() => {
        const topRight = { position: google.maps.ControlPosition.TOP_RIGHT }
        // default mapOptions
        let mapOptions = {
            zoomControlOptions: topRight,
            streetViewControlOptions: null,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_LEFT,
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                mapTypeIds: [
                    google.maps.MapTypeId.ROADMAP,
                    google.maps.MapTypeId.SATELLITE,
                    google.maps.MapTypeId.HYBRID,
                    // google.maps.MapTypeId.TERRAIN,
                ],
            },
        }

        // mapOptions for desktop
        if (isDesktop) {
            mapOptions = {
                zoomControlOptions: topRight,
                streetViewControlOptions: topRight,
                mapTypeControlOptions: topRight,
            }
        }

        mapObj.current.setOptions(mapOptions)
    }, [isDesktop])

    // Clear any active IDs (called only during the first render)
    useEffect(() => {
        setActiveSiteId(null)
        setActiveSite(null)
    }, [setActiveSiteId])

    // Clear the active marker and active site
    // when the results are updated or close the popup
    // when results are updated
    useEffect(() => {
        if (sites.length === 0) {
            setActiveSiteId(null)
            setActiveSite(null)
        } else {
            setPopupOpen(false)
        }
    }, [setActiveSiteId, sites])

    // Open site popup on load if the current location is a site
    useEffect(() => {
        if (type === 'site') {
            setActiveSiteId(locationValue)
        }
    }, [setActiveSiteId, type, locationValue])

    // Each time activeSiteId is updated on the '/map' path
    // set setShouldPopupOpen to true to show the popup
    useEffect(() => {
        setPopupOpen(
            activeSiteId &&
                [
                    urls.map(),
                    urls.sitePopupOnly(urls.params.siteId),
                    urls.savedMap(urls.params.savedMapSlug),
                    urls.savedMapWithCode(
                        urls.params.savedMapSlug,
                        urls.params.gssCode,
                    ),
                ].includes(props.path),
        )
    }, [activeSiteId, props.path])

    useEffect(() => {
        if (
            [
                urls.map(),
                urls.sitePopupOnly(urls.params.siteId),
                urls.savedMap(urls.params.savedMapSlug),
                urls.savedMapWithCode(
                    urls.params.savedMapSlug,
                    urls.params.gssCode,
                ),
            ].includes(props.path)
        ) {
            setSiteInfoOpen(false)
            setAllowSidebar(true)

            mapObj.current.setOptions({
                draggable: true,
            })

            if (previousMap?.center) {
                mapObj.current.panTo(previousMap.center)
                mapObj.current.setZoom(previousMap.zoom)

                setPreviousMap(null)
            }

            // Derive the the active site using the activeSiteId
            // to populate the <SiteCard> in <MapPopup>
            if (activeSiteId && sites.length > 0) {
                const [site] = sites.filter(
                    ({ id }) => String(id) === String(activeSiteId),
                )

                if (site) {
                    setActiveSite(site)
                    setPopupOpen(true)
                }
            }
        }
    }, [
        activeSiteId,
        dispatch,
        props.path,
        sites,
        setAllowSidebar,
        previousMap,
    ])

    // Fetch site data if we're on a site detail page
    useEffect(() => {
        if (props.uri === urls.site(props.siteId)) {
            setLoadingSiteInfo(true)
            setAllowSidebar(false)

            setPreviousMap({
                center: mapObj.current.getCenter(),
                zoom: mapObj.current.getZoom(),
            })

            setActiveSiteId(props.siteId)

            getSite(props.siteId)
                .then((site) => {
                    // Set/update site data with response
                    setActiveSite(site)

                    mapObj.current.panTo(
                        new google.maps.LatLng(site.lat, site.lng),
                    )

                    mapObj.current.setZoom(14)

                    // Wait for map to finish panning
                    google.maps.event.addListenerOnce(
                        mapObj.current,
                        'idle',
                        () => {
                            setSiteInfoOpen(true)
                        },
                    )

                    setLoadingSiteInfo(false)
                })
                .catch((e) => {
                    if (e.status === 404) {
                        reload()
                    } else if (isConnectionError(e)) {
                        setConnectionError(true)
                    } else {
                        throw e // let the global error boundary handle non-404 errors (and notify sentry)
                    }
                })
        }
    }, [
        dispatch,
        props.siteId,
        props.uri,
        setActiveSiteId,
        setAllowSidebar,
        reload,
    ])

    // Get coordinates for pre-selected site and zoom to it.
    // This is when url contains only a site id, and the site detail panel is not shown.
    // It's a lesser known legacy feature but also used by the site selector widget.
    useEffect(() => {
        if (props.siteId && props.uri === urls.sitePopupOnly(props.siteId)) {
            setAllowSidebar(true)
            setActiveSiteId(props.siteId)
            getSite(props.siteId)
                .then((site) => {
                    mapObj.current.panTo(
                        new google.maps.LatLng(site.lat, site.lng),
                    )

                    mapObj.current.setZoom(14)
                })
                .catch((e) => {
                    if (e.status === 404) {
                        reload()
                    } else if (isConnectionError(e)) {
                        setConnectionError(true)
                    } else {
                        throw e // let the global error boundary handle non-404 errors (and notify sentry)
                    }
                })
        }
    }, [
        dispatch,
        props.siteId,
        props.uri,
        setActiveSiteId,
        setAllowSidebar,
        reload,
    ])

    // Allow the user to go back to the main map
    // if they click on the map at the top of a site detail page.
    useEffect(() => {
        if (siteInfoOpen) {
            // on a site detail page
            mapObj.current.setOptions({
                draggableCursor: 'pointer',
                draggable: false,
                disableDefaultUI: true,
            })
            mapEl.current.addEventListener('click', goToMap)
        } else {
            // on the main map page
            mapObj.current.setOptions({
                draggableCursor: '',
                draggable: true,
                disableDefaultUI: false,
            })
            mapEl.current.removeEventListener('click', goToMap)
        }
    }, [siteInfoOpen])

    // Auto-zoom
    const autoZoom = useAutoZoom({ mapObj, type, lat, lng, sites })

    // Add drawing tool
    useDrawingTools({
        hasMap,
        mapObj,
        autoZoom,
        disabled: !(loggedIn || isSavedMap),
        show: isDesktop,
    })

    // Pinpoint epicentre
    usePinpoint({
        hasMap,
        mapObj,
        autoZoom,
        pinpointingEpicentre,
        setPinpointingEpicentre,
    })

    // Set a search location marker
    useSearchMarker({ hasMap, mapObj, autoZoom, lat, lng, type })

    // Draw radius circle on the map
    useSearchRadius({ hasMap, mapObj, autoZoom, lat, lng, radius })

    // Add selected area(s) to map (or just the gss code override)
    useAreas({ hasMap, mapObj, autoZoom, gssCodeOverride })

    // Applies GeoJson of selected travel options to map
    useTravelBoundary({ hasMap, mapObj, autoZoom })

    // Overlays
    useOverlays({ hasMap, mapObj })

    // Boundaries
    useBoundaries({ hasMap, mapObj })

    // Open Green Spaces
    useOgs({ hasMap, mapObj })

    // Custom geojson
    const { fileDropOverlay } = useCustomGeojson({
        hasMap,
        mapObj,
        autoZoom,
        loggedIn,
        setHiddenSiteIds,
    })

    // Site info and site selector buttons within MapPopup
    const buttonStyles = styles('map__site-popup-cta', {
        siteSelector,
    })

    const siteInfoButton =
        activeSite &&
        (siteSelector ? (
            <a
                href={urls.site(activeSite.id)}
                className={buttonStyles}
                target="_blank"
                rel="noopener noreferrer"
            >
                Site information
            </a>
        ) : (
            <Link className={buttonStyles} to={urls.site(activeSite.id)}>
                Site information
            </Link>
        ))

    const siteSelectorButton = siteSelector && (
        <button
            className={buttonStyles}
            type="button"
            onClick={(e) => {
                e.stopPropagation()
                // get data from public site detail api and pass it to the parent frame
                selectSite(activeSite.id) // TODO: error handling
            }}
        >
            Select this site
        </button>
    )

    if (connectionError) {
        return (
            <>
                <ErrorBoundary
                    hasError
                    fullPage
                    message={`Couldn't connect to the ${
                        process.env.NODE_ENV === 'production'
                            ? 'Internet'
                            : 'backend'
                    }`}
                />
                <div ref={mapEl} style={{ display: 'none' }} />
            </>
        )
    }

    return (
        <>
            <div
                className={styles('map')}
                ref={mapEl}
                title={siteInfoOpen ? 'Back to full map' : null}
            >
                {hasMap && (
                    <>
                        <MapMarkers
                            markers={sites}
                            hiddenMarkers={hiddenSiteIds}
                            map={mapObj.current}
                            allowClick={!siteInfoOpen}
                            autoZoom={autoZoom}
                        />
                        {activeSite && popupOpen && !siteInfoOpen && (
                            <MapPopup
                                map={mapObj.current}
                                lat={activeSite.lat}
                                lng={activeSite.lng}
                                handleClose={() => {
                                    setActiveSite(null)
                                    setActiveSiteId(null)
                                }}
                            >
                                <SiteCard
                                    site={activeSite}
                                    className={styles('map__site-popup-card')}
                                    loggedIn={loggedIn}
                                    darkTheme
                                />
                                {siteInfoButton}
                                {siteSelectorButton}
                            </MapPopup>
                        )}
                    </>
                )}
            </div>

            {/* Site Information */}
            <SiteInfoSpringWrapper
                className={styles('map__site-information')}
                open={activeSite && siteInfoOpen}
            >
                {activeSiteId && activeSite && (
                    <SiteInfo
                        site={activeSite}
                        handleClose={goToMap}
                        uri={props.uri}
                    />
                )}
            </SiteInfoSpringWrapper>

            {props.uri !== urls.site(props.siteId) && (
                // Hide MapKey on site detail page
                <MapKey className={styles('map__key')} />
            )}

            {(loggedIn || (isSavedMap && hasGlobalSiteOptions)) &&
                props.uri !== urls.site(props.siteId) && (
                    <GlobalSiteOptions
                        className={styles('map__global-site-options')}
                        disabled={!loggedIn && isSavedMap}
                        isTablet={isTablet}
                    />
                )}

            {loadingSiteInfo && (
                <div className={styles('map__loading')}>
                    <Loading />
                </div>
            )}

            {fileDropOverlay}
        </>
    )
}

export default Map
