import React from 'react'
import classnames from 'classnames/bind'
import css from './SiteInfo.module.scss'
import { colors } from '../../consts/styleVariables'
import Boxout from '../Boxout/Boxout'
import Detail from '../Detail/Detail'
import NoIcon from '../Icons/No'
import FFIcon from '../Icons/FF'
import JerseyIcon from '../Icons/Jersey'
import CarIcon from '../Icons/Car'
import AccessibleIcon from '../Icons/Accessible'
import IconLabel from '../IconLabel/IconLabel'

import { SiteInfoDetailGrid } from './SiteInfoUtils'

const styles = classnames.bind(css)

const SiteInfoOverview = ({ overview, siteId }) => (
    <Boxout title="Overview" className={styles('site-information__boxout')}>
        {(overview.operational ||
            overview.ffFunded ||
            overview.disabilityAccess ||
            overview.hasChangingRooms ||
            overview.carParkExists) && (
            <div
                className={styles(
                    'site-information__highlight',
                    'site-information__highlight--grid',
                )}
            >
                {overview.operational === false && (
                    <>
                        <IconLabel
                            label="Non-operational"
                            icon={<NoIcon fill={colors.red} />}
                            className={styles(
                                'site-information__highlight-icon',
                            )}
                        />
                        {overview.recEndDate ? (
                            <IconLabel
                                label={`Closed on ${new Date(
                                    overview.recEndDate,
                                ).toLocaleDateString()}`}
                                icon={<NoIcon fill={colors.red} />}
                                className={styles(
                                    'site-information__highlight-icon',
                                )}
                            />
                        ) : null}
                    </>
                )}
                {overview.ffFunded && (
                    <IconLabel
                        label="Funded by Football Foundation"
                        icon={<FFIcon />}
                        className={styles('site-information__highlight-icon')}
                    />
                )}
                {overview.disabilityAccess && (
                    <IconLabel
                        label="Disabled access"
                        icon={<AccessibleIcon />}
                        className={styles('site-information__highlight-icon')}
                    />
                )}
                {overview.hasChangingRooms && (
                    <IconLabel
                        label="Changing rooms"
                        icon={<JerseyIcon />}
                        className={styles('site-information__highlight-icon')}
                    />
                )}
                {overview.carParkExists && (
                    <IconLabel
                        label={
                            overview.carParkCapacity
                                ? `${overview.carParkCapacity} Parking spaces`
                                : `Car parking`
                        }
                        icon={<CarIcon />}
                        className={styles('site-information__highlight-icon')}
                    />
                )}
            </div>
        )}
        <SiteInfoDetailGrid>
            <Detail
                label="Owner Organisation"
                value={overview.ownerOrgName || 'N/A'}
                className={styles('site-information__detail')}
            />
            <Detail
                label="Owner Type"
                value={overview.ownerType || 'N/A'}
                className={styles('site-information__detail')}
            />
            <Detail
                label="Management Type"
                value={overview.managementType || 'N/A'}
                className={styles('site-information__detail')}
            />
        </SiteInfoDetailGrid>
        <div>
            <p>
                Noticed something wrong?{' '}
                <a
                    href={`https://footballfoundation-self.achieveservice.com/service/PitchFinder?PitchFinderID=${siteId}`}
                >
                    Update site information
                </a>
                .
            </p>
        </div>
    </Boxout>
)

export default SiteInfoOverview
