import React, { useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import classnames from 'classnames/bind'
import css from './SiteInfo.module.scss'
import Detail from '../Detail/Detail'
import { UiContext } from '../../context/UiContext'

const styles = classnames.bind(css)

export const SiteInfoSpringWrapper = ({ children, className, open }) => {
    const { isTablet } = useContext(UiContext)

    const height = isTablet ? '50%' : '55%'

    // `open` means whether SiteInfo should end up being open or closed,
    // so it's not dependent on the animation status.
    const props = useSpring({
        height: open ? height : '0%',
        opacity: open ? 1 : 0,
        config: {
            duration: 300,
        },
        overflow: open ? 'visible' : 'hidden',
    })

    return (
        <animated.div style={props} className={className}>
            {children}
        </animated.div>
    )
}

export const SiteInfoDetailGrid = ({ children }) => {
    const hasChildren = (children?.map ? children : [children])
        ?.map((c) => (typeof c === 'string' ? c.trim() : c))
        .filter(Boolean).length

    return (
        <div
            className={styles('site-information__detail-grid', {
                'site-information__detail-grid--even':
                    children?.length % 2 === 0,
            })}
        >
            {hasChildren ? (
                children
            ) : (
                <Detail
                    label="N/A"
                    value=""
                    className={styles('site-information__detail')}
                />
            )}
        </div>
    )
}

export const getFormattedAddress = (address) => {
    Object.keys(address).forEach(
        (k) => !address[k] && address[k] !== undefined && delete address[k],
    )
    return Object.values(address)
        .map((key) => key)
        .join(', ')
}
