import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import classnames from 'classnames/bind'
import inputSelectCss from '../InputSelect/InputSelect.module.scss'
import SearchIcon from '../Icons/Search'
import { defaultStyles } from '../InputSelect/reactSelectStyles'
import { getSearchAutocompleteDebounced } from '../../services/SearchAutocomplete/SearchAutocomplete'

const styles = classnames.bind(inputSelectCss)

const selectStyles = defaultStyles()

// Single select: always show Input because SingleValue is always hidden
const Input = (props) => {
    return (
        <components.Input
            {...props}
            isHidden={false}
            className={styles('input-select__search__input')}
        />
    )
}

// Custom Search Icon Indicator
const ValueContainer = ({ children, ...props }) => {
    return (
        <components.ValueContainer
            {...props}
            className={styles('input-select__search')}
        >
            {!!children && (
                <div className={styles('input-select__search__icon')}>
                    <SearchIcon />
                </div>
            )}
            {children}
        </components.ValueContainer>
    )
}

const Placeholder = ({ ...props }) => {
    return (
        <components.Placeholder
            className={styles('input-select__search__placeholder')}
            {...props}
        />
    )
}

const SingleValue = ({ children, ...props }) => {
    return (
        <components.SingleValue
            {...props}
            className={styles('input-select__search__value')}
        >
            {children}
        </components.SingleValue>
    )
}

// Custom Menu
const Menu = ({ children, ...props }) => {
    const {
        selectProps: { inputValue },
    } = props
    // Only show if select props has a value
    // So NoOptionsMessage does not show on first focus
    if (inputValue.length) {
        return <components.Menu {...props}>{children}</components.Menu>
    }

    return null
}

const SiteSearchSelect = ({ location, changeLocation, className, ...rest }) => {
    // isMulti is allowed for area (i.e. region) search only
    const isMulti = location.type === 'area'

    const value = isMulti
        ? Array.isArray(location.value)
            ? location.value
            : [
                  {
                      value: location.value,
                      label: location.label,
                      level: location.level,
                      lat: location.lat,
                      lng: location.lng,
                  },
              ]
        : location
    const valueHasLabel = !!(value?.label || value[0]?.label)

    const { nonOperational, nonFootball } = useSelector(
        ({ mapOptions }) => mapOptions,
    )

    const [inputValue, setInputValue] = useState(value.label)
    const selectRef = useRef(null)
    const preventInputOnBlur = useRef(false)
    const preventInputOnClear = useRef(false)

    // When switching back from multi to single select then we need to
    // set `inputValue` which was undefined when isMulti was false.
    // This will also end up setting `value` afterwards.
    useEffect(() => {
        if (!isMulti && Array.isArray(value) && inputValue !== value[0]) {
            setInputValue(value[0])
        }
    }, [isMulti, inputValue, value])

    // When user clicks "Use my current location", clear out the inputValue
    useEffect(() => {
        if (
            location?.type === 'geolocation' ||
            !Object.keys(location).length ||
            !valueHasLabel
        ) {
            setInputValue('')
        }
    }, [location, valueHasLabel])

    const loadOptions = useCallback(
        getSearchAutocompleteDebounced({
            level: location.level,
            nonOperational,
            nonFootball,
        }),
        [location.level, nonOperational, nonFootball],
    )

    return (
        <div className={styles('input-select', className)}>
            <AsyncCreatableSelect
                ref={selectRef}
                aria-labelledby="browse-sites-aria-label"
                placeholder="Find sites near a postcode, place or region"
                autoFocus
                value={valueHasLabel ? value : null} // Only pass through value if there is a label, this means placeholder displays.
                isMulti={isMulti}
                inputValue={isMulti ? undefined : inputValue}
                // clearing the AsyncSelect will reset the whole location -> don't allow clearing it while we're only changing inputValue:
                isClearable={Boolean(location.value)}
                backspaceRemovesValue={isMulti} // disabling this prevents onChange firing when pressing backspace without any inputValue
                cacheOptions={false} // disabled, doesn't work properly
                allowCreateWhileLoading
                createOptionPosition="first"
                getNewOptionData={(inputValue) => ({
                    label: 'Find on map',
                    options: [
                        {
                            type: 'query',
                            label: inputValue,
                            value: inputValue,
                        },
                    ],
                })}
                isValidNewOption={() => !isMulti}
                loadOptions={loadOptions}
                components={{
                    DropdownIndicator: null,
                    ValueContainer,
                    Input,
                    SingleValue,
                    Menu,
                    Placeholder,
                    IndicatorSeparator: null,
                }}
                styles={selectStyles}
                onChange={(option, action) => {
                    preventInputOnBlur.current = true

                    if (isMulti && Array.isArray(option)) {
                        changeLocation(
                            // remove extra props not needed here
                            option.map((o) => ({
                                value: o.value,
                                label: o.label,
                                level: o.level,
                                lat: o.lat,
                                lng: o.lng,
                            })),
                        )
                    } else {
                        changeLocation(option)

                        if (action.action === 'clear') {
                            preventInputOnClear.current = true
                            setInputValue('')
                        } else {
                            setInputValue(option ? option.label : '')
                        }
                    }
                }}
                onBlur={() => {
                    // Updates the query when the input field loses focus.
                    // This is needed in order to trigger a change
                    // when pressing "done" on an ios on-screen keyboard,
                    // which otherwise would ignore the selection.
                    //
                    // This blur event is also triggered after selecting an option
                    // (because the dropdown closes).
                    // But we don't want to do anything here in that scenario.
                    if (preventInputOnBlur.current) {
                        preventInputOnBlur.current = false
                    } else {
                        if (!selectRef.current?.select) {
                            // https://sentry.io/organizations/torchbox/issues/1575446505/
                            const msg = 'selectRef.current.select is null'
                            console.warn(msg) // eslint-disable-line no-console
                            // Capturing an exception might be unnecessary
                            if (window.Sentry) {
                                Sentry.captureException(new Error(msg))
                            }
                            return
                        }
                        const { select } = selectRef.current.select
                        const { inputValue } = select.props

                        if (inputValue) {
                            changeLocation({
                                type: 'query',
                                label: inputValue,
                                value: inputValue,
                            })
                        }
                    }
                }}
                onFocus={() => {
                    if (!isMulti) {
                        // Prevents onFocus from updating the inputValue when
                        // the clear button is clicked.
                        if (preventInputOnClear.current) {
                            preventInputOnClear.current = false
                        } else {
                            setInputValue(inputValue)

                            // This forces react-select to load new options on
                            // focus based on inputValue.
                            if (selectRef.current) {
                                selectRef.current.select.select.select.handleInputChange(
                                    {
                                        currentTarget: {
                                            value: inputValue || '',
                                        },
                                    },
                                )
                            }
                        }
                    }
                }}
                onInputChange={(newValue, { action }) => {
                    // action is one of: 'set-value', 'input-change', 'input-blur', 'menu-close'
                    if (!isMulti) {
                        if (action === 'input-change') {
                            setInputValue(newValue)
                        }
                    }
                }}
                {...rest}
            />
        </div>
    )
}

SiteSearchSelect.propTypes = {
    location: PropTypes.oneOfType([
        PropTypes.shape({}), // TODO
    ]),
    changeLocation: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    className: PropTypes.string,
}

export default SiteSearchSelect
