import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import classnames from 'classnames/bind'

import { UiContext } from '../../context/UiContext'

import { formatCurrency } from '../../utils/utils'
import { SITE_SUMMARY_LABELS } from '../../consts/consts'
import Heading from '../Heading/Heading'
import Loading from '../Loading/Loading'

import css from './Summary.module.scss'

const styles = classnames.bind(css)

const labelStyles = styles('summary__box-label')
const valueStyles = styles('summary__box-value')
const valueStylesCurrency = styles('summary__box-value', 'currency')

const Funding = ({ title, grants }) => (
    <div className={styles('summary__box')}>
        <h3 className={styles('summary__heading')}>{title}</h3>
        <p>
            <span className={labelStyles}>Pre-Pipeline:</span>
            <span className={valueStyles}>
                {parseInt(grants.grantsNumPrePipeline, 10).toLocaleString()}
            </span>
            <span className={labelStyles}>Project Value:</span>
            <span className={valueStylesCurrency}>
                {formatCurrency(grants.grantsProjectValPrePipeline)}
            </span>
        </p>
        <p>
            <span className={labelStyles}>Application Development:</span>
            <span className={valueStyles}>
                {parseInt(grants.grantsNumApplicationDev, 10).toLocaleString()}
            </span>
            <span className={labelStyles}>Grant Value:</span>
            <span className={valueStylesCurrency}>
                {formatCurrency(grants.grantsApplicationDev)}
            </span>
            <span className={labelStyles}>Project Value:</span>
            <span className={valueStylesCurrency}>
                {formatCurrency(grants.grantsProjectValApplicationDev)}
            </span>
        </p>
        <p>
            <span className={labelStyles}>In Assessment:</span>
            <span className={valueStyles}>
                {parseInt(grants.grantsNumPending, 10).toLocaleString()}
            </span>
            <span className={labelStyles}>Grant Value:</span>
            <span className={valueStylesCurrency}>
                {formatCurrency(grants.grantsPending)}
            </span>
            <span className={labelStyles}>Project Value:</span>
            <span className={valueStylesCurrency}>
                {formatCurrency(grants.grantsProjectValPending)}
            </span>
        </p>
        <p>
            <span className={labelStyles}>Awarded:</span>
            <span className={valueStyles}>
                {parseInt(grants.grantsNumAwarded, 10).toLocaleString()}
            </span>
            <span className={labelStyles}>Grant Value:</span>
            <span className={valueStylesCurrency}>
                {formatCurrency(grants.grantsAwarded)}
            </span>
            <span className={labelStyles}>Project Value:</span>
            <span className={valueStylesCurrency}>
                {formatCurrency(grants.grantsProjectValAwarded)}
            </span>
        </p>
    </div>
)

const Summary = ({ className }) => {
    const { loggedIn } = useContext(UiContext)
    const hasGssCodeOverride = useSelector(({ location }) =>
        Boolean(location?.gssCodeOverride),
    )

    // stats are kept up to date via the summaryMiddleware
    const stats = useSelector(({ sites }) => sites.stats)

    // If e.g. `stats.facilities` doesn't exist that means the detailed summary data is not loaded yet
    if (!stats?.facilities) {
        return <Loading />
    }
    if (!stats?.sites?.numMatching) {
        return <Heading text="No results" />
    }

    // use this to higlight fields that haven't been added in SITE_SUMMARY_LABELS yet
    // (some are not shown on purpose)
    // const hasLabels = (labels, stats) =>
    //     Object.keys(stats).every((e) => Object.keys(labels).includes(e))
    // if (process.env.NODE_ENV === 'development') {
    //     // Check that we have all the labels defined in consts.js
    //     Object.keys(SITE_SUMMARY_LABELS).forEach((key) => {
    //         if (!hasLabels(SITE_SUMMARY_LABELS[key], stats[key])) {
    //             console.warn(`Missing entries in SITE_SUMMARY_LABELS[${key}]`)
    //         }
    //     })
    // }

    return (
        <div>
            <div className={styles('summary', className)}>
                <div className={styles('summary__inner')}>
                    {/* Sites */}
                    <div className={styles('summary__box')}>
                        <h3 className={styles('summary__heading')}>Sites</h3>
                        {Object.keys(stats.sites).map(
                            (key) =>
                                SITE_SUMMARY_LABELS.sites[key] && (
                                    <p key={key}>
                                        <span
                                            className={styles(
                                                'summary__box-label',
                                            )}
                                        >
                                            {SITE_SUMMARY_LABELS.sites[key]}
                                        </span>
                                        <span
                                            className={styles(
                                                'summary__box-value',
                                            )}
                                        >
                                            {parseInt(
                                                stats.sites[key],
                                                10,
                                            ).toLocaleString()}
                                        </span>
                                    </p>
                                ),
                        )}
                    </div>

                    {/* Facilities */}
                    <div className={styles('summary__box')}>
                        <h3 className={styles('summary__heading')}>
                            Facilities
                        </h3>
                        {Object.keys(stats.facilities).map((key) => {
                            return (
                                <p key={key}>
                                    <span
                                        className={styles('summary__box-label')}
                                    >
                                        {SITE_SUMMARY_LABELS.facilities[key]}
                                    </span>
                                    <span
                                        className={styles('summary__box-value')}
                                    >
                                        {parseInt(
                                            stats.facilities[key],
                                            10,
                                        ).toLocaleString()}
                                    </span>
                                </p>
                            )
                        })}
                    </div>

                    {/* FA Teams */}
                    {loggedIn && (
                        <div className={styles('summary__box')}>
                            <h3 className={styles('summary__heading')}>
                                FA Teams
                            </h3>
                            {Object.keys(stats.teams).map((key) => {
                                return (
                                    <p key={key}>
                                        <span
                                            className={styles(
                                                'summary__box-label',
                                            )}
                                        >
                                            {SITE_SUMMARY_LABELS.teams[key]}
                                        </span>
                                        <span
                                            className={styles(
                                                'summary__box-value',
                                            )}
                                        >
                                            {parseInt(
                                                stats.teams[key],
                                                10,
                                            ).toLocaleString()}
                                        </span>
                                    </p>
                                )
                            })}
                        </div>
                    )}

                    {/* Funding */}
                    {(loggedIn || hasGssCodeOverride) && (
                        <>
                            <Funding
                                grants={stats.grants}
                                title="FF Funding - All"
                            />
                            <Funding
                                grants={stats.grantsLffp}
                                title="FF Funding - via Local Football Facility Plans"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Summary
