import React from 'react'
import PropTypes from 'prop-types'

const ChevronLeft = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest}>
        <path
            d="M6 12l.893 1.017L16.548 24 19 21.965 10.237 12 19 2.035 16.548 0 6.893 10.983 6 12z"
            fill={fill}
        />
    </svg>
)

ChevronLeft.defaultProps = {
    fill: null,
}

ChevronLeft.propTypes = {
    fill: PropTypes.string,
}

export default ChevronLeft
