import React from 'react'
import PropTypes from 'prop-types'

const Copy = ({ fill, ...rest }) => (
    <svg width="19" height="22" viewBox="0 0 19 22" {...rest}>
        <path
            d="M14 0H2C.9 0 0 .9 0 2v14h2V2h12V0zm-1 4l6 6v10c0 1.1-.9 2-2 2H5.99C4.89 22 4 21.1 4 20l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L12 5.5V11z"
            fill={fill}
        />
    </svg>
)

Copy.defaultProps = {
    fill: null,
}

Copy.propTypes = {
    fill: PropTypes.string,
}

export default Copy
