import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getGeoJsonForFeature } from '../../services/Geo/Geo'
import { DATA_LAYER_Z_INDEX } from '../../consts/consts'
import { average } from '../../utils/utils'
import {
    forceUpdateLocation,
    setLocationLoading,
} from '../../store/Location/Location'

const zIndex = DATA_LAYER_Z_INDEX.indexOf('area')

export const useAreas = ({ hasMap, mapObj, autoZoom, gssCodeOverride }) => {
    const dispatch = useDispatch()

    // Get the all the areas and convert them to string, which can be used as a single dependency
    const areaInRegionTab = useSelector(
        ({ location }) => location.area && location.area.value,
    )
    const locationValue = useSelector(({ location }) =>
        location.type === 'area' ? location.value : null,
    )

    const locationType = useSelector(({ location }) => location.type)

    const distinctAreas = new Set()
    if (areaInRegionTab) {
        distinctAreas.add(areaInRegionTab)
    }
    if (Array.isArray(locationValue)) {
        locationValue.forEach(({ value }) => {
            distinctAreas.add(value)
        })
    } else if (locationValue) {
        distinctAreas.add(locationValue)
    }

    // Ignore all other areas if there's a gssCodeOverride for a saved map
    const areasString =
        gssCodeOverride ||
        Array.from(distinctAreas)
            .sort()
            .join(',')

    useEffect(() => {
        if (!hasMap) {
            return undefined
        }

        const dataLayer = new google.maps.Data()
        dataLayer.setStyle({
            fillOpacity: 0,
            clickable: false,
            zIndex,
        })
        dataLayer.setMap(mapObj.current)

        if (areasString) {
            Promise.all(
                areasString.split(',').map((area) =>
                    getGeoJsonForFeature(area)
                        .then((json) => {
                            dataLayer.addGeoJson(json)
                            autoZoom({ areas: dataLayer })
                            if (gssCodeOverride || locationType === 'area') {
                                // When we have a gss code override then we don't have the area name,
                                // so we'll update it from the boundary geojson instead.
                                // We also set lat/lng because all features have that pre-calculated.
                                const { properties } = json.features[0]
                                const [
                                    lng,
                                    lat,
                                ] = properties.centroid_4326.coordinates
                                return {
                                    lat,
                                    lng,
                                    label: properties.name,
                                    value: properties.code,
                                }
                            }
                            return undefined
                        })
                        .catch((e) => {
                            if (!e.cancelled) {
                                throw e
                            }
                        }),
                ),
            ).then((latLngLabelList) => {
                const filteredList = latLngLabelList.filter(Boolean)
                if (filteredList.length) {
                    const lat = average(filteredList.map(({ lat }) => lat))
                    const lng = average(filteredList.map(({ lng }) => lng))
                    const label = filteredList
                        .map(({ label }) => label)
                        .join(', ')
                    dispatch(
                        forceUpdateLocation({
                            lat,
                            lng,
                            label,
                            value: filteredList,
                        }),
                    )
                }
                dispatch(setLocationLoading(false))
            })
            // TODO: add error handling
        }

        return () => {
            // hopefully all related data is GC'd by this without having to remove any features explicitly
            dataLayer.setMap(null)
            autoZoom({ areas: null })
        }
    }, [
        hasMap,
        mapObj,
        dispatch,
        gssCodeOverride,
        autoZoom,
        areasString,
        locationType,
    ])
}
