export const queryStringToObject = (queryString) => {
    if (!queryString) {
        return null
    }

    return Object.fromEntries(new URLSearchParams(queryString).entries())
}

export const objectToQueryString = (object) => {
    const queryString = new URLSearchParams(object)
    return `?${queryString.toString()}`
}
