import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import ExternalLink from '../ExternalLink/ExternalLink'
import css from './Detail.module.scss'

const styles = classnames.bind(css)

const Detail = ({ label, value, className }) => {
    let formattedValue = value
    if (value === true) {
        formattedValue = 'Yes'
    } else if (value === false) {
        formattedValue = 'No'
    } else if (value && value !== 'N/A' && typeof value !== 'object') {
        formattedValue = String(value)
        if (formattedValue.includes('@')) {
            formattedValue = <a href={`mailto:${value}`}>{value}</a>
        } else if (/^http|www\./.test(formattedValue)) {
            const url = formattedValue.startsWith('www.')
                ? `http://${value}`
                : value
            formattedValue = <ExternalLink url={url} />
        } else if (label.toLowerCase().includes('phone')) {
            formattedValue = <a href={`tel:${value}`}>{value}</a>
        }
    }
    return (
        <div className={styles('detail', className)}>
            <span className={styles('detail__label')}>{label}</span>
            <span className={styles('detail__value')}>{formattedValue}</span>
        </div>
    )
}
Detail.defaultProps = {
    className: null,
}

Detail.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
}

export default Detail
