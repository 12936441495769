import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames/bind'
import IconFilter from '../Icons/Filter'
import IconCross from '../Icons/Cross'
import { UiContext } from '../../context/UiContext'

import css from './MenuButton.module.scss'

const styles = classnames.bind(css)

const MenuButton = ({ className }) => {
    const { showSidebar, setShowSidebar } = useContext(UiContext)

    return (
        <button
            type="button"
            title={showSidebar ? 'Close Sidebar' : 'Open Sidebar'}
            className={styles('menu-button', className)}
            onClick={() => {
                setShowSidebar(!showSidebar)
            }}
        >
            <span className="visually-hidden" role="status">
                {showSidebar ? 'Sidebar is open' : 'Sidebar is closed'}
            </span>
            {showSidebar ? (
                <IconCross className={styles('menu-button__cross')} />
            ) : (
                <IconFilter />
            )}
            <span>FILTER</span>
        </button>
    )
}

MenuButton.propTypes = {
    className: PropTypes.string,
}

export default MenuButton
