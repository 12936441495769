import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import classnames from 'classnames/bind'
import css from './FilterValuesSelect.module.scss'
import { defaultStyles } from '../InputSelect/reactSelectStyles'
import {
    ClearIndicator,
    NoOptionsMessage,
    Placeholder,
} from '../InputSelect/Components'

const styles = classnames.bind(css)

const FilterValuesSelect = ({
    value,
    isMulti,
    operator,
    options,
    handleChange,
    className,
    configId,
    ...rest
}) => {
    // TODO: fix operator=between: https://projects.torchbox.com/projects/ff-pitchfinder/tickets/524

    if (!options || !options.length) {
        throw new Error('FilterValuesSelect: missing options')
    }

    useEffect(() => {
        // When switching back from multi to single select then we need to
        // drop any additional values and keep only the first one.
        if (!isMulti && Array.isArray(value)) {
            handleChange(value[0])
        }
        // When switching from single to multi select then we need to ensure this is a list.
        if (isMulti && !Array.isArray(value)) {
            handleChange([value])
        }
    }, [isMulti, value, handleChange])

    return (
        <div className={styles('input-select', className)}>
            <Select
                isMulti={isMulti}
                value={
                    operator === 'between' && value?.length > 2
                        ? value.slice(0, 2)
                        : value
                }
                isClearable
                options={options}
                defaultOptions
                cacheOptions={false} // disabled, doesn't work properly
                components={{
                    ClearIndicator,
                    NoOptionsMessage,
                    Placeholder,
                }}
                styles={defaultStyles()}
                onChange={(option) => {
                    handleChange(option)
                }}
                {...rest}
            />
        </div>
    )
}

const optionPropType = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
})

FilterValuesSelect.propTypes = {
    value: PropTypes.oneOfType([
        optionPropType,
        PropTypes.arrayOf(optionPropType),
    ]),
    options: PropTypes.arrayOf(optionPropType),
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default FilterValuesSelect
