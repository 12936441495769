import React from 'react'
import classnames from 'classnames/bind'
import css from './SiteInfo.module.scss'
import Boxout from '../Boxout/Boxout'
import Detail from '../Detail/Detail'
import { SiteInfoDetailGrid } from './SiteInfoUtils'

const styles = classnames.bind(css)

const SiteInfoArea = ({ area }) => {
    return (
        <Boxout
            title="ABOUT THE AREA"
            className={styles('site-information__boxout')}
        >
            {/* TODO: add distance information */}
            {/* {site.area.distanceFromSearch && (
                            <div
                                className={styles(
                                    'site-information__highlight',
                                    'site-information__highlight--grid',
                                )}
                            >
                                <IconLabel
                                    label="Disabled access"
                                    icon={<AccessibleIcon />}
                                    className={styles(
                                        'site-information__highlight-icon',
                                    )}
                                />
                            </div>
                        )} */}
            <SiteInfoDetailGrid>
                <Detail
                    label="Local Authority"
                    value={area.localAuthorityName || 'N/A'}
                    className={styles('site-information__detail')}
                />
                <Detail
                    label="Parliamentary Constituency"
                    value={area.parliamentaryConstituencyName || 'N/A'}
                    className={styles('site-information__detail')}
                />
                <Detail
                    label="Ward"
                    value={area.wardName || 'N/A'}
                    className={styles('site-information__detail')}
                />
                <Detail
                    label="FA Region"
                    value={area.fARegion || 'N/A'}
                    className={styles('site-information__detail')}
                />
                <Detail
                    label="FA County"
                    value={area.faCounty || 'N/A'}
                    className={styles('site-information__detail')}
                />
                <Detail
                    label="FF Region"
                    value={area.ffRegion || 'N/A'}
                    className={styles('site-information__detail')}
                />
                <Detail
                    label="MSOA"
                    value={area.msoaName || 'N/A'}
                    className={styles('site-information__detail')}
                />
                <Detail
                    label="LSOA"
                    value={area.lsoaName || 'N/A'}
                    className={styles('site-information__detail')}
                />
            </SiteInfoDetailGrid>
        </Boxout>
    )
}

export default SiteInfoArea
