import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import { UiContext } from '../../context/UiContext'
import Tabs from '../Tabs/Tabs'
import { formatCamelCaseToSentenceCase } from '../../utils/utils'
import Detail from '../Detail/Detail'

import css from './SiteInfoFacilities.module.scss'

const styles = classnames.bind(css)

export const SiteInfoFacilitiesDetailGrid = ({ children }) => (
    <div className={styles('site-information-facilities__detail-grid')}>
        {children}
    </div>
)

const getFacilityTabName = ({ name, obj, length, index }) => {
    if (length > 1) {
        const letter = (index + 10).toString(36).toUpperCase()
        return `${name} (${obj.facilitySubTypeName}) [${letter}]`
    }
    return `${name} (${obj.facilitySubTypeName})`
}

const detailProps = {
    className: styles('site-information-facilities__detail'),
}

const getFacilityTabPanel = ({ name, obj, loggedIn }) => (
    <div tabkey={obj.facilityId}>
        <h4 className={styles('site-information-facilities__heading')}>
            OVERVIEW
        </h4>
        <SiteInfoFacilitiesDetailGrid>
            <Detail
                label="Facility Type"
                value={name || 'N/A'}
                {...detailProps}
            />
            <Detail
                label="Facility Sub Type"
                value={obj.facilitySubTypeName || 'N/A'}
                {...detailProps}
            />
            <Detail
                label="Facility Size"
                value={obj.displaySize || 'N/A'}
                {...detailProps}
            />
            <Detail
                label="Facility Surface"
                value={obj.surfaceType || 'N/A'}
                {...detailProps}
            />
        </SiteInfoFacilitiesDetailGrid>

        <h4 className={styles('site-information-facilities__heading')}>
            ATTRIBUTES
        </h4>
        <SiteInfoFacilitiesDetailGrid>
            <Detail
                label="Floodlit"
                value={obj.floodlit ? 'Yes' : 'No'}
                {...detailProps}
            />
            <Detail
                label="Indoor"
                value={obj.indoor ? 'Yes' : 'No'}
                {...detailProps}
            />
            <Detail
                label="Pitches"
                value={obj.pitches || 'N/A'}
                {...detailProps}
            />
            {loggedIn && (
                <>
                    <Detail
                        label="Width"
                        value={obj.width || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Length"
                        value={obj.length || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Pile Length"
                        value={obj.pileLength || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Overmarked"
                        value={obj.Overmarked || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Is Football Facility"
                        value={obj.isFootballFacility ? 'Yes' : 'No'}
                        {...detailProps}
                    />
                </>
            )}
            {/* Temporary fix so SiteInfoFacilitiesDetailGrid reads even amount of children */}
            <></>
            <></>
        </SiteInfoFacilitiesDetailGrid>

        {loggedIn && (
            <>
                <h4 className={styles('site-information-facilities__heading')}>
                    Opening and Refurbishment Info
                </h4>
                <SiteInfoFacilitiesDetailGrid>
                    <Detail
                        label="Year Built"
                        value={obj.yearBuilt || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Opening Date"
                        value={obj.openingDate || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Refurbished"
                        value={obj.isRefurbished ? 'Yes' : 'No'}
                        {...detailProps}
                    />
                    <Detail
                        label="Year Refurbished"
                        value={obj.yearRefurbished || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Facility Status"
                        value={obj.facilityStatus || 'N/A'}
                        {...detailProps}
                    />
                    <Detail
                        label="Facility Status Notes"
                        value={obj.facilityStatusNotes || 'N/A'}
                        {...detailProps}
                    />
                </SiteInfoFacilitiesDetailGrid>
            </>
        )}

        <h4 className={styles('site-information-facilities__heading')}>
            ACCESSIBILITY
        </h4>
        <SiteInfoFacilitiesDetailGrid>
            <Detail
                label="Accessibility"
                value={obj.accessibility || 'N/A'}
                {...detailProps}
            />
            <Detail
                label="Accessibility Group"
                value={obj.accessibilityGroup || 'N/A'}
                {...detailProps}
            />
        </SiteInfoFacilitiesDetailGrid>
    </div>
)

const SiteInfoFacilities = ({ facilityValues }) => {
    const { loggedIn } = useContext(UiContext)
    const tabs = Object.entries(facilityValues)
        .map(([facilityType, { facilities }]) => {
            const name = formatCamelCaseToSentenceCase(facilityType)
            return facilities.map((obj, index, { length }) => ({
                name: getFacilityTabName({ name, obj, length, index }),
                panel: getFacilityTabPanel({ name, obj, loggedIn }),
            }))
        })
        .flat()

    const [tabIndex, setTabIndex] = useState(0)

    return tabs.length ? (
        <div className={styles('site-information-facilities__tabs-container')}>
            <Tabs
                tabList={tabs.map((tab) => tab.name)}
                tabPanels={tabs.map((tab) => tab.panel)}
                controlledTabIndex={tabIndex}
                updateControlledTabIndex={setTabIndex}
                stacked
            />
        </div>
    ) : (
        <div className={styles('site-information-facilities__empty')}>N/A</div>
    )
}

SiteInfoFacilities.defaultProps = {
    facilityValues: {},
}

SiteInfoFacilities.propTypes = {
    facilityValues: PropTypes.object,
}

export default SiteInfoFacilities
