import React from 'react'
import PropTypes from 'prop-types'

const LocationThin = ({ fill, ...rest }) => (
    <svg
        width="60"
        height="60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M30.7576 5v6.0606h-1.5152V5h1.5152zM30.7576 48.9394V55h-1.5152v-6.0606h1.5152zM55 29.2424v1.5152h-6.0606v-1.5152H55zM11.0606 29.2424v1.5152H5v-1.5152h6.0606z"
            fill={fill}
        />
        <path
            d="M30 48.1818c10.0416 0 18.1818-8.1403 18.1818-18.1818S40.0416 11.8182 30 11.8182c-10.0415 0-18.1818 8.1403-18.1818 18.1818S19.9585 48.1818 30 48.1818z"
            stroke={fill}
            strokeWidth="2"
        />
        <path
            d="M30 37.5758c4.184 0 7.5758-3.3918 7.5758-7.5758S34.184 22.4243 30 22.4243 22.4242 25.816 22.4242 30 25.816 37.5758 30 37.5758z"
            stroke={fill}
            strokeWidth="2"
        />
    </svg>
)

LocationThin.defaultProps = {
    fill: '#009A3A',
}

LocationThin.propTypes = {
    fill: PropTypes.string,
}

export default LocationThin
