import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: null,
    show: false,
    level: null,
}

const boundariesSlice = createSlice({
    name: 'boundaries',
    initialState,
    reducers: {
        resetBoundaries: () => initialState,
        replaceBoundaries: (state, { payload }) => ({
            ...payload,
            loading: payload && payload.level && payload.show,
        }),
        setBoundariesShow: (state, { payload }) => {
            state.show = payload
        },
        setBoundariesLevel: (state, { payload }) => {
            state.level = payload
        },
        setBoundariesLoading: (state, { payload }) => {
            state.loading = payload
        },
    },
})

const { actions, reducer } = boundariesSlice

export const {
    resetBoundaries,
    replaceBoundaries,
    setBoundariesShow,
    setBoundariesLevel,
    setBoundariesLoading,
} = actions

export default reducer
