import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames/bind'
import { replaceOverlay, resetOverlay } from '../../store/Overlay/Overlay'
import { usePromiseGlobal } from '../../hooks/hooks'
import { getConfig } from '../../services/Config/Config'
import { transformedConfig } from '../../utils/overlay'
import Loading from '../Loading/Loading'
import InputSelect from '../InputSelect/InputSelect'
import InputRange from '../InputRange/InputRange'
import Button from '../Button/Button'
import DeleteButton from '../DeleteButton/DeleteButton'
import EditButton from '../EditButton/EditButton'
import css from './OverlayGroup.module.scss'
import { ACTIVE_MODAL_OVERLAY_SELECTOR, LABELS } from '../../consts/consts'
import { UiContext } from '../../context/UiContext'

const styles = classnames.bind(css)

export const getGroupConfig = (configObjArray, optgroup) =>
    configObjArray.find((configObject) => configObject.value === optgroup)

export const getOverlayLevels = (optgroupConfig) => {
    // Create an array of options for each type
    // to populate the first dropdown.
    // Return only the label and value so we don't get
    // groups in the dropdown
    return optgroupConfig.options.map((o) => ({
        label: o.label,
        value: o.level,
    }))
}

export const getOverlayLevel = (optgroupConfig, level) => {
    // Find the current type object
    return optgroupConfig.options.find(
        (configObject) => configObject.level === level,
    )
}

const Group = ({ config, overlay, className, disabled }) => {
    const dispatch = useDispatch()

    const { setActiveModal } = useContext(UiContext)

    const optgroupConfig = getGroupConfig(config, overlay.optgroup)

    const secondaryType = optgroupConfig.options[0].type // 'range' or 'select'

    // Get options for first dropdown
    const options = getOverlayLevels(optgroupConfig)

    // Get config for the selected level
    const optionsConfig = getOverlayLevel(optgroupConfig, overlay.level)

    // secondaryValue is currently either {from, to} or {value, label} depending on secondaryType
    const [secondaryValue, setSecondaryValue] = useState(null)

    // Update secondaryValue based on props
    useEffect(() => {
        if (!overlay.value) {
            return
        }
        if (secondaryType === 'select') {
            // {value, label}
            setSecondaryValue(
                optionsConfig.options.find(
                    ({ value }) => value === overlay.value,
                ),
            )
        }
        if (secondaryType === 'range') {
            // {from, to}
            setSecondaryValue(overlay.value)
        }
    }, [overlay, secondaryType, optionsConfig.options])

    return (
        <div className={styles('overlay-group', className)}>
            <span className={styles('overlay-group__label')}>
                {LABELS.overlay[overlay.optgroup]}
            </span>

            {null && // TODO: re-enable and fix issues when switching between overlays
                !disabled && (
                    <EditButton
                        title="Change the overlay"
                        className={styles('overlay-group__edit')}
                        handleClick={() => {
                            setActiveModal(ACTIVE_MODAL_OVERLAY_SELECTOR)
                        }}
                    />
                )}

            <InputSelect
                className={styles('overlay-group__input')}
                value={{
                    value: overlay.level,
                    label: LABELS.level[overlay.level],
                }}
                options={options}
                isDisabled={disabled}
                handleChange={({ value: level }) => {
                    let { value } = secondaryValue
                    const { label } = secondaryValue

                    // Value shape is different if type is 'range'
                    if (secondaryType === 'range') {
                        value = secondaryValue // {from, to}
                        // there's no label either
                    }

                    dispatch(
                        replaceOverlay({
                            level,
                            label,
                            value,
                            optgroup: overlay.optgroup,
                            year: overlay.year,
                        }),
                    )
                }}
                slim
            />

            {secondaryType === 'select' && (
                <InputSelect
                    className={styles('overlay-group__input')}
                    value={secondaryValue}
                    options={optionsConfig && optionsConfig.options}
                    isDisabled={disabled}
                    handleChange={(value) => {
                        dispatch(
                            replaceOverlay({
                                level: overlay.level,
                                label: value.label,
                                value: value.value,
                                optgroup: overlay.optgroup,
                                year: overlay.year,
                            }),
                        )
                    }}
                    slim
                />
            )}

            {secondaryType === 'range' && (
                <>
                    <InputRange
                        className={styles('overlay-group__input')}
                        label="Range"
                        measurement="Age"
                        from={secondaryValue?.from ?? ''}
                        fromDefault="0"
                        handleChangeFrom={(value) => {
                            setSecondaryValue({
                                ...secondaryValue,
                                from: value,
                            })
                        }}
                        to={secondaryValue?.to ?? ''}
                        toDefault="90"
                        disabled={disabled}
                        handleChangeTo={(value) => {
                            setSecondaryValue({ ...secondaryValue, to: value })
                        }}
                    />
                    <Button
                        className={styles('overlay-group__input')}
                        text="Apply range"
                        isRounded
                        disabled={disabled}
                        handleClick={() => {
                            const { from, to } = secondaryValue
                            // Stop user exceeding range bounds
                            const rangeValue = {
                                ...secondaryValue,
                                // 0 <= FROM <= 90
                                from:
                                    from >= 0 && from <= 90
                                        ? from === ''
                                            ? 0
                                            : parseInt(from, 10)
                                        : 0,
                                // 0 <= TO <= 90
                                to:
                                    to >= 0 && to <= 90
                                        ? to === ''
                                            ? 90
                                            : parseInt(to, 10)
                                        : 90,
                            }

                            dispatch(
                                replaceOverlay({
                                    level: overlay.level,
                                    value: rangeValue,
                                    optgroup: overlay.optgroup,
                                    year: overlay.year,
                                }),
                            )
                        }}
                    />
                </>
            )}
        </div>
    )
}

const OverlayGroup = ({ overlay, className, disabled }) => {
    // load overlay config
    const [config, loading] = usePromiseGlobal(getConfig)
    const overlayConfig = config && transformedConfig(config.overlays)
    const dispatch = useDispatch()

    if (loading) {
        return <Loading />
    }

    return (
        <>
            {/* Only render when we have an overlay from Redux */}
            {config && overlay && overlay.level && (
                <>
                    <Group
                        overlay={overlay}
                        config={overlayConfig}
                        className={className}
                        disabled={disabled}
                    />
                    {!disabled && (
                        <DeleteButton
                            text="Remove overlay"
                            handleClick={() => {
                                dispatch(resetOverlay())
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default OverlayGroup
