import { latLng2PointRelative } from './components/Map/utils'

import { queryStringToObject } from './utils/queryString'

const queryObject = queryStringToObject(window.location.search)

window.screenshot =
    queryObject && (queryObject.screenshot || queryObject.snapshot)

// this listener is for testing only (png cannot be downloaded like this on AWS lambda)
window.addEventListener('captureready', async () => {
    const { default: html2canvas } = await import('html2canvas')
    const { saveAs } = await import('file-saver')

    // the following options have been copied from the lambda function source code
    const { left, top, width, height } = window.get_bounds_for_snapshot()
    const padding = 15
    const clip = {
        x: left - padding,
        y: top - padding,
        width: width + padding * 2,
        height: height + padding * 2,
        scale: 1,
    }

    const canvas = await html2canvas(document.body, {
        // https://github.com/niklasvh/html2canvas/issues/1519
        useCORS: true,
        allowTaint: false,
        ignoreElements: (node) => node.nodeName === 'IFRAME',
        ...clip,
    })
    canvas.toBlob((blob) => saveAs(blob, 'screenshot.png'))
})

const getBoundsForMapFeatures = () => {
    // Returns the bounding rectangle relative to the map edges.

    const { bounds } = window // window.bounds is set in Map.js
    const ne = latLng2PointRelative(bounds.getNorthEast())
    const sw = latLng2PointRelative(bounds.getSouthWest())
    return {
        top_left: { x: sw.x, y: ne.y },
        top_right: { x: ne.x, y: ne.y }, // ne
        bottom_right: { x: ne.x, y: sw.y },
        bottom_left: { x: sw.x, y: sw.y }, // sw
        top: ne.y,
        left: sw.x,
        bottom: sw.y,
        right: ne.x,
        y: ne.y,
        x: sw.x,
        width: ne.x - sw.x,
        height: sw.y - ne.y,
    }
}

let mapKeyZoom = 1.0

const getBoundsWithAspectRatio = () => {
    const boundsWithinMap = getBoundsForMapFeatures()
    const mapBounds = window.map.getDiv().getBoundingClientRect()
    const aspectRatio = 1.8

    let {
        width: mapKeyWidth,
        height: mapKeyHeight, // eslint-disable-line prefer-const
    } = window.mapKey.getBoundingClientRect()
    let { height } = boundsWithinMap
    let width = boundsWithinMap.height * aspectRatio

    // make the map key larger depending on how small it is compared to the viewport
    mapKeyZoom = 0.25 * (width / mapKeyWidth)
    // but don't let the map key become so tall that the top is cut off
    if (mapKeyHeight * mapKeyZoom > height) {
        mapKeyZoom = height / mapKeyHeight
    }
    mapKeyWidth *= mapKeyZoom
    mapKeyWidth += 10 // leave some space between the boudaries and the map key

    // Shift the viewport so that the boundary is between the left edge and the map key,
    // or completely to the left if it's too wide. If it still overlaps with the map key
    // then increase the width (and height to keep aspect ratio).
    // Keep the boundary in the middle if there's no map key.
    let leftAdjust = (width - boundsWithinMap.width) / 2
    if (window.mapKey.innerText.trim()) {
        if (width - boundsWithinMap.width < mapKeyWidth) {
            leftAdjust = 0
            width = boundsWithinMap.width + mapKeyWidth
            height = width / aspectRatio
        } else if (width - (leftAdjust + boundsWithinMap.width) < mapKeyWidth) {
            leftAdjust = (width - (boundsWithinMap.width + mapKeyWidth)) / 2
        }
    }
    return {
        left: mapBounds.left + boundsWithinMap.left - leftAdjust,
        top: mapBounds.top + boundsWithinMap.top,
        width,
        height,
    }
}

// This function is called from the AWS lambda function, see the LFFP project.
// It might be called more than once, but it shouldn't be, so we just memoise it.
let boundsCached
window.get_bounds_for_snapshot = () => {
    if (boundsCached) {
        return boundsCached
    }
    const bounds = getBoundsWithAspectRatio()

    // align the MapKey with the bottom right edge of the screenshot bounds
    const { mapKey } = window
    mapKey.style.position = 'fixed'
    const { width, height } = mapKey.getBoundingClientRect()
    const left = bounds.left + bounds.width - width
    const top = bounds.top + bounds.height - height
    mapKey.style.left = `${left}px`
    mapKey.style.top = `${top}px`
    mapKey.style.bottom = 'initial'
    mapKey.style.right = 'initial'
    mapKey.style.transformOrigin = 'bottom right'
    mapKey.style.transform = `scale(${mapKeyZoom})`

    boundsCached = bounds
    return bounds
}
