import React from 'react'

import classnames from 'classnames/bind'
import Loading from '../Loading/Loading'
import { Step } from '../MultiStep/MultiStep'

import css from './Selector.module.scss'

const styles = classnames.bind(css)

export const ConfigNotLoaded = ({ label, error }) => (
    <div className={styles('selector')}>
        <Step headingText={`Loading ${label}...`} aria-live="polite">
            <div className={styles('selector__row')}>
                {error ? (
                    `Unable to load ${label}. Please try again later.`
                ) : (
                    <Loading />
                )}
            </div>
        </Step>
    </div>
)
