import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import InputCheckbox from '../InputCheckbox/InputCheckbox'
import css from './OpenGreenSpacesSelector.module.scss'
import { replaceOgs } from '../../store/OpenGreenSpaces/OpenGreenSpaces'

const styles = classnames.bind(css)

const OpenGreenSpacesSelector = ({ className }) => {
    const dispatch = useDispatch()
    const enabled = useSelector(({ ogs }) => ogs?.show || false)

    return (
        <div className={styles('checkbox-select', className)}>
            <InputCheckbox
                id="ogs"
                name="ogs"
                label="Open Green Spaces"
                checked={enabled}
                className={styles('checkbox-select__checkbox')}
                handleChange={(e) => {
                    dispatch(replaceOgs({ show: e.target.checked }))
                }}
                isTick
            />
        </div>
    )
}

OpenGreenSpacesSelector.defaultProps = {
    className: null,
}

OpenGreenSpacesSelector.propTypes = {
    className: PropTypes.string,
}

export default OpenGreenSpacesSelector
