import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { Link } from '@reach/router'
import copyToClipboard from 'copy-to-clipboard'

import { useAlert } from '../../hooks/hooks'
import { destroySavedMap } from '../../store/SavedMap/SavedMap'
import FacilityCount from '../FacilityCount/FacilityCount'
import CrossIcon from '../Icons/Cross'
import ShareIcon from '../Icons/Share'
import { urls } from '../../urls'
import { colors } from '../../consts/styleVariables'
import { LABELS } from '../../consts/consts'

import css from './SavedMapCard.module.scss'

const styles = classnames.bind(css)

const SavedMapCard = ({ slug, name, location, filters, rules, className }) => {
    const dispatch = useDispatch()
    const alert = useAlert()

    return (
        <Link
            className={styles('search-card', className)}
            onClick={(e) => {
                // Don't navigate when clicking on share/delete buttons
                if (e.target.closest(`.${styles('search-card__buttons')}`)) {
                    e.preventDefault()
                }
            }}
            to={urls.savedMap(slug)}
        >
            <div className={styles('search-card__header')}>
                <h3 className={styles('search-card__name')}>{name}</h3>
                <span className={styles('search-card__location')}>
                    {location?.label || 'England'}
                    {location?.radius && ` - ${location.radius} mile radius`}
                    {location?.travelMode &&
                        location?.travelTime &&
                        ` - Within ${location.travelTime} mins of distance of ${
                            location?.label
                        } if ${LABELS.travelMode[location.travelMode]}`}
                </span>
            </div>
            <div className={styles('search-card__buttons')}>
                {/* share */}
                <button
                    type="button"
                    className={styles(
                        'search-card__button',
                        'search-card__button--share',
                    )}
                    onClick={() => {
                        const url = window.location.origin + urls.savedMap(slug)
                        copyToClipboard(url)
                        alert.current.info(
                            <>
                                Saved map url has been copied to the clipboard:
                                <br />
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {url}
                                </a>
                            </>,
                        )
                    }}
                >
                    <ShareIcon fill={colors.greenTwo} />
                </button>
                {/* delete */}
                <button
                    type="button"
                    className={styles('search-card__button')}
                    onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (window.confirm('Delete saved map?')) {
                            dispatch(destroySavedMap(slug, alert))
                        }
                    }}
                >
                    <CrossIcon fill={colors.red} />
                </button>
            </div>
            <div className={styles('search-card__details')}>
                {filters && filters.length && (
                    <div className={styles('search-card__facilites')}>
                        {filters.map(({ number, name }) => (
                            <FacilityCount
                                key={name}
                                number={number}
                                name={name}
                                className={styles(
                                    'search-card__facility-count',
                                )}
                            />
                        ))}
                    </div>
                )}
                {rules && (
                    <p className={styles('search-card__stats')}>{rules}</p>
                )}
            </div>
        </Link>
    )
}

SavedMapCard.propTypes = {
    name: PropTypes.string.isRequired,
    location: PropTypes.PropTypes.shape({}),
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            number: PropTypes.number,
            name: PropTypes.string,
        }),
    ),
}

export default SavedMapCard
