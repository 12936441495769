import React from 'react'
import { useSelector } from 'react-redux'
import { useScript } from '../../hooks/hooks'

import Map from '../Map/Map'
import Loading from '../Loading/Loading'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'

const MapLoader = (props) => {
    const [loaded, error] = useScript(
        `https://maps.googleapis.com/maps/api/js?key=${window.REACT_APP_GOOGLE_MAPS_API_KEY ||
            process.env
                .REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=drawing,geometry`,
    )

    const { loaded: asyncStateLoaded } = useSelector(
        ({ asyncInitialState }) => asyncInitialState,
    )

    if (error || (loaded && !window.google)) {
        return (
            // Rendering ErrorBoundary without it catching an exception won't notify sentry
            <ErrorBoundary
                hasError
                fullPage
                message="Couldn't load Google Maps"
            />
        )
    }

    return loaded && asyncStateLoaded ? <Map {...props} /> : <Loading />
}

export default MapLoader
