import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { replaceLocation } from '../../store/Location/Location'
import { LABELS } from '../../consts/consts'

export const usePinpoint = ({
    hasMap,
    mapObj,
    autoZoom,
    pinpointingEpicentre,
    setPinpointingEpicentre,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const map = mapObj.current

        if (pinpointingEpicentre && hasMap) {
            map.setOptions({ draggableCursor: 'crosshair' })

            // Site selector: never remove the crosshair icon
            if (window.siteSelector) {
                return () => {}
            }

            // Pinpoint location (not site selector mode)
            const pinpointEventListner = google.maps.event.addListenerOnce(
                map,
                'click',
                (event) => {
                    dispatch(
                        replaceLocation({
                            type: 'pinpoint',
                            label: LABELS.locationType.pinpoint,
                            value: LABELS.locationType.pinpoint,
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng(),
                        }),
                    )
                    map.setOptions({ draggableCursor: '' })
                    setPinpointingEpicentre(false)
                    autoZoom({ pinpoint: event.latLng })
                },
            )
            return () => {
                google.maps.event.removeListener(pinpointEventListner)
                map.setOptions({ draggableCursor: '' })
                if (!pinpointingEpicentre) {
                    autoZoom({ pinpoint: null })
                }
            }
        }

        return () => {}
    }, [
        hasMap,
        mapObj,
        autoZoom,
        dispatch,
        setPinpointingEpicentre,
        pinpointingEpicentre,
    ])
}
