import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { reactColorSelectStyles } from '../InputSelect/reactSelectStyles'

const optionSelector = (color) => (o) =>
    o?.color?.toLowerCase() === color.toLowerCase() ||
    o?.value?.toLowerCase() === color.toLowerCase()

const ColorValuesSelect = ({ color, options, handleChangeColor, required }) => {
    // If it's a custom color then use that as is and add it to the options.
    let currentOptions = options
    if (color && !options.some(optionSelector(color))) {
        currentOptions = [
            ...options,
            {
                value: color,
                label: color,
                color,
            },
        ]
    }

    const value = color ? currentOptions.find(optionSelector(color)) : null

    return (
        <Select
            isClearable={!required}
            placeholder={required ? 'Random colour' : 'No colour'}
            value={value}
            onChange={handleChangeColor}
            options={currentOptions}
            styles={reactColorSelectStyles()}
        />
    )
}

ColorValuesSelect.propTypes = {
    color: PropTypes.string,
    options: PropTypes.array.isRequired,
    handleChangeColor: PropTypes.func.isRequired,
}

export default ColorValuesSelect
