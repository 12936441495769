import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames/bind'

import SavedMapCard from '../SavedMapCard/SavedMapCard'
import Loading from '../Loading/Loading'
import Heading from '../Heading/Heading'
import { usePromise } from '../../hooks/hooks'
import { getSavedMapsForUser } from '../../services/SavedMaps/SavedMaps'

import css from './SavedMapList.module.scss'

const styles = classnames.bind(css)

const noop = new Promise(() => {})

const getRuleLabels = (filters) => {
    const filterRules = Object.values(filters)
        .filter(({ rules }) => rules.length > 0)
        .map(({ rules }) => rules.map(({ label }) => label))

    // Flatten list and remove duplicates
    const uniqueFiltersSet = new Set([].concat(...filterRules))

    return Array.from(uniqueFiltersSet).join(', ')
}

const getNumRules = (filters) =>
    Object.values(filters).reduce((sum, { rules }) => sum + rules.length, 0)

const SavedMapList = () => {
    // TODO: error handling

    // Fetch list of saved maps when state.savedMap.loading changes to false
    const deletePending = useSelector(({ savedMap: { loading } }) => loading)
    const [savedMaps, loading] = usePromise(
        useCallback(() => (deletePending ? noop : getSavedMapsForUser()), [
            deletePending,
        ]),
    )

    if (loading || deletePending) {
        return <Loading />
    }

    return (
        <div className={styles('saved-searches')}>
            {savedMaps.length > 0 ? (
                savedMaps.map(({ id, slug, data }) => (
                    <SavedMapCard
                        key={id}
                        className={styles('saved-searches__item')}
                        slug={slug}
                        name={slug}
                        location={data.location}
                        filters={[
                            {
                                name: 'Filter Groups',
                                number: Object.keys(data.filters || []).length,
                            },
                            {
                                name: 'Filters',
                                number: getNumRules(data.filters || []),
                            },
                        ]}
                        rules={getRuleLabels(data.filters || [])}
                    />
                ))
            ) : (
                <Heading heading="h2" text="No Results" />
            )}
        </div>
    )
}

export default SavedMapList
