import React, { useReducer, useEffect, Suspense } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import FilterValuesSelect from '../FilterValuesSelect/FilterValuesSelect'
import FilterValuesAsyncSelect from '../FilterValuesAsyncSelect/FilterValuesAsyncSelect'
import FilterValuesAsyncCreatableSelect from '../FilterValuesAsyncCreatableSelect/FilterValuesAsyncCreatableSelect'
import InputSelect from '../InputSelect/InputSelect'
import InputRadio from '../InputRadio/InputRadio'
import Heading from '../Heading/Heading'
import Loading from '../Loading/Loading'

import css from './ComposedInput.module.scss'

const styles = classnames.bind(css)

// Loading DatePicker lazily because it needs moment-js, which is huge
// because we can't easily skip loading locales that we don't need.
const DatePicker = React.lazy(() => import('../DatePicker/DatePicker'))

export const operatorLabels = {
    equal: 'Equals',
    not_equal: 'Does not equal',
    in: 'One of',
    not_in: 'None of',
    less: 'Less than',
    less_or_equal: 'Less than or equal to',
    greater: 'Greater than',
    greater_or_equal: 'Greater than or equal to',
    between: 'Between',
    begins_with: 'Begins with',
    not_begins_with: 'Does not begin with',
    contains: 'Contains',
    not_contains: 'Does not contain',
    ends_with: 'Ends with',
    not_ends_with: 'Does not end with',
    is_empty: 'Is empty',
    is_not_empty: 'Is not empty',
    is_null: 'Is null',
    is_not_null: 'Is not null',
}

const ComposedInput = ({
    activeFilter,
    setUnappliedFilter,
    headingText,
    config: { id, type, input, operators, options },
    config,
    required,
}) => {
    const [localState, updateLocalState] = useReducer((state, payload) => {
        const newState = { ...state, ...payload }
        // some operators are not allowed to have a value
        if (
            ['is_empty', 'is_not_empty', 'is_null', 'is_not_null'].includes(
                newState.operator,
            )
        ) {
            delete newState.value
        }
        if (type === 'integer' && typeof newState.value === 'string') {
            newState.value = newState.value
                .replace(/[^\d-]/g, '')
                .replace(/^-$/, '')
        }
        if (type === 'double' && typeof newState.value === 'string') {
            newState.value = newState.value
                .replace(/[^\d-.]/g, '')
                .replace(/^-$/, '')
        }
        return newState
    }, activeFilter)

    // value : String | Number | Array[{value, label}]
    const { value } = localState

    const operator = localState.operator || (operators && operators[0])
    if (!operator) {
        throw new Error(`Filter operator is undefined: ${config.id}`)
    }

    const setValue = (v) => updateLocalState({ operator, value: v })
    const setOperator = (v) => updateLocalState({ operator: v })

    useEffect(() => {
        setUnappliedFilter(localState)
    }, [setUnappliedFilter, localState])

    const isMulti = ['in', 'not_in', 'between'].includes(operator)

    // prettier-ignore
    const is = {
        AsyncSelect: type === 'fk' && !options,
        Select: (['string', 'integer', 'double', 'yearrange', 'fk'].includes(type) && options),
        AsyncCreatableSelect: ['string', 'integer', 'double'].includes(type) && !options,
        Boolean: type === 'boolean',
        Date: ['datetime', 'date'].includes(type),
    }

    if (Object.values(is).filter(Boolean).length !== 1) {
        throw new Error(`Invalid filter config: ${config.id}`)
    }

    return (
        <div className={styles('composed-input')}>
            {headingText && (
                <div className={styles('composed-input__heading')}>
                    <Heading heading="h4" text={headingText} isSubHeading />
                    {required && (
                        <span
                            className={styles(
                                'composed-input__heading__required',
                            )}
                            aria-hidden="true"
                        >
                            *
                        </span>
                    )}
                </div>
            )}
            <div className={styles('composed-input__fields')}>
                {operators && (
                    <InputSelect
                        className={styles('composed-input__operators')}
                        value={{
                            value: operator,
                            label: operatorLabels[operator],
                        }}
                        options={operators.map((op) => ({
                            value: op,
                            label: operatorLabels[op],
                        }))}
                        handleChange={({ value }) => {
                            setOperator(value)
                        }}
                        aria-labelledby={`${id}_operator`}
                    />
                )}
                <span
                    id={`${id}_operator`}
                    className="visually-hidden"
                    aria-hidden
                >
                    Select a filter operator
                </span>

                {/* There's no filter value selector field for certain operators */}
                {![
                    'is_empty',
                    'is_not_empty',
                    'is_null',
                    'is_not_null',
                ].includes(operator) && (
                    <div className={styles('composed-input__input-wrapper')}>
                        {is.AsyncSelect && (
                            <>
                                <FilterValuesAsyncSelect
                                    // If `options` is null then it will make
                                    // async requests for the autocomplete.
                                    // If `options` is a (non-empty) array then it will
                                    // use only those, without making any requests.
                                    options={options}
                                    type={input}
                                    value={value}
                                    isMulti={isMulti}
                                    operator={operator}
                                    handleChange={setValue}
                                    configId={id}
                                    aria-labelledby={`${id}_value`}
                                />
                                <span
                                    id={`${id}_value`}
                                    className="visually-hidden"
                                >
                                    Enter a value
                                </span>
                            </>
                        )}
                        {is.Select && (
                            <>
                                <FilterValuesSelect
                                    // `options` is always a non-empty array here
                                    options={options}
                                    type={input}
                                    value={value}
                                    isMulti={isMulti}
                                    operator={operator}
                                    handleChange={setValue}
                                    configId={id}
                                    aria-labelledby={`${id}_value`}
                                />
                                <span
                                    id={`${id}_value`}
                                    className="visually-hidden"
                                >
                                    Enter a value
                                </span>
                            </>
                        )}
                        {is.AsyncCreatableSelect && (
                            <>
                                <FilterValuesAsyncCreatableSelect
                                    // Never has any `options`
                                    type={input}
                                    configType={type}
                                    value={value}
                                    isMulti={isMulti}
                                    operator={operator}
                                    handleChange={setValue}
                                    configId={id}
                                    aria-labelledby={`${id}_value`}
                                />
                                <span
                                    id={`${id}_value`}
                                    className="visually-hidden"
                                >
                                    Enter a value
                                </span>
                            </>
                        )}
                        {is.Boolean &&
                            // We're using an alias `key` for destructuring to avoid shadowing the `value` variable:
                            options.map(({ value: key, label }) => (
                                <React.Fragment key={key}>
                                    <InputRadio
                                        label={label}
                                        id={`${id}_${key}`}
                                        name={id}
                                        value={key}
                                        handleChange={({
                                            target: { value },
                                        }) => {
                                            setValue(value)
                                        }}
                                        checked={value === key}
                                        aria-labelledby={`${id}_value`}
                                    />
                                    <span
                                        id={`${id}_value`}
                                        className="visually-hidden"
                                    >
                                        Select a value
                                    </span>
                                </React.Fragment>
                            ))}
                        {is.Date && (
                            <Suspense fallback={<Loading />}>
                                <DatePicker
                                    id={id}
                                    value={value}
                                    operator={operator}
                                    handleChange={(value) => {
                                        setValue(value)
                                    }}
                                />
                            </Suspense>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

ComposedInput.defaultProps = {
    activeFilter: {},
}
const optionPropType = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
})

ComposedInput.propTypes = {
    activeFilter: PropTypes.shape({}),
    setUnappliedFilter: PropTypes.func.isRequired,
    config: PropTypes.shape({
        type: PropTypes.string.isRequired,
        input: PropTypes.string.isRequired,
        operators: PropTypes.arrayOf(PropTypes.string).isRequired,
        options: PropTypes.arrayOf(optionPropType),
    }).isRequired,
}

export default ComposedInput
