import React from 'react'
import PropTypes from 'prop-types'

const Plus = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest}>
        <path d="M13 11h11v2H13v11h-2V13H0v-2h11V0h2v11z" fill={fill} />
    </svg>
)

Plus.defaultProps = {
    fill: null,
}

Plus.propTypes = {
    fill: PropTypes.string,
}

export default Plus
