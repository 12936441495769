import React from 'react'
import BaseIcon from './BaseIcon'

const SuccessIcon = () => (
    <BaseIcon color="#FFFFFF">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
        <polyline points="22 4 12 14.01 9 11.01" />
    </BaseIcon>
)

export default SuccessIcon
