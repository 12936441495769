import React from 'react'
import PropTypes from 'prop-types'

const List = ({ fill, ...rest }) => (
    <svg width="14" height="16" viewBox="0,0,16,16" {...rest}>
        <path
            fill={fill}
            d="M0 10h16V8H0v2zm0 4h16v-2H0v2zm0-8h16V4H0v2zm0-6v2h16V0H0z"
        />
    </svg>
)

List.defaultProps = {
    fill: null,
}

List.propTypes = {
    fill: PropTypes.string,
}

export default List
