import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import { removeFirstOccurrence } from '../../utils/utils'
import { useMedia } from '../../hooks/hooks'
import Tabs from '../Tabs/Tabs'
import InputSelect from '../InputSelect/InputSelect'
import InputText from '../InputText/InputText'
import SiteSearchSelect from '../SiteSearchSelect/SiteSearchSelect'
import SelectRegion from '../SelectRegion/SelectRegion'
import { LABELS } from '../../consts/consts'
import InlineLink from '../InlineLink/InlineLink'
import { urls } from '../../urls'
import CurrentLocation from '../Icons/CurrentLocation'

import css from './SiteSearchTabs.module.scss'
import IconLabel from '../IconLabel/IconLabel'

const styles = classnames.bind(css)

const radiusOptions = [
    {
        value: null,
        label: 'Any',
    },
    ...[1, 2, 3, 5, 10, 20].map((n) => ({
        value: n,
        label: `+ ${n} miles`,
    })),
]

const travelModeOptions = Object.entries(LABELS.travelMode).map(
    ([key, value]) => ({
        value: key,
        label: value,
    }),
)

const SiteSearchTabs = ({
    location,
    tabIndex,
    changeTabIndex,
    changeLocation,
    changeRadius,
    changeArea,
    changeTravelTime,
    changeTravelMode,
    useCurrentLocation,
    loggedIn,
    path,
}) => {
    const tabs = {
        radius: {
            name: 'Radius',
            panel: (
                <div tabkey="1">
                    <InputSelect
                        value={radiusOptions.find(
                            ({ value }) => value === location.radius,
                        )}
                        options={radiusOptions}
                        handleChange={changeRadius}
                        aria-labelledby="radius"
                    />
                    <span id="radius" className="visually-hidden" aria-hidden>
                        Select a radius
                    </span>
                </div>
            ),
        },
        travel: {
            name: 'Travel',
            panel: (
                <div
                    tabkey="2"
                    className={styles('site-search-meta__row--container')}
                >
                    <div className={styles('site-search-meta__input')}>
                        <InputText
                            className={styles(
                                'site-search-meta__input_journey-time',
                            )}
                            type="number"
                            value={location.travelTime || ''}
                            handleChange={changeTravelTime}
                            aria-labelledby="journey-time"
                            min="0"
                            step="1"
                            max="120"
                        />
                        <span
                            id="journey-time"
                            className="visually-hidden"
                            aria-hidden
                        >
                            Select a journey time in minutes
                        </span>
                        <span
                            className={styles('site-search-meta__text_journey')}
                        >
                            mins
                        </span>
                    </div>
                    <div className={styles('site-search-meta__input')}>
                        <InputSelect
                            className={styles(
                                'site-search-meta__select-transport',
                            )}
                            value={
                                location.travelMode &&
                                travelModeOptions.find(
                                    ({ value }) =>
                                        value === location.travelMode,
                                )
                            }
                            options={[{ label: 'All' }, ...travelModeOptions]}
                            handleChange={changeTravelMode}
                            aria-labelledby="journey-transport"
                        />
                        <span
                            id="journey-transport"
                            className="visually-hidden"
                            aria-hidden
                        >
                            Select a mode of transport
                        </span>
                    </div>
                </div>
            ),
        },
        region: {
            name: 'Region',
            panel: (
                <div tabkey="3">
                    <SelectRegion
                        areaValue={location.area}
                        options={location.areasAtLocation}
                        changeArea={changeArea}
                        className={styles('site-search-meta__search_region')}
                    />
                    <span
                        id="browse-regions-aria-label"
                        className="visually-hidden"
                        aria-hidden
                    >
                        Enter a value
                    </span>
                </div>
            ),
        },
    }

    let visibleTabs =
        !location.type || !location.lat
            ? [] // don't show any tabs when no location is selected
            : location.type === 'area'
            ? [] // don't show any tabs when a region/area is selected from the autocomplete
            : location.areasAtLocation // show region tab only when we got the data back for areasAtLocation
            ? ['radius', 'travel', 'region']
            : ['radius', 'travel']

    // Hide travel and region tabs if not logged in
    if (!loggedIn) {
        removeFirstOccurrence(visibleTabs, 'travel')
        removeFirstOccurrence(visibleTabs, 'region')
    }

    // hide all tabs if multiple locations are selected (because lat/lng is ambigous)
    if (
        location.type === 'area' &&
        Array.isArray(location.value) &&
        location.value.length > 1
    ) {
        visibleTabs = []
    }

    if (tabIndex >= visibleTabs.length) {
        changeTabIndex(0)
    }

    const renderTabs = visibleTabs.map((name) => tabs[name])

    // set default radius only if it's undefined but not if it's null
    const isMobile = useMedia('(max-width: 768px)')
    const defaultRadius = isMobile ? null : 3
    const hasRadiusTab = visibleTabs.includes('radius')
    // TODO: The changeRadius currently changes on every render so we keep it in a ref instead.
    // This is not a proper way to do it, instead changeRadius should be defined with useReducer to keep the reference stable.
    const changeRadiusRef = useRef()
    changeRadiusRef.current = changeRadius
    useEffect(() => {
        if (hasRadiusTab && location.radius === undefined) {
            changeRadiusRef.current(
                radiusOptions.find(({ value }) => value === defaultRadius),
            )
        }
    }, [hasRadiusTab, location.radius, defaultRadius])

    return (
        <>
            <SiteSearchSelect
                location={location}
                changeLocation={changeLocation}
            />

            <div className={styles('site-search__links-row')}>
                {location.type !== 'geolocation' && (
                    <IconLabel
                        icon={<CurrentLocation />}
                        onClick={useCurrentLocation}
                        label="Use my location"
                        className={styles('site-search__current-location')}
                    />
                )}
                {path === urls.home() && (
                    <InlineLink
                        className={styles('site-search__browse-map')}
                        to={urls.map()}
                    >
                        Browse whole of England
                    </InlineLink>
                )}
            </div>

            <span id="browse-sites-aria-label" className="visually-hidden">
                Enter a value
            </span>

            <Tabs
                tabList={renderTabs.map((tab) => tab.name)}
                tabPanels={renderTabs.map((tab) => tab.panel)}
                controlledTabIndex={tabIndex}
                updateControlledTabIndex={changeTabIndex}
                light
                alignLeft
                allowOverflow
            />
        </>
    )
}

SiteSearchTabs.propTypes = {
    changeRadius: PropTypes.func.isRequired,
    location: PropTypes.shape({}), // TODO
    changeLocation: PropTypes.func.isRequired,
    changeArea: PropTypes.func.isRequired,
    changeTravelTime: PropTypes.func.isRequired,
    changeTravelMode: PropTypes.func.isRequired,
}

export default SiteSearchTabs
