import React from 'react'
import { ACTIVE_MODAL_FILTER_GROUP } from '../../consts/consts'

import Modal, { useModal } from '../Modal/Modal'
import Close from '../Close/Close'
import FilterGroupForm from '../FilterGroupForm/FilterGroupForm'

const FilterGroupModal = () => {
    const { activeModal, closeModal } = useModal()

    return activeModal === ACTIVE_MODAL_FILTER_GROUP ? (
        <Modal titleText="Add a filter group">
            <FilterGroupForm />
            <Close handleClick={closeModal} />
        </Modal>
    ) : null
}

export default FilterGroupModal
