export const ACTIVE_MODAL_FILTER_GROUP = 'filterGroupModal'

export const ACTIVE_MODAL_SITE_SEARCH = 'siteSearchModal'

export const ACTIVE_MODAL_FILTER_SELECTOR = 'filterSelectorModal'

export const ACTIVE_MODAL_OVERLAY_SELECTOR = 'overlaySelectorModal'

export const ACTIVE_MODAL_SAVE_MAP = 'SavedMapModalSave'
export const ACTIVE_MODAL_RENAME_MAP = 'SavedMapModalRename'
export const ACTIVE_MODAL_SHARE_MAP = 'shareMapModal'

export const LOCATION_TABINDEX = {
    radius: 0,
    travel: 1,
    area: 2,
}

export const LABELS = {
    overlay: {
        deprivation: 'Deprivation (IMD)',
        population: 'Population density',
        activelives: 'Active Lives',
        nssec: 'Socio-economic',
    },
    level: {
        lsoa: 'LSOA',
        msoa: 'MSOA',
        la: 'Local Authority',
        eur: 'Region',
        fac: 'County FA',
        far: 'FA Region',
        ffr: 'FF Region',
    },
    travelMode: {
        'public_transport': 'Public transport',
        'cycling': 'Cycling',
        'driving': 'Driving',
        'driving+train': 'Driving & train',
        'walking': 'Walking',
        'walking+coach': 'Walking & coach',
        'walking_bus': 'Walking & bus',
        'walking_train': 'Walking & train',
    },
    locationType: {
        pinpoint: 'Pinpointed location',
        geolocation: 'Near me',
    },
    activeLivesDescriptions: {
        active: '% of people who are active (at least 150 mins a week)',
        fairlyActive:
            '% of people who are fairly active (30 - 149 mins a week)',
        inactive: '% of people who are inactive (less than 30 mins a week)',
    },
    nssecDescriptions: {
        nssec_6_7_8PerAll:
            "% of people whose work status is 'Semi-Routine, Routine or Unemployed' (NS-SEC 2011: Category 6-8)",
    },
}

LABELS.levelShort = { ...LABELS.level, la: 'LA' }

export const BOUNDARY_OPTIONS = Object.entries({
    lsoa: 'LSOA',
    msoa: 'MSOA',
    la: 'Local Authority',
    eur: 'Region',
    fac: 'County FA',
    far: 'FA Region',
    ffr: 'FF Region',
    cnst: 'Constituency',
    ward: 'Ward',
}).map(([value, label]) => ({ value, label }))

export const QUICK_FILTER_GROUP_ID = 'quickFilters'

export const DEFAULT_FILTER_GROUP_LABEL = 'Site Grouping'

// TODO: keys are incorrect: likely should be based on PamSection.active_objects.values('identifier')
// Or the tooltip text itself could be added to reffpf instead.
export const SITE_INFO_TOOLTIP_RATING = {
    quality: 'quality tooltip text',
    inclusivity: 'inclusivity tooltip text',
    sustainability: 'sustainability tooltip text',
    engagement: 'engagement tooltip text',
    usage: 'usage tooltip text',
    nffsOutcomes: 'nffsOutcomes tooltip text',
}

export const SITE_SUMMARY_LABELS = {
    sites: {
        numMatching: 'Total',
        ffFundedSites: 'Football Foundation funded',
        nonFootballSites: 'Non-football',
    },
    facilities: {
        totalFacilities: 'Total',
        numThreeGPitches: '3G pitches',
        numGrassPitches: 'Grass pitches',
    },
    teams: {
        numClubsLastSeason: 'Clubs',
        numTeamsLastSeason: 'Teams',
    },
}

const overlayColours = ['#704c20', '#ac6f28', '#e09744', '#edcda5', '#f6f7f4']

export const OVERLAY_COLOURS = {
    deprivation: overlayColours,
    population: [...overlayColours].reverse(),
    activelives: [...overlayColours].reverse(),
    nssec: [...overlayColours].reverse(),
    ogs: {
        fillColor: 'green',
        fillOpacity: 0.3,
    },
}

export const OVERLAY_SCALE_LABELS = {
    deprivation: { 0: 'Most deprived', 4: 'Least deprived' },
    population: { 0: 'Least populated', 4: 'Most populated' },
    activelives: {},
    nssec: {},
}

export const OVERLAY_DEFAULT_OFFSETS = Object.fromEntries(
    [0, 20, 40, 60, 80, 100].map((o) => [o, true]),
)

// https://projects.torchbox.com/projects/ff-pitchfinder/tickets/478#update-64173531
// 0 - 20
// 20 - 30
// 30 - 40
// 40 - 60
// above 60
export const OVERLAY_NSSEC_OFFSETS = Object.fromEntries(
    [0, 20, 30, 40, 60, 100].map((o) => [o, true]),
)

export const OVERLAY_DEFAULTS = {
    deprivation: {
        level: 'msoa',
        value: 'overall',
    },
    population: {
        level: 'msoa',
        value: { from: 0, to: 90 },
    },
    activelives: {
        level: 'la',
        value: 'inactive',
    },
    nssec: {
        level: 'lsoa',
        value: 'nssec_6_7_8PerAll',
    },
}

export const HIGH_RES_ZOOM_THRESHOLD = {
    lsoa: 13,
    soa: 13,
    dz: 12,
    iz: 10,
    msoa: 10, // this could be lower, except for London
    la: 9,
    eur: 9,
    fac: 13,
    far: 13,
    ffr: 13,
    uk: Infinity, // always low res
    cnst: 10,
    ward: 11,
    ogs: 12,
}

export const DATA_LAYER_Z_INDEX = [
    // 'circle'  // not a data layer currently
    'boundaries',
    'overlay',
    'area',
    'ogs',
    'travel',
    'searchMarker', // Overlaps with site markers. Make sure it's underneath.
    'marker',
    'customGeojson',
]

export const SAVED_MAP_MAX_LENGTH = 150

// colour = location_known ? (pitchfinder_id ? light blue : mid blue) : dark blue
// colour = location_known ? (pitchfinder_id ? '#00ACF4' : '#00699C') : '#002544'
export const PRIORITY_PROJECT_COLOURS = {
    pfIdKnown: '#00acf4',
    pfIdNotKnown: '#00699c',
    locationNotKnown: '#002544',
}

export const FILTER_GROUP_ICONS_TO_COLOURS = {
    '3g-ftp-fullsize': '#1BA39C',
    '3g-ftp-medium': '#1E90FF',
    '3g-ftp-small': '#DC143C',
    'ntps-with-changing-rooms': '#D47500',
    'ntps-without-changing-rooms': '#939393',
    'grass-pitches': '#4B6A88',
    'indoor-spaces': '#7462E0',
    'mugas': '#AA8F00',
    'parks-green-spaces': '#FF4500',
    'star': '#D25299',
}

export const FILTER_GROUP_ICON_LABELS = {
    '3g-ftp-fullsize': 'Aqua',
    '3g-ftp-medium': 'Blue',
    '3g-ftp-small': 'Crimson',
    'grass-pitches': 'Bismark',
    'indoor-spaces': 'Purple',
    'mugas': 'Gold',
    'ntps-with-changing-rooms': 'Orange',
    'ntps-without-changing-rooms': 'Gray',
    'parks-green-spaces': 'Red',
    'star': 'Pink',
}

export const SQ_KM_TO_SQ_MI = 0.386102 // i.e. 1 / (1.60934 * 1.60934)
