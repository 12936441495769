import React from 'react'
import classnames from 'classnames/bind'

import { ACTIVE_MODAL_SITE_SEARCH } from '../../consts/consts'
import Modal, { useModal } from '../Modal/Modal'
import Close from '../Close/Close'
import SiteSearchForm from '../SiteSearchForm/SiteSearchForm'
import css from './SiteSearchForm.module.scss'

const styles = classnames.bind(css)

const SiteSearchModal = () => {
    const { activeModal, closeModal } = useModal()

    return (
        <>
            {activeModal === ACTIVE_MODAL_SITE_SEARCH && (
                <Modal titleText="Edit location">
                    <div className={styles('site_search_modal__form')}>
                        <SiteSearchForm />
                    </div>
                    <Close handleClick={closeModal} />
                </Modal>
            )}
        </>
    )
}

export default SiteSearchModal
