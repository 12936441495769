import { createAxiosClient, get } from '../Services'
import { urls } from '../../urls'
import { GetConfigError } from '../../utils/errors'

export const client = createAxiosClient()

const url = urls.api.config()

export const getConfig = () => {
    return get({ client, url })
        .then(({ data }) => data)
        .catch(() => Promise.reject(new GetConfigError()))
}
