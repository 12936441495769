import { useEffect } from 'react'
import { drawCircle, getBoundsIfIsland } from './utils'

export const useSearchRadius = ({
    hasMap,
    mapObj,
    autoZoom,
    lat,
    lng,
    radius,
}) => {
    useEffect(() => {
        let radiusCircle

        // Hide circle for islands when we take a screenshot
        // (the whole island will be visible because of useAutoZoom)
        const hideCircle = window.screenshot && getBoundsIfIsland({ lat, lng })

        // Add circle if a radius has been selected
        if (hasMap && radius && !hideCircle) {
            const bounds = new google.maps.LatLngBounds()

            const circle = drawCircle(
                new google.maps.LatLng(lat, lng),
                radius,
                bounds,
            )

            // Rectangle to "cover the world"
            const rectangle = [
                new google.maps.LatLng(-90, 0),
                new google.maps.LatLng(-90, 180),
                new google.maps.LatLng(90, 180),
                new google.maps.LatLng(90, -180),
                new google.maps.LatLng(-90, -180),
            ]

            // Create a new polygon
            radiusCircle = new google.maps.Polygon({
                map: mapObj.current,
                paths: [circle, rectangle],
                strokeColor: '#1c1c1c',
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: '#1c1c1c',
                fillOpacity: 0.25,
                clickable: false,
            })
            autoZoom({ searchRadius: bounds })
        }

        return () => {
            if (radiusCircle) {
                radiusCircle.setMap(null)
                autoZoom({ searchRadius: null })
            }
        }
    }, [hasMap, mapObj, autoZoom, lat, lng, radius])
}
