import { createAxiosClient, get } from '../Services'
import { GEOSITE_URL } from '../../urls'

export const client = createAxiosClient()

export const getGeoJsonForFeature = (code) => {
    const url = `${GEOSITE_URL}/feature/${code}.geojson`
    const params = { simplify: 10 }

    return get({
        url,
        client,
        params,
        cancelPrevious: `getGeoJsonForFeature-${code}`,
    }).then(({ data }) => data)
}
