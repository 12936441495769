/* eslint-disable no-bitwise, no-plusplus, no-continue, no-underscore-dangle, no-else-return, consistent-return, no-console, max-classes-per-file */
import { CancelToken } from 'axios'
import { get } from '../services/Services'
import { OVERLAY_COLOURS, HIGH_RES_ZOOM_THRESHOLD } from '../consts/consts'
import { pointToTile } from './mercator'

// TODO
// this is needed to keep the current cursor style which .setStyle() would otherwise remove
window.overlayCursorStyle = ''

const cachedOverlayData = {}

class GeoJSONLayer {
    constructor(serverURL, country, level, zoom) {
        let threshold = HIGH_RES_ZOOM_THRESHOLD[level]
        if (level === 'lsoa' && country === 'northern_ireland') {
            threshold -= 1
        }
        if (level) {
            this.layer = zoom > threshold ? `${level}-hi` : `${level}-lo`
        }
        this.country = country
        this.quads = null
        this.quadsLoading = false
        this.serverURL = serverURL
    }

    get layerKey() {
        if (this.country === 'scotland') {
            return this.layer.replace('lsoa', 'dz').replace('msoa', 'iz')
        }
        if (this.country === 'northern_ireland') {
            return this.layer.replace('lsoa', 'soa')
        }
        return this.layer
    }

    getQuadURL(quadkey) {
        return `/tiles/${this.layerKey}/${quadkey}.geojson`
    }

    loadQuads(onComplete, _parent) {
        let parent = _parent

        if (this.quads !== null || this.quadsLoading) {
            return
        }

        this.quadsLoading = true

        if (
            this.layer &&
            ['fac', 'far', 'ffr'].indexOf(this.layer.substr(0, 3)) === -1
        ) {
            // quads for MSOA and LSOA models are rendered together (see populate_quads.py)
            // specifying a parent geometry here excludes quads that conatain features from other countries only
            // FA and FF areas are for England only and might not have a Country as a parent
            parent =
                parent ||
                {
                    england: 'E92000001',
                    wales: 'W92000004',
                    scotland: '',
                    northern_ireland: '',
                }[this.country]
        }

        if (!this.layerKey) {
            this.quadsLoading = false
            return
        }

        // Get quads
        get({
            url: `${this.serverURL}/tiles/${this.layerKey}/tree.json`,
            params: { parent: parent || undefined }, // axios ignores `undefined` params
        })
            .then(({ data }) => {
                this.quads = data
                this.quadsLoading = false
                if (onComplete) {
                    onComplete()
                }
            })
            .catch((e) => {
                console.error(e) // TODO
                this.quadsLoading = false
            })
    }

    static getQuadKey(row, col, zoom) {
        // https://github.com/stamen/modestmaps-js/blob/fedc94504ef95140a8ccc3c19907445987d13c6d/examples/microsoft/bing.js#L20
        let quadKey = ''

        for (let i = 1; i <= zoom; i++) {
            const rowBit = (row >> (zoom - i)) & 1
            const colBit = (col >> (zoom - i)) & 1
            quadKey += (rowBit << 1) + colBit
        }

        return quadKey
    }

    tileToQuads(row, col, zoom) {
        const quadKey = this.constructor.getQuadKey(row, col, zoom)

        // Look for a quad above this tile
        for (let i = 0; i < this.quads.length; i++) {
            if (quadKey.startsWith(this.quads[i])) {
                return [this.quads[i]]
            }
        }
        // No quad found above, search for and return all quads below it then
        return (
            this.quads && this.quads.filter((quad) => quad.startsWith(quadKey))
        )
    }

    getQuads(bounds, zoom) {
        const boundsNeLatLng = bounds.getNorthEast()
        const boundsSwLatLng = bounds.getSouthWest()
        const boundsNwLatLng = new google.maps.LatLng(
            boundsNeLatLng.lat(),
            boundsSwLatLng.lng(),
        )
        const boundsSeLatLng = new google.maps.LatLng(
            boundsSwLatLng.lat(),
            boundsNeLatLng.lng(),
        )

        const tileCoordinateNw = pointToTile(boundsNwLatLng, zoom)
        const tileCoordinateSe = pointToTile(boundsSeLatLng, zoom)
        const quads = {}
        let tileColumns = tileCoordinateSe.x - tileCoordinateNw.x + 1
        let tileRows = tileCoordinateSe.y - tileCoordinateNw.y + 1
        const minX = tileCoordinateNw.x
        let minY = tileCoordinateNw.y

        while (tileRows--) {
            while (tileColumns--) {
                const quadKeys = this.tileToQuads(
                    minY,
                    minX + tileColumns,
                    zoom,
                )
                for (let i = 0; i < quadKeys.length; i++) {
                    quads[quadKeys[i]] = true
                }
            }
            minY++
            tileColumns = tileCoordinateSe.x - tileCoordinateNw.x + 1
        }

        return [...Object.keys(quads)]
    }
}

export class GeoJSONOverlay {
    constructor(map, serverURL, zIndex) {
        this.map = map
        this.serverURL = serverURL
        this.zIndex = zIndex
        this.loadedQuads = {}
        this.loadedFeatures = {}
        this.layer = null
        this.country = null
        this.level = null
        this.overlay = null
        this.dataCacheKey = null
        this.colourMap = 'default'
        this.withinFeature = null
        this.withinFeaturePoygons = null
        this.mapDataLayer = null
        this.cachedOverlayData = cachedOverlayData // use shared cache across all instances
        this.refreshStyle = this.refreshStyle.bind(this)
        this.updateQuads = this.updateQuads.bind(this)

        google.maps.event.addListener(map, 'dragend', this.updateQuads)
        google.maps.event.addListener(map, 'zoom_changed', this.updateQuads)
    }

    refreshStyle() {
        const colours = OVERLAY_COLOURS[this.colourMap]

        // allow controlling opacity for saved maps
        let opacityOutside = 1
        try {
            const overlayOpacity = new URLSearchParams(
                document.location.search,
            ).get('overlay-opacity')
            opacityOutside = parseInt(overlayOpacity, 10) / 100
        } catch (_) {} // eslint-disable-line no-empty
        if (Number.isNaN(opacityOutside)) {
            opacityOutside = 1
        }

        if (!Array.isArray(colours) && typeof colours === 'object') {
            this.mapDataLayer.setStyle({
                zIndex: this.zIndex,
                fillOpacity: 0,
                strokeWeight: 1,
                cursor: window.overlayCursorStyle,
                ...colours,
            })
        } else if (colours) {
            this.mapDataLayer.setStyle((f) => {
                const code = f.getProperty('code')
                const d = cachedOverlayData[this.dataCacheKey][code]
                if (!d) {
                    console.warn('missing', code) // noqa
                    return
                }

                let fillColor = null
                if (d.quintile_0 !== null) {
                    fillColor = colours[d.quintile_0]
                }

                if (
                    !this.showColours ||
                    this.showColours.indexOf(fillColor) !== -1
                ) {
                    // TODO: highlight bracket on the map legend
                    // var selectedGssCode = $('[name=within][value=gss_area] ~ select option:selected').val()
                    // if (f.getProperty('code') === selectedGssCode) {
                    //     $('.legend-for-bracket span').each(function() {
                    //         if (
                    //             rgb2hex($(this).css('background-color')) ===
                    //             fillColor
                    //         ) {
                    //             $(this)
                    //                 .parent()
                    //                 .css('font-weight', 'bold')
                    //         }
                    //     })
                    // }

                    let fillOpacity = 0.6
                    // make areas outside the selected one more transparent if we have this in the url: ?overlay-opacity=50
                    // currently this works for LAs only
                    if (
                        this.withinFeaturePoygons &&
                        opacityOutside != null &&
                        this.level === 'la'
                    ) {
                        const coords = f.getProperty('centroid').coordinates
                        const centroid = new google.maps.LatLng(
                            coords[1],
                            coords[0],
                        )
                        let within = false
                        for (
                            let i = 0;
                            i < this.withinFeaturePoygons.length;
                            i++
                        ) {
                            const _within = google.maps.geometry.poly.containsLocation(
                                centroid,
                                this.withinFeaturePoygons[i],
                            )
                            if (_within) {
                                within = true
                                break
                            }
                        }

                        if (!within) {
                            fillOpacity *= opacityOutside
                        }
                    }

                    return {
                        zIndex: this.zIndex,
                        fillColor,
                        fillOpacity,
                        strokeWeight: 0,
                        cursor: window.overlayCursorStyle,
                    }
                } else {
                    return {
                        zIndex: this.zIndex,
                        fillOpacity: 0,
                        strokeWeight: 0,
                        cursor: window.overlayCursorStyle,
                    }
                }
            })
        } else {
            this.mapDataLayer.setStyle({
                zIndex: this.zIndex,
                fillOpacity: 0,
                strokeWeight: 1,
                cursor: window.overlayCursorStyle,
            })
        }
    }

    unloadQuads() {
        // Abort pending requests
        const loadedQuadUrls = Object.keys(this.loadedQuads)

        for (let i = 0; i < loadedQuadUrls.length; i++) {
            const quadUrl = loadedQuadUrls[i]
            const { cancelToken } = this.loadedQuads[quadUrl]

            if (cancelToken) {
                cancelToken.cancel()
                this.loadedQuads[quadUrl].cancelToken = null
            }
        }

        // Clean this.loadedQuads and this.loadedFeatures
        this.loadedQuads = {}
        this.loadedFeatures = {}

        // Forget current layer
        this.layer = null

        // Remove all features from the map, except for the selected one
        this.mapDataLayer.forEach((feature) => {
            if (feature !== window.within_feature) {
                this.mapDataLayer.remove(feature)
            }
        })
    }

    updateQuads() {
        if (this.country === null || this.overlay === null) {
            return
        }

        const mapZoom = this.map.getZoom()
        const mapBounds = this.map.getBounds()
        const layer = new GeoJSONLayer(
            this.serverURL,
            this.country,
            this.level,
            mapZoom,
        )

        // Unload quads if the layer has changed
        if (this.layer === null || layer.layer !== this.layer.layer) {
            this.unloadQuads()
            this.layer = layer
        }

        // Map must have bounds to load quads
        if (!mapBounds) {
            return
        }

        // Make sure the quad list is loaded
        if (!this.layer.quads) {
            this.layer.loadQuads(
                this.updateQuads,
                // this.withinFeature && this.withinFeature.getProperty('code'),
            )
            return
        }

        // Load new quads
        const quads = this.layer.getQuads(mapBounds, mapZoom)
        let remainingRequests = quads.length

        const onQuadLoaded = (quad, quadUrl) => ({ data }) => {
            const keepFeatures = {}

            if (quadUrl in this.loadedQuads) {
                // Remove pending request object from quad
                this.loadedQuads[quadUrl].cancelToken = null

                // Check features:
                // - Remove features that are already loaded
                // - Take note of features that are not already loaded
                for (let i = 0; i < data.features.length; i++) {
                    const feature = data.features[i]
                    if (!feature.id && feature.id !== 0) {
                        console.warn(
                            'Error: geojson feature without an ID',
                            feature,
                        ) // noqa
                    }
                    const featureCode = feature.properties.code
                    let keep

                    // Quads are generated statically, so this limits how we can filter them by area,
                    // so we have to get rid of some features that fall outside the selected area.
                    if (this.withinFeature) {
                        // it we had the parent gss codes for each feature we could just use those to filter them:
                        //
                        // if(this.level == 'uk'){
                        //     keep = true;
                        // }else if(this.level == 'eur'){
                        //     keep = this.withinFeature.getProperty('gid') == feature.properties.country_gid;
                        // }else if(this.level == 'la'){
                        //     keep = this.withinFeature.getProperty('gid') == feature.properties.region_gid;
                        // }else if(['lsoa', 'soa', 'dz'].indexOf(this.level) != -1){
                        //     keep = this.withinFeature.getProperty('gid') == feature.properties.local_authority_gid;
                        // }else{
                        //     throw new Error('Undefined area level: ' + this.level)
                        // }

                        // ... but we don't have the parent gss codes, only the centroids
                        // so we use the centroids of each feature to tell if it's within the this.withinFeature
                        const featureCentroid = new google.maps.LatLng(
                            feature.properties.centroid.coordinates[1],
                            feature.properties.centroid.coordinates[0],
                        )

                        for (
                            let j = 0;
                            j < this.withinFeaturePoygons.length;
                            j++
                        ) {
                            const _within = google.maps.geometry.poly.containsLocation(
                                featureCentroid,
                                this.withinFeaturePoygons[j],
                            )
                            if (_within) {
                                keep = true
                                break
                            } else {
                                keep = false
                            }
                        }

                        if (keep) {
                            keepFeatures[featureCode] = true
                        }
                    } // end this.withinFeature

                    if (featureCode in this.loadedFeatures) {
                        // Already loaded. Don't add it again from this quad too.
                        keepFeatures[featureCode] = false
                    } else if (keep) {
                        this.loadedFeatures[featureCode] = true
                    }
                } // end for each feature

                if (this.withinFeature) {
                    // now remove the features that were marked unnecessary above
                    for (let i = data.features.length - 1, _code; i >= 0; i--) {
                        _code = data.features[i].properties.code
                        if (!(_code in keepFeatures)) {
                            data.features.splice(i, 1)
                        }
                    }
                }

                // quads are shared between England and Wales, so we just take care of that here
                // TODO: specify `parent` for `serve_tree` to load less unneeded quads
                for (let i = data.features.length - 1, _code; i >= 0; i--) {
                    _code = data.features[i].properties.code

                    if (['lsoa', 'msoa'].indexOf(this.level) !== -1) {
                        if (this.country === 'england')
                            if (
                                _code.substr(0, 1) !== 'E' &&
                                _code !== '999999999'
                            ) {
                                data.features.splice(i, 1)
                                continue
                            }

                        if (this.country === 'wales')
                            if (_code.substr(0, 1) !== 'W') {
                                data.features.splice(i, 1)
                                continue
                            }
                    }

                    if (['la', 'eur'].indexOf(this.level) !== -1) {
                        if (this.country === 'england')
                            if (
                                _code.substr(0, 1) !== 'E' &&
                                _code !== '999999999'
                            ) {
                                data.features.splice(i, 1)
                                continue
                            }

                        if (this.country === 'wales')
                            if (_code.substr(0, 1) !== 'W') {
                                data.features.splice(i, 1)
                                continue
                            }
                        if (this.country === 'scotland')
                            if (_code.substr(0, 1) !== 'S') {
                                data.features.splice(i, 1)
                                continue
                            }

                        if (this.country === 'northern_ireland')
                            if (_code.substr(0, 1) !== 'N') {
                                data.features.splice(i, 1)
                                continue
                            }
                    }
                }

                // Load features
                // TODO: try to reset styles in data.features
                this.mapDataLayer.addGeoJson(data)

                remainingRequests--

                if (!remainingRequests) {
                    // once everything is loaded we can fetch e.g. the IMD data for these features
                    if (window.within_feature) {
                        this.mapDataLayer.remove(window.within_feature)
                    }
                    this.onAllTilesLoaded()
                }
            }
        }

        quads.forEach((quad) => {
            const quadUrl = this.serverURL + this.layer.getQuadURL(quad)

            // Skip if the quad has already been loaded
            if (this.loadedQuads[quadUrl]) {
                remainingRequests--
                return
            }

            // Add quad to this.loadedQuads
            // We need `new String` to set `.cancelToken` on it
            this.loadedQuads[quadUrl] = new String(quad) // eslint-disable-line

            const cancelToken = CancelToken.source()
            // Get quad from server
            get({
                url: quadUrl,
                cancelToken: cancelToken.token,
            })
                .then(onQuadLoaded(quad, quadUrl))
                .catch(() => {
                    if (quadUrl in this.loadedQuads) {
                        // Remove pending request object from quad
                        this.loadedQuads[quadUrl].cancelToken = null
                    }
                })

            // Save request object into quad
            this.loadedQuads[quadUrl].cancelToken = cancelToken
        })
    }

    clearOverlay = () => {
        this.showColours = null
        this.setOverlay({ mapDataLayer: this.mapDataLayer })
    }

    showBrackets = (offsets) => {
        const colours = OVERLAY_COLOURS[this.colourMap]
        if (!colours) {
            return
        }
        const showColoursOld = this.showColours
        this.showColours = Object.values(offsets)
            .slice(1)
            .map((show, i) => (show ? colours[i] : null))

        // Don't call refreshStyle if the selcted brackets didn't change
        if (String(showColoursOld) !== String(this.showColours)) {
            // Skip refreshStyle if initial showColours value was unset and every bracket would be still selected
            if (!showColoursOld && Object.values(offsets).every(Boolean)) {
                // skip
            } else {
                this.refreshStyle()
            }
        }
    }

    setWithinFeature({ areas, level }) {
        const codes = Array.isArray(areas)
            ? areas.map(({ value }) => value)
            : [areas]

        this.mapDataLayer.forEach((f) => {
            if (codes.includes(f.getProperty('code'))) {
                if (this.withinFeature) {
                    this.withinFeature.push(f)
                } else {
                    this.withinFeature = [f]
                }
            }
        })

        if (this.withinFeature) {
            this.withinFeature.forEach((f) => {
                // use this.withinFeature only within an LA:
                if (
                    f.getProperty('model') === 'geo.localauthority' &&
                    ['lsoa', 'msoa', 'la'].indexOf(level) !== -1
                ) {
                    // we need to convert the feature to a list of polygons to be able to use it with google.maps.geometry.poly.containsLocation
                    this.withinFeaturePoygons = this.withinFeaturePoygons || []

                    const g = f.getGeometry()

                    for (let i = 0, l = g.getLength(); i < l; i++) {
                        const p = new google.maps.Polygon({
                            paths: g.getAt(i).getArray(),
                        })
                        this.withinFeaturePoygons.push(p)
                    }

                    // if this.withinFeature is an LA and we have an LA overlay too then we don't hide areas outside of it
                    // but we still need this.withinFeaturePoygons to make them more transparent
                    if (level === 'la') {
                        this.withinFeature = null
                    }
                } else {
                    this.withinFeature = null
                }
            })
        }
    }

    setOverlay({
        country,
        level,
        overlay,
        dataCacheKey,
        colourmap,
        mapDataLayer,
    }) {
        this.mapDataLayer = mapDataLayer

        return new Promise((resolve) => {
            // set default styles until the statistical data loads
            this.mapDataLayer.setStyle({
                zIndex: this.zIndex,
                fillOpacity: 0,
                strokeWeight: 1,
                strokeColor: '#333',
            })

            this.onAllTilesLoaded = () => {
                resolve(this)
            }

            const prevLevel = this.level
            const prevCountry = this.country
            this.country = country
            this.level = level
            this.overlay = overlay
            this.dataCacheKey = dataCacheKey
            this.colourMap = colourmap

            // Refresh the style
            // refreshStyle();
            // This ^ is useful if the data is included in the geojson,
            // but we fetch that separately here, from the onAllTilesLoaded callback.

            if (prevCountry !== this.country || prevLevel !== this.level) {
                this.unloadQuads()
                this.updateQuads()
            } else {
                // no need to updateQuads, we keep the existing boundaries and trigger callback immediately
                this.onAllTilesLoaded()
            }
        })
    }
}
