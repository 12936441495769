/* eslint-disable no-plusplus, no-continue, no-console */

import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { GeoJSONOverlay } from '../../utils/geojsonTiles'
import { setOverlayLoading, replaceOffsets } from '../../store/Overlay/Overlay'
import { createOverlayTooltip } from './utils'
import { get } from '../../services/Services'
import { getConfig } from '../../services/Config/Config'
import { usePrevious, usePromiseGlobal, useAlert } from '../../hooks/hooks'
import {
    DATA_LAYER_Z_INDEX,
    OVERLAY_DEFAULT_OFFSETS,
    OVERLAY_NSSEC_OFFSETS,
} from '../../consts/consts'
import { GEOSITE_URL } from '../../urls'

// Calculate brackets (modifies `cache` and `data` in place)
const setBrackets = (cache, data, field, n) => {
    data.sort((a, b) => a[field] - b[field])
    const offsets = [...Array(n).keys()] // [0,1,2 ... n-1]
        .slice(1) // [1,2 ... n-1]
        .map((i) => data[Math.floor((i * data.length) / n)][field])
        .concat(Infinity)

    const values = Object.values(cache)
    for (let i = 0; i < values.length; i++) {
        const e = values[i]
        if (e[field] == null) {
            continue
        }
        for (let i = 0; i < offsets.length; i++) {
            if (e[field] < offsets[i]) {
                e.quintile_0 = i
                break
            }
        }
    }
}

const throwIfNoData = (data, responseURL) => {
    if (!data.length) {
        throw new Error(`No data for ${responseURL}`)
    }
}

const ignoreCancelled = (e) => {
    if (!e.cancelled) {
        throw e
    }
}

const zIndex = DATA_LAYER_Z_INDEX.indexOf('overlay')

export const useOverlays = ({ hasMap, mapObj }) => {
    const country = 'england'
    const dispatch = useDispatch()
    const alert = useAlert()

    // load overlay config
    const [config] = usePromiseGlobal(getConfig)
    const overlayConfig = config?.overlays

    const zoom = useSelector(({ map: { zoom } }) => zoom)

    // Allow showing all LSOAs if we have a GSS code override,
    // in which case features outside it are not shown
    // so there won't be too many of them
    const isLSOAAvailable = window.saved_search_gss_code_override
        ? true
        : zoom > 11

    const areas = useSelector(
        ({ location: { type, value } }) => type === 'area' && value,
    )

    const {
        optgroup: overlay,
        level: selectedLevel,
        value,
        year,
        offsets,
        loading,
    } = useSelector(({ overlay }) => overlay, shallowEqual)

    // `level` in local state is needed because we need to switch area levels automatically
    // TODO: sidebar currently shows only `selectedLevel`
    const [level, setLevel] = useState(selectedLevel)

    const geoJSONOverlayRef = useRef()
    const mapDataLayerRef = useRef()
    const boundariesLoadingRef = useRef(false)
    const cachedOverlayDataRef = useRef()

    const hasOverlaySelected = Boolean(
        hasMap && overlay && level && value && year,
    )

    // Calculate cache `key` to store data and use it to colour areas.
    const overlayValueKey =
        overlay && value
            ? {
                  population: `${value.from}_${value.to}`,
                  deprivation: value,
                  activelives: value,
                  nssec: value,
              }[overlay]
            : null
    const key = [overlay, overlayValueKey, level, country, year].join('_')

    // setup/teardown
    // NB add new functionality in a separate useEffect
    useEffect(() => {
        if (hasOverlaySelected) {
            mapDataLayerRef.current = new google.maps.Data()
            mapDataLayerRef.current.setStyle({
                zIndex,
                fillOpacity: 0,
                strokeWeight: 1,
                strokeColor: '#333',
            })
            mapDataLayerRef.current.setMap(mapObj.current)
            geoJSONOverlayRef.current = new GeoJSONOverlay(
                mapObj.current,
                GEOSITE_URL,
                zIndex,
            )
        } else if (geoJSONOverlayRef.current) {
            geoJSONOverlayRef.current.clearOverlay(mapDataLayerRef.current)
        }
    }, [mapObj, hasOverlaySelected, dispatch])

    // Add tooltips
    useEffect(() => {
        if (hasOverlaySelected) {
            const tooltip = createOverlayTooltip(mapObj.current.getDiv())

            const mouseoverListener = mapDataLayerRef.current.addListener(
                'mouseover',
                (event) => {
                    let tooltipText = 'Loading...'
                    const data =
                        cachedOverlayDataRef.current &&
                        cachedOverlayDataRef.current[key]
                    if (data) {
                        const f = event.feature
                        const code = f.getProperty('code')
                        const name = f.getProperty('name')
                        const featureCachedData = data[code]
                        const showAreaName = !/^(95|E01|E02|W01|W02)/.test(code)

                        if (!Object.prototype.hasOwnProperty.call(data, code)) {
                            // Show loading message when switching between overlays and data has not fully loaded yet
                            if (boundariesLoadingRef.current) {
                                tooltip.show(tooltipText)
                                return
                            }
                            console.warn(`Missing data: ${name} (${code})`)
                            if (
                                process.env.NODE_ENV === 'production' &&
                                window.Sentry
                            ) {
                                // TODO: sentry docs don't exist for this any more, might not work
                                Sentry.withScope((scope) => {
                                    scope.setExtra('overlay', overlay)
                                    scope.setExtra('code', code)
                                    scope.setExtra('name', name)
                                    Sentry.captureMessage(
                                        'Missing data for overlay',
                                    )
                                })
                            }
                            // Return now to avoid uncaught exceptions below
                            return
                        }

                        if (overlay === 'population') {
                            tooltipText = `${featureCachedData.densityPercentile}% ranking<br>
                                ${featureCachedData.densityByAgeSum} people per square mile<br>
                                ${featureCachedData.byAgeSum} people`
                            // don't show area name for smaller areas
                            if (showAreaName) {
                                tooltipText += `<br>${name}`
                            }
                        }

                        if (overlay === 'deprivation') {
                            tooltipText = `${featureCachedData.percentile}% ranking`
                            // don't show area name for smaller areas
                            if (showAreaName) {
                                tooltipText += ` - ${name}`
                            }
                        }

                        if (overlay === 'activelives') {
                            tooltipText = `${featureCachedData.percentage}%`
                            // don't show area name for smaller areas
                            if (showAreaName) {
                                tooltipText += ` - ${name}`
                            }
                        }

                        if (overlay === 'nssec') {
                            tooltipText = `${featureCachedData.percentage}%`
                            // don't show area name for smaller areas
                            if (showAreaName) {
                                tooltipText += ` - ${name}`
                            }
                        }

                        // Highlight feature
                        mapDataLayerRef.current.revertStyle()
                        mapDataLayerRef.current.overrideStyle(f, {
                            strokeWeight: 2,
                            strokeOpacity: 0.7,
                        })
                    }

                    tooltip.show(tooltipText)
                },
            )
            const mouseoutListener = mapDataLayerRef.current.addListener(
                'mouseout',
                () => {
                    mapDataLayerRef.current.revertStyle()
                    tooltip.hide()
                },
            )
            return () => {
                google.maps.event.removeListener(mouseoverListener)
                google.maps.event.removeListener(mouseoutListener)
                tooltip.remove()
            }
        }
        return () => {}
    }, [mapObj, hasOverlaySelected, dispatch, overlay, key])

    // The level can change during the lifecycle of this component,
    // so setting the initial value is not enough,
    // we need to keep the local state up to date from redux.
    useEffect(() => {
        setLevel(selectedLevel)
    }, [selectedLevel])

    // Set `level` based on `selectedLevel` and
    // switch to MSOA if trying to view LSOAs when zoomed out
    useEffect(() => {
        setLevel(
            selectedLevel === 'lsoa' && !isLSOAAvailable
                ? 'msoa'
                : selectedLevel,
        )
    }, [selectedLevel, isLSOAAvailable])

    // Reset offsets and visible brackets only when switching to a different overlay.
    const previousOverlay = usePrevious(overlay)
    useEffect(() => {
        if (loading) {
            return
        }
        if (geoJSONOverlayRef.current && cachedOverlayDataRef.current) {
            // calculate offsets that will be used by the MapKey
            const data = cachedOverlayDataRef.current[key]
            if (data) {
                if (overlay === 'activelives') {
                    const brackets = 5
                    const values = Object.values(data)
                    values.sort((a, b) => a.percentage - b.percentage)
                    const lower = values[0].percentage
                    const upper = values[values.length - 1].percentage
                    const range = upper - lower
                    const activelivesOffsets = [
                        ...Array(brackets + 1).keys(),
                    ].map((i) => (lower + (range * i) / brackets).toFixed(1))
                    if (
                        !offsets ||
                        (previousOverlay && previousOverlay !== overlay)
                    ) {
                        dispatch(
                            replaceOffsets(
                                Object.fromEntries(
                                    activelivesOffsets.map((o) => [o, true]),
                                ),
                            ),
                        )
                    }
                } else if (
                    !offsets ||
                    (previousOverlay && previousOverlay !== overlay)
                ) {
                    if (overlay === 'nssec') {
                        dispatch(replaceOffsets(OVERLAY_NSSEC_OFFSETS))
                    } else {
                        dispatch(replaceOffsets(OVERLAY_DEFAULT_OFFSETS))
                    }
                }
            }
        }
    }, [dispatch, offsets, overlay, previousOverlay, key, loading])

    // Show/hide brackets on the map
    useEffect(() => {
        if (
            !loading &&
            geoJSONOverlayRef.current &&
            cachedOverlayDataRef.current &&
            offsets
        ) {
            geoJSONOverlayRef.current.showBrackets(offsets)
        }
    }, [offsets, loading])

    // skip loading overlays during this render because ...
    const skip =
        // setLevel() is about to change the `level` (to switch between msoa/lsoa depending on the zoom)
        (selectedLevel === 'lsoa' && level === 'lsoa' && !isLSOAAvailable) ||
        (selectedLevel === 'lsoa' && level === 'msoa' && isLSOAAvailable) ||
        // replaceMapState() will change `zoom` after the map becomes idle, and this component will render again
        mapObj.current?.getZoom() !== zoom ||
        // detect invalid config (it will be correct on next render):
        !(overlayConfig && overlayConfig[overlay]?.level.includes(level))

    // Set loading state manually in some special cases
    const previousSelectedLevel = usePrevious(selectedLevel)
    useEffect(() => {
        // If selected lsoa at a level where it's not available -> nothing to load
        const cond1 =
            selectedLevel === 'lsoa' && level !== 'lsoa' && !isLSOAAvailable
        // If switching from lsoa to msoa on a level where lsoa wasn't available -> nothing to load
        const cond2 =
            selectedLevel === 'msoa' &&
            previousSelectedLevel === 'lsoa' &&
            !isLSOAAvailable
        if (cond1 || cond2) {
            dispatch(setOverlayLoading(false))
        }
    }, [dispatch, level, selectedLevel, isLSOAAvailable, previousSelectedLevel])

    // Show message when LSOA is selected but not available at the zoom level
    useEffect(() => {
        const alertOptions = { type: 'warning', timeout: 7000 }
        if (
            level &&
            selectedLevel === 'lsoa' &&
            level !== 'lsoa' &&
            !isLSOAAvailable
        ) {
            const msg =
                'Showing MSOA level info in the overlay. Zoom in close to see LSOAs.'
            alert.current.show(msg, alertOptions)
        } else if (
            selectedLevel === 'lsoa' &&
            level === 'lsoa' &&
            isLSOAAvailable
        ) {
            const msg = 'Showing LSOA level info in the overlay.'
            alert.current.show(msg, alertOptions)
        }
    }, [selectedLevel, level, isLSOAAvailable, alert])

    // Load boundaries and data afterwards.
    // Don't reload boundaries if not needed and use cache for the data.
    useEffect(() => {
        if (!hasOverlaySelected || skip) {
            return
        }
        // .setOverlay() can handle it if we call it again before the previous boundaries have been loaded
        // if (boundariesLoadingRef.current) {
        //     console.log('Switching between overlays while loading')
        // }

        dispatch(setOverlayLoading(true))
        boundariesLoadingRef.current = true

        geoJSONOverlayRef.current
            .setOverlay({
                country,
                level,
                overlay,
                dataCacheKey: key,
                colourmap: overlay,
                mapDataLayer: mapDataLayerRef.current,
            })
            .then(({ cachedOverlayData, refreshStyle }) => {
                const overlayOpacity = new URLSearchParams(
                    document.location.search,
                ).get('overlay-opacity')
                if (overlayOpacity) {
                    geoJSONOverlayRef.current.setWithinFeature({
                        areas,
                        level,
                    })
                }

                const done = () => {
                    refreshStyle()
                    boundariesLoadingRef.current = false
                    cachedOverlayDataRef.current = cachedOverlayData
                    dispatch(setOverlayLoading(false))
                }
                // If we already have the data just apply it to the boundaries
                if (key in cachedOverlayData) {
                    done()
                    return
                }

                // Load the data otherwise
                cachedOverlayData[key] = {}
                const cache = cachedOverlayData[key]
                const url = `${GEOSITE_URL}/${overlay}/`
                const params = { country, level, year }
                const cancelPrevious = 'overlay'

                if (overlay === 'deprivation') {
                    get({
                        url,
                        params: { ...params, slug: value },
                        cancelPrevious,
                    })
                        .then(
                            ({ data: { data }, request: { responseURL } }) => {
                                throwIfNoData(data, responseURL)

                                data.forEach((e) => {
                                    const p = cache[e.code] || {}
                                    p.rank = e.rank
                                    p.percentile = e.percentile
                                    cache[e.code] = p
                                })

                                setBrackets(cache, data, 'rank', 5)

                                done()
                            },
                        )
                        .catch(ignoreCancelled)
                }

                if (overlay === 'population') {
                    get({
                        url,
                        params: {
                            ...params,
                            from_age: value.from,
                            to_age: value.to,
                        },
                        cancelPrevious,
                    })
                        .then(
                            ({ data: { data }, request: { responseURL } }) => {
                                throwIfNoData(data, responseURL)

                                data.forEach((e) => {
                                    const p = cache[e.code] || {}
                                    p.byAgeSum = e.byAgeSum
                                    if (e.densityByAgeSum == null) {
                                        return
                                    }
                                    const d = Math.floor(
                                        e.densityByAgeSum * 1.60934 * 1.60934,
                                    )
                                    e.densityByAgeSum = d
                                    p.densityByAgeSum = d
                                    cache[e.code] = p
                                })

                                setBrackets(cache, data, 'densityByAgeSum', 5)
                                // unlike the IMD data we don't have percentiles pre-calculated for population
                                data.forEach((e, i) => {
                                    const d = (100 * i) / data.length
                                    cache[
                                        e.code
                                    ].densityPercentile = d.toFixed()
                                })

                                done()
                            },
                        )
                        .catch(ignoreCancelled)
                }

                if (overlay === 'activelives') {
                    get({
                        url,
                        params: { ...params, slug: value },
                        cancelPrevious,
                    })
                        .then(
                            ({ data: { data }, request: { responseURL } }) => {
                                throwIfNoData(data, responseURL)

                                data.forEach((e) => {
                                    const p = cache[e.code] || {}
                                    p.percentage = e.percentage
                                    cache[e.code] = p
                                })

                                // Set quintiles by actual percentage value, not rank.
                                const brackets = 5

                                // Scale it between the minimum and maximum values (instead of 0 and 100):
                                data.sort((a, b) => a.percentage - b.percentage)
                                const values = Object.values(cache)
                                const lower = values[0].percentage
                                const upper =
                                    values[values.length - 1].percentage
                                const range = upper - lower
                                for (let i = 0; i < values.length; i++) {
                                    const value = values[i]
                                    const scaled =
                                        (value.percentage - lower) *
                                        (100 / range)
                                    value.quintile_0 = Math.min(
                                        brackets - 1, // the highest value would exceed would be just about in the 6th quintile
                                        Math.floor((scaled / 100) * brackets),
                                    )
                                }

                                done()
                            },
                        )
                        .catch(ignoreCancelled)
                }

                if (overlay === 'nssec') {
                    get({
                        url,
                        params: { ...params, slug: value },
                        cancelPrevious,
                    })
                        .then(
                            ({ data: { data }, request: { responseURL } }) => {
                                throwIfNoData(data, responseURL)

                                data.forEach((e) => {
                                    const p = cache[e.code] || {}
                                    p.percentage = e.percentage
                                    cache[e.code] = p
                                })

                                // Assign each object to brackets via the quintile_0 property.
                                // NB we're not actually using quitlies for this but hard coded brackets.
                                const values = Object.values(cache)
                                const brackets = Object.keys(
                                    OVERLAY_NSSEC_OFFSETS,
                                ).map(Number)
                                for (let i = 0; i < values.length; i++) {
                                    const v = values[i]
                                    brackets.reduce((a, c) => {
                                        if (
                                            v.percentage > a &&
                                            v.percentage <= c
                                        ) {
                                            v.quintile_0 = brackets.indexOf(a)
                                        }
                                        return c
                                    })
                                }
                                done()
                            },
                        )
                        .catch(ignoreCancelled)
                }
            })
    }, [
        hasOverlaySelected,
        skip,
        overlay,
        key,
        country,
        level,
        value,
        year,
        dispatch,
        mapObj,
        areas,
    ])
}
