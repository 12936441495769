import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import { UiContext } from '../../context/UiContext'

import InputCheckbox from '../InputCheckbox/InputCheckbox'
import InputSelect from '../InputSelect/InputSelect'
import CheckIcon from '../Icons/Check'
import css from './BoundariesSelector.module.scss'
import { BOUNDARY_OPTIONS } from '../../consts/consts'
import {
    setBoundariesLevel,
    setBoundariesShow,
} from '../../store/Boundaries/Boundaries'

const styles = classnames.bind(css)

const BoundariesSelector = ({ className }) => {
    const dispatch = useDispatch()
    const level = useSelector(({ boundaries: { level } }) => level)
    const show = useSelector(({ boundaries: { show } }) => show)
    const value = BOUNDARY_OPTIONS.find(({ value }) => value === level)
    const isSavedMap = useSelector(({ savedMap: { slug } }) => Boolean(slug))
    const { loggedIn } = useContext(UiContext)
    const disabled = !loggedIn && isSavedMap

    return (
        <div className={styles('checkbox-select', className)}>
            {show ? (
                <>
                    <button
                        type="button"
                        className={styles('checkbox-select__button')}
                        disabled={disabled}
                        onClick={() => {
                            dispatch(setBoundariesShow(false))
                        }}
                    >
                        <CheckIcon
                            tabIndex="0"
                            className={styles('checkbox-select__button-icon')}
                        />
                    </button>
                    <InputSelect
                        value={value}
                        options={BOUNDARY_OPTIONS}
                        className={styles('checkbox-select__select')}
                        isDisabled={disabled}
                        handleChange={({ value }) => {
                            dispatch(setBoundariesLevel(value))
                        }}
                        slim
                    />
                </>
            ) : (
                <InputCheckbox
                    id="area-boundaries"
                    name="area-boundaries"
                    label="Area Boundaries"
                    checked={show}
                    className={styles('checkbox-select__checkbox')}
                    disabled={disabled}
                    handleChange={(e) => {
                        dispatch(setBoundariesShow(e.target.checked))
                    }}
                    isTick
                />
            )}
        </div>
    )
}

BoundariesSelector.defaultProps = {
    className: null,
}

BoundariesSelector.propTypes = {
    className: PropTypes.string,
}

export default BoundariesSelector
