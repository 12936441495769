import { createAxiosClient, get } from '../Services'
import { urls } from '../../urls'

export const client = createAxiosClient()

export const getSite = (siteId) => {
    const url = urls.api.site_info(siteId)

    return get({
        url,
        client,
    }).then(({ data }) => data)
}
