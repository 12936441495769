import { createSlice } from '@reduxjs/toolkit'
import { fetchSites } from '../Sites/Sites'
import { queryStringToObject } from '../../utils/queryString'

const queryObject = queryStringToObject(window.location.search)

export const initialMapState = {
    // Bounds in redux are updated whenever the map zoom/position is changed.
    // They are then read from `sites/fetchSites` and passed to the filter_sites api.
    // The initial map position is determined by the `location` slice instead,
    // however the initial bounds here should correspond to `location.lat/lng` too.
    northEast: {
        lat: 59.02,
        lng: 4.96,
    },
    southWest: {
        lat: 46.69,
        lng: -14.12,
    },
    zoom: queryObject?.zoom ? parseInt(queryObject.zoom, 10) : 6,
}

const mapSlice = createSlice({
    name: 'map',
    initialState: initialMapState,
    reducers: {
        resetMapState: () => initialMapState,
        replaceMapState: (state, { payload }) => payload,
    },
})

const { actions, reducer } = mapSlice

export const { resetMapState, replaceMapState } = actions

/**
 * Updates the map bounds in state,
 * then dispatches a fetch action
 */
export const applyMapState = (bounds) => (dispatch) => {
    dispatch(actions.replaceMapState(bounds))

    dispatch(fetchSites())
}

export default reducer
