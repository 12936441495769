import React, { Component } from 'react'
import classnames from 'classnames/bind'

import Logo from '../Icons/Logo'
import Heading from '../Heading/Heading'
import css from './ErrorBoundary.module.scss'

const styles = classnames.bind(css)

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: props.hasError, fullPage: props.fullPage }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        if (window.Sentry) {
            Sentry.captureException(error, { extra: errorInfo })
        }
    }

    render() {
        if (this.state.hasError) {
            if (this.state.fullPage) {
                return (
                    <div className={styles('error-boundry')}>
                        <Logo fill="green" />
                        <Heading
                            text={
                                this.props.message ||
                                "It looks like something went wrong. We've been notified and will fix this soon."
                            }
                        />
                        <a href={window.location.href}>Click here to reload</a>
                    </div>
                )
            }

            return <p>{this.props.message}</p>
        }

        return this.props.children
    }
}

export default ErrorBoundary
