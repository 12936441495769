import { useEffect, useRef } from 'react'
import { createOverlayTooltip } from './utils'

/**
 * Functions "ported" from legacy PitchFinder
 */

function DrawingManager() {
    return new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_LEFT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
            strokeWeight: 1,
            editable: true,
            draggable: true,
        },
        polylineOptions: {
            strokeWeight: 1,
            editable: true,
            draggable: true,
        },
    })
}

const clearArray = (array) => {
    while (array.length) {
        array.pop()
    }
}

/**
 * Gets the measurements of the polygon and
 * returns the html to render in the tooltip
 */

const getPolygonMeasurements = (polygon, mapLabels, map) => {
    // remove existing map labels for measurments
    for (let a = 0, b = mapLabels.length; a < b; a += 1) {
        mapLabels[a].setMap(null)
    }

    const path = polygon.getPath()

    const vertices = []

    path.forEach((p) => {
        vertices.push(p)
    })

    // create a list of coordinate pairs
    const edges = []

    for (let j, k = 0, l = vertices.length; k < l; k += 1) {
        j = k + 1
        if (j === l) {
            j = 0
        }
        edges.push([vertices[k], vertices[j]])
    }

    // add side length to each edge
    let perimeter = 0

    for (let x = 0, y = edges.length; x < y; x += 1) {
        const p1 = edges[x][0]
        const p2 = edges[x][1]
        const midpoint = new google.maps.LatLng(
            (p1.lat() + p2.lat()) / 2,
            (p1.lng() + p2.lng()) / 2,
        )
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
            p1,
            p2,
        )
        perimeter += distance

        const mapLabel = new google.maps.Marker({
            label: `${(distance / 1000).toFixed(3)} km`,
            position: midpoint,
            map,
            fontSize: 14,
            align: 'left',
            icon: '/',
        })

        mapLabels.push(mapLabel)
    }

    // add area and perimeter with a tooltip
    const area = google.maps.geometry.spherical.computeArea(path)

    const text = `
        Perimeter: ${(perimeter / 1000).toFixed(3)} km <br>
        Area: ${Math.round(area)}m<sup>2</sup><br>
        <span style="padding-left: 39px;">
            ${Math.round(area * 10.7639104)}ft<sup>2</sup>
        </span><br>
        <span style="padding-left: 39px;">${(area * 0.0001).toFixed(
            2,
        )} ha</span>
    `

    return text
}

/**
 * Adds a clear drawings button to the map controls
 */

const addClearDrawingButton = (mapObj, allShapes, allMapLabels, drawingObj) => {
    const button = document.createElement('div')

    button.innerHTML = `
        <div class="map-control remove-polygons">
            <div draggable="false" title="Remove polygons">
                <span>
                    <div>x</div>
                </span>
            </div>
        </div>`

    button.addEventListener('click', () => {
        allShapes.forEach((shape) => {
            shape.setMap(null)
        })

        clearArray(allShapes)

        allMapLabels.forEach((label) => {
            label.forEach((l) => {
                l.setMap(null)
            })

            clearArray(label)
        })

        clearArray(allMapLabels)

        drawingObj.setDrawingMode(null)
    })

    mapObj.controls[google.maps.ControlPosition.TOP_LEFT].push(button)

    return button
}

/**
 * Adds event listeners for:
 * Drawing complete and when a polygon has been updated
 */

const addDrawingEventListeners = (
    allShapes,
    allMapLabels,
    mapObj,
    drawingObj,
    tooltip,
    autoZoom,
) => {
    google.maps.event.addListener(drawingObj, 'overlaycomplete', (event) => {
        const mapLabels = []

        let measurementsText = ''

        allMapLabels.push(mapLabels)

        allShapes.push(event.overlay)

        if (event.overlay instanceof google.maps.Polygon) {
            const polygon = event.overlay
            const path = polygon.getPath()

            autoZoom({ drawing: polygon })

            measurementsText = getPolygonMeasurements(
                polygon,
                mapLabels,
                mapObj,
                tooltip,
            )

            google.maps.event.addListener(path, 'insert_at', () => {
                measurementsText = getPolygonMeasurements(
                    polygon,
                    mapLabels,
                    mapObj,
                    tooltip,
                )
            })

            google.maps.event.addListener(path, 'set_at', () => {
                measurementsText = getPolygonMeasurements(
                    polygon,
                    mapLabels,
                    mapObj,
                    tooltip,
                )
            })

            google.maps.event.addListener(polygon, 'mouseover', () => {
                tooltip.show(measurementsText)
            })

            google.maps.event.addListener(polygon, 'mouseout', () => {
                tooltip.hide()
            })

            drawingObj.setDrawingMode(null)
        }
    })
}

/**
 * Combines the drawing tools
 */

const addDrawingTools = (mapEl, mapObj, autoZoom) => {
    const drawingObj = DrawingManager()
    drawingObj.setMap(mapObj)

    // Hold all labels and shapes so they can be removed
    const allMapLabels = []
    const allShapes = []

    const tooltip = createOverlayTooltip(mapEl)

    const button = addClearDrawingButton(
        mapObj,
        allShapes,
        allMapLabels,
        drawingObj,
    )

    addDrawingEventListeners(
        allShapes,
        allMapLabels,
        mapObj,
        drawingObj,
        tooltip,
        autoZoom,
    )

    return { button, drawingObj, tooltip }
}

// TODO: saved maps!
export const useDrawingTools = ({
    hasMap,
    mapObj,
    autoZoom,
    disabled,
    show,
}) => {
    const buttonRef = useRef()
    const drawingObjRef = useRef()
    const showRef = useRef(show)

    // initialise
    useEffect(() => {
        if (hasMap && !disabled && !window.siteSelector && !window.screenshot) {
            const { button, drawingObj, tooltip } = addDrawingTools(
                mapObj.current.getDiv(),
                mapObj.current,
                autoZoom,
            )
            buttonRef.current = button
            drawingObjRef.current = drawingObj

            // we need to show/hide controls here too, not just in the second useEffect
            button.style.display = showRef.current ? 'block' : 'none'
            drawingObjRef.current.setOptions({
                drawingControl: showRef.current,
            })
            return () => {
                tooltip.remove()
                button.remove()
                // TODO: remove everything else
            }
        }
        return undefined
    }, [hasMap, mapObj, autoZoom, disabled])

    // show/hide controls
    useEffect(() => {
        showRef.current = show
        if (buttonRef.current && drawingObjRef.current) {
            buttonRef.current.style.display = show ? 'block' : 'none'
            drawingObjRef.current.setOptions({ drawingControl: show })
        }
    }, [show])
}
