import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames/bind'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import { applyOption } from '../../store/MapOptions/MapOptions'

import css from './GlobalSiteOptions.module.scss'

const styles = classnames.bind(css)

const GlobalSiteOptions = ({ className, disabled, isTablet }) => {
    const dispatch = useDispatch()
    const { nonOperational, nonFootball, hideAll } = useSelector(
        ({ mapOptions }) => mapOptions,
        shallowEqual,
    )
    if (window.siteSelector || window.screenshot || isTablet) {
        return null
    }

    return (
        <div className={styles('global-site-options', className)}>
            <InputCheckbox
                id="non-operational"
                name="non-operational"
                label="Non-operational sites"
                checked={nonOperational}
                disabled={disabled}
                handleChange={(e) => {
                    dispatch(applyOption({ nonOperational: e.target.checked }))
                }}
                isTick
            />
            <InputCheckbox
                id="non-football"
                name="non-football"
                label="Non-football sites"
                checked={nonFootball}
                disabled={disabled}
                handleChange={(e) => {
                    dispatch(applyOption({ nonFootball: e.target.checked }))
                }}
                isTick
            />
            <InputCheckbox
                id="hide-all"
                name="hide-all"
                label="Hide all sites"
                checked={hideAll}
                disabled={disabled}
                handleChange={(e) => {
                    dispatch(applyOption({ hideAll: e.target.checked }))
                }}
                isTick
            />
        </div>
    )
}

GlobalSiteOptions.defaultProps = {
    className: null,
    disabled: false,
}

GlobalSiteOptions.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

export default GlobalSiteOptions
