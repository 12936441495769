import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './InputText.module.scss'

const styles = classnames.bind(css)

const InputText = ({
    type,
    value,
    placeholder,
    handleChange,
    onBlur,
    className,
    helpText,
    ...rest
}) => (
    <>
        <input
            className={styles('input-text', className)}
            type={type}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            {...rest}
        />
        {helpText && (
            <span className={styles('input-text__help')}>{helpText}</span>
        )}
    </>
)

InputText.defaultProps = {
    type: 'text',
}

InputText.propTypes = {
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    helpText: PropTypes.string,
}

export default InputText
