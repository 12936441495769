import React from 'react'
import PropTypes from 'prop-types'

const No = ({ fill, ...rest }) => (
    <svg width="16" height="16" viewBox="0,0,16,16" {...rest}>
        <path
            d="M8 .5C3.86.5.5 3.86.5 8c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5C15.5 3.86 12.14.5 8 .5zM8 14c-3.315 0-6-2.685-6-6 0-1.388.473-2.662 1.268-3.675l8.407 8.407A5.927 5.927 0 0 1 8 14zm4.732-2.325L4.325 3.267A5.927 5.927 0 0 1 8 2c3.315 0 6 2.685 6 6a5.927 5.927 0 0 1-1.268 3.675z"
            fill={fill}
        />
    </svg>
)

No.defaultProps = {
    fill: null,
}

No.propTypes = {
    fill: PropTypes.string,
}

export default No
