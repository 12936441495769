import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import NoIcon from '../Icons/No'
import FFIcon from '../Icons/FF'
import FacilityCount from '../FacilityCount/FacilityCount'
import SiteCardIcon from '../SiteCardIcon/SiteCardIcon'

import css from './SiteCard.module.scss'

const styles = classnames.bind(css)

const SiteCard = ({
    site,
    darkTheme,
    loggedIn,
    className,
    handleSetActive,
    ...rest
}) => {
    const {
        id,
        siteName,
        address,
        distance,
        ffFunded,
        nonOperational,
        hasChangingRooms,
        hasParking,
        isPubliclyBookable,
        numberGrassPitches,
        numberArtificialPitches,
        numberIndoorPitches,
        numberFloodlitPitches,
        playersLastSeason,
        teamsLastSeason,
    } = site
    return (
        <div
            className={styles(
                'site-card',
                {
                    'site-card--non-operational': nonOperational,
                    'site-card--dark-theme': darkTheme,
                    'site-card--clickable': rest.onClick,
                },
                className,
            )}
            {...rest}
        >
            <div className={styles('site-card__header')}>
                {typeof address === 'string' && ( // `address` can be an object (site popup) or a string (saved map entry)
                    <span className={styles('site-card__address')}>
                        {address} {distance ? `- ${distance} mile radius` : ''}
                    </span>
                )}
                {nonOperational && (
                    <div className={styles('site-card__icon')}>
                        <span
                            id={`${id}-operatonal-label`}
                            className="visually-hidden"
                        >
                            This site is non-operational
                        </span>
                        <NoIcon
                            className={styles('site-card__icon')}
                            aria-labelledby={`${id}-operatonal-label`}
                        />
                    </div>
                )}
                <h3
                    className={styles('site-card__name', {
                        'site-card--non-operational': nonOperational,
                    })}
                >
                    {siteName}
                </h3>
            </div>

            <div className={styles('site-card__details')}>
                <div className={styles('site-card__facilites')}>
                    {numberGrassPitches > 0 && (
                        <FacilityCount
                            number={numberGrassPitches}
                            name="Grass"
                            darkTheme={darkTheme}
                        />
                    )}
                    {numberArtificialPitches > 0 && (
                        <FacilityCount
                            number={numberArtificialPitches}
                            name="Artificial"
                            darkTheme={darkTheme}
                        />
                    )}
                    {numberIndoorPitches > 0 && (
                        <FacilityCount
                            number={numberIndoorPitches}
                            name="Indoor"
                            darkTheme={darkTheme}
                        />
                    )}
                    {numberFloodlitPitches > 0 && (
                        <FacilityCount
                            number={numberFloodlitPitches}
                            name="Floodlight"
                            darkTheme={darkTheme}
                        />
                    )}
                </div>
                <div className={styles('site-card__facilites')}>
                    <SiteCardIcon
                        name="Changing rooms"
                        value={!!hasChangingRooms} // nullable
                        darkTheme={darkTheme}
                    />
                    <SiteCardIcon
                        name="Parking"
                        value={hasParking}
                        darkTheme={darkTheme}
                    />
                    <SiteCardIcon
                        name="Public bookings"
                        value={isPubliclyBookable}
                        darkTheme={darkTheme}
                    />
                </div>
                {loggedIn && (
                    <div
                        className={styles('site-card__stats', {
                            'site-card__stats--dark-theme': darkTheme,
                        })}
                    >
                        {`${teamsLastSeason} FA teams, ${playersLastSeason} players`}
                    </div>
                )}
                {ffFunded && (
                    <div
                        className={styles('site-card__funded')}
                        title="Football Foundation funded"
                    >
                        <FFIcon className={styles('site-card__funded_logo')} />
                        <span className={styles('site-card__funded_label')}>
                            Funded by
                            <br />
                            The Football Foundation
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

SiteCard.defaultProps = {
    darkTheme: false,
    handleSetActive: () => {},
    className: null,
}

SiteCard.propTypes = {
    site: PropTypes.shape({
        id: PropTypes.number.isRequired,
        siteName: PropTypes.string.isRequired,
        ffFunded: PropTypes.bool,
        nonOperational: PropTypes.bool,
        hasChangingRooms: PropTypes.bool,
        hasParking: PropTypes.bool,
        isPubliclyBookable: PropTypes.bool,
        numberGrassPitches: PropTypes.number,
        numberArtificialPitches: PropTypes.number,
        numberIndoorPitches: PropTypes.number,
        numberFloodlitPitches: PropTypes.number,
        playersLastSeason: PropTypes.number,
        teamsLastSeason: PropTypes.number,
    }).isRequired,
    darkTheme: PropTypes.bool,
    handleSetActive: PropTypes.func,
    className: PropTypes.string,
}

export default SiteCard
