import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames/bind'

import { ACTIVE_MODAL_SHARE_MAP } from '../../consts/consts'

import { UiContext } from '../../context/UiContext'
import Modal, { useModal } from '../Modal/Modal'
import Close from '../Close/Close'
import FormStep from '../FormStep/FormStep'
import Heading from '../Heading/Heading'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'

import css from './ShareMapModal.module.scss'

const styles = classnames.bind(css)

const ShareMapModal = () => {
    const { activeModal, closeModal } = useModal()

    const url = window.location.href

    const hasOverlay = useSelector(({ overlay }) => Boolean(overlay?.level))
    const { loggedIn } = useContext(UiContext)
    if (activeModal === ACTIVE_MODAL_SHARE_MAP) {
        return (
            <Modal titleText="Share map">
                <FormStep className={styles('share-map')}>
                    <div className="visually-hidden" role="status">
                        Share map
                    </div>
                    <Heading heading="h2" text="Share this map" />
                    <p className={styles('share_map__description')}>
                        Anyone with this link will be able to see your map.
                        {hasOverlay && !loggedIn ? (
                            <p>(The overlay won&apos;t be shown by default)</p>
                        ) : (
                            ''
                        )}
                    </p>
                    <div className={styles('share-map__row')}>
                        <div>
                            <Heading
                                heading="h4"
                                text="Link to share"
                                isSubHeading
                            />

                            <CopyToClipboard text={url} />
                        </div>
                    </div>
                </FormStep>
                <Close handleClick={closeModal} />
            </Modal>
        )
    }
    return null
}

export default ShareMapModal
