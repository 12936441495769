import React from 'react'
import PropTypes from 'prop-types'

const Circle = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest}>
        <circle cx="12" cy="12" r="12" fill={fill} />
    </svg>
)

Circle.defaultProps = {
    fill: null,
}

Circle.propTypes = {
    fill: PropTypes.string,
}

export default Circle
