import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import classnames from 'classnames/bind'
import css from './InputSearch.module.scss'
import { defaultStyles } from '../InputSelect/reactSelectStyles'

import SearchIcon from '../Icons/Search'

const styles = classnames.bind(css)

// Custom Dropdown Indicator
const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator
                {...props}
                className={styles('input-select__search')}
            >
                <SearchIcon />
            </components.DropdownIndicator>
        )
    )
}

const InputSearch = ({ value, options, handleChange, className, ...rest }) => {
    return (
        <div className={styles('input-select', className)}>
            <Select
                options={options}
                components={{ DropdownIndicator }}
                styles={defaultStyles()}
                onChange={handleChange}
                {...rest}
            />
        </div>
    )
}

InputSearch.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default InputSearch
