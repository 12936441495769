/* eslint func-names: ["error", "never"] */
/**
 * Custom Popup
 * https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
 */

import './createPopupStyles.scss'

export const createPopupClass = () => {
    /**
     * A customized popup on the map.
     * @param {!google.maps.LatLng} position
     * @param {!Element} content The bubble div.
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    function Popup(position, content) {
        this.position = position

        // The element that is positioned by location
        this.containerDiv = document.createElement('div')
        this.containerDiv.classList.add('map-popup')

        // Wrapper for the content
        const popupInner = document.createElement('div')
        popupInner.classList.add('map-popup__inner')

        content.classList.add('map-popup__content')

        popupInner.appendChild(content)

        this.containerDiv.appendChild(popupInner)

        // Optionally stop clicks, etc., from bubbling up to the map.
        window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(
            this.containerDiv,
        )
    }
    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(window.google.maps.OverlayView.prototype)

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function() {
        this.getPanes().floatPane.appendChild(this.containerDiv)
    }

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function() {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv)
        }
    }

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
            this.position,
        )

        // Hide the popup when it is far out of view.
        const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                ? 'block'
                : 'none'

        if (display === 'block') {
            this.containerDiv.style.left = `${divPosition.x}px`
            this.containerDiv.style.top = `${divPosition.y}px`
        }
        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display
        }
    }

    return Popup
}
