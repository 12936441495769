import React from 'react'
import PropTypes from 'prop-types'

const Search = ({ fill, ...rest }) => (
    <svg
        width="18"
        height="19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <g clipPath="url(#clip0)">
            <path
                fillRule="evenodd"
                d="M12.5 11.5929h-.79l-.28-.27c.98-1.14 1.57-2.62004 1.57-4.23004 0-3.58999-2.91-6.5-6.5-6.5S0 3.50287 0 7.09286c0 3.59004 2.91 6.50004 6.5 6.50004 1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01004-4.5-4.50004 0-2.48999 2.01-4.5 4.5-4.5s4.5 2.01001 4.5 4.5c0 2.49-2.01 4.50004-4.5 4.50004z"
                fill="#1C1C1C"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <path fill="#fff" d="M0 0h18v19H0z" />
            </clipPath>
        </defs>
    </svg>
)

Search.defaultProps = {
    fill: null,
}

Search.propTypes = {
    fill: PropTypes.string,
}

export default Search
