import React from 'react'
import PropTypes from 'prop-types'

import CategoryList from '../CategoryList/CategoryList'
import ListCta from '../ListCta/ListCta'
import RemoveButton from '../RemoveButton/RemoveButton'

const ActiveFilters = ({ activeFilters, handleSetStep, unapplyFilter }) => (
    <CategoryList>
        {activeFilters.map((filter) => (
            <React.Fragment key={filter.id}>
                <ListCta
                    text={filter.label}
                    handleClick={() => {
                        handleSetStep(filter)
                    }}
                />
                <RemoveButton
                    text={`Remove ${filter.label}`}
                    handleClick={() => {
                        unapplyFilter(filter.id)
                    }}
                />
            </React.Fragment>
        ))}
    </CategoryList>
)

ActiveFilters.propTypes = {
    activeFilters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            optgroup: PropTypes.string.isRequired,
        }),
    ),
    handleSetStep: PropTypes.func.isRequired,
    unapplyFilter: PropTypes.func.isRequired,
}

export default ActiveFilters
