import React from 'react'
import PropTypes from 'prop-types'

const MatchThin = ({ fill, ...rest }) => (
    <svg
        width="60"
        height="60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M60 8.18182V51.8182H0V8.18182h60zM58.1818 10H1.81818v40H58.1818V10z"
            fill="#009A3A"
        />
        <path
            d="M31.8374 8.18182V51.8182h-1.8182V8.18182h1.8182zM60 16.3636v27.2728H43.6364V16.3636H60zm-1.8182 1.8182H45.4545v23.6364h12.7273V18.1818zM16.3636 16.3636v27.2728H0V16.3636h16.3636zm-1.8181 1.8182H1.81818v23.6364H14.5455V18.1818z"
            fill={fill}
        />
        <path
            d="M60 23.6364v12.7273h-8.1818V23.6364H60zm-1.8182 1.8182h-4.5454v9.0909h4.5454v-9.0909zM8.18182 23.6364v12.7273H0V23.6364h8.18182zm-1.81818 1.8182H1.81818v9.0909h4.54546v-9.0909zM30.9091 22.7273c4.0166 0 7.2727 3.2561 7.2727 7.2727s-3.2561 7.2727-7.2727 7.2727S23.6364 34.0166 23.6364 30s3.2561-7.2727 7.2727-7.2727zm0 1.8181c-3.0125 0-5.4546 2.4421-5.4546 5.4546s2.4421 5.4545 5.4546 5.4545 5.4545-2.442 5.4545-5.4545-2.442-5.4546-5.4545-5.4546z"
            fill={fill}
        />
    </svg>
)

MatchThin.defaultProps = {
    fill: '#009A3A',
}

MatchThin.propTypes = {
    fill: PropTypes.string,
}

export default MatchThin
