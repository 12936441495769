import React, { Children } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './CategoryList.module.scss'

const styles = classnames.bind(css)

const CategoryList = ({ columns, children }) => (
    <ul
        className={styles('category-list', {
            'category-list--columns': columns,
        })}
    >
        {Children.map(children, (child) => (
            <li className={styles('category-list__item')}>{child}</li>
        ))}
    </ul>
)

CategoryList.propTypes = {
    children: PropTypes.node.isRequired,
}

export default CategoryList
