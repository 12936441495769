import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { objectToQueryString } from '../../utils/queryString'
import { UiContext } from '../../context/UiContext'

import QuickFilters from '../QuickFilters/QuickFilters'

import {
    applyFilter,
    unapplyFilter,
    updateGroup,
} from '../../store/Filters/Filters'

import { QUICK_FILTER_GROUP_ID } from '../../consts/consts'

// TODO: rename because this is for quick filters only
const SidebarFilterSelector = () => {
    const { loggedIn } = useContext(UiContext)

    const dispatch = useDispatch()

    const activeFilterGroupId = QUICK_FILTER_GROUP_ID

    const { type, value, label, radius, area } = useSelector(
        ({ location }) => location,
        shallowEqual,
    )

    const group = useSelector(({ filters }) => filters[activeFilterGroupId])

    const rulesArray = group?.rules || []

    // Get an array of active quick filter IDs to pass to quick filters
    // for checkbox value comparison
    const activeQuickFilterIds = rulesArray
        .filter(({ quick }) => quick)
        .map(({ id }) => id)

    useEffect(() => {
        const rulesForQueryString = Object.fromEntries(
            rulesArray.map(({ id }) => [id, 'on']),
        )

        const objectForQueryString = {
            ...(label &&
                type !== 'area' &&
                area?.label !== label && { query: label }),
            ...((area || type === 'area') && { code: area?.value || value }),
            ...(radius && { radius }),
            ...rulesForQueryString,
        }

        // When anonymous users select an LA from the autocomplete
        if (Array.isArray(objectForQueryString.code)) {
            objectForQueryString.code = objectForQueryString.code[0].value
        }

        if (!window.screenshot) {
            // TODO: view saved map logged out -> home -> map -> select quick filters -> back -> back ->
            // saved map will have the quick filters in the url
            if (Object.keys(objectForQueryString).length && !loggedIn) {
                // We don't add quick filter to the url for logged in users.
                // They are supposed to use the saved maps feature instead.
                window.history.replaceState(
                    {},
                    'Map',
                    objectToQueryString(objectForQueryString),
                )
            } else {
                window.history.replaceState(
                    {},
                    'Map',
                    window.location.href.split('?')[0],
                )
            }
        }
    }, [rulesArray, type, value, label, radius, area, loggedIn])

    return (
        <QuickFilters
            activeFilterIds={activeQuickFilterIds}
            applyQuickFilter={({ id }) => {
                if (!group) {
                    dispatch(
                        updateGroup({
                            filterGroupId: activeFilterGroupId,
                            rules: [],
                        }),
                    )
                }

                dispatch(
                    applyFilter({
                        id,
                        filterGroupId: activeFilterGroupId,
                        quick: true,
                    }),
                )
            }}
            unapplyQuickFilter={({ id }) => {
                dispatch(
                    unapplyFilter({
                        id,
                        filterGroupId: activeFilterGroupId,
                    }),
                )
            }}
            listView
        />
    )
}

export default SidebarFilterSelector
