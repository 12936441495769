import React from 'react'
import PropTypes from 'prop-types'

const Check = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest}>
        <path
            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
            fill={fill}
        />
    </svg>
)

Check.defaultProps = {
    fill: null,
}

Check.propTypes = {
    fill: PropTypes.string,
}

export default Check
