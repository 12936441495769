import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import CircleIcon from '../Icons/Circle'
import EditButton from '../EditButton/EditButton'
import { FILTER_GROUP_ICONS_TO_COLOURS } from '../../consts/consts'

import css from './FilterGroupEditButton.module.scss'

const styles = classnames.bind(css)

const FilterGroupEditButton = ({
    filterGroupLabel,
    filterGroupColor,
    handleClick,
}) => (
    <div className={styles('filter-group-edit')}>
        <span className={styles('filter-group-edit__label')}>
            {filterGroupLabel}
        </span>
        {filterGroupColor && (
            <CircleIcon
                fill={
                    FILTER_GROUP_ICONS_TO_COLOURS[filterGroupColor] ||
                    filterGroupColor
                }
                className={styles('filter-group-edit__circle')}
            />
        )}
        <EditButton
            title="Edit filter group name and colour"
            handleClick={handleClick}
        />
    </div>
)

FilterGroupEditButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
    filterGroupLabel: PropTypes.string.isRequired,
    filterGroupColor: PropTypes.string,
}

export default FilterGroupEditButton
