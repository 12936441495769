import React, { useRef, useState } from 'react'

import classnames from 'classnames/bind'

import CopyIcon from '../Icons/Copy'

import css from './CopyToClipboard.module.scss'

const styles = classnames.bind(css)

const CopyToClipboard = ({ text }) => {
    const [copySuccess, setCopySuccess] = useState(false)
    const textAreaRef = useRef(null)

    const copyToClipboard = (e) => {
        textAreaRef.current.select()
        document.execCommand('copy')
        e.target.focus()
        setCopySuccess(true)
    }

    return (
        <div className={styles('copy-to-clipboard')}>
            <input
                ref={textAreaRef}
                value={text}
                className={styles('copy-to-clipboard__input-text')}
                readOnly
            />

            {/* If browser supports copy command, display button */}
            {document.queryCommandSupported('copy') && (
                <div className={styles('copy-to-clipboard__button-container')}>
                    <button
                        type="button"
                        aria-label={
                            copySuccess
                                ? 'Copied to clipboard'
                                : 'Copy shareable link to clipboard'
                        }
                        onClick={copyToClipboard}
                        className={styles('copy-to-clipboard__button')}
                    >
                        <div
                            className={styles('copy-to-clipboard__button-text')}
                        >
                            {copySuccess ? 'Copied' : 'Copy'}
                        </div>
                        <div
                            className={styles('copy-to-clipboard__button-icon')}
                        >
                            <CopyIcon />
                        </div>
                    </button>
                </div>
            )}
        </div>
    )
}

export default CopyToClipboard
