import React from 'react'

const Origami = (props) => (
    <svg viewBox="0 0 129 104" {...props}>
        <g transform="translate(-6.000000, 0.000000)">
            <polygon fill="#007141" points="135 0 38 0 6 81 87 41 135 21" />
            <polygon fill="#0FA149" points="79 0 98 97 135 104 135 0" />
            <polygon fill="#5AB947" points="34 10 6 81 87 41" />
        </g>
    </svg>
)

export default Origami
