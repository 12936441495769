/* eslint-disable no-console */
import { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GeoJSONOverlay } from '../../utils/geojsonTiles'
import { setOgsLoading } from '../../store/OpenGreenSpaces/OpenGreenSpaces'
import { createOverlayTooltip } from './utils'
import { DATA_LAYER_Z_INDEX } from '../../consts/consts'
import { GEOSITE_URL } from '../../urls'

const zIndex = DATA_LAYER_Z_INDEX.indexOf('ogs')

const country = 'england'

export const useOgs = ({ hasMap, mapObj }) => {
    const dispatch = useDispatch()

    const show = useSelector(({ ogs }) => ogs?.show)

    // refs
    const geoJSONOverlayRef = useRef()
    const mapDataLayerRef = useRef()
    const ogsLoadingRef = useRef(false)

    // setup/teardown
    // NB add new functionality in a separate useEffect
    useEffect(() => {
        if (hasMap) {
            mapDataLayerRef.current = new google.maps.Data()
            mapDataLayerRef.current.setStyle({
                zIndex,
                fillOpacity: 0,
                strokeWeight: 1,
                strokeColor: '#333',
            })
            geoJSONOverlayRef.current = new GeoJSONOverlay(
                mapObj.current,
                GEOSITE_URL,
                zIndex,
            )
        } else if (geoJSONOverlayRef.current) {
            geoJSONOverlayRef.current.clearOverlay(mapDataLayerRef.current)
        }
    }, [mapObj, hasMap])

    // Add tooltips
    useEffect(() => {
        if (!hasMap) {
            return () => {}
        }
        const tooltip = createOverlayTooltip(mapObj.current.getDiv())

        const mouseoverListener = mapDataLayerRef.current.addListener(
            'mouseover',
            (event) => {
                const f = event.feature
                const fn = f.getProperty('function')
                const name = f.getProperty('name')
                const name2 = f.getProperty('name2')
                const name3 = f.getProperty('name3')
                const tooltipText = [name, name2, name3, `[${fn}]`]
                    .filter(Boolean)
                    .join('<br>')

                // Highlight feature
                mapDataLayerRef.current.revertStyle()
                mapDataLayerRef.current.overrideStyle(f, {
                    strokeWeight: 2,
                    strokeOpacity: 0.7,
                })

                tooltip.show(tooltipText)
            },
        )
        const mouseoutListener = mapDataLayerRef.current.addListener(
            'mouseout',
            () => {
                mapDataLayerRef.current.revertStyle()
                tooltip.hide()
            },
        )
        return () => {
            google.maps.event.removeListener(mouseoverListener)
            google.maps.event.removeListener(mouseoutListener)
            tooltip.remove()
        }
    }, [mapObj, hasMap, dispatch])

    // Load ogs boundaries when it's first enabled
    useEffect(() => {
        if (!hasMap || !show) {
            return
        }
        if (ogsLoadingRef.current) {
            // TODO
            console.warn('Switching between ogs while loading')
        }

        ogsLoadingRef.current = true
        dispatch(setOgsLoading(true))

        geoJSONOverlayRef.current
            .setOverlay({
                country,
                level: 'ogs',
                overlay: 'ogs',
                colourmap: 'ogs',
                mapDataLayer: mapDataLayerRef.current,
            })
            .then(({ refreshStyle }) => {
                refreshStyle()
                ogsLoadingRef.current = false
                dispatch(setOgsLoading(false))
            })
    }, [mapObj, hasMap, show, dispatch])

    // Toggle visibility
    useEffect(() => {
        if (hasMap) {
            mapDataLayerRef.current.setMap(show ? mapObj.current : null)
        }
    }, [mapObj, hasMap, show])
}
