import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './IconLabel.module.scss'

const styles = classnames.bind(css)

const IconLabel = ({ icon, label, className, ...rest }) => (
    <div className={styles('icon-label', className)} {...rest}>
        <div className={styles('icon-label__icon')}>{icon}</div>
        <span className={styles('icon-label__text')}>{label}</span>
    </div>
)

IconLabel.propTypes = {
    className: null,
}

IconLabel.propTypes = {
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default IconLabel
