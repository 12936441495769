import React from 'react'
import PropTypes from 'prop-types'

const Car = ({ fill, ...rest }) => (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M19 2l1.75 7H22v10h-1v3h-4v-3H5v3H1v-3H0V9h1.25L3 2h16zm-4 13H8v2h7v-2zM2 11v3h4v-2l-4-1zm18 0l-4 1v2h4v-3zm-2.4926758-7H4.50372314L3.251 9h15.5l-1.2436758-5z"
            fill={fill}
            fillRule="evenodd"
        />
    </svg>
)

Car.defaultProps = {
    fill: null,
}

Car.propTypes = {
    fill: PropTypes.string,
}

export default Car
