import React, { useContext, useState, useRef, useEffect } from 'react'
import classnames from 'classnames/bind'
import { animated, useSpring } from 'react-spring'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from '../../hooks/hooks'

import { UiContext } from '../../context/UiContext'
import {
    ACTIVE_MODAL_OVERLAY_SELECTOR,
    ACTIVE_MODAL_SAVE_MAP,
    ACTIVE_MODAL_RENAME_MAP,
    ACTIVE_MODAL_SHARE_MAP,
} from '../../consts/consts'
import { isInitialLocation } from '../../utils/utils'
import ResultsList from '../ResultsList/ResultsList'
import Tabs from '../Tabs/Tabs'
import SidebarFilterSelector from '../SidebarFilterSelector/SidebarFilterSelector'
import FilterGroups from '../FilterGroups/FilterGroups'
import Location from '../Location/Location'
import IconButton from '../IconButton/IconButton'
import Summary from '../Summary/Summary'
import Loading from '../Loading/Loading'
import BoundariesSelector from '../BoundariesSelector/BoundariesSelector'
import OpenGreenSpacesSelector from '../OpenGreenSpacesSelector/OpenGreenSpacesSelector'
import OverlayGroup from '../OverlayGroup/OverlayGroup'
import Heading from '../Heading/Heading'
import OverlayIcon from '../Icons/Overlay'
import MapIcon from '../Icons/Map'
import ListIcon from '../Icons/List'
import FilterIcon from '../Icons/Filter'
import StarIcon from '../Icons/Star'
import ShareIcon from '../Icons/Share'
import DownloadIcon from '../Icons/Download'

import { exportSites, fetchSitesSummary } from '../../store/Sites/Sites'

import css from './Sidebar.module.scss'

const styles = classnames.bind(css)

const SidebarSpringWrapper = ({ children }) => {
    const { showSidebar, isTablet, allowSidebar } = useContext(UiContext)

    // Translate would be more preferable, however
    // there is an issue in Chrome with scrolling not working
    // in the results list when using transition: translate()
    let animationStyles = {
        position: 'absolute',
        width: '380px',
        height: '100%',
        maxWidth: '100vw',
    }

    if (allowSidebar) {
        if (isTablet) {
            animationStyles = {
                ...animationStyles,
                left: showSidebar ? '0px' : `-${animationStyles.width}`,
            }
        } else {
            animationStyles = {
                ...animationStyles,
                left: '0px',
            }
        }
    } else {
        animationStyles = {
            ...animationStyles,
            left: `-${animationStyles.width}`,
        }
    }

    const animationProps = useSpring({
        ...animationStyles,
        config: {
            duration: 250,
        },
    })

    return <animated.div style={animationProps}>{children}</animated.div>
}

const Sidebar = ({ gssCode }) => {
    const {
        loggedIn,
        setActiveModal,
        setShowSidebar,
        allowSidebar,
        initialData,
        isTablet,
    } = useContext(UiContext)

    const alert = useAlert()

    const dispatch = useDispatch()

    const isSavedMap = useSelector(({ savedMap: { slug } }) => Boolean(slug))
    const isOwnSavedMap = useSelector(({ savedMap: { own } }) => own)
    const isSavedMapLoading = useSelector(
        ({ savedMap: { loading } }) => loading,
    )

    const overlay = useSelector(({ overlay }) => overlay)

    const { loaded } = useSelector(({ asyncInitialState }) => asyncInitialState)

    const exportButtonRef = useRef()

    const exportDisabled =
        useSelector(({ filters, location }) => {
            const haveFilters = Object.values(filters)
                .map(({ rules }) => rules)
                .flat().length
            const initialLocation = isInitialLocation(
                location?.lat,
                location?.lng,
            )
            const exportEnabled = haveFilters || !initialLocation
            return !exportEnabled
        }) || isSavedMapLoading

    const tabs = {
        mapSettings: 0,
        results: 1,
        resultSummary: 2,
    }

    const [tabIndex, setTabIndex] = useState(tabs.mapSettings)

    // If an anonymous user views a saved map then we disable most UI elements
    const disabled = !loggedIn && isSavedMap

    // Show certain UI elements only for logged in users OR for anyone when viewing a saved map
    const loggedInOrSavedMap = loggedIn || isSavedMap

    // When the summary tab has been selected for the first time then load the data for it.
    // Subsequent updates will be handled by summaryMiddleware.
    // TODO: do this without global variables
    if (tabIndex === tabs.resultSummary) {
        window.shouldLoadSitesSummary = true
    }
    useEffect(() => {
        if (window.shouldLoadSitesSummary && !window.sitesSummaryFirstLoaded) {
            dispatch(fetchSitesSummary())
            window.sitesSummaryFirstLoaded = true
        }
    })

    const tabPanels = [
        <div tabkey="1" name="SEARCH">
            <div className={styles('sidebar__filters')}>
                <Location
                    className={styles('sidebar__location')}
                    disabled={disabled}
                    gssCodeOverride={gssCode}
                />
                {initialData &&
                    (loggedInOrSavedMap ? (
                        <FilterGroups disabled={disabled} />
                    ) : (
                        <SidebarFilterSelector />
                    ))}
            </div>
            {overlay.level && (
                <div className={styles('sidebar__overlay-groups')}>
                    <Heading heading="h3" text="Overlays" isSubHeading />
                    <OverlayGroup overlay={overlay} disabled={disabled} />
                </div>
            )}
            <div className={styles('sidebar__options')}>
                <div className={styles('sidebar__options-row')}>
                    {/*
                            Pinpointing location is currently didabled:
                            https://projects.torchbox.com/projects/ff-pitchfinder/tickets/522
                            import MarkerIcon from '../Icons/Marker'
                            const {
                                ...
                                pinpointingEpicentre,
                                setPinpointingEpicentre,
                            } = useContext(UiContext)
                            <IconButton
                                text="Pinpoint location"
                                icon={<MarkerIcon />}
                                className={styles('sidebar__options-button')}
                                handleClick={() => {
                                    setPinpointingEpicentre(
                                        !pinpointingEpicentre,
                                    )
                                }}
                            />
                            */}
                    <IconButton
                        text="View list of results"
                        icon={<ListIcon />}
                        className={styles('sidebar__options-button')}
                        handleClick={() => {
                            setTabIndex(tabs.results)
                        }}
                    />
                    {!loggedInOrSavedMap && (
                        <IconButton
                            text="Share"
                            icon={<ShareIcon />}
                            className={styles('sidebar__options-button')}
                            handleClick={() => {
                                setActiveModal(ACTIVE_MODAL_SHARE_MAP)
                            }}
                        />
                    )}
                    {!overlay.level && (
                        <IconButton
                            text="Add overlay"
                            icon={<OverlayIcon />}
                            className={styles('sidebar__options-button')}
                            handleClick={() => {
                                setActiveModal(ACTIVE_MODAL_OVERLAY_SELECTOR)
                            }}
                        />
                    )}
                </div>

                <div className={styles('sidebar__options-row')}>
                    {loggedInOrSavedMap && (
                        <BoundariesSelector
                            className={styles('sidebar__checkbox-select')}
                        />
                    )}
                </div>
                <div className={styles('sidebar__options-row')}>
                    <OpenGreenSpacesSelector
                        className={styles('sidebar__checkbox-select')}
                    />
                </div>
            </div>
            {loggedInOrSavedMap && (
                <div
                    className={styles(
                        'sidebar__fixed-button',
                        'sidebar__fixed-button--map-settings',
                    )}
                >
                    <div className={styles('sidebar__options-buttons')}>
                        {isSavedMap && loggedIn && isOwnSavedMap && !gssCode && (
                            <IconButton
                                isSquare
                                text="Update"
                                icon={<StarIcon />}
                                disabled={isSavedMapLoading}
                                handleClick={() => {
                                    setActiveModal(ACTIVE_MODAL_RENAME_MAP)
                                }}
                            />
                        )}
                        {loggedIn && (
                            <IconButton
                                isSquare
                                text={isSavedMap ? 'Save new' : 'Save'}
                                icon={<StarIcon />}
                                disabled={isSavedMapLoading}
                                handleClick={() => {
                                    setActiveModal(ACTIVE_MODAL_SAVE_MAP)
                                }}
                            />
                        )}

                        {!loggedIn && (
                            <IconButton
                                isSquare
                                text="Share"
                                icon={<ShareIcon />}
                                disabled={isSavedMapLoading}
                                handleClick={() => {
                                    setActiveModal(ACTIVE_MODAL_SHARE_MAP)
                                }}
                            />
                        )}

                        <IconButton
                            isSquare
                            text="Export"
                            icon={<DownloadIcon />}
                            disabled={exportDisabled}
                            title={
                                exportDisabled
                                    ? 'Search for a location or add some filters first'
                                    : null
                            }
                            buttonRef={exportButtonRef}
                            handleClick={() => {
                                alert.current.info(
                                    'Please wait while the Excel file export is being generated',
                                )
                                exportButtonRef.current.setAttribute(
                                    'disabled',
                                    'disabled',
                                )
                                setTimeout(() => {
                                    if (
                                        !exportDisabled &&
                                        exportButtonRef.current
                                    ) {
                                        exportButtonRef.current.removeAttribute(
                                            'disabled',
                                        )
                                    }
                                }, 5000)
                                dispatch(exportSites('xls'))
                            }}
                        />
                    </div>
                </div>
            )}

            {!loggedInOrSavedMap && isTablet && (
                <div className={styles('sidebar__fixed-button')}>
                    <IconButton
                        text="Show on map"
                        icon={<MapIcon />}
                        handleClick={() => {
                            setShowSidebar(false)
                        }}
                    />
                </div>
            )}
        </div>,
        <div tabkey="2" name="SITES">
            <ResultsList className={styles('sidebar__results-list')} />
            <div className={styles('sidebar__fixed-button')}>
                <IconButton
                    text="Filter results"
                    icon={<FilterIcon />}
                    className={styles('sidebar__options-button')}
                    handleClick={() => {
                        setTabIndex(tabs.mapSettings)
                    }}
                />
            </div>
        </div>,
        loggedInOrSavedMap && (
            <div tabkey="3" name="SUMMARY">
                <Summary className={styles('sidebar__summary')} />
                <div className={styles('sidebar__fixed-button')}>
                    <IconButton
                        text="Filter results"
                        icon={<FilterIcon />}
                        className={styles('sidebar__options-button')}
                        handleClick={() => {
                            setTabIndex(tabs.mapSettings)
                        }}
                    />
                </div>
            </div>
        ),
    ].filter(Boolean)

    return (
        <SidebarSpringWrapper>
            {allowSidebar && (
                <div className={styles('sidebar')}>
                    <div className={styles('sidebar__body')}>
                        {loaded ? (
                            <Tabs
                                className={styles('sidebar__tabs')}
                                panelClassName={styles('sidebar__tabs-panel')}
                                controlledTabIndex={tabIndex}
                                updateControlledTabIndex={setTabIndex}
                                tabPanels={tabPanels}
                            />
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
            )}
        </SidebarSpringWrapper>
    )
}

export default Sidebar
