import React from 'react'
import { Helmet } from 'react-helmet'
import classnames from 'classnames/bind'
import css from './SiteInfo.module.scss'
import { colors } from '../../consts/styleVariables'
import Heading from '../Heading/Heading'
import BackIcon from '../Icons/Back'
import Boxout from '../Boxout/Boxout'
import NoIcon from '../Icons/No'

import { getFormattedAddress } from './SiteInfoUtils'
import SiteInfoOverview from './SiteInfoOverview'
import SiteInfoContact from './SiteInfoContact'
import SiteInfoFacilities from './SiteInfoFacilities'
import SiteInfoGrants from './SiteInfoGrants'
import SiteInfoRatings from './SiteInfoRatings'
import SiteInfoDisability from './SiteInfoDisability'
import SiteInfoArea from './SiteInfoArea'

const styles = classnames.bind(css)

const SiteInfo = ({ site, handleClose, uri }) => {
    const nonOperational = site?.overview?.operational === false

    return (
        <div className={styles('site-information')}>
            <Helmet>
                <title>
                    {site.siteName} - Site Profile | Football Foundation
                </title>
                <link
                    rel="canonical"
                    href={`${window.location.origin}${uri}/`}
                />
                {site.address && (
                    <meta
                        name="description"
                        content={`Explore the facilities and find contact information for ${
                            site.siteName
                        }, ${getFormattedAddress(site.address)}.`}
                    />
                )}
            </Helmet>
            <div className={styles('site-information__header')}>
                <div className={styles('site-information__header-inner')}>
                    <button //eslint-disable-line
                        className={styles('site-information__back')}
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <BackIcon
                            className={styles('site-information__back-icon')}
                        />
                        Back to full map
                    </button>
                    {nonOperational ? (
                        <NoIcon
                            fill={colors.red}
                            className={styles('site-information__header-icon')}
                        />
                    ) : null}
                    <Heading
                        text={site.siteName}
                        className={styles('site-information__header-heading', {
                            'non-operational': nonOperational,
                        })}
                    />
                    {site.address && (
                        <address>{getFormattedAddress(site.address)}</address>
                    )}
                </div>
            </div>
            <div className={styles('site-information__inner')}>
                <div className={styles('site-information__row')}>
                    {/* OVERVIEW */}
                    {site.overview && (
                        <SiteInfoOverview
                            overview={site.overview}
                            siteId={site.siteId}
                        />
                    )}

                    {/* CONTACT */}
                    {site.primaryContact && (
                        <SiteInfoContact
                            contactInfo={site.primaryContact}
                            address={site.address}
                        />
                    )}
                </div>
                {/* FACILITIES */}
                {site.facilities && (
                    <div className={styles('site-information__row')}>
                        <Boxout
                            title="Facilities"
                            className={styles('site-information__boxout')}
                            zeroBottomPadding
                        >
                            <SiteInfoFacilities
                                facilityValues={site.facilities}
                                overview={site.overview}
                            />
                        </Boxout>
                    </div>
                )}
                {/* GRANTS */}
                {site.grantSummaries && (
                    <SiteInfoGrants grants={site.grantSummaries} />
                )}

                {/* STATISTICS */}
                {site.ratings && <SiteInfoRatings ratings={site.ratings} />}

                {/* DISABILITY */}
                {site.overview?.disabilityAccess ? (
                    <div className={styles('site-information__row')}>
                        <SiteInfoDisability site={site} />
                    </div>
                ) : null}

                <div className={styles('site-information__row')}>
                    {/* ABOUT THE AREA */}
                    {site.area && <SiteInfoArea area={site.area} />}
                </div>
            </div>
        </div>
    )
}

export default SiteInfo
